/**
 * @generated SignedSource<<3656db827cdca3ce78b1d222a9bda375>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
export type CancelSubscriptionInput = {
  reason?: string | null;
  subscriptionId: string;
};
export type CancelSubscriptionModalMutation$variables = {
  input: CancelSubscriptionInput;
};
export type CancelSubscriptionModalMutation$data = {
  readonly cancelSubscription: {
    readonly subscription: {
      readonly isCancelling: boolean;
      readonly status: SubscriptionStatus;
    };
  };
};
export type CancelSubscriptionModalMutation = {
  response: CancelSubscriptionModalMutation$data;
  variables: CancelSubscriptionModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelling",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelSubscriptionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSubscriptionOutput",
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelSubscriptionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSubscriptionOutput",
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19d5101fab61176313208402aa28cb4c",
    "id": null,
    "metadata": {},
    "name": "CancelSubscriptionModalMutation",
    "operationKind": "mutation",
    "text": "mutation CancelSubscriptionModalMutation(\n  $input: CancelSubscriptionInput!\n) {\n  cancelSubscription(input: $input) {\n    subscription {\n      status\n      isCancelling\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3de7c8629d3174bfadcf45893780ac9b";

export default node;
