/**
 * @generated SignedSource<<1926bc0a6ca0e6d298a9e715efc7a037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OIDCSection_ClientJwks_application$data = {
  readonly clientJwks: {
    readonly __typename: "ApplicationJwksStatic";
    readonly jwks: string;
  } | {
    readonly __typename: "ApplicationJwksUrl";
    readonly url: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "OIDCSection_ClientJwks_application";
};
export type OIDCSection_ClientJwks_application$key = {
  readonly " $data"?: OIDCSection_ClientJwks_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"OIDCSection_ClientJwks_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OIDCSection_ClientJwks_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "clientJwks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "type": "ApplicationJwksUrl",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jwks",
              "storageKey": null
            }
          ],
          "type": "ApplicationJwksStatic",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VerifyApplication",
  "abstractKey": null
};

(node as any).hash = "d92add55b8b28607d7e8300ae2945f0a";

export default node;
