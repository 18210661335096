/**
 * @generated SignedSource<<d6017f4e29b626913132b5f8f3b05298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomDomain_DKMitID_identityProvider$data = {
  readonly customDomain: {
    readonly __typename: "CompletedDanishMitIDCustomDomain";
    readonly domainName: string;
  } | {
    readonly __typename: "PendingDanishMitIDCustomDomain";
    readonly cname: {
      readonly actual: string | null;
      readonly expected: string;
      readonly matches: boolean;
    };
    readonly domainName: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "CustomDomain_DKMitID_identityProvider";
};
export type CustomDomain_DKMitID_identityProvider$key = {
  readonly " $data"?: CustomDomain_DKMitID_identityProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomDomain_DKMitID_identityProvider">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomDomain_DKMitID_identityProvider",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "customDomain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainAssertion",
              "kind": "LinkedField",
              "name": "cname",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actual",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "matches",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "PendingDanishMitIDCustomDomain",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "CompletedDanishMitIDCustomDomain",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DanishMitID",
  "abstractKey": null
};
})();

(node as any).hash = "4644ee28eb8d1beac28d7d7198228a58";

export default node;
