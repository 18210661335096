import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { ItsmeProviderQuery } from './__generated__/ItsmeProviderQuery.graphql';
import { ItsmeProviderMutation } from './__generated__/ItsmeProviderMutation.graphql';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}

export interface FormValues {
  clientId : string
  serviceCode : string
  requestExtraIdData : boolean
  requestSecurityData : boolean
}

export function ItsmeProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<ItsmeProviderQuery>(
    graphql`
      query ItsmeProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on ItsME {
            clientId
            serviceCode
            requestExtraIdData
            requestSecurityData
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|ITSME`),
    tenantId: tenant.relayId,
    environment: props.environment
  });


  const [{executePromise}] = useMutation<ItsmeProviderMutation>(graphql`
    mutation ItsmeProviderMutation(
      $input: UpdateItsMEInput!
    ) {
      updateItsME(input: $input) {
        id
        isConfigured
        ... on ItsME {
          clientId
          serviceCode
          requestExtraIdData
          requestSecurityData
        }
      }
    }
  `);


  if (!data.identityProvider) return null;

  const initialValues : FormValues = {
    clientId: data.identityProvider?.clientId ?? '',
    serviceCode: data.identityProvider?.serviceCode ?? '',
    requestExtraIdData: data.identityProvider?.requestExtraIdData || false,
    requestSecurityData: data.identityProvider?.requestSecurityData || false
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, isSuccess, error}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p>⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_ITSME_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          {!initialValues.clientId ? (
            <EnvironmentDialog environment="PRODUCTION" heading={(
              <React.Fragment>
                <h1>
                  <strong>{translate('INFO_ITSME_CLIENT')}</strong>
                </h1>
                <br />
                <h3>{translate('INFO_ITSME_URLS_PROD')}</h3>
              </React.Fragment>
            )}>
              {domains?.map(domain => (
                <p key={domain.name}>
                  <div>
                    <i>{environment === 'PRODUCTION' ? 'Production domain' : 'Test domain'}</i>&nbsp;<strong>{domain.name}</strong>
                  </div>
                  <div>
                    <div>
                      <i>{translate('INFO_ITSME_REDIRECT_URI')}</i>
                    </div>
                    https://{domain.name}/Itsme/Callback
                  </div>
                  <div>
                    <div>
                      <i>{translate('INFO_ITSME_JWKSET_URL')}</i>
                    </div>
                    https://{domain.name}/.well-known/client-jwks
                  </div>
                </p>
              ))}
            </EnvironmentDialog>
          ) : null}
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Itsme Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_ITSME_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_ITSME_CLIENTID')}
              />
              <InputField
                type="text"
                label={translate('LABEL_ITSME_SERVICECODE')}
                name="serviceCode"
                placeholder={translate('LABEL_ITSME_SERVICECODE')}
              />

              <Switch name="requestExtraIdData" label={translate('LABEL_ITSME_REQUEST_EXTRAIDDATA')} />
              <Switch name="requestSecurityData" label={translate('LABEL_ITSME_REQUEST_SECURITYDATA')} />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your itsme settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_ITSME_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_ITSME_URLS_PROD': 'INFO_ITSME_URLS_TEST')}</p>
              {domains?.map(domain => (
                <p key={domain.name}>
                  <i>{environment === 'PRODUCTION' ? 'Production domain' : 'Test domain'}</i>&nbsp;<strong>{domain.name}</strong>
                  <br />
                  <i>{translate('INFO_ITSME_REDIRECT_URI')}</i>
                  <br />
                   https://{domain.name}/Itsme/Callback
                  <br />
                  <i>{translate('INFO_ITSME_JWKSET_URL')}</i><br />
                  https://{domain.name}/.well-known/client-jwks
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}