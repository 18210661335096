/**
 * @generated SignedSource<<787bbdaf1d229c646de6ffb98f9fdfa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type DomainsScreenCreateQuery$variables = {
  environment: Environment;
  id: string;
};
export type DomainsScreenCreateQuery$data = {
  readonly tenant: {
    readonly features: {
      readonly CUSTOM_DOMAINS: boolean;
    };
    readonly viewerPermissions: {
      readonly domains: Permission;
    };
  } | null;
};
export type DomainsScreenCreateQuery = {
  response: DomainsScreenCreateQuery$data;
  variables: DomainsScreenCreateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "CUSTOM_DOMAINS",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainsScreenCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DomainsScreenCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b02d0384845293ca89dad7d0b747de1",
    "id": null,
    "metadata": {},
    "name": "DomainsScreenCreateQuery",
    "operationKind": "query",
    "text": "query DomainsScreenCreateQuery(\n  $id: ID!\n  $environment: Environment!\n) {\n  tenant(id: $id) {\n    viewerPermissions {\n      domains(environment: $environment)\n    }\n    features {\n      CUSTOM_DOMAINS(environment: $environment)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c79baa00f113bc0e5c49922da3d56f01";

export default node;
