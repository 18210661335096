/**
 * @generated SignedSource<<7bd92deb38967d0580f6f0c0842f0b27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateNorwegianBankIDCServerInput = {
  certificate: string;
  environment: Environment;
  password?: string | null;
  tenantId: string;
};
export type NOBankIDOldProviderMutation$variables = {
  input: UpdateNorwegianBankIDCServerInput;
};
export type NOBankIDOldProviderMutation$data = {
  readonly updateNorwegianBankIDCserver: {
    readonly hasCertificate: boolean;
    readonly id: string;
    readonly isConfigured: boolean;
  };
};
export type NOBankIDOldProviderMutation = {
  response: NOBankIDOldProviderMutation$data;
  variables: NOBankIDOldProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NorwegianBankIDCServer",
    "kind": "LinkedField",
    "name": "updateNorwegianBankIDCserver",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasCertificate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NOBankIDOldProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NOBankIDOldProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1965d915198b9fde8541489adbc13cf9",
    "id": null,
    "metadata": {},
    "name": "NOBankIDOldProviderMutation",
    "operationKind": "mutation",
    "text": "mutation NOBankIDOldProviderMutation(\n  $input: UpdateNorwegianBankIDCServerInput!\n) {\n  updateNorwegianBankIDCserver(input: $input) {\n    id\n    isConfigured\n    hasCertificate\n  }\n}\n"
  }
};
})();

(node as any).hash = "52141be67886f5fed5f88628e2b80e41";

export default node;
