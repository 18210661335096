/**
 * Making our own sort of helmet component
 * instead of taking a dependency on react-helmet (less dependencies is better)
 *
 * Why even update the document.title in a single-page-app?
 * It makes the "history" easier to navigate if using the native browser tools (like right clicking the back button)
 */

import React, { useEffect } from 'react';

export type HelmetProps = {
  title: string | string[] | null
}

const suffix = 'Criipto Dashboard';
function maybeConcat(input: string | string[], seperator: string) {
  if (Array.isArray(input)) return input.join(seperator);
  return input;
}

export default function Helmet(props: HelmetProps) {
  useEffect(() => {
    const title = props.title ? `${maybeConcat(props.title, ' | ')} | ${suffix}` : suffix;
    if (document.title === title) return;
    document.title = title;
  }, [props.title]);

  return null;
};