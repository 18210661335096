// https://en.wikipedia.org/wiki/VAT_identification_number
// https://github.com/wbond/vat-moss.js/blob/master/vat-moss.js
// https://stripe.com/docs/billing/customer/tax-ids
type VAT_HELP = {
  [key: string]: {
    text: string,
    pattern: string
  }
}
export const VAT_HELP : VAT_HELP = {
  AT: {
    text: 'ATU99999999',
    pattern: 'ATU[0-9]{8}'
  },
  BE: {
    text: 'BE0123456789',
    pattern: 'BE(1|0?)[0-9]{9}'
  },
  BG: {
    text: '123456789',
    pattern: '[0-9]{9,10}'
  },
  CY: {
    text: 'CY12345678Z',
    pattern: 'CY[0-9]{8}[A-Z]'
  },
  CZ: {
    text: 'CZ1234567890',
    pattern: 'CZ[0-9]{8,10}'
  },
  DE: {
    text: 'DE123456789',
    pattern: 'DE[0-9]{9}'
  },
  DK: {
    text: 'DK12345678',
    pattern: 'DK[0-9]{8}'
  },
  EE: {
    text: 'EE123456789',
    pattern: 'EE[0-9]{9}'
  },
  ES: {
    text: 'ESA1234567Z',
    pattern: 'ES[A-Z0-9][0-9]{7}[A-Z0-9]'
  },
  FI: {
    text: 'FI12345678',
    pattern: 'FI[0-9]{8}'
  },
  FR: {
    text: 'FRAB123456789',
    pattern: 'FR[A-Z0-9]{2}[0-9]{9}'
  },
  GR: {
    text: 'EL123456789',
    pattern: 'EL[0-9]{9}'
  },
  HR: {
    text: 'HR12345678912',
    pattern: 'HR[0-9]{11}'
  },
  HU: {
    text: 'HU12345678',
    pattern: 'HU[0-9]{8}'
  },
  IE: {
    text: 'IE1234567AB',
    pattern: 'IE[0-9]{7}[A-Z]{1,2}'
  },
  IT: {
    text: 'IT12345678912',
    pattern: 'IT[0-9]{11}'
  },
  LT: {
    text: 'LT123456789123',
    pattern: 'LT([0-9]{9}|[0-9]{12})'
  },
  LU: {
    text: 'LU12345678',
    pattern: 'LU[0-9]{8}'
  },
  LV: {
    text: 'LV12345678912',
    pattern: 'LV[0-9]{11}'
  },
  MT: {
    text: 'MT12345678',
    pattern: 'MT[0-9]{8}'
  },
  NL: {
    text: 'NL123456789B12',
    pattern: 'NL[0-9]{9}B[0-9]{2}'
  },
  NO: {
    text: 'NO993101583MVA',
    pattern: 'NO[0-9]{9}MVA'
  },
  PL: {
    text: 'PL1234567890',
    pattern: 'PL[0-9]{10}'
  },
  PT: {
    text: 'PT123456789',
    pattern: 'PT[0-9]{9}'
  },
  RO: {
    text: 'RO1234567891',
    pattern: 'RO[0-9]{2,10}'
  },
  SE: {
    text: 'SE999999999901',
    pattern: 'SE[0-9]{12}'
  },
  SI: {
    text: 'SI12345678',
    pattern: 'SI[0-9]{8}'
  },
  SK: {
    text: 'SK1234567891',
    pattern: 'SK[0-9]{10}'
  },
}
