import React from 'react';
import {Application} from '@app/models';
import InputField, { BaseInputFieldProps } from '@app/components/FormFields/InputField/InputField';
import { translate } from '@app/i18n';

const ApplicationNameField = (props: BaseInputFieldProps) => (
  <InputField<Application>
    type="text"
    label={translate('LABEL_APP_NAME')}
    name="name"
    placeholder="My application"
    required
    helpText="The application name will be shown to your end users in certain corner cases."
    className={props.className}
  />
);
export default ApplicationNameField;
