/**
 * @generated SignedSource<<44d7ba1105daab9564841199fa03aa7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type UpdateBillableProductInput = {
  after: string;
  before: string;
  enabled: boolean;
  note?: string | null;
  product: Product;
  tenantId: string;
};
export type ProductBillableMutation$variables = {
  input: UpdateBillableProductInput;
};
export type ProductBillableMutation$data = {
  readonly admin_updateBillableProduct: {
    readonly tenant: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ProductBillable_tenant">;
    };
  };
};
export type ProductBillableMutation = {
  response: ProductBillableMutation$data;
  variables: ProductBillableMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductBillableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBillableProductOutput",
        "kind": "LinkedField",
        "name": "admin_updateBillableProduct",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProductBillable_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductBillableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBillableProductOutput",
        "kind": "LinkedField",
        "name": "admin_updateBillableProduct",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenantBillable",
                "kind": "LinkedField",
                "name": "billable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantBillableProduct",
                    "kind": "LinkedField",
                    "name": "Verify",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantBillableProduct",
                    "kind": "LinkedField",
                    "name": "Signatures",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantAgeverificationBillableProduct",
                    "kind": "LinkedField",
                    "name": "Ageverification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "dk",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78dcff40a2a7c0918b5e81c77228b585",
    "id": null,
    "metadata": {},
    "name": "ProductBillableMutation",
    "operationKind": "mutation",
    "text": "mutation ProductBillableMutation(\n  $input: UpdateBillableProductInput!\n) {\n  admin_updateBillableProduct(input: $input) {\n    tenant {\n      id\n      ...ProductBillable_tenant\n    }\n  }\n}\n\nfragment ProductBillable_tenant on AdminTenant {\n  id\n  billable {\n    Verify {\n      enabled\n      before\n      after\n      actual\n    }\n    Signatures {\n      enabled\n      before\n      after\n      actual\n    }\n    Ageverification {\n      dk {\n        enabled\n        before\n        after\n        actual\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68684e89c9badd63c883c6af842a5837";

export default node;
