/**
 * @generated SignedSource<<2d926ee63298dbb43febee1c1bc114e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VerifyApplicationCreator_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DomainField_tenant">;
  readonly " $fragmentType": "VerifyApplicationCreator_tenant";
};
export type VerifyApplicationCreator_tenant$key = {
  readonly " $data"?: VerifyApplicationCreator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerifyApplicationCreator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerifyApplicationCreator_tenant",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "FragmentSpread",
      "name": "DomainField_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "5effd3e9a721d9ced0d954884c5aa4c8";

export default node;
