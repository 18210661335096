import React from 'react';
import styles from './ConfigurationFooter.module.css';

export default function ConfigurationFooter(props: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.footer}>
      {props.children}
    </div>
  )
}