/**
 * @generated SignedSource<<aa88599063b895e54e0387dd4e026534>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationRealmValidation_application$data = {
  readonly id: string;
  readonly realm: string;
  readonly " $fragmentType": "ApplicationRealmValidation_application";
};
export type ApplicationRealmValidation_application$key = {
  readonly " $data"?: ApplicationRealmValidation_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationRealmValidation_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationRealmValidation_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realm",
      "storageKey": null
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "831cb62b16c573fab6ecdf757ee3d4dc";

export default node;
