import { createSlice, createAsyncThunk, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import {singleton as config} from '@app/config';

import {RootState, AppDispatch} from './store';
import { clearTokens } from './authSlice';

export interface ApiState {

};

export interface ApiRequestState {
  pending : boolean,
  success : boolean,
  error : Error | null
}

export const defaultApiRequestState : ApiRequestState = {
  // Not technically correct since the request has not been started yet
  // but for UI purposes we generally want to default to here.
  pending: true,
  success: false,
  error: null
};

export const pendingApiRequestState : ApiRequestState = {
  ...defaultApiRequestState,
  pending: true,
  success: false,
  error: null
};

export const successApiRequestState : ApiRequestState = {
  ...defaultApiRequestState,
  pending: false,
  success: true,
  error: null
};

export const errorApiRequestState = (error : Error) : ApiRequestState => {
  return {
    ...defaultApiRequestState,
    pending: false,
    success: false,
    error
  }
};

type Headers = {[key: string]: string};
export type VerifyGetRequest = {
  method: 'GET',
  url: string,
  headers?: Headers
};
export type VerifyPostRequest<T> = {
  method: 'POST',
  url: string,
  headers?: Headers,
  contentType: string,
  data: T
};
export type VerifyUploadRequest = {
  method: 'POST',
  url: string,
  data: FormData,
  headers?: Headers
}
export type VerifyEmptyPostRequest = {
  method: 'POST',
  url: string,
  headers?: Headers,
  contentType: undefined,
  accept?: string
};
export type VerifyPutRequest<T> = {
  method: 'PUT',
  url: string,
  headers?: Headers,
  contentType: string,
  data: T
}
export type VerifyEmptyPutRequest = {
  method: 'PUT',
  url: string,
  headers?: Headers,
  data: undefined
}
export type VerifyDeleteRequest = {
  method: 'DELETE',
  url: string,
  headers?: Headers
};
export type VerifyRequest<T = any> = VerifyGetRequest | VerifyPostRequest<T> | VerifyEmptyPostRequest | VerifyPutRequest<T> | VerifyEmptyPutRequest | VerifyDeleteRequest | VerifyUploadRequest;

function isVerifyGetRequest(request: VerifyRequest): request is VerifyGetRequest {
  return request.method === 'GET';
}
function isVerifyEmptyPostRequest<T>(request: VerifyRequest<T>): request is VerifyEmptyPostRequest {
  return request.method === 'POST' && (request as VerifyEmptyPostRequest).contentType == undefined;
}
function isVerifyPostRequest<T>(request: VerifyRequest<T>): request is VerifyPostRequest<T> {
  return request.method === 'POST' && (request as VerifyPostRequest<T>).contentType != undefined;
}
function isVerifyPutRequest<T>(request: VerifyRequest<T>): request is VerifyPutRequest<T> {
  return request.method === 'PUT' && request.data != undefined;
}
function isVerifyEmptyPutRequest<T>(request: VerifyRequest<T>): request is VerifyEmptyPutRequest {
  return request.method === 'PUT' && request.data == undefined;
}
function isVerifyDeleteRequest<T>(request: VerifyRequest<T>): request is VerifyDeleteRequest {
  return request.method === 'DELETE';
}
function isVerifyUploadRequest<T>(request: VerifyRequest<T>): request is VerifyUploadRequest {
  return request.method === 'POST' && (request as VerifyUploadRequest).data instanceof FormData;
}

export function valuesToFormData(input: {[key: string]: File | string}) {
  const formData = new FormData();
  Object.keys(input).forEach(key => {
    formData.append(key, input[key]);
  });

  return formData;
}

function tryParseJSON(input : string) {
  try {
    return JSON.parse(input);
  } catch (err) {
    return null;
  }
}

export type dispatchVerifyRequestMock<T> = (dispatch: any, request: VerifyRequest, transform: (data: any) => T) => Promise<T>;
export function dispatchVerifyRequest<T>(dispatch: AppDispatch, request: VerifyRequest, transform: (data: any) => T) {
  return dispatch(verifyRequest(request)).then(unwrapResult).then(transform);
}

export const verifyRequest = createAsyncThunk<object | undefined, VerifyRequest, {state: RootState, dispatch: AppDispatch}>(
  'api/verifyRequest',
  async (request, thunkAPI) => {
    const state = thunkAPI.getState();
    const headers : Headers = {
      ...request.headers,
      ...state.auth?.access_token ? {
        Authorization: `Bearer ${state.auth.access_token}`
      } : {}
    };

    let url = request.url;
    if (config.apiBase && !url.startsWith(config.apiBase)) {
      url = `${config.apiBase}${url}`
    }

    let response;
    if (isVerifyGetRequest(request)) {
      response = await fetch(url, {
        method: 'GET',
        headers: {
          ...headers
        }
      });
    } else if (isVerifyUploadRequest(request)) {
      response = await fetch(url, {
        method: 'POST',
        headers: {
          ...headers
        },
        body: request.data
      });
    } else if (isVerifyPostRequest(request)) {
      response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(request.data),
        headers: {
          ...headers,
          "Content-Type": request.contentType
        }
      });
    } else if (isVerifyEmptyPostRequest(request)) {
      response = await fetch(url, {
        method: 'POST',
        headers: {
          ...headers,
          ...(request.accept ? {
            "Accept": request.accept
          } : {})
        }
      });
    } else if (isVerifyPutRequest(request)) {
      response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(request.data),
        headers: {
          ...headers,
          "Content-Type": request.contentType
        }
      });
    } else if (isVerifyEmptyPutRequest(request)) {
      response = await fetch(url, {
        method: 'PUT',
        headers: {
          ...headers
        }
      });
    } else if (isVerifyDeleteRequest(request)) {
      response = await fetch(url, {
        method: 'DELETE',
        headers: {
          ...headers
        }
      });
    }

    if (response) {
      if (response.status === 401) {
        thunkAPI.dispatch(clearTokens());
        return undefined;
      }

      if (!response.ok) {
        const text = await response.text();
        const json = tryParseJSON(text);
        throw new Error(json?.message ?? text);
      }

      if (response.status === 204) {
        return undefined;
      }

      const data = await response.json();
      return data;
    }
    return undefined;
  }
);

export const initialState: ApiState = {};
export const ApiSlice = createSlice({
  name: 'api',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {

  }
})

export default ApiSlice;