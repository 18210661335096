import React from 'react';
import styles from './TagGroup.module.css';

export interface TagGroupProps {
  header?: string;
  children: React.ReactNode;
}

export default function TagGroup({ header, children }: TagGroupProps) {
  const shouldRenderChildren = React.Children.count(children) > 0;

  const TagGroup = (
    <>
      <h2 className={styles['taggroup-header']}>{header}</h2>
      {shouldRenderChildren &&
        <div className={styles['tag-group']}>
          <ul>{children}</ul>
        </div>
      }
    </>
  );

  return TagGroup;
}
