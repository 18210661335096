/**
 * @generated SignedSource<<b52fda6d8f162875387b1c6d4bb95c34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type PaymentIntentStatus = "canceled" | "processing" | "requires_action" | "requires_capture" | "requires_confirmation" | "requires_payment_method" | "succeeded" | "%future added value";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
export type UpdatePaymentMethodInput = {
  name: string;
  stripePaymentMethod?: string | null;
  stripePaymentToken?: string | null;
  tenantId: string;
};
export type PaymentMethodModal_UpdateMutation$variables = {
  input: UpdatePaymentMethodInput;
};
export type PaymentMethodModal_UpdateMutation$data = {
  readonly updatePaymentMethod: {
    readonly billing: {
      readonly paymentMethod: {
        readonly brand: string;
        readonly last4: string;
        readonly name: string;
      } | null;
      readonly subscription: {
        readonly status: SubscriptionStatus;
      } | null;
    };
    readonly paymentIntent: {
      readonly id: string;
      readonly secret: string;
      readonly status: PaymentIntentStatus;
      readonly subscriptionId: string | null;
    } | null;
    readonly tenant: {
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  };
};
export type PaymentMethodModal_UpdateMutation = {
  response: PaymentMethodModal_UpdateMutation$data;
  variables: PaymentMethodModal_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v3 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v4 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "kind": "LinkedField",
  "name": "paymentMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last4",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentIntent",
  "kind": "LinkedField",
  "name": "paymentIntent",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secret",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentMethodModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePaymentMethodOutput",
        "kind": "LinkedField",
        "name": "updatePaymentMethod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Billing",
            "kind": "LinkedField",
            "name": "billing",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentMethodModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePaymentMethodOutput",
        "kind": "LinkedField",
        "name": "updatePaymentMethod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Billing",
            "kind": "LinkedField",
            "name": "billing",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60904774791692c53e5e452538474a24",
    "id": null,
    "metadata": {},
    "name": "PaymentMethodModal_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentMethodModal_UpdateMutation(\n  $input: UpdatePaymentMethodInput!\n) {\n  updatePaymentMethod(input: $input) {\n    tenant {\n      environments\n      verifyEnvironments: environments(product: Verify)\n      signaturesEnvironments: environments(product: Signatures)\n      id\n    }\n    billing {\n      paymentMethod {\n        name\n        brand\n        last4\n      }\n      subscription {\n        status\n        id\n      }\n      id\n    }\n    paymentIntent {\n      id\n      status\n      secret\n      subscriptionId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba4e442c41aa58ad7b21520b35c99aa6";

export default node;
