import { OAUTH2_SCOPE } from '@app/constants';
import { TenantRouteParams } from '@app/models';
import { useSelector } from '@app/redux';
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

export default function OrganizationTenantLoginScreen() {
  const params = useParams<TenantRouteParams>();
  const scope = `organization_tenant:${params.tenantId}`;
  const authClient = useSelector(state => state.auth.client);

  useEffect(() => {
    authClient.loginWithRedirect({
      scope: `${OAUTH2_SCOPE} ${scope}`,
      appState: {
        returnTo: `/tenant/${params.tenantId}`
      }
    });
  }, [authClient]);

  return (
    <i className="fa fa-spinner fa-pulse fa-2x" />
  );
}