import React from 'react';
import useEnvironment from '@app/hooks/useEnvironment';
import { useTenantId } from '@app/hooks/useTenant';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { TenantCriiptoAdminScreenQuery } from './__generated__/TenantCriiptoAdminScreenQuery.graphql';
import IdpOrderToggle from './components/IdpOrderToggle';
import EnvironmentFeatureToggle from './components/EnvironmentFeatureToggle';
import ProductBillable from './components/ProductBillable';
import { NavLink } from 'react-router-dom';
import AddOrganizationTenantButton from '../CriiptoAdminScreen/components/AddOrganizationTenantButton';
import RemoveOrganizationTenantButton from '../CriiptoAdminScreen/components/RemoveOrganizationTenantButton';
import CopyValueButton from '@app/components/CopyValueButton/CopyValueButton';
import FeatureToggle from './components/FeatureToggle';

export default function TenantCriiptoAdminScreen() {
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<TenantCriiptoAdminScreenQuery>(
    graphql`
      query TenantCriiptoAdminScreenQuery($id: ID!) {
        admin {
          ...AddOrganizationTenantButton_admin
          tenant(id: $id) {
            stripe {
              customerId
            }

            organization {
              id
              organization {
                name
              }
            }

            tableStorageKey

            tenant {
              entityIdentifier
              longTenantId
              ...IdpOrderToggle_tenant
              ...FeatureToggle_tenant
            }

            ...AddOrganizationTenantButton_tenant
            ...RemoveOrganizationTenantButton_tenant
            ...EnvironmentFeatureToggle_tenant
            ...ProductBillable_tenant
          }
        }
      }
    `
  , {
    id: tenantId.relayId
  });

  if (!data.admin.tenant) return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label">Entity ID</label>
            <div className="flex flex-row">
              <input className="form-control" readOnly={true} defaultValue={data.admin.tenant.tenant.entityIdentifier} />
              <CopyValueButton variant="default" value={data.admin.tenant.tenant.entityIdentifier} tooltip="Copy to clipboard" className="!mt-0 button-medium" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label">Tenant ID</label>
            <div className="flex flex-row">
              <input className="form-control" readOnly={true} defaultValue={data.admin.tenant.tenant.longTenantId} />
              <CopyValueButton variant="default" value={data.admin.tenant.tenant.longTenantId} tooltip="Copy to clipboard" className="!mt-0 button-medium" />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label">Table storage key</label>
            <div className="flex flex-row">
              <input className="form-control" readOnly={true} defaultValue={data.admin.tenant.tableStorageKey} />
              <CopyValueButton variant="default" value={data.admin.tenant.tableStorageKey} tooltip="Copy to clipboard" className="!mt-0 button-medium" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label"><a href={data.admin.tenant.stripe ? `https://manage.stripe.com/customers/${data.admin.tenant.stripe.customerId}` : undefined} target="_blank" rel="noopener noreferrer">Stripe customer ID</a></label>
            <div className="flex flex-row">
              <input className="form-control" readOnly={true} defaultValue={data.admin.tenant.stripe?.customerId ?? ''} />
              <CopyValueButton variant="default" value={data.admin.tenant.stripe?.customerId ?? ''} tooltip="Copy to clipboard" className="!mt-0 button-medium" />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            {data.admin.tenant.organization ? (
              <React.Fragment>
                <label className="control-label">
                  <NavLink to={`/admin/organizations/${data.admin.tenant.organization.id}`}>Organization</NavLink>
                </label>
                <div className="flex flex-row gap-[8px] items-center">
                  <NavLink to={`/admin/organizations/${data.admin.tenant.organization.id}`}>{data.admin.tenant.organization.organization.name}</NavLink>
                  <RemoveOrganizationTenantButton tenant={data.admin.tenant} />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label className="control-label">Organization</label>
                <AddOrganizationTenantButton admin={data.admin} tenant={data.admin.tenant} organization={null} />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <ProductBillable tenant={data.admin.tenant} product="Verify" />
        </div>
        <div className="col-md-6 col-xs-12">
          <ProductBillable tenant={data.admin.tenant} product="Signatures" />
        </div>
      </div>
      <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <ProductBillable tenant={data.admin.tenant} product="Ageverification_DK" />
        </div>
      </div>

      <h3 className={`text-environment-${environment} mt-16`}>Environment specific - {environment === 'PRODUCTION' ? 'PRODUCTION' : 'TEST'}</h3>
      <div className="row" style={{marginTop: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <EnvironmentFeatureToggle environment={environment} feature="DK_PocesAddress" tenant={data.admin.tenant} />
        </div>
        <div className="col-md-6 col-xs-12">
          <EnvironmentFeatureToggle environment={environment} feature="FREE_CUSTOM_DOMAINS" tenant={data.admin.tenant} />
        </div>
        <div className="col-md-6 col-xs-12">
          <EnvironmentFeatureToggle environment={environment} feature="EXTENSIONS" tenant={data.admin.tenant} />
        </div>
      </div>

      <h3 className={`mt-16`}>Global</h3>
      <div className="row" style={{marginTop: '15px'}}>
        <div className="col-md-6 col-xs-12"><IdpOrderToggle idp="SEBANKID" tenant={data.admin.tenant.tenant} /></div>
        <div className="col-md-6 col-xs-12"><IdpOrderToggle idp="NOBANKID" tenant={data.admin.tenant.tenant} /></div>
        <div className="col-md-6 col-xs-12"><IdpOrderToggle idp="DKMITID_CUSTOM_DOMAIN" tenant={data.admin.tenant.tenant} /></div>
        <div className="col-md-6 col-xs-12"><FeatureToggle feature="FRENCH_PUBLIK" tenant={data.admin.tenant.tenant} /></div>
      </div>
    </div>
  );
}