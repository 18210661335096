import {useState} from 'react';
import { GraphQLTaggedNode, useMutation as relayUseMutation, UseMutationConfig } from "react-relay";
import { MutationParameters, VariablesOf } from 'relay-runtime';
import useMounted from './useMounted';

type Executor<T extends MutationParameters> = {
  execute: (variables : VariablesOf<T>) => void
  executePromise: (variables : VariablesOf<T>) => Promise<T["response"]>;
}
export type State<T extends MutationParameters> = {
  pending: boolean
  error: Error | null
  success: boolean
  variables: T["variables"] | null
  response: T["response"] | null
}

type Config<T extends MutationParameters> = {
  updater: UseMutationConfig<T>["updater"]
}

export default function useMutation<T extends MutationParameters>(query : GraphQLTaggedNode, config?: Config<T>) : [Executor<T>, State<T>] {
  const isMounted = useMounted();
  const [commit, inFlight] = relayUseMutation<T>(query);
  const [error, setError] = useState<State<T>["error"]>(null);
  const [success, setSuccess] = useState<State<T>["success"]>(false);
  const [response, setResponse] = useState<State<T>["response"]>(null);
  const [variables, setVariables] = useState<State<T>["variables"]>(null);

  const executePromise = (variables : VariablesOf<T>) => {
    setVariables(variables);
    return new Promise<T["response"]>((resolve, reject) => {
      commit({
        ...config,
        variables,
        onError: (error) => {
          if (isMounted.current) {
            setError(error);
            setSuccess(false);
            setResponse(null);
          }
          reject(error);
        },
        onCompleted: (response, errors) => {
          if (errors?.length) {
            const error = new Error(errors.map(error => error.message).join('\n'));
            if (isMounted.current) {
              setError(error);
              setSuccess(false);
            }
            reject(error);
            return;
          }

          if (isMounted.current) {
            setError(null);
            setSuccess(true);
            setResponse(response);
          }
          resolve(response);
        }
      });
    });
  };

  const execute = (variables : VariablesOf<T>) => {
    executePromise(variables).catch(() => null);
  };

  return [{execute, executePromise}, {pending: inFlight, error, success, response, variables}];
}