import React from 'react';
import {singleton as config} from '@app/config';
import {AnchorButton, LinkButton} from '@components/Button';

import styles from './OnboardingScreen.module.css';
import cx from 'classnames';
import Panel from '@components/Panel';
import useTestLoginApplication from './hooks/useTestLoginApplication';
import DanishMitIDTestLoginPanel from './components/DanishMitIDTestLoginPanel';
import { useTenantId } from '@app/hooks/useTenant';
import { TENANT_ID_PREFIX } from '@app/constants';
import { authorizeUrl } from '@app/helpers';

export default function OnboardingScreenLogin() {
  const application = useTestLoginApplication();
  const {tenantId} = useTenantId();

  if (tenantId === undefined) {
    return (
      <div className={cx('container', styles['onboarding-screen'], 'text-center')}>
        <h2>Hold on a second ...</h2>
      </div>
    );
  }

  return (
    <div className={cx('container', styles['onboarding-screen'])}>
      <h2 className='text-center'>Perform your first e-ID login</h2>
      <div className={styles['panels']}>
        {config.enableOnboardingMitID && (
          <DanishMitIDTestLoginPanel application={application} title="Danish MitID" />
        )}
        <Panel title="Norwegian BankID">
          <div>
            <p>
              Will open in a new browser tab.
            </p>
            <p>
              <a href="https://docs.criipto.com/verify/e-ids/norwegian-bankid/#test-users" target="_blank" rel="noopener noreferrer">Generate your own test-user</a>
            </p>
          </div>
          <AnchorButton variant="primary" target="_blank" rel="noopener noreferrer" href={authorizeUrl(application, 'urn:grn:authn:no:bankid').href}>
            Login with Norwegian BankID
          </AnchorButton>
        </Panel>
        <Panel title="Finnish Trust Network">
          <div>
            <p>
              Will open in a new browser tab.
            </p>
            <p>
              Select a bank (like Aktia) and follow the steps on the screen using the prefilled values.
            </p>
          </div>
          <AnchorButton variant="primary" target="_blank" rel="noopener noreferrer" href={authorizeUrl(application, 'urn:grn:authn:fi:bank-id').href}>
            Login with Finnish Trust Network
          </AnchorButton>
        </Panel>

      </div>

      <h2 className='text-center'>Get started with signatures</h2>
      <div className={styles['panels']}>
        <Panel title="Register signatures application">
          <div>
            <p>
              Criipto Signatures supports signing PDF documents (PAdES-compliant) with all e-IDs supported by Criipto Verify.
            </p>
            <p>
              To get started please create a signature application to retrieve your test credentials.
            </p>
          </div>
          <LinkButton variant="primary" to={`/tenant/${tenantId}/applications/add?tags=signatures`}>
            Create signatures application
          </LinkButton>
        </Panel>
        <Panel title="Signatures documentation">
          <div>
            <p>
              Criipto Signatures offers a GraphQL API to enable you to build unique signature workflows that fit your exact application needs.
            </p>
          </div>
          <AnchorButton variant="primary" target="_blank" rel="noopener noreferrer" href={`https://docs.criipto.com/signatures`}>
            Criipto Signatures documentation
          </AnchorButton>
        </Panel>
      </div>
    </div>
  )
}