/**
 * @generated SignedSource<<9a89d090a374597daa70159570013f6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgeverificationApplicationCreator_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DomainField_tenant">;
  readonly " $fragmentType": "AgeverificationApplicationCreator_tenant";
};
export type AgeverificationApplicationCreator_tenant$key = {
  readonly " $data"?: AgeverificationApplicationCreator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgeverificationApplicationCreator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgeverificationApplicationCreator_tenant",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "FragmentSpread",
      "name": "DomainField_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "a02ac1b46128adb7684ace6b4c3524ea";

export default node;
