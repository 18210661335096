/**
 * @generated SignedSource<<793b17a106f22d1d325984b846e61e4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "Admin" | "Developer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UsersScreen_user$data = {
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly role: UserRole | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditUserButton_user" | "RemoveUserButton_user">;
  readonly " $fragmentType": "UsersScreen_user";
};
export type UsersScreen_user$key = {
  readonly " $data"?: UsersScreen_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersScreen_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersScreen_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveUserButton_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditUserButton_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bb256a3949e5c0058a0acdc91217a332";

export default node;
