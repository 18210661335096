import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSlackModal } from '@app/redux/modalsSlice';
import Modal from '../Modal/Modal';
import { AnchorButton } from '@components/Button';
import { RootState } from '@app/redux/store';
import { singleton as config } from '@app/config';

export function SlackModal() {
  const dispatch = useDispatch();
  const showModal = useSelector((state: RootState) => state.modals.showSlackModal);
  const toggleModal = () => {
    dispatch(toggleSlackModal());
  };

  return (
    <Modal open={showModal} onHide={toggleModal}>
      <Modal.Header>
        <h2>Slack support</h2>
        <SlackForm />
      </Modal.Header>
    </Modal>
  );
}

export function SlackForm() {
  const { supportSlackUrl } = config;

  return (
    <React.Fragment>
      <p>
        Join Criipto Community on Slack
      </p>
      <AnchorButton variant="primary" href={config.SLACK_JOIN_URL} target="_blank">Join slack</AnchorButton>
      <p style={{marginTop: '10px'}}>Or <a href={supportSlackUrl} target="_blank" rel="noopener noreferrer" className="!underline">go to Slack</a> if you already joined.</p>
    </React.Fragment>
  );
}
