/**
 * @generated SignedSource<<f2ee5017b2e7d6e02d4c1047e540087c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type OrganizationScreen_Query$variables = {
  organizationId: string;
};
export type OrganizationScreen_Query$data = {
  readonly organization: {
    readonly name: string;
    readonly viewerPermissions: {
      readonly productionApi: Permission;
      readonly tenants: Permission;
      readonly testApi: Permission;
      readonly users: Permission;
    };
  };
};
export type OrganizationScreen_Query = {
  response: OrganizationScreen_Query$data;
  variables: OrganizationScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerOrganizationPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenants",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "users",
      "storageKey": null
    },
    {
      "alias": "testApi",
      "args": [
        {
          "kind": "Literal",
          "name": "environment",
          "value": "TEST"
        }
      ],
      "kind": "ScalarField",
      "name": "api",
      "storageKey": "api(environment:\"TEST\")"
    },
    {
      "alias": "productionApi",
      "args": [
        {
          "kind": "Literal",
          "name": "environment",
          "value": "PRODUCTION"
        }
      ],
      "kind": "ScalarField",
      "name": "api",
      "storageKey": "api(environment:\"PRODUCTION\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationScreen_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3527adba98aedc6c2a10c777bf55a7a9",
    "id": null,
    "metadata": {},
    "name": "OrganizationScreen_Query",
    "operationKind": "query",
    "text": "query OrganizationScreen_Query(\n  $organizationId: ID!\n) {\n  organization(id: $organizationId) {\n    name\n    viewerPermissions {\n      tenants\n      users\n      testApi: api(environment: TEST)\n      productionApi: api(environment: PRODUCTION)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3149640dd7e9451f107df4849f5c9257";

export default node;
