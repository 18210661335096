/**
 * @generated SignedSource<<733b8d960df1a82b8f2521ce68af6dd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type OrganizationsScreen_Admin_PermissionsQuery$variables = {};
export type OrganizationsScreen_Admin_PermissionsQuery$data = {
  readonly admin: {
    readonly permissions: {
      readonly organizations: {
        readonly settings: Permission;
      };
    };
  };
};
export type OrganizationsScreen_Admin_PermissionsQuery = {
  response: OrganizationsScreen_Admin_PermissionsQuery$data;
  variables: OrganizationsScreen_Admin_PermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQuery",
    "kind": "LinkedField",
    "name": "admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminPermissions",
        "kind": "LinkedField",
        "name": "permissions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminOrganizationPermissions",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settings",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationsScreen_Admin_PermissionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationsScreen_Admin_PermissionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bc1ce751bd52a79c4c2353ed6d85ba93",
    "id": null,
    "metadata": {},
    "name": "OrganizationsScreen_Admin_PermissionsQuery",
    "operationKind": "query",
    "text": "query OrganizationsScreen_Admin_PermissionsQuery {\n  admin {\n    permissions {\n      organizations {\n        settings\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1228c505b0fd762983b3bea596dd9529";

export default node;
