/**
 * @generated SignedSource<<32ebf1e647452a25dcf39842fe81ab62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteOrganizationApiKeyInput = {
  apiKeyId: string;
};
export type DeleteOrganizationApiKeyButtonMutation$variables = {
  input: DeleteOrganizationApiKeyInput;
};
export type DeleteOrganizationApiKeyButtonMutation$data = {
  readonly deleteOrganizationApiKey: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_List_organization">;
    };
  };
};
export type DeleteOrganizationApiKeyButtonMutation = {
  response: DeleteOrganizationApiKeyButtonMutation$data;
  variables: DeleteOrganizationApiKeyButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrganizationApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteOrganizationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_List_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteOrganizationApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteOrganizationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ViewerOrganizationPermissions",
                "kind": "LinkedField",
                "name": "viewerPermissions",
                "plural": false,
                "selections": [
                  {
                    "alias": "testApi",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "environment",
                        "value": "TEST"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "api",
                    "storageKey": "api(environment:\"TEST\")"
                  },
                  {
                    "alias": "productionApi",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "environment",
                        "value": "PRODUCTION"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "api",
                    "storageKey": "api(environment:\"PRODUCTION\")"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "environment",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a92443dd136a821a72c3864f71ce420",
    "id": null,
    "metadata": {},
    "name": "DeleteOrganizationApiKeyButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteOrganizationApiKeyButtonMutation(\n  $input: DeleteOrganizationApiKeyInput!\n) {\n  deleteOrganizationApiKey(input: $input) {\n    organization {\n      id\n      ...ApiKeys_List_organization\n    }\n  }\n}\n\nfragment ApiKeys_List_organization on Organization {\n  ...DeleteOrganizationApiKeyButton_organization\n  apiKeys {\n    id\n    clientId\n    environment\n    ...DeleteOrganizationApiKeyButton_apiKey\n  }\n}\n\nfragment DeleteOrganizationApiKeyButton_apiKey on OrganizationApiKey {\n  id\n  environment\n}\n\nfragment DeleteOrganizationApiKeyButton_organization on Organization {\n  id\n  viewerPermissions {\n    testApi: api(environment: TEST)\n    productionApi: api(environment: PRODUCTION)\n  }\n}\n"
  }
};
})();

(node as any).hash = "391d93b8f349a106107154d3cf09329c";

export default node;
