/**
 * @generated SignedSource<<243dafa1cdbc1bbb1ccbd19fbc87613e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationTenantsScreenQuery$variables = {
  id: string;
};
export type OrganizationTenantsScreenQuery$data = {
  readonly admin: {
    readonly organization: {
      readonly tenants: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"RemoveOrganizationTenantButton_tenant" | "TenantsScreen_AdminTenantItem_tenant">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_organization">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_admin">;
  };
};
export type OrganizationTenantsScreenQuery = {
  response: OrganizationTenantsScreenQuery$data;
  variables: OrganizationTenantsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationTenantsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TenantsScreen_AdminTenantItem_tenant"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RemoveOrganizationTenantButton_tenant"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AddOrganizationTenantButton_organization"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddOrganizationTenantButton_admin"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationTenantsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityIdentifier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortId",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantBillable",
                    "kind": "LinkedField",
                    "name": "billable",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Verify",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Signatures",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantAgeverificationBillableProduct",
                        "kind": "LinkedField",
                        "name": "Ageverification",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdminTenantBillableProduct",
                            "kind": "LinkedField",
                            "name": "dk",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminOrganization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminOrganizationPermissions",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settings",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3dfe7fa91ddfeb0feae8ccf5901b0f0a",
    "id": null,
    "metadata": {},
    "name": "OrganizationTenantsScreenQuery",
    "operationKind": "query",
    "text": "query OrganizationTenantsScreenQuery(\n  $id: ID!\n) {\n  admin {\n    organization(id: $id) {\n      tenants {\n        id\n        ...TenantsScreen_AdminTenantItem_tenant\n        ...RemoveOrganizationTenantButton_tenant\n      }\n      ...AddOrganizationTenantButton_organization\n      id\n    }\n    ...AddOrganizationTenantButton_admin\n  }\n}\n\nfragment AddOrganizationTenantButton_admin on AdminQuery {\n  permissions {\n    organizations {\n      settings\n    }\n  }\n}\n\nfragment AddOrganizationTenantButton_organization on AdminOrganization {\n  id\n  ...AddOrganizationTenantModal_organization\n}\n\nfragment AddOrganizationTenantModal_organization on AdminOrganization {\n  id\n  organization {\n    name\n    id\n  }\n}\n\nfragment RemoveOrganizationTenantButton_tenant on AdminTenant {\n  id\n  organization {\n    id\n  }\n  ...RemoveOrganizationTenantModal_tenant\n}\n\nfragment RemoveOrganizationTenantModal_tenant on AdminTenant {\n  id\n  tenant {\n    name\n    id\n  }\n  organization {\n    id\n    organization {\n      name\n      id\n    }\n  }\n}\n\nfragment TenantsScreen_AdminTenantItem_tenant on AdminTenant {\n  tenant {\n    name\n    shortTenantId\n    longTenantId\n    entityIdentifier\n    organization {\n      id\n      shortId\n      name\n    }\n    id\n  }\n  billable {\n    Verify {\n      actual\n    }\n    Signatures {\n      actual\n    }\n    Ageverification {\n      dk {\n        actual\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f09128c7b42e7a9c80e2d2ac6d64fce7";

export default node;
