/**
 * @generated SignedSource<<cc772bf352f2c50b065e8e72001517cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSignaturesApplicationScreen_application$data = {
  readonly __typename: string;
  readonly id: string;
  readonly name: string;
  readonly signatures?: {
    readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_application" | "SignaturesWebhooks_application">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DeleteApplicationButton_application" | "GeneralSection_application" | "IdentityProvidersSection_application" | "SignaturesApplicationIntegrate_application">;
  readonly " $fragmentType": "EditSignaturesApplicationScreen_application";
};
export type EditSignaturesApplicationScreen_application$key = {
  readonly " $data"?: EditSignaturesApplicationScreen_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditSignaturesApplicationScreen_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSignaturesApplicationScreen_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Signatures_Application",
          "kind": "LinkedField",
          "name": "signatures",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApiKeys_application"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SignaturesWebhooks_application"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SignaturesApplication",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneralSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentityProvidersSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SignaturesApplicationIntegrate_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApplicationButton_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "54e02d5ba626a93732e5986604b918fe";

export default node;
