/**
 * @generated SignedSource<<4f762125054fd0ccbcb58fa7ede1fea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApiKeys_List_organization$data = {
  readonly apiKeys: ReadonlyArray<{
    readonly clientId: string;
    readonly environment: Environment;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DeleteOrganizationApiKeyButton_apiKey">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteOrganizationApiKeyButton_organization">;
  readonly " $fragmentType": "ApiKeys_List_organization";
};
export type ApiKeys_List_organization$key = {
  readonly " $data"?: ApiKeys_List_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_List_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiKeys_List_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteOrganizationApiKeyButton_organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationApiKey",
      "kind": "LinkedField",
      "name": "apiKeys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "environment",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeleteOrganizationApiKeyButton_apiKey"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "dcf72851c66ea362d5cc9312f6e995e1";

export default node;
