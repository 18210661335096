/**
 * @generated SignedSource<<f5070e37bc3bf941ec966bdb8a33d66c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type SettingsScreen_Query$variables = {
  tenantId: string;
};
export type SettingsScreen_Query$data = {
  readonly tenant: {
    readonly id: string;
    readonly name: string;
    readonly viewerPermissions: {
      readonly billing: Permission;
    };
  };
};
export type SettingsScreen_Query = {
  response: SettingsScreen_Query$data;
  variables: SettingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "tenantId"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerTenantPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billing",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsScreen_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v1/*: any*/),
        "action": "THROW",
        "path": "tenant"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsScreen_Query",
    "selections": [
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "8873003360378c1c789b304b6a163b24",
    "id": null,
    "metadata": {},
    "name": "SettingsScreen_Query",
    "operationKind": "query",
    "text": "query SettingsScreen_Query(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    id\n    viewerPermissions {\n      billing\n    }\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "d874ca5d0b230069c217ab6b191cf853";

export default node;
