/**
 * @generated SignedSource<<3fe449350231a665677c0ff05976b3b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StylingScreenQuery$variables = {
  tenantId: string;
};
export type StylingScreenQuery$data = {
  readonly tenant: {
    readonly features: {
      readonly CUSTOM_UI: boolean;
    };
    readonly id: string;
  } | null;
};
export type StylingScreenQuery = {
  response: StylingScreenQuery$data;
  variables: StylingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "Tenant",
    "kind": "LinkedField",
    "name": "tenant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantFeatures",
        "kind": "LinkedField",
        "name": "features",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "CUSTOM_UI",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StylingScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StylingScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "06cd7c74a3e96780f8611fce1e2bede1",
    "id": null,
    "metadata": {},
    "name": "StylingScreenQuery",
    "operationKind": "query",
    "text": "query StylingScreenQuery(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    id\n    features {\n      CUSTOM_UI\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ebabdfff7756a28fabb161eabad62c9";

export default node;
