import React, {useState, useMemo, useEffect} from 'react';
import {graphql} from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';

import {debounce} from 'lodash';
import { DomainLookupQuery } from './__generated__/DomainLookupQuery.graphql';
import { useTenantId } from '@app/hooks/useTenant';
import useEnvironment from '@app/hooks/useEnvironment';

export type DomainLookupResponse = Required<DomainLookupQuery["response"]["domainLookup"]>;
interface Props {
  domain: string,
  onLookup: (lookup: DomainLookupResponse) => void
}
export default function DomainLookup(props: Props) {
  const [domain, setDomain] = useState('');
  const tenantId = useTenantId();
  const environment = useEnvironment();
  const debouncedDomain = useMemo(() => debounce(setDomain, 150), [setDomain]);

  const data = useLazyLoadQuery<DomainLookupQuery>(graphql`
    query DomainLookupQuery($domain: String!, $tenantId: ID!, $environment: Environment! $skip: Boolean!) {
      domainLookup(domain: $domain, tenantId: $tenantId, environment: $environment) @skip(if: $skip) {
        cname {
          actual
          expected
          matches
        }

        txt {
          actual
          expected
          matches
        }

        available
        valid
      }
    }
  `, {
    domain: domain.trim(),
    tenantId: tenantId.relayId,
    environment,
    skip: !domain.trim().length
  });

  useEffect(() => {
    if (!props.domain) return;
    debouncedDomain(props.domain);
  }, [props.domain, debouncedDomain]);

  useEffect(() => {
    if (!data.domainLookup) return;
    props.onLookup(data.domainLookup);
  }, [data, props.onLookup]);
  return null;
}