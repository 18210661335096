/**
 * @generated SignedSource<<12c488810d445fc280577f59a2ebcee1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuditScreen_Event_event$data = {
  readonly api_key: string | null;
  readonly event: string;
  readonly raw: string;
  readonly timestamp: string;
  readonly user: {
    readonly email: string | null;
    readonly sub: string;
  } | null;
  readonly " $fragmentType": "AuditScreen_Event_event";
};
export type AuditScreen_Event_event$key = {
  readonly " $data"?: AuditScreen_Event_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuditScreen_Event_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuditScreen_Event_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditEventUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sub",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "api_key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "event",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "type": "AuditEvent",
  "abstractKey": "__isAuditEvent"
};

(node as any).hash = "7ba40c2a0522fcd574ddee948ea3377e";

export default node;
