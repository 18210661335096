import React from 'react';
import { Link } from 'react-router-dom';

import Panel from '@components/Panel';
import DanishMitIDTestLoginPanel from './components/DanishMitIDTestLoginPanel';
import { useSelector } from '@app/redux';
import logo from './assets/mitid.png';
import useTestLoginApplication from './hooks/useTestLoginApplication';
import { useTenantId } from '@app/hooks/useTenant';
import { TENANT_ID_PREFIX } from '@app/constants';

import styles from './OnboardingScreen.module.css';
import cx from 'classnames';

export default function OnboardingScreenMitID() {
  const application = useTestLoginApplication();
  const {tenantId} = useTenantId();


  return (
    <div className={cx('container', styles['onboarding-screen'])}>
      <h2 className="text-center d-flex align-items-center justify-content-center">
        Get started with <img src={logo} style={{height: '22px', marginLeft: '10px'}} />
      </h2>
      <div className={styles['panels']}>
        <DanishMitIDTestLoginPanel application={application} title="Try a test login" />
        <Panel title="Apply for production">
          <p>
            Once you have tested that your MitID integration is working you can apply for production.
          </p>
          <p>
            First your Criipto Organization must be <a href="https://docs.criipto.com/how-to/get-ready-for-production/" target="_blank" rel="noopener noreferrer">setup for production</a>
            &nbsp;and after that you can apply for MitID production via the <Link to={`/tenant/${tenantId}/providers/DK_MITID`}>Identity sources</Link> tab.
          </p>
        </Panel>
        <Panel title="Technical details">
          <div>
            <p>Criipto supports the OpenID Connect protocol to easily let you start accepting logins with MitID.</p>
            <p>
              See our documentation on <a href="https://docs.criipto.com/getting-started/oidc-intro/" target="_blank" rel="noopener noreferrer">OpenID Connect</a>
              &nbsp;and on <a href="https://docs.criipto.com/verify/e-ids/danish-mitid/" target="_blank" rel="noopener noreferrer">Danish MitID</a>
              &nbsp;to get started
            </p>
          </div>
        </Panel>
      </div>

      <h2 className="text-center d-flex align-items-center justify-content-center">
        How it works - in 1 minute
      </h2>
      <div className="d-flex align-items-center justify-content-center">
        <iframe src="https://player.vimeo.com/video/594547234" style={{border: 0, width: '100%', minHeight: '500px'}} />
      </div>
    </div>
  )
}