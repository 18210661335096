import React from 'react';

import {AnchorButton} from '@components/Button';
import Panel from '@components/Panel';
import { Application } from '@app/models';

import { authorizeUrl } from '@app/helpers';

type OidcDiscovery = {
  authorization_endpoint: string
}

interface Props {
  title?: string,
  oidc?: OidcDiscovery
  application: Application
}

export default function DanishMitIDTestLoginPanel(props: Props) {
  const {oidc, application, title} = props;
  return (
    <Panel title={title || 'Danish MitID'}>
      <div>
        <p>
          Will open in a new browser tab.
        </p>
        <p>
          <a href="https://docs.criipto.com/verify/e-ids/danish-mitid/#test-users" target="_blank" rel="noopener noreferrer">Generate your own test-user</a>
        </p>
      </div>
      <AnchorButton variant="primary" target="_blank" href={authorizeUrl(application, 'urn:grn:authn:dk:mitid:low', oidc).href}>
        Login with Danish MitID
      </AnchorButton>
    </Panel>
  )
}