import React, { useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { useHistory, useParams } from 'react-router-dom';
import { CustomerSupportRedirectScreenQuery } from './__generated__/CustomerSupportRedirectScreenQuery.graphql';
import { TenantRouteParams } from '@app/models';

export default function CustomerSupportRedirectScreen() {
  const history = useHistory();
  const params = useParams<TenantRouteParams>();
  const data = useLazyLoadQuery<CustomerSupportRedirectScreenQuery>(
    graphql`
      query CustomerSupportRedirectScreenQuery {
        viewer {
          ... on UserViewer {
            isCriiptoAdmin
          }
        }
      }
    `,
    {

    }
  );

  useEffect(() => {
    if (data.viewer?.isCriiptoAdmin) {
      history.push(`/admin/tenants/${params.tenantId}`);
      return;
    }
  }, [data]);

  return (
    <CustomerSupportRedirectScreen.Loader />
  )
}

CustomerSupportRedirectScreen.Loader = () => {
  return (
    <i className="fa fa-spinner fa-pulse fa-2x" />
  );
}