/**
 * @generated SignedSource<<dc276260b61db188ed318d05cb43ea56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditVerifyApplicationScreen_application$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedOptionsSection_application" | "CopyApplicationButton_application" | "DeleteApplicationButton_application" | "GeneralSection_application" | "IdentityProvidersSection_application" | "NativeSection_application" | "OIDCSection_application" | "UISection_application" | "VerifyApplicationIntegrate_application">;
  readonly " $fragmentType": "EditVerifyApplicationScreen_application";
};
export type EditVerifyApplicationScreen_application$key = {
  readonly " $data"?: EditVerifyApplicationScreen_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditVerifyApplicationScreen_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditVerifyApplicationScreen_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneralSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentityProvidersSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UISection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvancedOptionsSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OIDCSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VerifyApplicationIntegrate_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApplicationButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CopyApplicationButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NativeSection_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "812fd426e461d0c6604d4ea539cf7cae";

export default node;
