/**
 * @generated SignedSource<<55fb001e0957f6dfe2d54c679bf1a40a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardScreen_SubscriptionBox_tenant$data = {
  readonly environments: ReadonlyArray<Environment>;
  readonly shortTenantId: string;
  readonly " $fragmentType": "DashboardScreen_SubscriptionBox_tenant";
};
export type DashboardScreen_SubscriptionBox_tenant$key = {
  readonly " $data"?: DashboardScreen_SubscriptionBox_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardScreen_SubscriptionBox_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardScreen_SubscriptionBox_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "environments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "3ff4a30d8abfbad4415b2a84c220a1c2";

export default node;
