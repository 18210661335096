/**
 * @generated SignedSource<<7150f10097765bf9ed0a8e441fbf1277>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type routes_TenantScreen_Query$variables = {
  environment: Environment;
  tenantId: string;
};
export type routes_TenantScreen_Query$data = {
  readonly tenant: {
    readonly features: {
      readonly EXTENSIONS: boolean;
    };
    readonly longTenantId: string;
    readonly name: string;
    readonly viewerPermissions: {
      readonly analytics: Permission;
      readonly applications: Permission;
      readonly billing: Permission;
      readonly domains: Permission;
      readonly extensions: Permission;
      readonly providers: Permission;
      readonly styling: Permission;
      readonly users: Permission;
    };
  } | null;
  readonly viewer: {
    readonly isCriiptoAdmin?: boolean;
  } | null;
};
export type routes_TenantScreen_Query = {
  response: routes_TenantScreen_Query$data;
  variables: routes_TenantScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longTenantId",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "users",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "analytics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "styling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "applications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "extensions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "EXTENSIONS",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCriiptoAdmin",
      "storageKey": null
    }
  ],
  "type": "UserViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_TenantScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_TenantScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67dce3d28137a16cccc1843dbcf4fba7",
    "id": null,
    "metadata": {},
    "name": "routes_TenantScreen_Query",
    "operationKind": "query",
    "text": "query routes_TenantScreen_Query(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    name\n    longTenantId\n    viewerPermissions {\n      billing\n      users\n      analytics(environment: $environment)\n      providers(environment: $environment)\n      domains(environment: $environment)\n      styling(environment: $environment)\n      applications(environment: $environment)\n      extensions(environment: $environment)\n    }\n    features {\n      EXTENSIONS(environment: $environment)\n    }\n    id\n  }\n  viewer {\n    __typename\n    ... on UserViewer {\n      isCriiptoAdmin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88f30f16137b13d9dbbda58d3cb4ef0f";

export default node;
