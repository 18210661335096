/**
 * @generated SignedSource<<1cd35b0074dec3e25717c444d359bf36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type OnboardingAgeVerificationScreenQuery$variables = {
  environment: Environment;
  product: ReadonlyArray<Product>;
  tenantId: string;
};
export type OnboardingAgeVerificationScreenQuery$data = {
  readonly tenant: {
    readonly domains: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      }>;
    };
    readonly environments: ReadonlyArray<Environment>;
    readonly onboarding: {
      readonly hasApplication: boolean;
      readonly hasDomain: boolean;
      readonly products: ReadonlyArray<Product>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CreateAgeVerificationApplicationComponent_tenant" | "CreateDomainComponent_tenant">;
  } | null;
};
export type OnboardingAgeVerificationScreenQuery = {
  response: OnboardingAgeVerificationScreenQuery$data;
  variables: OnboardingAgeVerificationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "product"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "environment",
  "variableName": "environment"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "product",
      "variableName": "product"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantOnboarding",
  "kind": "LinkedField",
  "name": "onboarding",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "products",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "hasDomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v4/*: any*/),
        {
          "kind": "Literal",
          "name": "product",
          "value": "Ageverification_DK"
        }
      ],
      "kind": "ScalarField",
      "name": "hasApplication",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingAgeVerificationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": "domains",
            "args": (v5/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "__tenant_domains_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "CreateAgeVerificationApplicationComponent_tenant"
          },
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "CreateDomainComponent_tenant"
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OnboardingAgeVerificationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "domains",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "environment",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": [
              "environment"
            ],
            "handle": "connection",
            "key": "tenant_domains",
            "kind": "LinkedHandle",
            "name": "domains"
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerTenantPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "kind": "ScalarField",
                "name": "domains",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d69cd693461ae8c367e9c24b41b7bc5f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "tenant",
            "domains"
          ]
        }
      ]
    },
    "name": "OnboardingAgeVerificationScreenQuery",
    "operationKind": "query",
    "text": "query OnboardingAgeVerificationScreenQuery(\n  $tenantId: ID!\n  $environment: Environment!\n  $product: [Product!]!\n) {\n  tenant(id: $tenantId) {\n    domains(first: 1000, environment: $environment) {\n      edges {\n        node {\n          id\n          name\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    environments(product: $product)\n    ...CreateAgeVerificationApplicationComponent_tenant_2bptcK\n    ...CreateDomainComponent_tenant_2bptcK\n    onboarding {\n      products\n      hasDomain(environment: $environment)\n      hasApplication(environment: $environment, product: Ageverification_DK)\n    }\n    id\n  }\n}\n\nfragment CreateAgeVerificationApplicationComponent_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...DomainField_tenant_2bptcK\n}\n\nfragment CreateDomainComponent_tenant_2bptcK on Tenant {\n  viewerPermissions {\n    domains(environment: $environment)\n  }\n}\n\nfragment DomainField_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        environment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "baf26ecf82ebae70918fbba026d5bf9c";

export default node;
