/**
 * @generated SignedSource<<e53c287e11f47a51263b56f0e7fa77fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
export type ConfirmPaymentIntentInput = {
  paymentIntentId: string;
  subscriptionId: string;
};
export type PaymentMethodModal_ConfirmMutation$variables = {
  input: ConfirmPaymentIntentInput;
};
export type PaymentMethodModal_ConfirmMutation$data = {
  readonly confirmPaymentIntent: {
    readonly subscription: {
      readonly status: SubscriptionStatus;
      readonly " $fragmentSpreads": FragmentRefs<"ConfirmPaymentButton_subscription">;
    };
    readonly tenant: {
      readonly ageverificationEnvironments: ReadonlyArray<Environment>;
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  };
};
export type PaymentMethodModal_ConfirmMutation = {
  response: PaymentMethodModal_ConfirmMutation$data;
  variables: PaymentMethodModal_ConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v4 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v5 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v6 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentMethodModal_ConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPaymentIntentOutput",
        "kind": "LinkedField",
        "name": "confirmPaymentIntent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfirmPaymentButton_subscription"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentMethodModal_ConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPaymentIntentOutput",
        "kind": "LinkedField",
        "name": "confirmPaymentIntent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentIntent",
                "kind": "LinkedField",
                "name": "paymentIntent",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secret",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f1f0afab7cd899651ad923e9514e015",
    "id": null,
    "metadata": {},
    "name": "PaymentMethodModal_ConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentMethodModal_ConfirmMutation(\n  $input: ConfirmPaymentIntentInput!\n) {\n  confirmPaymentIntent(input: $input) {\n    subscription {\n      status\n      ...ConfirmPaymentButton_subscription\n      id\n    }\n    tenant {\n      environments\n      verifyEnvironments: environments(product: Verify)\n      signaturesEnvironments: environments(product: Signatures)\n      ageverificationEnvironments: environments(product: [Ageverification_DK])\n      id\n    }\n  }\n}\n\nfragment ConfirmPaymentButton_subscription on BillingSubscription {\n  id\n  paymentIntent {\n    id\n    status\n    secret\n    subscriptionId\n  }\n}\n"
  }
};
})();

(node as any).hash = "16457bffdb6fcc5fc5bd696a411e572d";

export default node;
