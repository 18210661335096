import * as React from 'react';
import { FieldMetaProps, FieldHelperProps, FieldInputProps, useFormikContext} from 'formik';

// Custom useField, returns null if used outside of useFormikContext
export default function useField<Val = any>(fieldName: string): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] | null {
  const formik = useFormikContext();

  if (!formik) {
    console.debug("no Formik context, returning null")
    return null;
  }

  const { getFieldProps, getFieldMeta, getFieldHelpers, registerField, unregisterField } = formik;

  React.useEffect(() => {
    if (fieldName) {
      registerField(fieldName, {})
    }
    return () => {
      if (fieldName) {
        unregisterField(fieldName);
      }
    };
  }, [registerField, unregisterField, fieldName]);

  const fieldHelpers = React.useMemo(() => getFieldHelpers(fieldName), [getFieldHelpers, fieldName]);

  return [getFieldProps({name: fieldName}), getFieldMeta(fieldName), fieldHelpers];
}
