import React, {useState, useEffect} from 'react';
import {graphql} from 'react-relay';

import Button from '@components/Button';
import {Environment, TenantRouteParams} from '@app/models';
import {translate} from '@app/i18n';
import {singleton as config} from '@app/config';
import DomainLookup, { DomainLookupResponse } from './DomainLookup';

import styles from './DomainCreater.module.css';
import { validateDomainPrefix, sanitizeDomainInput } from '@app/helpers';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';
import useMutation from '@app/hooks/useMutation';
import { DomainCreaterMutation } from './__generated__/DomainCreaterMutation.graphql';
import { useParams } from 'react-router-dom';
import { getRelayDomainConnections } from '../../utils';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import Alert from '@app/components/Alert/Alert';
import InputField from '@app/components/FormFields/InputField/InputField';

export type DomainType = "CRIIPTO" | "CLIENT";

interface Props {
  onCancel?: () => void;
  onSave?: (domain: {name: string}) => void;
  domainType: DomainType;
  initialValue?: string;
}

export default function DomainCreater(props : Props) {
  const {initialValue} = props;
  const [lookupResponse, setLookupResponse] = useState<DomainLookupResponse | null>(null);
  const [domainNameInput, setDomainNameInput] = useState(props.initialValue || '');
  const params = useParams<TenantRouteParams>();
  const tracking = useTracking();
  const environment = useEnvironment();

  useEffect(() => {
    setDomainNameInput(initialValue || '');
  }, [initialValue]);

  const {domainType} = props;
  const domainSuffix =
    domainType === 'CLIENT' ? `` :
      `.${config.easyIdDns}`;

  const subdomainSuffix =
    environment === 'TEST' ?
      (domainType === 'CLIENT' && domainNameInput.endsWith('pp.mitid.dk')) ? '' : '-test'
      : '';

  const domainName =
      domainType === 'CLIENT' ?
      domainNameInput :
      domainNameInput ? `${domainNameInput}${subdomainSuffix}${domainSuffix}` : null;

  const domainPrefixError = domainType === 'CRIIPTO' ? validateDomainPrefix(domainNameInput, domainSuffix) : undefined;
  const isValid =
    domainName &&
    lookupResponse &&
    domainPrefixError === undefined &&
    (domainType !== 'CLIENT' || lookupResponse.cname.matches) &&
    (domainType !== 'CLIENT' || lookupResponse.txt.matches || !lookupResponse.txt.actual) &&
    lookupResponse.available;

  const canSubmit = domainName && isValid;

  const [{executePromise}, state] = useMutation<DomainCreaterMutation>(graphql`
    mutation DomainCreaterMutation(
      $connections: [ID!]!
      $input: CreateDomainInput!
      $environment: Environment!
    ) {
      createDomain(input: $input) {
        tenant {
          onboarding {
            hasDomain(environment: $environment)
          }
        }

        domainEdge @appendEdge(connections: $connections) {
          cursor
          node {
            id
            name
            environment
          }
        }
      }
    }
  `);

  const handleSubmit = async () => {
    if (!domainName) return;
    if (!isValid) return;

    const tenantId = btoa(`tenant:${params.tenantId}`);
    const connections = getRelayDomainConnections(tenantId, environment);

    const result = await executePromise({
      environment,
      input: {
        tenantId,
        environment,
        name: domainName
      },
      connections
    });

    tracking.domainCreated({
      environment: result.createDomain.domainEdge.node.environment as Environment
    });
    if (props.onSave) props.onSave(result.createDomain.domainEdge.node);
  };

  return (
    <div className={styles["domain-creater"]}>
      <div style={{maxWidth: '768px'}}>
        <InputField
          name="domain"
          className="max-w-[50%]"
          placeholder={(domainType === 'CRIIPTO' ? 'Your subdomain' : 'Your domain')}
          value={domainNameInput}
          onChange={(event) => setDomainNameInput(sanitizeDomainInput(event.target.value, domainType))}
          disabled={state.pending}
          required
          suffix={domainType === 'CRIIPTO' ? `${subdomainSuffix}.${config.easyIdDns}` : undefined}
        />

        {domainName && !domainPrefixError && (
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
              <DomainLookup domain={domainName} onLookup={setLookupResponse} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        )}
        <small className={styles["form-text"]}>
          {domainName || ''}&nbsp;
          {domainName && domainPrefixError !== undefined ? (
            <span className="text-danger">{domainPrefixError}</span>
          ) : null}
          {lookupResponse && lookupResponse.valid && !lookupResponse.available && (
            <span className="text-danger">{translate('DNS_NAME_UNAVAILABLE')}</span>
          )}
          {lookupResponse && !lookupResponse.valid && (
            <span className="text-danger">Domain name invalid.</span>
          )}
        </small>
        {domainType === 'CLIENT' && lookupResponse && lookupResponse.valid && !lookupResponse.cname.matches && (
          lookupResponse.cname.actual ? (
            <Alert
              variant="error"
              className="mt-[15px]"
              title="An error occurred"
              message={translate('VERIFY_ERROR', { expected: lookupResponse.cname.expected, actual: lookupResponse.cname.actual })}
            />
          ) : (
            <Alert
              variant="error"
              className="mt-[15px]"
              title="No DNS record found."
            />
            )
          )}
        {domainType === 'CLIENT' && lookupResponse && lookupResponse.valid && lookupResponse.txt.actual && !lookupResponse.txt.matches && (
          <Alert variant="error" className="mt-[15px]" title="Invalid TXT record for domain" message="Please contact support." />
        )}
      </div>

      {domainType === 'CLIENT' && (
        <React.Fragment>
          {domainName && environment === 'TEST' && !domainName.includes('test') ? (
            <p>We suggest that you include <em>test</em> in your domain name as it cannot be reused in production.</p>
          ) : null}
          <p>
            To make your domain work you must configure a <strong>DNS CNAME record</strong> to point from your domain ({domainName}) to <strong>idp.{config.easyIdDns}</strong>
          </p>
          <p>
            We will automatically provision a (free) managed TLS (https) certificate for you. <br />
            If you wish, you can upload your own certificate later.
          </p>
        </React.Fragment>
      )}

      {state.error ? (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
      ) : null}

      <div className="form-actions">
        <div className="flex flex-row gap-2">
        {props.onCancel ? (
          <Button variant="default" type="button" onClick={props.onCancel}>
            {translate('CANCEL')}
          </Button>
        ) : null}
          <Button variant="primary" type="submit" onClick={handleSubmit} disabled={!canSubmit} working={state.pending}>
            {domainName ? (
              `Add domain '${domainName}'`
            ) : 'Add domain'}
          </Button>
        </div>
      </div>
    </div>
  );
}
