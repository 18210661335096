/**
 * @generated SignedSource<<8295b7cf1094dd6e547075798b3a8867>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersScreen_User_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditUserButton_tenant" | "RemoveUserButton_tenant">;
  readonly " $fragmentType": "UsersScreen_User_tenant";
};
export type UsersScreen_User_tenant$key = {
  readonly " $data"?: UsersScreen_User_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersScreen_User_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersScreen_User_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveUserButton_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditUserButton_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "fd2b026b5f9b9aa389b7057820086133";

export default node;
