/**
 * @generated SignedSource<<78d02ebf4abec4e4f0086a6a9f70e370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CodeFlow_application$data = {
  readonly clientSecretEnabled: boolean;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "CodeFlow_application";
};
export type CodeFlow_application$key = {
  readonly " $data"?: CodeFlow_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodeFlow_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeFlow_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSecretEnabled",
      "storageKey": null
    }
  ],
  "type": "VerifyApplication",
  "abstractKey": null
};

(node as any).hash = "9199118184f3ba7f98f4de332a3b6990";

export default node;
