import React, { useReducer } from 'react';
import Button, { ButtonProps } from '@app/components/Button/Button';
import { Tooltip } from 'react-bootstrap';

type Props = {
  value: string;
  variant: ButtonProps['variant'];
  tooltip?: string;
  className?: string;
  size?: ButtonProps['size'];
};

export default function CopyValueButton(props: Props) {
  const { value, tooltip } = props;

  const [showTemporaryTooltip, toggleTemporaryTooltip] = useReducer((value) => !value, false);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard.writeText(value);

    toggleTemporaryTooltip();

    setTimeout(() => {
      toggleTemporaryTooltip();
    }, 1500);
  };

  return (
    <React.Fragment>
      <Button
        tooltip={showTemporaryTooltip ? undefined : tooltip}
        onClick={handleClick}
        className={props.className}
        variant={props.variant}
        size={props.size}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 11V3.4375L12.5625 1H8V11H15ZM16 3V11V12H15H8H7V11V1V0H8H13L16 3ZM1 4H6V5H1V15H8V13H9V15V16H8H1H0V15V5V4H1Z" fill="white" />
        </svg>

        <Tooltip
          placement="bottom"
          id="value_copied_tooltip"
          style={{
            opacity: showTemporaryTooltip ? 1 : 0,
            marginTop: '82px',
            width: '120px',
            // offsetting position to "fix" the hidden tooltip (increasing z-index doesn't have an effect)
            bottom: '-15px',
          }}
        >
          Value copied!
        </Tooltip>
      </Button>
    </React.Fragment>
  );
}
