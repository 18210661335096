/**
 * @generated SignedSource<<ebc706c73866bf909162283159fb95e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiKeys_Organization_Query$variables = {
  organizationId: string;
};
export type ApiKeys_Organization_Query$data = {
  readonly organization: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationApiKeyButton_organization" | "ApiKeys_List_organization">;
  };
};
export type ApiKeys_Organization_Query = {
  response: ApiKeys_Organization_Query$data;
  variables: ApiKeys_Organization_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApiKeys_Organization_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApiKeys_List_organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AddOrganizationApiKeyButton_organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApiKeys_Organization_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerOrganizationPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": "testApi",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "environment",
                    "value": "TEST"
                  }
                ],
                "kind": "ScalarField",
                "name": "api",
                "storageKey": "api(environment:\"TEST\")"
              },
              {
                "alias": "productionApi",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "environment",
                    "value": "PRODUCTION"
                  }
                ],
                "kind": "ScalarField",
                "name": "api",
                "storageKey": "api(environment:\"PRODUCTION\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationApiKey",
            "kind": "LinkedField",
            "name": "apiKeys",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "environment",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04c681c7305ef8af74bb80ff952cfbc0",
    "id": null,
    "metadata": {},
    "name": "ApiKeys_Organization_Query",
    "operationKind": "query",
    "text": "query ApiKeys_Organization_Query(\n  $organizationId: ID!\n) {\n  organization(id: $organizationId) {\n    name\n    ...ApiKeys_List_organization\n    ...AddOrganizationApiKeyButton_organization\n    id\n  }\n}\n\nfragment AddOrganizationApiKeyButton_organization on Organization {\n  id\n  viewerPermissions {\n    testApi: api(environment: TEST)\n    productionApi: api(environment: PRODUCTION)\n  }\n}\n\nfragment ApiKeys_List_organization on Organization {\n  ...DeleteOrganizationApiKeyButton_organization\n  apiKeys {\n    id\n    clientId\n    environment\n    ...DeleteOrganizationApiKeyButton_apiKey\n  }\n}\n\nfragment DeleteOrganizationApiKeyButton_apiKey on OrganizationApiKey {\n  id\n  environment\n}\n\nfragment DeleteOrganizationApiKeyButton_organization on Organization {\n  id\n  viewerPermissions {\n    testApi: api(environment: TEST)\n    productionApi: api(environment: PRODUCTION)\n  }\n}\n"
  }
};
})();

(node as any).hash = "e11e1c9acf65a4c8e564dab689453e95";

export default node;
