import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import Button from '@app/components/Button/Button';
import { Form, FormError, FormSuccess, InputField, Select, Switch } from '@app/components/Form/Form';
import useMutation from '@app/hooks/useMutation';
import { OrganizationSettingsScreen_Mutation } from './__generated__/OrganizationSettingsScreen_Mutation.graphql';
import { OrganizationSettingsScreen_Query } from './__generated__/OrganizationSettingsScreen_Query.graphql';
import { countries } from '@app/screens/BillingScreen/countries';

type Values = {
  name: string
  automatic_production_access_verify: boolean
  automatic_production_access_signatures: boolean,
  kyc: {
    companyName: string
    vatCountry: string
    vatNumber: string
  }
}


export default function OrganizationSettingsScreen(props: {
  organizationId: string
}) {
  const data = useLazyLoadQuery<OrganizationSettingsScreen_Query>(
    graphql`
      query OrganizationSettingsScreen_Query($id: ID!) {
        admin {
          organization(id: $id) @required(action: THROW) {
            id
            organization {
              name
            }
            settings {
              automatic_production_access_verify
              automatic_production_access_signatures
            }
            kyc {
              companyName
              vatCountry
              vatNumber
            }
          }
        }
      }
    `,
    {
      id: props.organizationId
    }
  );

  const [executor, status] = useMutation<OrganizationSettingsScreen_Mutation>(
    graphql`
      mutation OrganizationSettingsScreen_Mutation($input: UpdateAdminOrganizationInput!) {
        admin_updateOrganization(input: $input) {
          organization {
            name
          }
          adminOrganization {
            settings {
              automatic_production_access_verify
              automatic_production_access_signatures
            }
            kyc {
              companyName
              vatCountry
              vatNumber
            }
          }
        }
      }
    `
  );

  const initialValues : Values = {
    name: data.admin.organization.organization.name,
    automatic_production_access_verify: data.admin.organization.settings.automatic_production_access_verify,
    automatic_production_access_signatures: data.admin.organization.settings.automatic_production_access_signatures,
    kyc: data.admin.organization.kyc ?? {
      vatCountry: '',
      vatNumber: '',
      companyName: ''
    }
  }

  const handleSubmit = async (values: Values) => {
    await executor.executePromise({
      input: {
        organizationId: data.admin.organization.id,
        name: values.name,
        settings: {
          automatic_production_access_verify: values.automatic_production_access_verify,
          automatic_production_access_signatures: values.automatic_production_access_signatures
        },
        kyc: values.kyc.companyName ? values.kyc : null
      }
    });
  }

  return (
    <div className="max-w-[750px]">
      <Form
        key="organization_settings"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className="flex flex-col gap-[16px]"
      >
        {({isPending, error, isSuccess, values, setFieldValue}) => (
          <React.Fragment>
            <InputField type="text" label="Organization name" name="name" required />

            <div>
              <Switch
                name="automatic_production_access_verify"
                label="Automatically give new tenants production access for Verify"
                help={<small>Only works for tenants created manually with org selector or via API</small>}
              />
            </div>
            <div>
              <Switch
                name="automatic_production_access_signatures"
                label="Automatically give new tenants production access for Signatures"
                help={<small>Only works for tenants created manually with org selector or via API</small>}
              />
            </div>

            <InputField  type="text" label="Company name" name="kyc.companyName" />

            <div className="flex flex-row gap-[16px]">
              <Select
                name="kyc.vatCountry"
                label="VAT Country"
                groupClassName="flex-1 basis-1/6"
                options={[
                  {value: '', label: 'Select country'}
                ].concat(Object.entries(countries).map(([key, value]) => ({
                  value: key,
                  label: value
                })))}
              />

              <InputField groupClassName="grow !mt-0" type="text" label="VAT number" name="kyc.vatNumber" />
            </div>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'Organization updated'} />
            )}
            <div>
              <Button variant="primary" type="submit" working={isPending}>Update organization</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}