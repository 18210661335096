/**
 * @generated SignedSource<<285e9a520de0c208f980e9ed5d29294d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainCertificateType = "AZURE" | "CUSTOMER_PROVIDED" | "MANAGED" | "%future added value";
export type DomainType = "CRIIPTO" | "CUSTOM" | "MITID" | "%future added value";
export type SettingsQuery$variables = {
  id: string;
};
export type SettingsQuery$data = {
  readonly domain: {
    readonly certificate: {
      readonly expiresAt: string;
      readonly isExpired: boolean;
      readonly isValid: boolean;
      readonly issuedAt: string;
      readonly issuer: string;
      readonly subject: string;
      readonly type: DomainCertificateType;
    } | null;
    readonly dns: {
      readonly cname: {
        readonly actual: string | null;
        readonly expected: string;
        readonly matches: boolean;
      };
      readonly txt: {
        readonly actual: string | null;
        readonly expected: string;
        readonly matches: boolean;
      };
    };
    readonly id: string;
    readonly name: string;
    readonly type: DomainType;
    readonly " $fragmentSpreads": FragmentRefs<"DomainCertificateUploadModal_domain">;
  } | null;
};
export type SettingsQuery = {
  response: SettingsQuery$data;
  variables: SettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "expected",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matches",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainDns",
  "kind": "LinkedField",
  "name": "dns",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainAssertion",
      "kind": "LinkedField",
      "name": "cname",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainAssertion",
      "kind": "LinkedField",
      "name": "txt",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainCertificate",
  "kind": "LinkedField",
  "name": "certificate",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExpired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Domain",
        "kind": "LinkedField",
        "name": "domain",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainCertificateUploadModal_domain"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Domain",
        "kind": "LinkedField",
        "name": "domain",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "environment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longTenantId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f1b68989039df311aa5a9f4ea6d1a31",
    "id": null,
    "metadata": {},
    "name": "SettingsQuery",
    "operationKind": "query",
    "text": "query SettingsQuery(\n  $id: ID!\n) {\n  domain(id: $id) {\n    id\n    name\n    type\n    dns {\n      cname {\n        actual\n        expected\n        matches\n      }\n      txt {\n        actual\n        expected\n        matches\n      }\n    }\n    certificate {\n      type\n      isExpired\n      isValid\n      issuedAt\n      expiresAt\n      issuer\n      subject\n    }\n    ...DomainCertificateUploadModal_domain\n  }\n}\n\nfragment DomainCertificateUploadForm_domain on Domain {\n  id\n  name\n  environment\n  tenant {\n    longTenantId\n    id\n  }\n}\n\nfragment DomainCertificateUploadModal_domain on Domain {\n  name\n  ...DomainCertificateUploadForm_domain\n}\n"
  }
};
})();

(node as any).hash = "dd2999cfb7c9a00917f02f885fb590a2";

export default node;
