/**
 * @generated SignedSource<<bbb3637c911c98553e30b6e5858e87e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvancedOptionsSection_application$data = {
  readonly frameOrigin: string | null;
  readonly id: string;
  readonly sessionLifetime: string;
  readonly tokenLifetime: string | null;
  readonly " $fragmentType": "AdvancedOptionsSection_application";
};
export type AdvancedOptionsSection_application$key = {
  readonly " $data"?: AdvancedOptionsSection_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedOptionsSection_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvancedOptionsSection_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frameOrigin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionLifetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenLifetime",
      "storageKey": null
    }
  ],
  "type": "VerifyApplication",
  "abstractKey": null
};

(node as any).hash = "b414ac37f59cc142ef4b0c03a3f7d5e9";

export default node;
