import React from 'react';
import { useField } from 'formik';

import { translate } from '@app/i18n';
import { Switch } from '@app/components/Form/Form';
import Select from '@app/components/FormFields/Select/Select';
import HelpText from '@app/components/FormFields/HelpText';

import { ApplicationOAuth2SettingsInput, UserInfoResponseStrategy, ClaimPropertyFormat, NullClaimValues } from './Sections/__generated__/OIDCSectionMutation.graphql';
import { camelCase } from 'lodash';

const userInfoResponseStrategies : UserInfoResponseStrategy[] = ["FROM_TOKEN_ENDPOINT", "PLAIN_JSON", "SIGNED_JWT"];
const claimPropertyFormats : ClaimPropertyFormat[] = ["COMPACT", "DEFAULT", "NAMESPACED"];
const nullClaimValues : NullClaimValues[] = ["ALLOW", "REMOVE"];


export default function OAuth2ConfigForm() {
  const [{value}, , {setValue}] = useField<ApplicationOAuth2SettingsInput>('oauth2');

  const handleChange = (key : keyof ApplicationOAuth2SettingsInput, event : React.ChangeEvent<HTMLSelectElement>) => {
    handleValue(key, event.target.value)
  }

  const handleValue = (key: keyof ApplicationOAuth2SettingsInput, newValue: any) => {
    setValue({
      ...value,
      [key]: newValue
    });
  }

  return (
    <React.Fragment>
      <div className="form-group">
        <div className="flex flex-row items-center">
          <label className="switch mr-4">
            <input type="checkbox" checked={value.callbackOnLocationHash ?? false} onChange={(event) => handleValue('callbackOnLocationHash', event.target.checked)} /><i />
          </label>
          <label className="control-label">{translate('LABEL_OAUTH2_CALLBACKONLOCATIONHASH')}</label>
        </div>
        <HelpText>{translate('INFO_OAUTH2_CALLBACKONLOCATIONHASH')}</HelpText>
      </div>

      <Switch<ApplicationOAuth2SettingsInput>
        name="nullClaimValues"
        label="Allow null claim values"
        off="REMOVE"
        on="ALLOW"
        value={value.nullClaimValues ?? "ALLOW"}
        onChange={(value: NullClaimValues) => handleValue('nullClaimValues', value)}
      />
      <HelpText>{translate('INFO_OAUTH2_NULL_CLAIMS_VALUES_HANDLING')}</HelpText>

      <div className='flex flex-col gap-[12px] mb-[12px]'>

        <Select
          name="userinfo_response_strategy"
          label={translate('LABEL_CHOOSE_USERINFO_RESPONSE_STRATEGY')}
          value={value.userInfoResponseStrategy ?? "FROM_TOKEN_ENDPOINT"}
          onChange={(event) => handleChange('userInfoResponseStrategy', event)}
          helpText={translate('INFO_OIDC_USERINFO_STRATEGY')}
          className='mt-[25px]'
        >
          {userInfoResponseStrategies.map(value => (
            <option value={value} key={value}>{camelCase(value)}</option>
          ))}
        </Select>

        <Select
          name="jwt_property_format"
          label={translate('LABEL_CHOOSE_JWT_PROPERTY_FORMAT')}
          value={value.claimPropertyFormat ?? "DEFAULT"}
          onChange={(event) => handleChange('claimPropertyFormat', event)}
          helpText={translate('INFO_OIDC_JWT_PROPERTY_FORMAT')}
        >
          {claimPropertyFormats.map(value => (
          <option value={value} key={value}>{camelCase(value)}</option>
          ))}
        </Select>

      </div>
    </React.Fragment>
  )
}