import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface StorybookArgs {
  id: string,
  screen?: string,
  defaultArgs: {
    language: string,
    stylesheet: string | null,
    customUIScript: string | null,
    customUIStylesheet: string | null,
    'css-class': string | null,
    domain: string,
    action: string
  },
  storyArgs?: {
    [key: string]: string | boolean
  }
}

export function buildStorybookURL(props: StorybookArgs) {
  const {defaultArgs, storyArgs} = props;

  const url = new URL(`${process.env.CRIIPTO_VERIFY_STORYBOOK_AUTHORIY}/iframe.html`);
  url.searchParams.set('id', props.id);
  url.searchParams.set('domain', defaultArgs.domain);
  if (defaultArgs.stylesheet) url.searchParams.set('stylesheet', defaultArgs.stylesheet);
  if (defaultArgs.customUIScript) url.searchParams.set('customUIScript', defaultArgs.customUIScript);
  if (defaultArgs.customUIStylesheet) url.searchParams.set('customUIStylesheet', defaultArgs.customUIStylesheet);
  if (defaultArgs['css-class']) url.searchParams.set('css-class', defaultArgs['css-class']);

  const args = [
    `language:${defaultArgs.language}`,
    `action:${defaultArgs.action}`
  ].concat(Object.keys(storyArgs ?? {}).map(key => {
    const value = storyArgs![key];
    if (typeof value === "boolean") {
      return `${key}:!${value ? 'true' : 'false'}`
    }
    return `${key}:${value}`
  }));

  url.searchParams.set('args', args.join(';'));
  return url;
}

export function StorybookIframeRenderer(props: StorybookArgs & {
  style: React.CSSProperties
}) {
  const url = useMemo(() => buildStorybookURL(props), [props]);
  const [pending, setPending] = useState(true);

  const handleLoad = () => {
    setPending(false);
  }

  useEffect(() => {
    setPending(false);
  }, [url]);

  return (
    <iframe
      key={url.href}
      style={{
        ...props.style,
        visibility: pending ? 'hidden' : 'visible'
      }}
      src={url.href}
      onLoad={handleLoad}
    />
  )
}

export function StorybookWindowRenderer(props: StorybookArgs & {open: boolean, onClose: () => void}) {
  const {open, onClose} = props;
  const url = useMemo(() => buildStorybookURL(props), [props]);
  const [windowRef, setWindow] = useState<Window | null>(null);

  // Open/close effect
  useEffect(() => {
    if (!open) {
      windowRef?.close();
      setWindow(null);
      return;
    }

    if (open) {
      try {
        const windowRef = window.open(undefined, `storybook_window_${props.id}`);
        if (!windowRef) {
          throw new Error('Failed to open window');
        }

        const viewport = document.createElement('meta');
        viewport.setAttribute('name', 'viewport');
        viewport.setAttribute('content', 'width=device-width, initial-scale=1');
        windowRef.document.head.appendChild(viewport);
        console.log(viewport);
        setWindow(windowRef);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }, [open]);

  // Unmount effect
  useEffect(() => {
    return () => {
      windowRef?.close();
    };
  }, []);

  // Handle manual closure of window
  const closeHandler = useCallback(() => {
    onClose();
  }, [onClose]);
  useEffect(() => {
    windowRef?.addEventListener('beforeunload', closeHandler);
    return () => windowRef?.removeEventListener('beforeunload', closeHandler);
  }, [windowRef, closeHandler]);

  // Render/update iframe inside window
  useEffect(() => {
    if (!windowRef) return;
    if (!open) return;

    let iframe = windowRef.document.getElementById('storybook_iframe') as HTMLIFrameElement;
    if (!iframe) {
      windowRef.document.body.style.margin = '0';

      iframe = windowRef.document.createElement('iframe');
      iframe.setAttribute('id', 'storybook_iframe');
      iframe.style.height = '100%';
      iframe.style.width = '100%';
      iframe.style.border = '0';
      console.log(iframe);
      windowRef.document.body.appendChild(iframe);
    }

    iframe.src = url.href;
  }, [windowRef, url.href, open]);

  return null;
}