/**
 * @generated SignedSource<<50c39b769941597024e292fd3e199a31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VerifyApplicationIntegrate_application$data = {
  readonly domain: {
    readonly name: string;
  };
  readonly id: string;
  readonly realm: string;
  readonly technology: string | null;
  readonly technologyGroup: string | null;
  readonly tenant: {
    readonly shortTenantId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationTechnologySelector_application">;
  readonly " $fragmentType": "VerifyApplicationIntegrate_application";
};
export type VerifyApplicationIntegrate_application$key = {
  readonly " $data"?: VerifyApplicationIntegrate_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerifyApplicationIntegrate_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerifyApplicationIntegrate_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technology",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technologyGroup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortTenantId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationTechnologySelector_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "959245868b034b14fbf2edc113f75497";

export default node;
