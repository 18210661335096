/**
 * @generated SignedSource<<c4b275cb7bc446de40a0a746782001f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type ToolsScreen_Query$variables = {
  environment: Environment;
  tenantId: string;
};
export type ToolsScreen_Query$data = {
  readonly tenant: {
    readonly shortTenantId: string;
    readonly viewerPermissions: {
      readonly domains: Permission;
    };
  } | null;
  readonly viewer: {
    readonly isCriiptoAdmin?: boolean;
  } | null;
};
export type ToolsScreen_Query = {
  response: ToolsScreen_Query$data;
  variables: ToolsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTenantId",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCriiptoAdmin",
      "storageKey": null
    }
  ],
  "type": "UserViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ToolsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f88f66ba3007413cdf6ac69a56c849e",
    "id": null,
    "metadata": {},
    "name": "ToolsScreen_Query",
    "operationKind": "query",
    "text": "query ToolsScreen_Query(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    viewerPermissions {\n      domains(environment: $environment)\n    }\n    shortTenantId\n    id\n  }\n  viewer {\n    __typename\n    ... on UserViewer {\n      isCriiptoAdmin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0601136556f6e7df22b31e78ad45d321";

export default node;
