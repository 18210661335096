
import React, { useContext } from 'react';
import {Domain, Environment } from "@app/models";
import SegmentV2, { singleton as segment, Events } from "@app/segment";
import { singleton as config } from "@app/config";
import { IdToken } from "@auth0/auth0-spa-js";
import { TenantId, toLongTenantId, useOptionalTenantId } from './useTenant';
import { useSelector } from '@app/redux';
import * as Sentry from "@sentry/react";

import {ServersideIdentifyRequest, ServersideGroupRequest, ServersideTrackRequest} from '../../../analytics';

interface TrackingProps {
  [index: string]: any
}

class ServersideTracking {
  private identifyUrl = `${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/analytics-identify`;
  private groupUrl = `${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/analytics-group`;
  private trackUrl = `${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/analytics-track`;

  async identify(profile : IdToken, props?: TrackingProps) {
    const request : ServersideIdentifyRequest = {
      user: {
        id: profile.sub,
        email: profile.email!,
        name: profile.name
      },
      properties: props,
      timestamp: (new Date()).toJSON()
    };

    const response = await fetch(this.identifyUrl, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'content-type': 'application/json'
      },
      keepalive: true
    });

    if (response.status >= 400) {
      Sentry.captureException(new Error(`${this.identifyUrl} responded with (${response.status}): ${await response.text()}`));
    }
  }

  async group(profile : IdToken, tenant: {name: string, tenantId: string}) {
    const request : ServersideGroupRequest = {
      user: {
        id: profile.sub
      },
      tenant: {
        tenantId: toLongTenantId(tenant.tenantId),
        name: tenant.name
      },
      timestamp: (new Date()).toJSON()
    };

    const response = await fetch(this.groupUrl, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'content-type': 'application/json'
      },
      keepalive: true
    });

    if (response.status >= 400) {
      Sentry.captureException(new Error(`${this.groupUrl} responded with (${response.status}): ${await response.text()}`));
    }
  }

  async track(profile : IdToken, tenantId: TenantId | null, event: string, props?: TrackingProps) {
    const request : ServersideTrackRequest = {
      user: {
        id: profile.sub
      },
      tenant: tenantId ? {
        tenantId: toLongTenantId(tenantId.tenantId),
      } : undefined,
      event,
      properties: props,
      timestamp: (new Date()).toJSON()
    };

    const response = await fetch(this.trackUrl, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'content-type': 'application/json'
      },
      keepalive: true
    });

    if (response.status >= 400) {
      Sentry.captureException(new Error(`${this.trackUrl} responded with (${response.status}): ${await response.text()}`));
    }
  }
}

const integration =
  config.NETLIFY && config.USE_NETLIFY_FUNCTIONS_TRACKING ?
    new ServersideTracking() :
    config.USE_SEGMENT ? segment : undefined;

export interface Tracking {
  identify: (profile: IdToken, props?: TrackingProps) => void,
  group: (tenant: {name: string, tenantId: string}) => void,
  track: (event: keyof typeof Events, props?: TrackingProps, extra?: {profile?: IdToken, tenant?: TenantId}) => void,

  tenants: (profile: IdToken, tenants: TenantId[]) => void,

  domainCreated: (domain: Domain | {environment: Environment}) => void
  login: (claims: IdToken) => void

  // Screen/View events
  viewedOnboardingScreen: () => void,
  viewedDashboardScreen: () => void,
  viewedAnalyticsScreen: (context: 'domain' | undefined) => void,
  viewedLogsScreen: (context: 'domain' | undefined) => void,
  viewedStylingScreen: () => void,
}

export const TrackingContext = React.createContext({enabled: true});
export const IdentifyCache : {[key: string]: boolean} = {};

export default function useTracking() {
  const trackingContext = useContext(TrackingContext);
  const currentTenantId = useOptionalTenantId();
  const profile = useSelector(state => state.auth.profile);
  const claims = useSelector(state => state.auth.access_token_decoded);
  const enabled = claims?.['http://criipto/customer-support'] !== true && trackingContext.enabled;

  const tracking : Tracking = {
    identify(profile: IdToken, props?: TrackingProps) {
      const key = profile.sub+JSON.stringify(props);
      if (IdentifyCache[key]) return;

      integration?.identify(profile, props);
      IdentifyCache[key] = true;
      console.debug(`Tracking.identify`, profile.sub, props);
    },
    group: (tenant: {name: string, tenantId: string}) => {
      if (integration instanceof SegmentV2) {
        integration.group(tenant);
      } else {
        integration?.group(profile!, tenant);
      }

      console.debug(`Tracking.group`, toLongTenantId(tenant.tenantId));
    },
    track(event : keyof typeof Events, props? : TrackingProps, extra?) {
      const tenantId = extra?.tenant || currentTenantId;
      if (integration && integration instanceof SegmentV2) {
        integration.track(event, {
          ...props,
          tenantId: tenantId ?  toLongTenantId(tenantId.tenantId) : undefined
        });
      } else {
        integration?.track((extra?.profile || profile)!, tenantId || null, Events[event], props);
      }
      console.debug(`Tracking.track`, Events[event], props);
    },

    tenants(profile: IdToken, tenants: TenantId[]) {
      this.identify(profile, {
        tenantId: tenants.map(t => toLongTenantId(t.tenantId))
      });
    },

    domainCreated(domain: Domain | {environment: Environment}) {
      if (!enabled) return;

      if ("environment" in domain) {
        this.track('DOMAIN_REGISTERED', {
          "Production": domain.environment === "PRODUCTION",
          "Environment": domain.environment
        });

        if (domain.environment === 'TEST') {
          this.track('TEST_DOMAIN_CREATED');
        }
        return;
      }

      this.track('DOMAIN_REGISTERED', {
        "Production": domain.production,
        "Environment": domain.production ? "PRODUCTION" : "TEST" as Environment
      });

      if (!domain.production) {
        this.track('TEST_DOMAIN_CREATED');
      }
    },
    login(claims: IdToken) {
      if (!enabled) return;

      this.identify(claims);

      const method = claims.sub ? claims.sub.split("|")[0] : null;
      this.track('SIGN_IN', {
        method
      }, {
        profile: claims
      });
    },
    viewedOnboardingScreen() {
      if (!enabled) return;
      this.track('VIEWED_ONBOARDING_SCREEN');
    },
    viewedDashboardScreen() {
      if (!enabled) return;
      this.track('VIEWED_DASHBOARD_SCREEN');
    },
    viewedAnalyticsScreen(context: 'domain' | undefined) {
      if (!enabled) return;
      this.track('VIEWED_ANALYTICS_SCREEN', {context});
    },
    viewedLogsScreen(context: 'domain' | undefined) {
      if (!enabled) return;
      this.track('VIEWED_LOGS_SCREEN', {context});
    },
    viewedStylingScreen() {
      if (!enabled) return;
      this.track('VIEWED_STYLING_SCREEN');
    }
  };

  return tracking;
}