/**
 * @generated SignedSource<<81b6153f6446f532647fed397033ea21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteInvitationButton_invitation$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "DeleteInvitationButton_invitation";
};
export type DeleteInvitationButton_invitation$key = {
  readonly " $data"?: DeleteInvitationButton_invitation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteInvitationButton_invitation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteInvitationButton_invitation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Invitation",
  "abstractKey": null
};

(node as any).hash = "9b5f62bf4ea26e99190cedf82143feee";

export default node;
