import React from 'react';
import {translate} from '@app/i18n';

import {EnvironmentTag} from '@components/Tag';
import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import { AnchorButton } from '@app/components/Button/Button';

export function FITeliaProvider() {
  return (
    <React.Fragment>
      <EnvironmentDialog environment="TEST" />
      <div className="row">
        <div className="col-sm-6">
          <h3>
            {translate('CONNECT')} Finnish Trust Network, FTN
          </h3>
          <EnvironmentTag />
          <AnchorButton href="https://docs.criipto.com/eid-specifics/order-fi-bankid/" target="_blank" rel="noopener noreferrer" variant="primary">Order Finnish BankID</AnchorButton>
        </div>
        <div className="col-sm-6">
        </div>
      </div>
    </React.Fragment>
  )
}