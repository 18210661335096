
import { BUCKET, DIMENSION, DIMENSIONS } from '@app/api/subscription';
import React from 'react';
import { BUCKET_SETTINGS, DIMENSION_SETTINGS } from '../../constants';
import styles from './ChartTooltip.module.css';

interface Props {
  active: boolean
  label: string,
  bucket: BUCKET,
  dataDimension: DIMENSION,
  payload: any[],
  selectedDataDimensionValues: string[]
}

export default function ChartTooltip(props: Props) {
  const {active, label, payload, bucket, dataDimension, selectedDataDimensionValues} = props;

  if (!active) return null;
  if (!payload || !payload.length) return null;
  const total = Object.keys(payload[0].payload.dimension[dataDimension])
      .filter(search => selectedDataDimensionValues.includes(search))
      .reduce((total, key) => total + payload[0].payload.dimension[dataDimension][key], 0);

  const dimensionSettings = DIMENSION_SETTINGS[dataDimension];
  const valueLabel = dimensionSettings._valueLabel;

  return (
    <div className={styles["chart-tooltip"]}>
      <div className={styles["label"]}>{BUCKET_SETTINGS[bucket].tooltipFormatter(label)}</div>
      <div className={styles["dimension"]}>Total: {total}</div>
      {payload.map(item => (
        <div className={styles["dimension"]} key={item.name}>
          <div className={styles["color-box"]} style={{background: item.color}} />

          {`${valueLabel ? valueLabel(item.name.replace(`dimension.${dataDimension}.`, '')) : item.name.replace(`dimension.${dataDimension}.`, '')} : ${item.value}`}
        </div>
      ))}
    </div>
  );
}