/**
 * @generated SignedSource<<0557e4b6075f19d77221eafe5d9f8c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CprStrategy = "ASKUSER" | "OMIT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Configuration_identityProvider$data = {
  readonly brokerLandingPageDomain?: string | null;
  readonly cprIsOptional?: boolean;
  readonly cprLookupStrategy?: CprStrategy;
  readonly disableSignatories?: boolean;
  readonly id: string;
  readonly includePostalAddress?: boolean;
  readonly longName: string;
  readonly nemIdDomain?: string | null;
  readonly referenceText?: string | null;
  readonly serviceProviderId?: string | null;
  readonly serviceProviderReference?: string | null;
  readonly showOnNemId?: boolean;
  readonly tenant: {
    readonly id: string;
  };
  readonly " $fragmentType": "Configuration_identityProvider";
};
export type Configuration_identityProvider$key = {
  readonly " $data"?: Configuration_identityProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"Configuration_identityProvider">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Configuration_identityProvider",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longName",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brokerLandingPageDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nemIdDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cprLookupStrategy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cprIsOptional",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includePostalAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableSignatories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showOnNemId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceProviderId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceProviderReference",
          "storageKey": null
        }
      ],
      "type": "DanishMitID",
      "abstractKey": null
    }
  ],
  "type": "IdentityProvider",
  "abstractKey": "__isIdentityProvider"
};
})();

(node as any).hash = "f8a4236a662efd6ab1a1d6aa7c2010f1";

export default node;
