/**
 * @generated SignedSource<<a2e22b78c7c89b2bc151974e40b32649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type routes_ViewerQuery$variables = {
  skip: boolean;
};
export type routes_ViewerQuery$data = {
  readonly viewer?: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
      readonly longTenantId: string;
      readonly shortTenantId: string;
    }>;
  } | null;
};
export type routes_ViewerQuery = {
  response: routes_ViewerQuery$data;
  variables: routes_ViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longTenantId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_ViewerQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_ViewerQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "3d21e1c12c70ca2a5305d727687b6f68",
    "id": null,
    "metadata": {},
    "name": "routes_ViewerQuery",
    "operationKind": "query",
    "text": "query routes_ViewerQuery(\n  $skip: Boolean!\n) {\n  viewer @skip(if: $skip) {\n    __typename\n    tenants {\n      id\n      shortTenantId\n      longTenantId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "913072752d9b714b66b2d04e05ce27cd";

export default node;
