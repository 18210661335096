/**
 * @generated SignedSource<<7eed6a9c0755f0342cbcf8c22d26079a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyApplicationButton_application$data = {
  readonly id: string;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"CopyApplicationModal_application">;
  readonly " $fragmentType": "CopyApplicationButton_application";
};
export type CopyApplicationButton_application$key = {
  readonly " $data"?: CopyApplicationButton_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"CopyApplicationButton_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CopyApplicationButton_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CopyApplicationModal_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "8ffe750a58453b1ec560c9b6a4c550d2";

export default node;
