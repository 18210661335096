import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import {LOGOUT_URL} from '@app/constants';
import { translate } from '@app/i18n';

import { useSelector } from '@app/redux';

import { useVerifyAccessToken } from '@app/hooks/useAuth';
import { HeaderQuery } from './__generated__/HeaderQuery.graphql';
import { useOptionalEnvironment } from '@app/hooks/useEnvironment';
import { upperFirst } from 'lodash';

import cx from 'classnames';
import styles from './Header.module.css';

export default function Header(props: {isNavOpen?: boolean; onHamburger?: () => void}) {
  const location = useLocation();
  const authClient = useSelector(state => state.auth.client);
  const token = useVerifyAccessToken();
  const environmentCandidate = useOptionalEnvironment();

  const skipGraphQL = !token;
  const data = useLazyLoadQuery<HeaderQuery>(
    graphql`
      query HeaderQuery($skip: Boolean!) {
        viewer @skip (if: $skip) {
          ... on UserViewer {
            name
            email
            isCriiptoAdmin

            organizations {
              name
              shortId
            }
          }
        }
      }
    `,
    {
      skip: skipGraphQL
    },
    {
      fetchKey: token ?? undefined
    }
  );

  const viewer = data.viewer;

  const handleLogout = () => {
    authClient.logout({
      returnTo: LOGOUT_URL
    });
  };

  const [showDropDown, setShowDropDown] = useState(false);
  const handleDropDownToggle = (isOpen: boolean) => {
    setShowDropDown(isOpen);
  };

  const isGlobalScreen = location.pathname.includes('/users') || location.pathname.includes('/billing') || location.pathname.includes('tools/criipto-admin');
  const isTenantScreen = location.pathname.startsWith('/tenant');
  const showEnvironment = isTenantScreen && !isGlobalScreen;
  const environment = showEnvironment ? (environmentCandidate ?? 'TEST') : null;

  if (!viewer) return null;

  return (
    <header className={cx(styles.header, environment ? styles[environment] : undefined)}>
      <div className={styles['toolbar']}>
        <div className="flex align-items-center gap-[16px]">
          {props.onHamburger ? (
            <div className={styles['sidebar-hamburger']} onClick={props.onHamburger}>
              {!props.isNavOpen ? (
                <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path fill="currentColor" d="M9 13h15v2H9zM9 19h22v2H9zM9 25h12v2H9z"/></svg>
              ) : (
                <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><g filter="url(#b)" clip-path="url(#a)"><path d="M15.188 13.813 20 18.593l4.781-4.78.719-.72 1.406 1.407-.718.719L21.405 20l4.782 4.813.718.687-1.406 1.438-.719-.72L20 21.439l-4.813 4.78-.687.72-1.438-1.438.72-.688L18.561 20l-4.78-4.781-.72-.719 1.438-1.406.688.719Z" fill="#424974"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h40v40H0z"/></clipPath><filter id="b" x="-2" y="-1" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0.14902 0 0 0 0 0.180392 0 0 0 0 0.321569 0 0 0 0.05 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5579_44893"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_5579_44893" result="shape"/></filter></defs></svg>
              )}
            </div>
          ) : null}
          <div className="flex align-items-center justify-content-center gap-[16px]">
            <Link to="/">
              <img src="/assets/images/logomark.svg" alt="Criipto" />
            </Link>

            {environment && showEnvironment && (
              <span>{upperFirst(environment.toLowerCase())} environment</span>
            )}
          </div>
        </div>

        <nav id="nav" className={styles['tenant-dropdown']}>
          <div className={styles['nav-drop']}>
            <ul className={styles['admin-menu']}>
              <Dropdown id="tenant_dropdown" componentClass={'li'} onToggle={handleDropDownToggle} open={showDropDown}>
                <Dropdown.Toggle useAnchor={true} noCaret={true}>
                  <span className={styles['hidden-xs']}>{viewer.name ?? 'Your account'}&nbsp;</span>
                  <i className="fa fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles['dropdown-menu']}>
                  {viewer.email && (
                    <React.Fragment>
                      <li>
                        <div className={styles['menu-option']}>
                          {viewer.email}
                        </div>
                      </li>
                      <li className={styles['menu-seperator']} />
                    </React.Fragment>
                  )}
                  <li>
                    <Link to={`/user/mfa`} className={styles['menu-option']}>
                      Enroll MFA
                    </Link>
                  </li>
                  <li className={styles['menu-seperator']} />
                  {viewer.organizations?.length ? (
                    <React.Fragment>
                      {viewer.organizations.map(organization => (
                        <li key={organization.shortId}>
                          <Link to={`/organization/${organization.shortId}`} className={styles['menu-option']}>
                            {organization.name}
                          </Link>
                        </li>
                      ))}
                      <li className={styles['menu-seperator']} />
                    </React.Fragment>
                  ) : null}
                  {viewer.isCriiptoAdmin && (
                    <li>
                      <Link to="/admin" className={styles['menu-option']}>
                        Admin
                      </Link>
                    </li>
                  )}
                  <li>
                    <div className={styles['menu-option']} onClick={handleLogout} style={{ cursor: 'pointer' }}>
                      <span>
                        {translate('LOG_OUT')}
                      </span>
                    </div>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
