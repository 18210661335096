/**
 * @generated SignedSource<<5d7bf2dfac065b35c914574a6368c04a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
export type ConfirmPaymentIntentInput = {
  paymentIntentId: string;
  subscriptionId: string;
};
export type SignupScreen_Billing_ConfirmMutation$variables = {
  input: ConfirmPaymentIntentInput;
};
export type SignupScreen_Billing_ConfirmMutation$data = {
  readonly confirmPaymentIntent: {
    readonly subscription: {
      readonly status: SubscriptionStatus;
      readonly " $fragmentSpreads": FragmentRefs<"ConfirmPaymentButton_subscription" | "SubscriptionPlanModal_subscription">;
    };
    readonly tenant: {
      readonly ageverificationEnvironments: ReadonlyArray<Environment>;
      readonly billing: {
        readonly subscription: {
          readonly isCancelling: boolean;
          readonly periodEndAt: string;
          readonly periodStartAt: string;
          readonly status: SubscriptionStatus;
          readonly " $fragmentSpreads": FragmentRefs<"SubscriptionPlanModal_subscription" | "SubscriptionScreen_ProductPanel_subscription">;
        } | null;
      } | null;
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  };
};
export type SignupScreen_Billing_ConfirmMutation = {
  response: SignupScreen_Billing_ConfirmMutation$data;
  variables: SignupScreen_Billing_ConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SubscriptionPlanModal_subscription"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v5 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v6 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v7 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelling",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodStartAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodEndAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionProduct",
  "kind": "LinkedField",
  "name": "products",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Plan",
      "kind": "LinkedField",
      "name": "plan",
      "plural": false,
      "selections": [
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupScreen_Billing_ConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPaymentIntentOutput",
        "kind": "LinkedField",
        "name": "confirmPaymentIntent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfirmPaymentButton_subscription"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Billing",
                "kind": "LinkedField",
                "name": "billing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingSubscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SubscriptionScreen_ProductPanel_subscription"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupScreen_Billing_ConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPaymentIntentOutput",
        "kind": "LinkedField",
        "name": "confirmPaymentIntent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v11/*: any*/),
              (v8/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentIntent",
                "kind": "LinkedField",
                "name": "paymentIntent",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secret",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Billing",
                "kind": "LinkedField",
                "name": "billing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingSubscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c8f5efe241a3bfa175f1f65ff8f1498",
    "id": null,
    "metadata": {},
    "name": "SignupScreen_Billing_ConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation SignupScreen_Billing_ConfirmMutation(\n  $input: ConfirmPaymentIntentInput!\n) {\n  confirmPaymentIntent(input: $input) {\n    subscription {\n      status\n      ...SubscriptionPlanModal_subscription\n      ...ConfirmPaymentButton_subscription\n      id\n    }\n    tenant {\n      environments\n      verifyEnvironments: environments(product: Verify)\n      signaturesEnvironments: environments(product: Signatures)\n      ageverificationEnvironments: environments(product: [Ageverification_DK])\n      billing {\n        subscription {\n          status\n          isCancelling\n          periodStartAt\n          periodEndAt\n          ...SubscriptionPlanModal_subscription\n          ...SubscriptionScreen_ProductPanel_subscription\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment ConfirmPaymentButton_subscription on BillingSubscription {\n  id\n  paymentIntent {\n    id\n    status\n    secret\n    subscriptionId\n  }\n}\n\nfragment RemoveProductPlanModal_subscription on BillingSubscription {\n  id\n}\n\nfragment SubscriptionPlanModal_subscription on BillingSubscription {\n  id\n  isCancelling\n  currency\n  interval\n  products {\n    plan {\n      id\n    }\n    id\n  }\n}\n\nfragment SubscriptionScreen_ProductPanel_subscription on BillingSubscription {\n  status\n  ...SubscriptionPlanModal_subscription\n  ...RemoveProductPlanModal_subscription\n}\n"
  }
};
})();

(node as any).hash = "c0d2dfd92c4f047edbcfef065adbec87";

export default node;
