/**
 * @generated SignedSource<<86ba3013179cff33b852d712f2d0d092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomerSupportRedirectScreenQuery$variables = {};
export type CustomerSupportRedirectScreenQuery$data = {
  readonly viewer: {
    readonly isCriiptoAdmin?: boolean;
  } | null;
};
export type CustomerSupportRedirectScreenQuery = {
  response: CustomerSupportRedirectScreenQuery$data;
  variables: CustomerSupportRedirectScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCriiptoAdmin",
      "storageKey": null
    }
  ],
  "type": "UserViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerSupportRedirectScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerSupportRedirectScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df29c2c2021a4033e7a1b3b1169232bd",
    "id": null,
    "metadata": {},
    "name": "CustomerSupportRedirectScreenQuery",
    "operationKind": "query",
    "text": "query CustomerSupportRedirectScreenQuery {\n  viewer {\n    __typename\n    ... on UserViewer {\n      isCriiptoAdmin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5940406bc698eb8b156f31a5469d3bb";

export default node;
