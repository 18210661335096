/**
 * @generated SignedSource<<a33905041b1253d81c3441b66041ef82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeleteOrganizationApiKeyButton_organization$data = {
  readonly id: string;
  readonly viewerPermissions: {
    readonly productionApi: Permission;
    readonly testApi: Permission;
  };
  readonly " $fragmentType": "DeleteOrganizationApiKeyButton_organization";
};
export type DeleteOrganizationApiKeyButton_organization$key = {
  readonly " $data"?: DeleteOrganizationApiKeyButton_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteOrganizationApiKeyButton_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteOrganizationApiKeyButton_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerOrganizationPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": "testApi",
          "args": [
            {
              "kind": "Literal",
              "name": "environment",
              "value": "TEST"
            }
          ],
          "kind": "ScalarField",
          "name": "api",
          "storageKey": "api(environment:\"TEST\")"
        },
        {
          "alias": "productionApi",
          "args": [
            {
              "kind": "Literal",
              "name": "environment",
              "value": "PRODUCTION"
            }
          ],
          "kind": "ScalarField",
          "name": "api",
          "storageKey": "api(environment:\"PRODUCTION\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "48f2868de8c4c2b6929b04d17b497411";

export default node;
