/**
 * @generated SignedSource<<2dc8cee678966d62f9190cd2bd8c43f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type OrganizationUsageScreenQuery$variables = {
  environment: Environment;
  from: string;
  organizationId: string;
  to: string;
};
export type OrganizationUsageScreenQuery$data = {
  readonly admin: {
    readonly organization: {
      readonly tenants: ReadonlyArray<{
        readonly id: string;
        readonly tenant: {
          readonly name: string;
          readonly usage: ReadonlyArray<{
            readonly authentications: number;
            readonly bucket: string;
            readonly signatures: number;
          }>;
        };
      }>;
    } | null;
  };
};
export type OrganizationUsageScreenQuery = {
  response: OrganizationUsageScreenQuery$data;
  variables: OrganizationUsageScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "environment",
      "variableName": "environment"
    },
    {
      "kind": "Variable",
      "name": "from",
      "variableName": "from"
    },
    {
      "kind": "Literal",
      "name": "interval",
      "value": "MONTH"
    },
    {
      "kind": "Variable",
      "name": "to",
      "variableName": "to"
    }
  ],
  "concreteType": "Usage",
  "kind": "LinkedField",
  "name": "usage",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bucket",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authentications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatures",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationUsageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78b4d97f8007710261e34c048ce037a8",
    "id": null,
    "metadata": {},
    "name": "OrganizationUsageScreenQuery",
    "operationKind": "query",
    "text": "query OrganizationUsageScreenQuery(\n  $organizationId: ID!\n  $environment: Environment!\n  $from: String!\n  $to: String!\n) {\n  admin {\n    organization(id: $organizationId) {\n      tenants {\n        id\n        tenant {\n          name\n          usage(environment: $environment, interval: MONTH, from: $from, to: $to) {\n            bucket\n            authentications\n            signatures\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06553f85a4247e1eed76dfd1b204b97c";

export default node;
