/**
 * @generated SignedSource<<3ab79b9ed101437445117bba5ab40ea5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type UserPermission = "CONFIGURATION_MANAGEMENT" | "EXTENSIONS_PRODUCTION_READ" | "EXTENSIONS_PRODUCTION_WRITE" | "EXTENSIONS_TEST_READ" | "EXTENSIONS_TEST_WRITE" | "FINANCIALS" | "PRIVATE_KEY_MANAGEMENT" | "REPORTING" | "USER_ADMIN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvitationForm_tenant$data = {
  readonly id: string;
  readonly userPermissions: ReadonlyArray<UserPermission>;
  readonly viewerPermissions: {
    readonly users: Permission;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Permissions_tenant">;
  readonly " $fragmentType": "InvitationForm_tenant";
};
export type InvitationForm_tenant$key = {
  readonly " $data"?: InvitationForm_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationForm_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationForm_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerTenantPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "users",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Permissions_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "e697ad625bfa00e29cb21b5a2c63a7df";

export default node;
