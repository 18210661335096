/**
 * @generated SignedSource<<eeb740d0e9b6ba1b2c04d0a3a8d5cfac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PrepareDomainKeyInput = {
  domainId: string;
};
export type TokenSigningKeysPrepareMutation$variables = {
  input: PrepareDomainKeyInput;
};
export type TokenSigningKeysPrepareMutation$data = {
  readonly prepareDomainKey: {
    readonly __typename: "PrepareDomainKeyOutput";
    readonly domain: {
      readonly keys: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly active: boolean;
            readonly certificate: {
              readonly pkcs7: string;
              readonly validFrom: string;
              readonly validTo: string;
            };
            readonly expired: boolean;
            readonly id: string;
            readonly kid: string;
            readonly mayActivate: boolean;
            readonly mayActivateAt: string;
          };
        }>;
        readonly mayPrepare: boolean;
      };
    };
  } | {
    readonly __typename: "TimeoutError";
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type TokenSigningKeysPrepareMutation = {
  response: TokenSigningKeysPrepareMutation$data;
  variables: TokenSigningKeysPrepareMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "DomainKeyConnection",
  "kind": "LinkedField",
  "name": "keys",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mayPrepare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainKeyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainKey",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mayActivate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mayActivateAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BasicCertificate",
              "kind": "LinkedField",
              "name": "certificate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validFrom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pkcs7",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "keys(first:1000)"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "TimeoutError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TokenSigningKeysPrepareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "prepareDomainKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "PrepareDomainKeyOutput",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TokenSigningKeysPrepareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "prepareDomainKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "PrepareDomainKeyOutput",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abbc54213f27993e41e0966d7e2d9696",
    "id": null,
    "metadata": {},
    "name": "TokenSigningKeysPrepareMutation",
    "operationKind": "mutation",
    "text": "mutation TokenSigningKeysPrepareMutation(\n  $input: PrepareDomainKeyInput!\n) {\n  prepareDomainKey(input: $input) {\n    __typename\n    ... on PrepareDomainKeyOutput {\n      domain {\n        keys(first: 1000) {\n          mayPrepare\n          edges {\n            node {\n              id\n              kid\n              active\n              expired\n              mayActivate\n              mayActivateAt\n              certificate {\n                validFrom\n                validTo\n                pkcs7\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    ... on TimeoutError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "340cf5f314480b4e2acdde81ee87f886";

export default node;
