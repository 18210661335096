/**
 * @generated SignedSource<<28285b9e2ce06dc90fb59c49f7932f20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionScreen_ProductPanel_subscription$data = {
  readonly status: SubscriptionStatus;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveProductPlanModal_subscription" | "SubscriptionPlanModal_subscription">;
  readonly " $fragmentType": "SubscriptionScreen_ProductPanel_subscription";
};
export type SubscriptionScreen_ProductPanel_subscription$key = {
  readonly " $data"?: SubscriptionScreen_ProductPanel_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_subscription">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionScreen_ProductPanel_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubscriptionPlanModal_subscription"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveProductPlanModal_subscription"
    }
  ],
  "type": "BillingSubscription",
  "abstractKey": null
};

(node as any).hash = "ae2c679df3e10e6281880151967a9e2d";

export default node;
