/**
 * @generated SignedSource<<57fd52373e382422983eee898d37271f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type OnboardingScreenQuery$variables = {
  tenantId: string;
};
export type OnboardingScreenQuery$data = {
  readonly tenant: {
    readonly onboarding: {
      readonly products: ReadonlyArray<Product>;
    };
  } | null;
};
export type OnboardingScreenQuery = {
  response: OnboardingScreenQuery$data;
  variables: OnboardingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantOnboarding",
  "kind": "LinkedField",
  "name": "onboarding",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "products",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca2c8cc6323f75501f61b71807be46ae",
    "id": null,
    "metadata": {},
    "name": "OnboardingScreenQuery",
    "operationKind": "query",
    "text": "query OnboardingScreenQuery(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    onboarding {\n      products\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a542de8d3de9fd1e83cf9adaf5ad59b";

export default node;
