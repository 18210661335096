/**
 * @generated SignedSource<<8abdea1f627cf2cfb0ef4bf5b72a9d8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefreshApiKeyButton_application$data = {
  readonly id: string;
  readonly " $fragmentType": "RefreshApiKeyButton_application";
};
export type RefreshApiKeyButton_application$key = {
  readonly " $data"?: RefreshApiKeyButton_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefreshApiKeyButton_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefreshApiKeyButton_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Signatures_Application",
  "abstractKey": null
};

(node as any).hash = "1dba49a9c9490ecfb53159ca6850ccc5";

export default node;
