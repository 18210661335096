/**
 * @generated SignedSource<<c1f7164e17c4ae83dbc7e0b130bfffcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiKeyModal_apiKey$data = {
  readonly clientId: string;
  readonly clientSecret: string | null;
  readonly " $fragmentType": "ApiKeyModal_apiKey";
};
export type ApiKeyModal_apiKey$key = {
  readonly " $data"?: ApiKeyModal_apiKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApiKeyModal_apiKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiKeyModal_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSecret",
      "storageKey": null
    }
  ],
  "type": "ApplicationApiKey",
  "abstractKey": null
};

(node as any).hash = "bd017f691493bb4d99f120719517e4b8";

export default node;
