interface SerializableConstructor<T> {
  new(): { serialize(data: any): T };
}

interface DeserializableConstructor<T> {
  new(): { deserialize(data: any): T };
}

export function deserializer<T, D = any>(to: DeserializableConstructor<T>) {
  return (data: D) => new to().deserialize(data);
}

export function serializer<T>(to: SerializableConstructor<T>) {
  return (data: any) => new to().serialize(data);
}

export function multiDeserializer<T>(to: DeserializableConstructor<T>) {
  return (data: any[]) => data.map(x => deserializer(to)(x));
}

export function propMultiDeserializer<T>(to: DeserializableConstructor<T>, prop: string) {
  return (data: any) => multiDeserializer(to)(data[prop]);
}