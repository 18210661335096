/**
 * @generated SignedSource<<8be2395676bf9248f62ff00a82d3508a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddOrganizationTenantModal_TenantSearchQuery$variables = {
  limit: number;
  query: string;
  skip: boolean;
};
export type AddOrganizationTenantModal_TenantSearchQuery$data = {
  readonly admin?: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
      readonly organization: {
        readonly id: string;
      } | null;
      readonly tenant: {
        readonly entityIdentifier: string;
        readonly longTenantId: string;
        readonly name: string;
        readonly shortTenantId: string;
      };
    }> | null;
  };
};
export type AddOrganizationTenantModal_TenantSearchQuery = {
  response: AddOrganizationTenantModal_TenantSearchQuery$data;
  variables: AddOrganizationTenantModal_TenantSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTenantId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longTenantId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityIdentifier",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminOrganization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOrganizationTenantModal_TenantSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminQuery",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddOrganizationTenantModal_TenantSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminQuery",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "60c8eb4945c1db40c7883c2bab9d76c4",
    "id": null,
    "metadata": {},
    "name": "AddOrganizationTenantModal_TenantSearchQuery",
    "operationKind": "query",
    "text": "query AddOrganizationTenantModal_TenantSearchQuery(\n  $query: String!\n  $limit: Int!\n  $skip: Boolean!\n) {\n  admin @skip(if: $skip) {\n    tenants(query: $query, limit: $limit) {\n      id\n      tenant {\n        name\n        shortTenantId\n        longTenantId\n        entityIdentifier\n        id\n      }\n      organization {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b0f93e6d877b72105cbbbf6bbf5408c";

export default node;
