import React, {useEffect, useState} from 'react';

export interface PromiseState {
  pending: boolean,
  error: Error | null
}

export const initialPromiseState : PromiseState = {
  pending : false,
  error : null
}

type SetState = React.Dispatch<React.SetStateAction<PromiseState>>

export function setPromiseState<T>(promise : Promise<T>, setState : SetState) {
  promise.then(() => {
    setState({pending: false, error: null});
  }).catch(error => {
    setState({pending: false, error});
  });

  return promise;
}

type Callback<Argument, Response> = (arg?: Argument) => Promise<Response>;
type Trigger<Argument, Response> = (arg?: Argument) => Promise<Response>;
type Reset = () => void;
export default function usePromise<Response = any, Argument = any>(callback : Callback<Argument, Response>, deps: any[] | false) : [Response | null, PromiseState, Trigger<Argument, Response>, Reset] {
  const [response, setResponse] = useState<Response | null>(null);
  const [pending, setPending] = useState(deps === false ? false : true);
  const [error, setError] = useState<Error | null>(null);
  const trigger = (arg?: Argument) => {
    setPending(true);
    return Promise.resolve(callback(arg)).then((response: Response) => {
      setResponse(response);
      setError(null);
      setPending(false);
      return response;
    }).catch(error => {
      setResponse(null);
      setError(error);
      setPending(false);
      return Promise.reject(error);
    });
  };

  const reset = () => {
    setPending(false);
    setError(null);
    setResponse(null);
  }

  useEffect(() => {
    if (deps !== false) {
      trigger();
    }
  }, deps === false ? [] : deps);

  return [response, {pending, error}, trigger, reset];
}