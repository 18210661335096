export class EasyIdConfig {
  apiBase: string;
  subscriptionBase: string;
  subscriptionAuth0ClientID: string;
  subscriptionAuth0Audience: string;
  subscriptionGaussAppId: string;
  auth0Dns: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0ConsentScope: string;
  auth0ConsentCallback: string;
  easyIdDns: string;
  slackChannel: string;
  supportSlackUrl: string;
  applicationName: string;
  hideNorwegianBankIdOidcSettings: string;
  enableHeadsUpNotification: string;
  norwegianBankIdOidc: boolean;
  enableProviderStylingPreview: boolean;
  enableItsme: boolean;
  enableDKMitID: boolean;
  enableGermanSofort: boolean;
  enableFinnishTelia: boolean;
  enableBelgianEID: boolean;
  enableOnboardingMitID: boolean;
  enableExtensibility: boolean;
  enableDynamicScope: boolean;
  nemIdVerifyDomain: string;
  nemIdVerifyRealm: string;
  signaturesEnabled: boolean;
  signaturesGraphQLEndpoint: string;
  segmentToken?: string;

  STRIPE_PK: string;
  SLACK_JOIN_URL: string;
  NETLIFY: boolean;
  USE_NETLIFY_FUNCTIONS_TRACKING: boolean;
  USE_SEGMENT: boolean;
  CRIIPTO_QR_INTERMEDIEARY: string;
  CRIIPTO_QR_DEMO: string;

  constructor() {
    this.applicationName = "Criipto Verify"
    this.readAll();
  }

  readAll() {
    this.apiBase = process.env.apiBase!;
    this.subscriptionBase = process.env.subscriptionBase!;
    this.subscriptionAuth0ClientID = process.env.subscriptionAuth0ClientID!;
    this.subscriptionAuth0Audience = process.env.subscriptionAuth0Audience!;
    this.subscriptionGaussAppId = process.env.subscriptionGaussAppId!;
    this.auth0Dns = process.env.auth0Dns!;
    this.auth0ClientId = process.env.auth0ClientId!;
    this.auth0Audience = process.env.auth0Audience!;
    this.auth0ConsentScope = process.env.auth0ConsentScope!;
    this.auth0ConsentCallback = process.env.auth0ConsentCallback!;
    this.easyIdDns = process.env.easyIdDns ?? "grean.id"!;
    this.slackChannel = process.env.slackChannel ?? "C288XEY4U"; // defaults to #easyid-support
    this.supportSlackUrl = process.env.supportSlackUrl ?? "https://grean.slack.com";
    this.enableHeadsUpNotification = process.env.enableHeadsUpNotification!;
    this.hideNorwegianBankIdOidcSettings = process.env.hideNorwegianBankIdOidcSettings!;
    this.norwegianBankIdOidc = process.env.norwegianBankIdOidc === "true" ? true : false;
    this.enableProviderStylingPreview = process.env.enableProviderStylingPreview === "true" ? true : false;
    this.enableItsme = process.env.enableItsme === "true" ? true : false;
    this.enableDKMitID = process.env.enableDKMitID === "true" ? true : false;
    this.enableGermanSofort = process.env.enableGermanSofort === "true" ? true : false;
    this.enableFinnishTelia = process.env.enableFinnishTelia === "true" ? true : false;
    this.enableBelgianEID = process.env.enableBelgianEID === "true" ? true : false;
    this.enableOnboardingMitID = process.env.enableOnboardingMitID === "true" ? true : false;
    this.enableExtensibility = process.env.enableExtensibility === "true" ? true : false;
    this.enableDynamicScope = process.env.enableDynamicScope === "true" ? true : false;
    this.nemIdVerifyDomain = process.env.nemIdVerifyDomain!;
    this.nemIdVerifyRealm = process.env.nemIdVerifyRealm!;
    this.signaturesGraphQLEndpoint = process.env.signaturesGraphQLEndpoint!;
    this.signaturesEnabled = this.signaturesGraphQLEndpoint && this.signaturesGraphQLEndpoint.length ? true : false;
    this.STRIPE_PK = process.env.STRIPE_PK!;
    this.SLACK_JOIN_URL = process.env.SLACK_JOIN_URL!;
    this.NETLIFY = !!process.env.NETLIFY;
    this.USE_NETLIFY_FUNCTIONS_TRACKING = !!process.env.USE_NETLIFY_FUNCTIONS_TRACKING;
    this.USE_SEGMENT = !process.env.USE_NETLIFY_FUNCTIONS_TRACKING;
    this.segmentToken = process.env.segmentToken;
    this.CRIIPTO_QR_INTERMEDIEARY = process.env.CRIIPTO_QR_INTERMEDIEARY!;
    this.CRIIPTO_QR_DEMO = process.env.CRIIPTO_QR_DEMO!;
  }
}

export const singleton = new EasyIdConfig();