/**
 * @generated SignedSource<<aac24b8c3f7ffab8d3a1d1249c0f7ecd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationsScreen_AdminOrganizationItem_organization$data = {
  readonly id: string;
  readonly organization: {
    readonly name: string;
  };
  readonly " $fragmentType": "OrganizationsScreen_AdminOrganizationItem_organization";
};
export type OrganizationsScreen_AdminOrganizationItem_organization$key = {
  readonly " $data"?: OrganizationsScreen_AdminOrganizationItem_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationsScreen_AdminOrganizationItem_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationsScreen_AdminOrganizationItem_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminOrganization",
  "abstractKey": null
};

(node as any).hash = "4809f69716b7ed207ec95b325b3e6841";

export default node;
