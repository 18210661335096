/**
 * @generated SignedSource<<efe7666ded2f057d5572da059f1f5740>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignaturesApplicationCreator_tenant$data = {
  readonly features: {
    readonly DKNEMID_ENABLED: boolean;
    readonly FRENCH_PUBLIK: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DomainField_tenant">;
  readonly " $fragmentType": "SignaturesApplicationCreator_tenant";
};
export type SignaturesApplicationCreator_tenant$key = {
  readonly " $data"?: SignaturesApplicationCreator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignaturesApplicationCreator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignaturesApplicationCreator_tenant",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "FragmentSpread",
      "name": "DomainField_tenant"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "FRENCH_PUBLIK",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "DKNEMID_ENABLED",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "a8925b01f977eaff2a46b1aecae0660b";

export default node;
