import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button from "@app/components/Button";
import Tooltip from "@app/components/Tooltip";
import React, { useReducer } from "react";
import EditUserModal from "../EditUserModal/EditUserModal";
import { EditUserButton_tenant$key } from './__generated__/EditUserButton_tenant.graphql';
import { EditUserButton_user$key } from './__generated__/EditUserButton_user.graphql';

type Props = {
  tenant: EditUserButton_tenant$key,
  user: EditUserButton_user$key,
}

export default function EditUserButton(props: Props) {
  const tenant = useFragment(
    graphql`
      fragment EditUserButton_tenant on Tenant {
        ...EditUserModal_tenant
        viewerPermissions {
          users
        }
      }
    `,
    props.tenant
  );

  const user = useFragment(
    graphql`
      fragment EditUserButton_user on User {
        ...EditUserModal_user
      }
    `,
    props.user
  );

  const [showModal, toggleModal] = useReducer(value => !value, false);
  if (tenant.viewerPermissions.users !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Tooltip tooltip="Edit user" id="edit_user">
        <Button variant="default" className="button-small" autoWidth onClick={toggleModal}><i className="fa fa-edit" /></Button>
      </Tooltip>
      {showModal && (<EditUserModal open={true} onHide={toggleModal} tenant={tenant} user={user} />)}
    </React.Fragment>
  );
}