import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import {Environment} from '@app/models';

import Configuration from './Configuration/Configuration';
import Apply from './Apply/Apply';
import { DKMitIDProviderQuery } from './__generated__/DKMitIDProviderQuery.graphql';
import { useTenantId } from '@app/hooks/useTenant';
import CustomDomain from './CustomDomain';
import { StripeElementsProvider } from '@app/stripe';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';

interface Props {
  environment: Environment
}

export function DKMitIDProvider(props: Props) {
  const {environment} = props;
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<DKMitIDProviderQuery>(
    graphql`
      query DKMitIDProviderQuery($idpId: ID!, $tenantId: ID!, $environment: Environment!, $production: Boolean!) {
        identityProvider(id: $idpId) {
          id
          tenant {
            id
          }

          longName

          ... on DanishMitID {
            brokerLandingPageDomain
            serviceProviderId
          }

          ... Configuration_identityProvider
          ... Apply_MitID_identityProvider
          ... CustomDomain_DKMitID_identityProvider @include(if: $production)
        }

        tenant(id: $tenantId) {
          longTenantId
          ... Configuration_tenant
          ... Apply_MitID_tenant
          ... CustomDomain_DKMitID_tenant @include(if: $production)
        }
      }
    `,
    {
      environment: props.environment,
      production: props.environment === 'PRODUCTION',
      tenantId: btoa(`tenant:${tenantId.tenantId}`),
      idpId: btoa(`identityProvider:${tenantId.tenantId}|${props.environment}|DK_MITID`)
    }
  );

  const {identityProvider} = data;
  if (!data.tenant) return null;
  if (!identityProvider) return null;

  if (identityProvider.serviceProviderId) {
    return (
      <React.Fragment>
        <Configuration environment={environment} tenant={data.tenant} identityProvider={identityProvider} />
        {environment === 'PRODUCTION' ? (
          <GraphQLErrorBoundary>
            <StripeElementsProvider>
              <CustomDomain className="mt-[25px]" tenant={data.tenant} identityProvider={identityProvider} environment={environment} />
            </StripeElementsProvider>
          </GraphQLErrorBoundary>
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <Apply tenant={data.tenant} identityProvider={identityProvider} />
  );
}