import { isClientSecretTechnology, isClientSecretTechnologyGroup, TechnologyGroupTag, TechnologyTag, technologyTags } from '@app/models';
import React from 'react';

export type TechnologyChoice = TechnologyTag | `technology:other:${string | undefined}`;

export function isOtherTechnologyChoice(input: TechnologyChoice): input is `technology:other:${string | undefined}` {
  return input.startsWith('technology:other:');
}

export function isClientSecretTechnologyChoice(
  technology?: TechnologyChoice,
  technologyGroup?: TechnologyGroupTag
): boolean {
  if (technology && !isOtherTechnologyChoice(technology) && isClientSecretTechnology(technology)) {
    return true;
  }

  if (technologyGroup && isClientSecretTechnologyGroup(technologyGroup)) {
    return true;
  }
  return false;
}

interface Props {
  technology: TechnologyChoice | null,
  onChange: (technology: TechnologyChoice | null) => void,
  technologies?: TechnologyChoice[],
  disableOther?: boolean
}

export default function TechnologySelector(props: Props) {
  const {technology, onChange, disableOther} = props;
  const technologies = props.technologies ?? technologyTags;

  return (
    <React.Fragment>
      <div className="grid grid-cols-4 gap-[24px]">
        {technologies.map(t => (
          <Technology
            key={t}
            selected={technology === t}
            onSelect={onChange}
            technology={t}
          />
        ))}
        {disableOther !== true ? (
          <Technology
            selected={technology?.startsWith('technology:other:') || false}
            onSelect={onChange}
            technology={technology?.startsWith('technology:other:') ? technology : 'technology:other:'}
          />
        ) : null}
      </div>
      {technology?.startsWith('technology:other:') ? (
        <div className="form-group">
          <label className="control-label">
            Your feedback helps inform SDK and guide development, please consider inputting what technology you are working with:
          </label>
          <input className="form-control" value={technology.replace('technology:other:', '')} onChange={(event) => onChange(`technology:other:${event.target.value}`)} />
        </div>
      ) : null}
    </React.Fragment>
  )
}

interface TechnologyProps {
  technology: TechnologyChoice,
  selected: boolean,
  onSelect: (technology: TechnologyChoice) => void
}

function Technology(props: TechnologyProps) {
  const {technology, onSelect, selected} = props;

  return (
    <div
      onClick={() => onSelect(technology)}
      className={`text-center flex flex-col items-center justify-between cursor-pointer p-[15px] bg-white border ${selected ? 'border-primary-900' : 'border-grey hover:border-primary-700'}`}
    >
      {isOtherTechnologyChoice(technology) ? (
        <React.Fragment>
          <div>&nbsp;</div>
          <strong>Other</strong>
        </React.Fragment>
      ) : technology === 'technology:csharp' ? (
        <React.Fragment>
          <TechnologyLogo src={csharp_logo} />
          <strong>C#</strong>
        </React.Fragment>
      ) : technology === 'technology:nodejs' ? (
        <React.Fragment>
          <TechnologyLogo src={nodejs_logo} />
          <strong>Node.js</strong>
        </React.Fragment>
      ) : technology === 'technology:nodejs-express' ? (
        <React.Fragment>
          <TechnologyLogo src={nodejs_logo} />
          <strong>Node.js (Express)</strong>
        </React.Fragment>
      ) : technology === 'technology:javascript' ? (
        <React.Fragment>
          <TechnologyLogo src={javascript_logo} />
          <strong>JavaScript</strong>
        </React.Fragment>
      ): technology === 'technology:vuejs' ? (
        <React.Fragment>
          <TechnologyLogo src={vuejs_logo} />
          <strong>Vue.js</strong>
        </React.Fragment>
      ) : technology === 'technology:react' ? (
        <React.Fragment>
          <TechnologyLogo src={react_logo} />
          <strong>React</strong>
        </React.Fragment>
      ) : technology === 'technology:react-native' ? (
        <React.Fragment>
          <TechnologyLogo src={react_logo} />
          <strong>React Native</strong>
        </React.Fragment>
      ) : technology === 'technology:expo-react-native' ? (
        <React.Fragment>
          <TechnologyLogo src={expo_logo} />
          <strong>Expo (React Native)</strong>
        </React.Fragment>
      ) : technology === 'technology:ios' ? (
        <React.Fragment>
          <TechnologyLogo src={ios_logo} />
          <strong>iOS</strong>
        </React.Fragment>
      ) : technology === 'technology:android' ? (
        <React.Fragment>
          <TechnologyLogo src={android_logo} />
          <strong>Android</strong>
        </React.Fragment>
      ) : technology === 'technology:dotnet-maui' ? (
        <React.Fragment>
          <TechnologyLogo src={dotnet_logo} />
          <strong>.NET MAUI</strong>
        </React.Fragment>
      ) : technology === 'technology:php' ? (
        <React.Fragment>
          <TechnologyLogo src={php_logo} />
          <strong>PHP</strong>
        </React.Fragment>
      ) : technology === 'technology:wordpress' ? (
        <React.Fragment>
          <TechnologyLogo src={wordpress_logo} />
          <strong>Wordpress</strong>
        </React.Fragment>
      ) : technology === 'technology:java' ? (
        <React.Fragment>
          <TechnologyLogo src={java_logo} />
          <strong>Java</strong>
        </React.Fragment>
      ) : technology === 'technology:aspnet-core-v6' ? (
        <React.Fragment>
          <TechnologyLogo src={dotnet_logo} />
          <strong>ASP.NET Core 6.0</strong>
        </React.Fragment>
      ) : technology === 'technology:aspnet-core-v3' ? (
        <React.Fragment>
          <TechnologyLogo src={dotnet_logo} />
          <strong>ASP.NET Core 3.1</strong>
        </React.Fragment>
      ) : technology === 'technology:okta' ? (
        <React.Fragment>
          <TechnologyLogo src={okta_logo} />
          <strong>Okta</strong>
        </React.Fragment>
      ) : technology === 'technology:auth0' ? (
        <React.Fragment>
          <TechnologyLogo src={auth0_logo} />
          <strong>Auth0</strong>
        </React.Fragment>
      ) : technology === 'technology:onelogin' ? (
        <React.Fragment>
          <TechnologyLogo src={onelogin_logo} />
          <strong>OneLogin</strong>
        </React.Fragment>
      ) : technology === 'technology:pingfederate' ? (
        <React.Fragment>
          <TechnologyLogo src={pingfederate_logo} />
          <strong>PingFederate</strong>
        </React.Fragment>
      ) : technology === 'technology:aws-cognito' ? (
        <React.Fragment>
          <TechnologyLogo src={awscognito_logo} />
          <strong>AWS Cognito</strong>
        </React.Fragment>
      ) : technology === 'technology:firebase' ? (
        <React.Fragment>
          <TechnologyLogo src={firebase_logo} />
          <strong>Firebase</strong>
        </React.Fragment>
      ) : technology === 'technology:shopify' ? (
        <React.Fragment>
          <TechnologyLogo src={shopify_logo} />
          <strong>Shopify</strong>
        </React.Fragment>
      ) : technology === 'technology:woocommerce' ? (
        <React.Fragment>
          <TechnologyLogo src={woocommerce_logo} />
          <strong>WooCommerce</strong>
        </React.Fragment>
      ) : technology === 'technology:dandomain' ? (
        <React.Fragment>
          <TechnologyLogo src={dandomain_logo} />
          <strong>Dandomain</strong>
        </React.Fragment>
      ) : technology === 'technology:squarespace' ? (
        <React.Fragment>
          <TechnologyLogo src={squarespace_logo} />
          <strong>Squarespace</strong>
        </React.Fragment>
      ) : assertUnreachable(technology)}
    </div>
  )
}

function TechnologyLogo(props: {src: string}) {
  return (
    <div className="flex justify-center items-center flex-1">
      <img src={props.src} className="max-w-full w-full max-h-[80px] mb-[12px] min-w-[65px]" />
    </div>
  );
}

import csharp_logo from './logos/csharp.svg';
import dotnet_logo from './logos/dotnet.png';
import nodejs_logo from './logos/nodejs.svg';
import react_logo from './logos/react.svg';
import expo_logo from './logos/expo.png';
import javascript_logo from './logos/javascript.png';
import vuejs_logo from './logos/vuejs.svg';
import php_logo from './logos/php.png';
import wordpress_logo from './logos/wordpress.png';
import java_logo from './logos/java.svg';
import okta_logo from './logos/okta.png';
import auth0_logo from './logos/auth0.png';
import pingfederate_logo from './logos/pingfederate.png';
import onelogin_logo from './logos/onelogin.png';
import awscognito_logo from './logos/aws-cognito.svg';
import firebase_logo from './logos/firebase.svg';
import ios_logo from './logos/apple.svg';
import android_logo from './logos/android.svg';

import shopify_logo from './logos/shopify.svg';
import woocommerce_logo from './logos/woocommerce.svg';
import dandomain_logo from './logos/dandomain.svg';
import squarespace_logo from './logos/squarespace.svg';

function assertUnreachable(_value: never): never {
  throw new Error("Statement should be unreachable");
}