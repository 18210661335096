import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IdToken, Auth0Client } from '@auth0/auth0-spa-js';
import {LOGOUT_URL} from '@app/constants';
import { createAuthClient } from '@app/auth';
import jwt_decode from "jwt-decode";
import type {VerifyUserClaims} from '@criipto/dashboard-user-authentication';

export type VerifyAuthToken = VerifyUserClaims;

export interface AuthState {
  client: Auth0Client,
  access_token: string | null,
  access_token_decoded: VerifyAuthToken | null,
  id_token: string | null,
  profile: IdToken | null,
  valid: boolean,
  pending: boolean
};

export const initialState: AuthState = {
  client: createAuthClient(),
  access_token: null,
  access_token_decoded: null,
  id_token: null,
  profile: null,
  valid: false,
  pending: false
};

export type CheckSession = {
  access_token: string,
  id_token: string,
  profile: IdToken
}

export const checkSession = createAsyncThunk<CheckSession | null>(
  'auth/checkSession',
  async (args, thunkAPI) => {
    const {client} = (thunkAPI.getState() as any)[AuthSlice.name] as AuthState;
    const accessToken = await client.getTokenSilently().catch(err => {
      console.error('checkSessionError', err);

      return null;
    });

    if (!accessToken) return null;

    const claims = await client.getIdTokenClaims().catch(err => {
      console.error('checkSessionError', err);
      return null;
    });

    if (!claims) return null;

    return {
      access_token: accessToken,
      id_token: claims.__raw,
      profile: claims
    };
  }
);

export const AuthSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    init: (state) => {
      return {
        ...state
      };
    },
    setClient: (state, action: PayloadAction<Auth0Client>) => {
      return {
        ...state,
        client: action.payload
      }
    },
    setTokens: (state, action: PayloadAction<{access_token: string, id_token: string, profile: IdToken}>) => {
      return {
        ...state,
        access_token: action.payload.access_token,
        access_token_decoded: jwt_decode<VerifyAuthToken>(action.payload.access_token),
        id_token: action.payload.id_token,
        profile: action.payload.profile,
        valid: true,
        pending: false
      };
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        access_token: action.payload,
        access_token_decoded: jwt_decode<VerifyAuthToken>(action.payload),
      }
    },
    clearTokens: (state) => {
      return {
        ...state,
        access_token: null,
        id_token: null,
        profile: null,
        valid: false,
        pending: false
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkSession.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          access_token: payload.access_token,
          access_token_decoded: jwt_decode<VerifyAuthToken>(payload.access_token),
          id_token: payload.id_token,
          profile: payload.profile,
          valid: true,
          pending: false
        }
      }
      return {
        ...state,
        access_token: null,
        id_token: null,
        profile: null,
        valid: false,
        pending: false
      };
    });
    builder.addCase(checkSession.pending, (state) => {
      return {
        ...state,
        pending: true
      };
    });
  }
})

export const { setTokens, setAccessToken, clearTokens, setClient, init } = AuthSlice.actions;
export default AuthSlice;