import {IdServicesStyling} from '@app/models';
export type Styling = Omit<IdServicesStyling, 'deserialize' | 'viewVersionEditable'>;

export interface StorybookV4Entry {
  id: string
  title: string
  name: string
  importPath: string,
  tags: string[]
  type: 'story' | '%other values'
}
export type StorybookDashboardEntry = StorybookV4Entry & {
  screen: string | null
}
export interface StorybookV4Index {
  v: 4,
  entries: {
    [key: string]: StorybookV4Entry
  }
}
export type StorybookIndex = StorybookV4Index | {v: '%other versions'};
export function extractDashbookStories(input: StorybookIndex) : StorybookDashboardEntry[] {
  if (input.v === 4) {
    return Object.values(input.entries).filter(v => v.tags.includes('dashboard')).map(entry => ({
      ...entry,
      screen: entry.tags.find(s => s.startsWith('screen:'))?.replace('screen:', '') ?? null
    }));
  }
  return [];
}

export interface Viewport {
  key: string
  name: string;
  styles: ViewportStyles;
  type: 'desktop' | 'mobile' | 'tablet' | 'other';
}

export interface ViewportStyles {
  height: string;
  width: string;
}

export interface ViewportMap {
  [key: string]: Viewport;
}

export const MINIMAL_VIEWPORTS: ViewportMap = {
  mobile1: {
    key: 'mobile',
    name: 'Small mobile',
    styles: {
      height: '568px',
      width: '320px',
    },
    type: 'mobile',
  },
  mobile2: {
    key: 'mobile2',
    name: 'Large mobile',
    styles: {
      height: '896px',
      width: '414px',
    },
    type: 'mobile',
  },
  tablet: {
    key: 'tablet',
    name: 'Tablet',
    styles: {
      height: '1112px',
      width: '834px',
    },
    type: 'tablet',
  },
  'hd-laptop': {
    key: 'hd-laptop',
    name: 'Laptop (HD)',
    styles: {
      height: '768px',
      width: '1366px'
    },
    type: 'desktop'
  },
  'fullhd-laptop': {
    key: 'fullhd-laptop',
    name: 'Laptop (Full HD)',
    styles: {
      height: '1080px',
      width: '1920px'
    },
    type: 'desktop'
  }
};