/**
 * @generated SignedSource<<da536f8cbca8ce5b52b00501a200231e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddOrganizationTenantButton_admin$data = {
  readonly permissions: {
    readonly organizations: {
      readonly settings: Permission;
    };
  };
  readonly " $fragmentType": "AddOrganizationTenantButton_admin";
};
export type AddOrganizationTenantButton_admin$key = {
  readonly " $data"?: AddOrganizationTenantButton_admin$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_admin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddOrganizationTenantButton_admin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminOrganizationPermissions",
          "kind": "LinkedField",
          "name": "organizations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "settings",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminQuery",
  "abstractKey": null
};

(node as any).hash = "b064c79ebafb10640aadf49ba5fb185e";

export default node;
