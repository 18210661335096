/**
 * @generated SignedSource<<1fca31b5957b513290dd3a4d08efd5db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type CreateDomainInput = {
  environment: Environment;
  name: string;
  tenantId: string;
};
export type DomainCreaterMutation$variables = {
  connections: ReadonlyArray<string>;
  environment: Environment;
  input: CreateDomainInput;
};
export type DomainCreaterMutation$data = {
  readonly createDomain: {
    readonly domainEdge: {
      readonly cursor: string;
      readonly node: {
        readonly environment: Environment;
        readonly id: string;
        readonly name: string;
      };
    };
    readonly tenant: {
      readonly onboarding: {
        readonly hasDomain: boolean;
      };
    };
  };
};
export type DomainCreaterMutation = {
  response: DomainCreaterMutation$data;
  variables: DomainCreaterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantOnboarding",
  "kind": "LinkedField",
  "name": "onboarding",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "ScalarField",
      "name": "hasDomain",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainEdge",
  "kind": "LinkedField",
  "name": "domainEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "environment",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainCreaterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateDomainOutput",
        "kind": "LinkedField",
        "name": "createDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DomainCreaterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateDomainOutput",
        "kind": "LinkedField",
        "name": "createDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "domainEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5fb4c202522b06e8fe0cb8e097e3c13",
    "id": null,
    "metadata": {},
    "name": "DomainCreaterMutation",
    "operationKind": "mutation",
    "text": "mutation DomainCreaterMutation(\n  $input: CreateDomainInput!\n  $environment: Environment!\n) {\n  createDomain(input: $input) {\n    tenant {\n      onboarding {\n        hasDomain(environment: $environment)\n      }\n      id\n    }\n    domainEdge {\n      cursor\n      node {\n        id\n        name\n        environment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eac1ceab4864fdee49a088b80ffc68c1";

export default node;
