import React from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import Alert from '@app/components/Alert/Alert';
import DomainCreater from '../../DomainsScreen/components/DomainCreater/DomainCreater';

import { CreateDomainComponent_tenant$key } from './__generated__/CreateDomainComponent_tenant.graphql';

export function CreateDomainComponent(props: { initialValue?: string; tenant: CreateDomainComponent_tenant$key }) {
  const tenant = useFragment(
    graphql`
      fragment CreateDomainComponent_tenant on Tenant @argumentDefinitions(environment: { type: "Environment!" }) {
        viewerPermissions {
          domains(environment: $environment)
        }
      }
    `,
    props.tenant
  );

  if (tenant?.viewerPermissions.domains !== 'WRITE') {
    return <Alert variant="error" className="mt-[15px]" title="Access Restriction" message="You don't have the necessary permissions to create domains at the moment. Please ask your tenant admin to grant you user permissions." />;
  }

  if (!tenant) return null;

  return (
    <React.Fragment>
      <div className="flex flex-col gap-2">
        <div className="mt-[-2px] mb-[8px]">
          <h3 className="text-light-blue-900 text-[16px] font-semibold leading-[20px] tracking-[-0.16px]">Domain settings</h3>
        </div>
        <DomainCreater domainType='CRIIPTO' initialValue={props.initialValue} />
      </div>
    </React.Fragment>
  );
}
