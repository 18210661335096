import React from 'react';
import { TechnologyGroupTag, technologyGroupTags } from '@app/models';
import { IconEcommerce, SinglePageApp, WebApp, Native, Intermediary } from './TechnologyGroupSelectorIcon';
import styles from './TechnologyGroupSelector_v2.module.css';
import cx from 'classnames';

interface Props {
  groups?: TechnologyGroupTag[];
  group: TechnologyGroupTag | null;
  onChange: (group: TechnologyGroupTag | null) => void;
}

export default function TechnologySelector(props: Props) {
  const { group, onChange, groups = technologyGroupTags } = props;

  return (
    <React.Fragment>
      <div className="flex flex-col gap-[6px]">
        {groups.map((t) => (
          <TechnologyGroup key={t} selected={group === t} onSelect={onChange} group={t} />
        ))}
      </div>
    </React.Fragment>
  );
}

interface TechnologyGroupProps {
  group: TechnologyGroupTag;
  selected: boolean;
  onSelect: (technology: TechnologyGroupTag) => void;
}

function TechnologyGroup(props: TechnologyGroupProps) {
  const { group, onSelect, selected } = props;

  return (
    <div onClick={() => onSelect(group)} className={cx(styles['technology-group-box'], selected ? styles['selected'] : null)}>
      {group === 'technology-group:native' ? (
        <React.Fragment>
          <div>
            <Native />
          </div>
          <div className={styles['technology-description']}>
            <strong>Native</strong>
            <p>Mobile, desktop and smart-device apps running natively. E.g. iOS, Android, React-Native, Expo</p>
          </div>
        </React.Fragment>
      ) : group === 'technology-group:single-page-app' ? (
        <React.Fragment>
          <div>
            <SinglePageApp />
          </div>
          <div className={styles['technology-description']}>
            <strong>Single-page Application</strong>
            <p>A JavaScript front-end app, using an API. E.g. plain Javascript app, React, Angular, Vue.js</p>
          </div>
        </React.Fragment>
      ) : group === 'technology-group:web-app' ? (
        <React.Fragment>
          <div>
            <WebApp />
          </div>
          <div className={styles['technology-description']}>
            <strong>Regular Web Application</strong>
            <p> Traditional web app using redirects. E.g. Node.js, Express, .NET Core, PHP</p>
          </div>
        </React.Fragment>
      ) : group === 'technology-group:intermediary' ? (
        <React.Fragment>
          <div>
            <Intermediary />
          </div>
          <div className={styles['technology-description']}>
            <strong>Intermediary</strong>
            <p>Running Criipto Verify behind another authentication service. E.g. Auth0, PingFederate, AWS Cognito, Firebase, Azure AD</p>
          </div>
        </React.Fragment>
      ) : group === 'technology-group:ecommerce' ? (
        <React.Fragment>
          <div>
            <IconEcommerce />
          </div>
          <div className={styles['technology-description']}>
            <strong>eCommerce</strong>
            <p>Integrating ageverification or authentication through an eCommerce platform. E.g. Shopify, WooCommerce, etc. </p>
          </div>
        </React.Fragment>
      ) : (
        assertUnreachable(group)
      )}
    </div>
  );
}

function Icon(props: { icons: string[] }) {
  const { icons } = props;

  return (
    <div
      className="mb-4 h-[100px] w-[100px] bg-zinc-200 flex flex-row align-center items-center justify-center gap-2 rounded-lg"
      style={{ fontSize: '40px' }}
    >
      {icons.map((icon) => (
        <i className={icon} key={icon} />
      ))}
    </div>
  );
}

function assertUnreachable(_value: never): never {
  throw new Error(`Statement should be unreachable: ${_value}`);
}
