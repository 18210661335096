/**
 * @generated SignedSource<<bd1f7aab66b2ccc0f7d6016d626aea58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateFrenchToulouseInput = {
  callbackHost?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  environment: Environment;
  tenantId: string;
};
export type FRToulouseProviderMutation$variables = {
  input: UpdateFrenchToulouseInput;
};
export type FRToulouseProviderMutation$data = {
  readonly updateFrenchToulouse: {
    readonly callbackHost: string | null;
    readonly clientId: string | null;
    readonly id: string;
    readonly isConfigured: boolean;
  };
};
export type FRToulouseProviderMutation = {
  response: FRToulouseProviderMutation$data;
  variables: FRToulouseProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FrenchToulouse",
    "kind": "LinkedField",
    "name": "updateFrenchToulouse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "callbackHost",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FRToulouseProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FRToulouseProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ad4519408eb37bdde5c7250cb7821c5",
    "id": null,
    "metadata": {},
    "name": "FRToulouseProviderMutation",
    "operationKind": "mutation",
    "text": "mutation FRToulouseProviderMutation(\n  $input: UpdateFrenchToulouseInput!\n) {\n  updateFrenchToulouse(input: $input) {\n    id\n    isConfigured\n    clientId\n    callbackHost\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b7ee31c8b9d00f51dbd9a591672a5c6";

export default node;
