/**
 * @generated SignedSource<<a3d80726e6ec8ad149857b6e3a3814cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteApplicationButton_application$data = {
  readonly domain: {
    readonly id: string;
  };
  readonly id: string;
  readonly name: string;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentType": "DeleteApplicationButton_application";
};
export type DeleteApplicationButton_application$key = {
  readonly " $data"?: DeleteApplicationButton_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteApplicationButton_application">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteApplicationButton_application",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};
})();

(node as any).hash = "4c63f29db77a2ed3f71fa82359d973b6";

export default node;
