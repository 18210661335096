import useMounted from "@app/hooks/useMounted";
import { useEffect, useMemo, useState } from "react";
import { StorybookDashboardEntry, StorybookIndex, extractDashbookStories } from "../PreviewsScreen/constants";

export default function usePreviewStories() : [StorybookDashboardEntry[], {pending: boolean}] {
  const isMounted = useMounted();
  const [storybookIndex, setStorybookIndex] = useState<StorybookIndex | null>(null);
  const stories = useMemo(() => storybookIndex ? extractDashbookStories(storybookIndex) : [], [storybookIndex]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setPending(true);

    (async () => {
      const response = await fetch(`${process.env.CRIIPTO_VERIFY_STORYBOOK_AUTHORIY!}/index.json`);
      const payload = await response.json() as StorybookIndex;

      if (isMounted.current) {
        setStorybookIndex(payload);
        setPending(false);
      }
    })();
  }, []);

  return useMemo(() => [stories, {pending}], [stories, pending]);
}