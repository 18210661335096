import React from 'react';
import styles from './FormFields.module.css';

export interface HelpTextProps {
  children: React.ReactNode;
}

export default function HelpText({ children }: HelpTextProps) {
  return <div className={styles['help-text']}>{children}</div>;
}
