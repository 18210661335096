import React from 'react';
import {graphql} from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';
import { NavLink, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { UserScreen_AdminQuery } from './__generated__/UserScreen_AdminQuery.graphql';
import { TenantItem } from '../TenantsScreen/TenantsScreen';

export default function CriiptoAdminUserScreen(props: {breadcrumb: React.ReactNode}) {
  const {breadcrumb} = props;
  const match = useRouteMatch<{userId: string}>();

  const data = useLazyLoadQuery<UserScreen_AdminQuery>(
    graphql`
      query UserScreen_AdminQuery($id: ID!) {
        admin {
          user(id: $id) {
            id
            sub
            name
            email

            tenants {
              id
              ... TenantsScreen_AdminTenantItem_tenant
            }
          }
        }
      }
    `,
    {
      id: match.params.userId
    }
  );

  if (!data.admin.user) return null;
  const user = data.admin.user;

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          User details
        </div>
        <div className="flex flex-row justify-between">
          <h1>
            {user.email}
          </h1>
        </div>

        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={`${match.url}`} exact activeClassName="active">Details</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/tenants`} activeClassName="active">Tenants</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-tab-content">
        <Switch>
          <Route path={match.path} exact>
            <ul>
              <li>{user.sub}</li>
              <li>{user.name}</li>
              <li>{user.email}</li>
            </ul>
          </Route>
          <Route path={`${match.path}/tenants`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <div>
                  {user.tenants.map(tenant => (
                    <TenantItem key={tenant.id} adminTenant={tenant} />
                  ))}
                </div>
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}