/**
 * @generated SignedSource<<3220676e13cb03f6906139c60388d09b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantsScreen_AdminTenantItem_tenant$data = {
  readonly billable: {
    readonly Ageverification: {
      readonly dk: {
        readonly actual: boolean;
      };
    };
    readonly Signatures: {
      readonly actual: boolean;
    };
    readonly Verify: {
      readonly actual: boolean;
    };
  };
  readonly tenant: {
    readonly entityIdentifier: string;
    readonly longTenantId: string;
    readonly name: string;
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly shortId: string;
    } | null;
    readonly shortTenantId: string;
  };
  readonly " $fragmentType": "TenantsScreen_AdminTenantItem_tenant";
};
export type TenantsScreen_AdminTenantItem_tenant$key = {
  readonly " $data"?: TenantsScreen_AdminTenantItem_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantsScreen_AdminTenantItem_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantsScreen_AdminTenantItem_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortTenantId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longTenantId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityIdentifier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortId",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminTenantBillable",
      "kind": "LinkedField",
      "name": "billable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantBillableProduct",
          "kind": "LinkedField",
          "name": "Verify",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantBillableProduct",
          "kind": "LinkedField",
          "name": "Signatures",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantAgeverificationBillableProduct",
          "kind": "LinkedField",
          "name": "Ageverification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminTenantBillableProduct",
              "kind": "LinkedField",
              "name": "dk",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};
})();

(node as any).hash = "6a0e5b6b8e5324eb65e8cabc158ae53a";

export default node;
