import React, {useEffect, useMemo, useReducer, useState} from 'react';
import cx from 'classnames';
import moment from "moment";
import { GraphQLTaggedNode, PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import {graphql} from 'react-relay';

import { SignaturesWebhooks_application$key } from './__generated__/SignaturesWebhooks_application.graphql';
import useMutation from '@app/hooks/useMutation';
import { SignaturesWebhooks_invocation$key } from './__generated__/SignaturesWebhooks_invocation.graphql';
import { SignaturesWebhooks_retry_Mutation } from './__generated__/SignaturesWebhooks_retry_Mutation.graphql';
import Button from '@app/components/Button/Button';
import { StandaloneSwitch } from '@app/components/Form/Form';
import { SignaturesWebhooks_LogEnty_application$key } from './__generated__/SignaturesWebhooks_LogEnty_application.graphql';
import { SignaturesWebhooks_ApplicationQuery, WebhookInvocationEvent } from './__generated__/SignaturesWebhooks_ApplicationQuery.graphql';
import { SignaturesWebhooks_SignatureOrderQuery } from './__generated__/SignaturesWebhooks_SignatureOrderQuery.graphql';
import { groupBy, property } from 'lodash';

interface Props {
  application: SignaturesWebhooks_application$key
}

const ApplicationQuery = graphql`
  query SignaturesWebhooks_ApplicationQuery($id: ID!, $from: String!, $to: String!) {
    signatures {
      application(id: $id) {
        webhookLogs(from: $from, to: $to) {
          __typename
          event
          timestamp
          correlationId
          ...SignaturesWebhooks_invocation
        }
      }
    }
  }
`;

const SignatureOrderQuery = graphql`
  query SignaturesWebhooks_SignatureOrderQuery($id: ID!, $from: String!, $to: String!) {
    signatures {
      signatureOrder(id: $id) {
        webhook {
          logs(from: $from, to: $to) {
            __typename
            event
            timestamp
            correlationId
            ...SignaturesWebhooks_invocation
          }
        }
      }
    }
  }
`;

const EVENTS : WebhookInvocationEvent[] = [
  "SIGNATORY_DOCUMENT_STATUS_CHANGED",
  "SIGNATORY_DOWNLOAD_LINK_OPENED",
  "SIGNATORY_REJECTED",
  "SIGNATORY_SIGNED",
  "SIGNATORY_SIGN_ERROR",
  "SIGNATORY_SIGN_LINK_OPENED",
  "SIGNATURE_ORDER_EXPIRED"
];

type Logs =
  NonNullable<SignaturesWebhooks_ApplicationQuery["response"]["signatures"]["application"]>["webhookLogs"] |
  NonNullable<NonNullable<SignaturesWebhooks_SignatureOrderQuery["response"]["signatures"]["signatureOrder"]>["webhook"]>["logs"];

import styles from './SignaturesWebhooks.module.css';

export default function SignaturesWebhooks(props: Props) {
  const application = useFragment(graphql`
    fragment SignaturesWebhooks_application on Signatures_Application {
      id
      ...SignaturesWebhooks_LogEnty_application
    }
  `, props.application);

  const [from, setFrom] = useState(() => moment().startOf('day').subtract(7, 'days').toDate())
  const [localTime, setLocalTime] = useState(false);
  const [to, setTo] = useState(() => moment().endOf('day').toDate());
  const [succeeded, setSucceeded] = useState<boolean | null>(null);
  const [event, setEvent] = useState<WebhookInvocationEvent | null>(null);
  const [signatureOrderId, setSignatureOrderId] = useState<string | null>(null);
  const deferredSid = useDebounce(signatureOrderId, 200);

  const handleChange = (key: 'from' | 'to', event: React.ChangeEvent<HTMLInputElement>) => {
    if (key === 'from') setFrom(new Date(event.target.value));
    if (key === 'to') setTo(new Date(event.target.value));
  }

  const [applicationQueryReference, loadApplicationQuery] = useQueryLoader<SignaturesWebhooks_ApplicationQuery>(ApplicationQuery, null);
  const [signatureOrderQueryReference, loadSignatureOrderQuery] = useQueryLoader<SignaturesWebhooks_SignatureOrderQuery>(SignatureOrderQuery, null);

  useEffect(() => {
    if (deferredSid) return;
    loadApplicationQuery({
      id: application.id,
      from: from.toJSON(),
      to: to.toJSON()
    });
  }, [from, to, succeeded, loadApplicationQuery, application.id, deferredSid]);

  useEffect(() => {
    if (!deferredSid) return;
    loadSignatureOrderQuery({
      id: deferredSid,
      from: from.toJSON(),
      to: to.toJSON()
    });
  }, [from, to, succeeded, loadSignatureOrderQuery, deferredSid]);

  const query = deferredSid ? SignatureOrderQuery : ApplicationQuery;
  const queryReference = deferredSid ? signatureOrderQueryReference : applicationQueryReference;

  return (
    <React.Fragment>
      <div className="flex flex-col gap-[8px]">
        <div className="flex flex-row gap-[8px] items-center">
          Between
          <div className="form-group">
            <input className="form-control" type="datetime-local" value={moment(from).format('YYYY-MM-DDTHH:mm')} onChange={event => handleChange('from', event)} />
          </div>
          and
          <div className="form-group !mt-0">
            <input className="form-control" type="datetime-local" value={moment(to).format('YYYY-MM-DDTHH:mm')} onChange={event => handleChange('to', event)} />
          </div>

          <StandaloneSwitch
            className="!mt-0"
            label="Show events in local time"
            value={localTime}
            onChange={enabled => setLocalTime(enabled)}
          />
        </div>
        <div className="flex flex-row gap-[8px] items-center">
          <div className="form-group !mt-0">
            <select
              className="form-control"
              value={succeeded === true ? 'true' : succeeded === false ? 'false' : ''}
              onChange={event => {
                if (event.target.value === 'true') setSucceeded(true);
                else if (event.target.value === 'false') setSucceeded(false);
                else setSucceeded(null);
              }}
            >
              <option value="">All states</option>
              <option value="true">Only succesfull</option>
              <option value="false">Only failed</option>
            </select>
          </div>
          <div className="form-group !mt-0">
            <select
              className="form-control"
              value={event ?? ""}
              onChange={event => {
                if (event.target.value) setEvent(event.target.value as any)
                else setEvent(null)
              }}
            >
              <option value="">All events</option>
              {EVENTS.map(event => <option key={event} value={event}>{event}</option>)}
            </select>
          </div>
        </div>
        <div className="flex flex-row gap-[8px] items-center">
          <div className="form-group horizontal !mt-0 gap-[8px]">
            <label className="control-label break-keep whitespace-nowrap">SignatureOrder ID:</label>
            <input type="text" className="form-control" value={signatureOrderId ?? ''} onChange={event => setSignatureOrderId(event.target.value ? event.target.value : null)} />
          </div>
        </div>
      </div>
      <div className="mt-[25px]">
        <React.Suspense fallback={"Loading ..."}>
          {queryReference && (
            <Logs
              query={query}
              queryReference={queryReference}
              event={event}
              succeeded={succeeded}
              localTime={localTime}
              application={application}
            />
          )}
        </React.Suspense>
      </div>
    </React.Fragment>
  );
}

function Logs(props: {
  query: GraphQLTaggedNode,
  queryReference:
    PreloadedQuery<SignaturesWebhooks_ApplicationQuery> |
    PreloadedQuery<SignaturesWebhooks_SignatureOrderQuery>,
  event: WebhookInvocationEvent | null,
  succeeded: boolean | null,
  localTime: boolean,
  application: SignaturesWebhooks_LogEnty_application$key
}) {
  const data = usePreloadedQuery<SignaturesWebhooks_ApplicationQuery | SignaturesWebhooks_SignatureOrderQuery>(props.query, props.queryReference);
  const logs = "application" in data.signatures ? data.signatures.application?.webhookLogs : data.signatures.signatureOrder?.webhook?.logs;
  if (!logs) return null;

  const groups =
    Object.values(groupBy(logs, property('correlationId'))).map(group => ({
      event: group[0].event,
      latest: group[0],
      entries: group
    }))
    .filter(g =>
        props.succeeded === null ||
        (props.succeeded === true && g.latest.__typename === 'WebhookSuccessfulInvocation') ||
        (props.succeeded === false && g.latest.__typename !== 'WebhookSuccessfulInvocation'))
    .filter(g => !props.event || g.event === props.event)
    .sort((a, b) => new Date(a.latest.timestamp).valueOf() - new Date(b.latest.timestamp).valueOf()).reverse();

  return (
    <React.Fragment>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-1 p-[8px]"></div>
        <div className="col-span-3 p-[8px]">Timestamp</div>
        <div className="col-span-7 p-[8px]">Event</div>
        <div className="col-span-1 p-[8px]"></div>
        <div className="col-span-12 border-b-2 border-slate-300" />

        {groups.map(g => (
          <React.Fragment key={g.latest.correlationId}>
            <LogGroup
              application={props.application}
              localTime={props.localTime}
              group={g}
              variables={{
                from: props.queryReference.variables.from,
                to: props.queryReference.variables.to
              }}
            />
            <div className="col-span-12 border-b border-slate-300" />
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
}

function LogGroup(props: {
  group: {
    event: Logs[0]["event"]
    latest: Logs[0]
    entries: Logs
  }
  application: SignaturesWebhooks_LogEnty_application$key,
  variables: Omit<SignaturesWebhooks_ApplicationQuery["variables"], "id">,
  localTime: boolean
}) {
  const {group} = props;
  const [showGroup, toggleGroup] = useReducer(value => !value, false);
  return (
    <React.Fragment>
      <LogEntry
        {...props}
        enableDetails={false}
        enableRetry={true}
        onClick={toggleGroup}
        invocation={group.latest}
        attempts={group.entries.length > 1 ? group.entries.length : undefined}
      />
      {showGroup ? (
        <React.Fragment>
          <div className="col-span-12 border-b border-slate-200" />
          {group.entries.map(e => (
            <React.Fragment key={e.event+e.correlationId+e.timestamp}>
              <LogEntry
                application={props.application}
                localTime={props.localTime}
                invocation={e}
                enableDetails={true}
                enableRetry={false}
                variables={props.variables}
              />
              <div className="col-span-12 border-b border-slate-200" />
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

function LogEntry(props: {
  invocation: SignaturesWebhooks_invocation$key,
  application: SignaturesWebhooks_LogEnty_application$key,
  variables: Omit<SignaturesWebhooks_ApplicationQuery["variables"], "id">,
  localTime: boolean
  enableDetails: boolean
  enableRetry: boolean
  onClick?: () => void
  attempts?: number
}) {
  const application = useFragment(
    graphql`
      fragment SignaturesWebhooks_LogEnty_application on Signatures_Application {
        id
      }
    `,
    props.application
  );
  const invocation = useFragment(
    graphql`
      fragment SignaturesWebhooks_invocation on WebhookInvocation {
        timestamp
        url
        requestBody
        responseBody
        event
        correlationId
        signatureOrderId

        ... on WebhookSuccessfulInvocation {
          responseStatusCode
        }
        ... on WebhookHttpErrorInvocation {
          responseStatusCode
          retryPayload
          retryingAt
        }

        ... on WebhookExceptionInvocation {
          exception
          retryPayload
          retryingAt
        }
        ... on WebhookTimeoutInvocation {
          responseTimeout
          retryPayload
          retryingAt
        }
      }
    `,
    props.invocation
  );

  const [highlight, setHighlight] = useState(false);
  useEffect(() => {
    if (!highlight) return;

    const timeout = setTimeout(() => setHighlight(false), 2000);
    return () => clearTimeout(timeout);
  }, [highlight]);

  const retryInput = (invocation.signatureOrderId && invocation.retryPayload) ? {
    signatureOrderId: invocation.signatureOrderId,
    retryPayload: invocation.retryPayload
  } : null;

  const [executor, status] = useMutation<SignaturesWebhooks_retry_Mutation>(
    graphql`
      mutation SignaturesWebhooks_retry_Mutation($input: RetrySignatureOrderWebhookInput!) {
        signatures {
          retrySignatureOrderWebhook(input: $input) {
            invocation {
              signatureOrderId
              ...SignaturesWebhooks_invocation
            }
          }
        }
      }
    `,
    {
      updater: (store, data) => {
        const invocation = store.getRootField('signatures')?.getLinkedRecord('retrySignatureOrderWebhook', {
          input: retryInput
        })?.getLinkedRecord('invocation');
        if (!invocation) return;

        const existing = store.get(application.id)?.getLinkedRecords('webhookLogs', props.variables);
        const logs = [invocation as any].concat(existing ?? []);
        store.get(application.id)?.setLinkedRecords(logs, 'webhookLogs', props.variables);

        const signatureOrderId = invocation.getValue('signatureOrderId');
        if (signatureOrderId) {
          const existing = store.get(signatureOrderId)?.getLinkedRecord('webhook')?.getLinkedRecords('logs', props.variables);
          const logs = [invocation as any].concat(existing ?? []);
          store.get(signatureOrderId)?.getLinkedRecord('webhook')?.setLinkedRecords(logs, 'logs', props.variables);
        }
      }
    }
  );

  const handleRetry = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!retryInput) return;
    await executor.executePromise({
      input: retryInput
    });

    setHighlight(true);
  };

  const [showDetails, toggleDetails] = useReducer(value => !value, false);
  const handleClick = () => {
    if (props.enableDetails) {
      toggleDetails();
      return;
    }
    props.onClick?.();
  }

  const highlightStyles = {[styles.highlight]: highlight};
  return (
    <React.Fragment>
      <div onClick={handleClick} className={cx('cursor-pointer col-span-1 p-[8px]', styles['webhook-entry-item'], highlightStyles)}>
        {invocation.responseTimeout ? (
          <span className="badge bg-rose-600">Timeout</span>
        ) : invocation.exception ? (
          <span className="badge bg-rose-600">Error</span>
        ) : (invocation.responseStatusCode ?? 0) >= 400 ? (
          <span className="badge bg-rose-600">{invocation.responseStatusCode}</span>
        ) : (invocation.responseStatusCode ?? 0) >= 300 ? (
          <span className="badge bg-yellow-500">{invocation.responseStatusCode}</span>
        ) : (invocation.responseStatusCode ?? 0) >= 200 ? (
          <span className="badge bg-green-600">{invocation.responseStatusCode}</span>
        ) : null}
      </div>
      <div onClick={handleClick} className={cx('cursor-pointer col-span-3 p-[8px]', styles['webhook-entry-item'], highlightStyles)}>
        {(props.localTime ? moment(invocation.timestamp) : moment.utc(invocation.timestamp)).format('YYYY-MM-DD HH:mm:ss')}
        {props.attempts ? ` (${props.attempts} attempts)` : null}
      </div>
      <div className={cx('cursor-pointer col-span-7 p-[8px]', styles['webhook-entry-item'], highlightStyles)} onClick={handleClick}>
        {invocation.event}
      </div>
      <div onClick={handleClick} className={cx('cursor-pointer col-span-1 p-[8px]', styles['webhook-entry-item'], highlightStyles)}>
        {props.enableRetry && invocation.signatureOrderId && invocation.retryPayload && (
          <Button variant="default" working={status.pending} onClick={handleRetry}>
            Retry
          </Button>
        )}
      </div>
      {showDetails && (
        <div className="col-span-12 p-[8px] gap-[8px] flex flex-row">
          <div className="basis-1/2">
            <strong>Request</strong><br />
            {invocation.url}<br />
            <pre style={{overflowX: 'auto'}}><code>{tryParseJSON(invocation.requestBody) ? JSON.stringify(JSON.parse(invocation.requestBody), null, 2) : invocation.requestBody}</code></pre>
          </div>
          <div className="basis-1/2">
            <strong>Response</strong><br />
            {invocation.responseStatusCode}{invocation.responseTimeout}<br />
            {invocation.retryingAt ? (<span>Retrying at: {invocation.retryingAt}<br /></span>) : null}
            <pre style={{overflowX: 'auto'}}><code>{invocation.responseBody ?? invocation.exception ?? (invocation.responseTimeout ? 'Response timed out' : '')}</code></pre>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

function tryParseJSON(input: string) {
  try {
    return JSON.parse(input);
  }
  catch {
    return null;
  }
}

function useDebounce(value: string | null, delay: number): string | null {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}