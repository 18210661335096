/**
 * @generated SignedSource<<dbae7ecd803d6cf66e4440fa2c2c0004>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateBelgianEIDInput = {
  clientId?: string | null;
  clientSecret?: string | null;
  environment: Environment;
  requestAddress: boolean;
  requestNationalNumber: boolean;
  requestPersonalInfo: boolean;
  requestPhoto: boolean;
  skipSummary: boolean;
  tenantId: string;
};
export type BEeIDProviderMutation$variables = {
  input: UpdateBelgianEIDInput;
};
export type BEeIDProviderMutation$data = {
  readonly updateBelgianEID: {
    readonly clientId: string | null;
    readonly id: string;
    readonly isConfigured: boolean;
    readonly requestAddress: boolean | null;
    readonly requestNationalNumber: boolean | null;
    readonly requestPersonalInfo: boolean | null;
    readonly requestPhoto: boolean | null;
    readonly skipSummary: boolean | null;
  };
};
export type BEeIDProviderMutation = {
  response: BEeIDProviderMutation$data;
  variables: BEeIDProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BelgianEID",
    "kind": "LinkedField",
    "name": "updateBelgianEID",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestPersonalInfo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestPhoto",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestNationalNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "skipSummary",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BEeIDProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BEeIDProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1ec88bc93d46a89419e8adda6e5463bd",
    "id": null,
    "metadata": {},
    "name": "BEeIDProviderMutation",
    "operationKind": "mutation",
    "text": "mutation BEeIDProviderMutation(\n  $input: UpdateBelgianEIDInput!\n) {\n  updateBelgianEID(input: $input) {\n    id\n    isConfigured\n    clientId\n    requestPersonalInfo\n    requestAddress\n    requestPhoto\n    requestNationalNumber\n    skipSummary\n  }\n}\n"
  }
};
})();

(node as any).hash = "33cabc15fcc397ca9153240f2c276300";

export default node;
