/**
 * @generated SignedSource<<8c2a1146d3a2c586713b0cc85e6d986f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type DashboardScreen_Query$variables = {
  environment: Environment;
  tenantId: string;
};
export type DashboardScreen_Query$data = {
  readonly tenant: {
    readonly ageverificationEnvironments: ReadonlyArray<Environment>;
    readonly signaturesEnvironments: ReadonlyArray<Environment>;
    readonly verifyEnvironments: ReadonlyArray<Environment>;
    readonly viewerPermissions: {
      readonly analytics: Permission;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DashboardScreen_SubscriptionBox_tenant">;
  } | null;
};
export type DashboardScreen_Query = {
  response: DashboardScreen_Query$data;
  variables: DashboardScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "ScalarField",
      "name": "analytics",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v5 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v6 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardScreen_SubscriptionBox_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "environments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortTenantId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec56bb70feeee5ef3643a9cd713f15b8",
    "id": null,
    "metadata": {},
    "name": "DashboardScreen_Query",
    "operationKind": "query",
    "text": "query DashboardScreen_Query(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    viewerPermissions {\n      analytics(environment: $environment)\n    }\n    verifyEnvironments: environments(product: Verify)\n    signaturesEnvironments: environments(product: Signatures)\n    ageverificationEnvironments: environments(product: [Ageverification_DK])\n    ...DashboardScreen_SubscriptionBox_tenant\n    id\n  }\n}\n\nfragment DashboardScreen_SubscriptionBox_tenant on Tenant {\n  environments\n  shortTenantId\n}\n"
  }
};
})();

(node as any).hash = "e4d8e72474fbd2dbd915f61d63d891ea";

export default node;
