/**
 * @generated SignedSource<<3c4b05e402e8c1f234bfb336f5356b8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type TenantNavigation_Query$variables = {
  environment: Environment;
  tenantId: string;
};
export type TenantNavigation_Query$data = {
  readonly tenant: {
    readonly features: {
      readonly EXTENSIONS: boolean;
    };
    readonly shortTenantId: string;
    readonly viewerPermissions: {
      readonly analytics: Permission;
      readonly applications: Permission;
      readonly billing: Permission;
      readonly domains: Permission;
      readonly extensions: Permission;
      readonly providers: Permission;
      readonly styling: Permission;
      readonly users: Permission;
    };
    readonly " $fragmentSpreads": FragmentRefs<"EnvironmentDropdown_tenant">;
  } | null;
};
export type TenantNavigation_Query = {
  response: TenantNavigation_Query$data;
  variables: TenantNavigation_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTenantId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "users",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "analytics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "styling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "applications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "extensions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "EXTENSIONS",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnvironmentDropdown_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TenantNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "environments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e72154318cb6efb85bebe9b8fb4b2f5",
    "id": null,
    "metadata": {},
    "name": "TenantNavigation_Query",
    "operationKind": "query",
    "text": "query TenantNavigation_Query(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    shortTenantId\n    viewerPermissions {\n      billing\n      users\n      analytics(environment: $environment)\n      providers(environment: $environment)\n      domains(environment: $environment)\n      styling(environment: $environment)\n      applications(environment: $environment)\n      extensions(environment: $environment)\n    }\n    features {\n      EXTENSIONS(environment: $environment)\n    }\n    ...EnvironmentDropdown_tenant\n    id\n  }\n}\n\nfragment EnvironmentDropdown_tenant on Tenant {\n  environments\n  ...ProductionUpgradeRequiredModal_tenant\n}\n\nfragment ProductionUpgradeRequiredModal_tenant on Tenant {\n  name\n  shortTenantId\n}\n"
  }
};
})();

(node as any).hash = "cd14c1fa953d33b4012eabcad47d7a2b";

export default node;
