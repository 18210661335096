/**
 * @generated SignedSource<<3bb96ef234a3ecfb17f5e2298d01691f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOrganizationTenantButton_organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantModal_organization">;
  readonly " $fragmentType": "AddOrganizationTenantButton_organization";
};
export type AddOrganizationTenantButton_organization$key = {
  readonly " $data"?: AddOrganizationTenantButton_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddOrganizationTenantButton_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddOrganizationTenantModal_organization"
    }
  ],
  "type": "AdminOrganization",
  "abstractKey": null
};

(node as any).hash = "2eded1d30b96efc6ea06f0c1cb9db4f0";

export default node;
