/**
 * @generated SignedSource<<229c9e7281cbc370b39561f468cf7db6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResendInvitationInput = {
  id: string;
};
export type ResendInvitationButton_resendInvitation_Mutation$variables = {
  input: ResendInvitationInput;
};
export type ResendInvitationButton_resendInvitation_Mutation$data = {
  readonly resendInvitation: {
    readonly dummy: boolean;
  };
};
export type ResendInvitationButton_resendInvitation_Mutation = {
  response: ResendInvitationButton_resendInvitation_Mutation$data;
  variables: ResendInvitationButton_resendInvitation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResendInvitationOutput",
    "kind": "LinkedField",
    "name": "resendInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dummy",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendInvitationButton_resendInvitation_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResendInvitationButton_resendInvitation_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c25a026417e43d9ca20af33dae984b9a",
    "id": null,
    "metadata": {},
    "name": "ResendInvitationButton_resendInvitation_Mutation",
    "operationKind": "mutation",
    "text": "mutation ResendInvitationButton_resendInvitation_Mutation(\n  $input: ResendInvitationInput!\n) {\n  resendInvitation(input: $input) {\n    dummy\n  }\n}\n"
  }
};
})();

(node as any).hash = "dab7285176ea1d3a95ab5d01eb8a7030";

export default node;
