/**
 * @generated SignedSource<<dcc16b281117210be9a2078fd31d188e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UISection_tenant$data = {
  readonly styling: {
    readonly cssUrl: string | null;
    readonly viewVersion: {
      readonly editable: boolean;
      readonly version: ViewVersion;
    };
  };
  readonly " $fragmentType": "UISection_tenant";
};
export type UISection_tenant$key = {
  readonly " $data"?: UISection_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UISection_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UISection_tenant",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "concreteType": "TenantStyling",
      "kind": "LinkedField",
      "name": "styling",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cssUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantStylingViewVersion",
          "kind": "LinkedField",
          "name": "viewVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "version",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "editable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "2d5bc848afb91bbe96b0758be5031f29";

export default node;
