/**
 * @generated SignedSource<<4f7d3ef37f658aaa77c7929804c76dc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserPermission = "CONFIGURATION_MANAGEMENT" | "EXTENSIONS_PRODUCTION_READ" | "EXTENSIONS_PRODUCTION_WRITE" | "EXTENSIONS_TEST_READ" | "EXTENSIONS_TEST_WRITE" | "FINANCIALS" | "PRIVATE_KEY_MANAGEMENT" | "REPORTING" | "USER_ADMIN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Permissions_tenant$data = {
  readonly userPermissions: ReadonlyArray<UserPermission>;
  readonly " $fragmentType": "Permissions_tenant";
};
export type Permissions_tenant$key = {
  readonly " $data"?: Permissions_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"Permissions_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Permissions_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPermissions",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "0d8ca9849c1b148d48acfbcd07b5683f";

export default node;
