import React, { useReducer } from 'react';

import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button, { ButtonProps } from '@app/components/Button/Button';
import UserInvitationModal from '../UserInvitationModal/UserInvitationModal';
import { UserInvitationButton_tenant$key } from './__generated__/UserInvitationButton_tenant.graphql';

type Props = Omit<ButtonProps, 'onClick'> & {
  tenant: UserInvitationButton_tenant$key;
};

export default function UserInvitationButton(props: Props) {
  const tenant = useFragment(
    graphql`
      fragment UserInvitationButton_tenant on Tenant {
        ...UserInvitationModal_tenant

        viewerPermissions {
          users
        }
      }
    `,
    props.tenant
  );

  const [showModal, toggleModal] = useReducer((value) => !value, false);

  if (tenant.viewerPermissions.users !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Button {...props} onClick={toggleModal}>
        {props.children || 'Add user'}
      </Button>
      {showModal ? <UserInvitationModal tenant={tenant} open={showModal} onHide={toggleModal} /> : null}
    </React.Fragment>
  );
}
