/**
 * @generated SignedSource<<789433e618528d45704dfe17c8266e9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationApiKeyModal_apiKey$data = {
  readonly clientId: string;
  readonly clientSecret: string | null;
  readonly " $fragmentType": "OrganizationApiKeyModal_apiKey";
};
export type OrganizationApiKeyModal_apiKey$key = {
  readonly " $data"?: OrganizationApiKeyModal_apiKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationApiKeyModal_apiKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationApiKeyModal_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSecret",
      "storageKey": null
    }
  ],
  "type": "OrganizationApiKey",
  "abstractKey": null
};

(node as any).hash = "3a9664944f24d09eb8d02a5477fd598f";

export default node;
