/**
 * @generated SignedSource<<a62b0c6c0ddc5bef7b5fc9a67a7b5cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProvidersScreen_ProviderScreenQuery$variables = {
  id: string;
};
export type ProvidersScreen_ProviderScreenQuery$data = {
  readonly identityProvider: {
    readonly __typename: string;
    readonly id: string;
    readonly longName: string;
    readonly " $fragmentSpreads": FragmentRefs<"SEBankIDProvider_identityProvider">;
  };
};
export type ProvidersScreen_ProviderScreenQuery = {
  response: ProvidersScreen_ProviderScreenQuery$data;
  variables: ProvidersScreen_ProviderScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvidersScreen_ProviderScreenQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "identityProvider",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SEBankIDProvider_identityProvider"
                }
              ],
              "type": "SwedishBankID",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "identityProvider"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvidersScreen_ProviderScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "identityProvider",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disableSSNInput",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BasicCertificate",
                "kind": "LinkedField",
                "name": "certificate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pkcs7",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validTo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SwedishBankID",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9456223a6097be706f3171057121aefb",
    "id": null,
    "metadata": {},
    "name": "ProvidersScreen_ProviderScreenQuery",
    "operationKind": "query",
    "text": "query ProvidersScreen_ProviderScreenQuery(\n  $id: ID!\n) {\n  identityProvider(id: $id) {\n    __typename\n    id\n    longName\n    ... on NorwegianBankID {\n      id\n    }\n    ... on SwedishBankID {\n      __typename\n      ...SEBankIDProvider_identityProvider\n    }\n    ... on DutchIdinEvidos {\n      id\n    }\n    ... on NorwegianVipps {\n      id\n    }\n    ... on ItsME {\n      id\n    }\n    ... on BelgianEID {\n      id\n    }\n    ... on NorwegianBankIDCServer {\n      id\n    }\n    ... on DanishMitID {\n      id\n    }\n    ... on DutchDigID {\n      id\n    }\n    ... on FrenchToulouse {\n      id\n    }\n    ... on FinnishTelia {\n      id\n    }\n    ... on DutchiDINBluem {\n      id\n    }\n    ... on GermanSofort {\n      id\n    }\n  }\n}\n\nfragment SEBankIDProvider_identityProvider on SwedishBankID {\n  id\n  tenant {\n    id\n  }\n  longName\n  disableSSNInput\n  certificate {\n    name\n    pkcs7\n    validFrom\n    validTo\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c7185e9d5fa68722e813b4a42e5d9dc";

export default node;
