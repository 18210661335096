import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import { ConfirmModal } from '@app/components/Modal/Modal';
import {DeleteOrganizationApiKeyButton_apiKey$key} from './__generated__/DeleteOrganizationApiKeyButton_apiKey.graphql';
import {DeleteOrganizationApiKeyButton_organization$key} from './__generated__/DeleteOrganizationApiKeyButton_organization.graphql';
import {DeleteOrganizationApiKeyButtonMutation} from './__generated__/DeleteOrganizationApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert';
import { useMfa } from '@app/hooks/useMfa';

interface Props {
  apiKey: DeleteOrganizationApiKeyButton_apiKey$key,
  organization: DeleteOrganizationApiKeyButton_organization$key
}

export default function DeleteOrganizationApiKeyButton(props: Props) {
  const toast = useToast();
  const [showConfirm, setShowConfirm] = useState(false);

  const apiKey = useFragment(graphql`
    fragment DeleteOrganizationApiKeyButton_apiKey on OrganizationApiKey {
      id
      environment
    }
  `, props.apiKey);

  const organization = useFragment(graphql`
    fragment DeleteOrganizationApiKeyButton_organization on Organization {
      id

      viewerPermissions {
        testApi: api(environment: TEST)
        productionApi: api(environment: PRODUCTION)
      }
    }
  `, props.organization);

  const checkMFA = useMfa(apiKey.environment === 'PRODUCTION');
  const [executor, state] = useMutation<DeleteOrganizationApiKeyButtonMutation>(graphql`
    mutation DeleteOrganizationApiKeyButtonMutation($input: DeleteOrganizationApiKeyInput!) {
      deleteOrganizationApiKey(input: $input) {
        organization {
          id
          ... ApiKeys_List_organization
        }
      }
    }
  `);

  const handleClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    await checkMFA();

    return executor.executePromise({
      input: {
        apiKeyId: apiKey.id
      }
    }).then(() => {
      toast.success({title: 'API key deleted'});
    }).catch(() => {
      // Handled by state
    });
  }

  if (apiKey.environment === 'TEST' && organization.viewerPermissions.testApi !== 'WRITE') return null;
  if (apiKey.environment === 'PRODUCTION' && organization.viewerPermissions.productionApi !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" autoWidth onClick={handleClick} data-test-id="button">
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        confirmText={"Delete API key"}
        confirmVariant="danger"
        data-test-id="confirm-modal"
      >
        <div>
          <h2>Delete API key</h2>
          <p>Are you sure you want to delete this API key? Any calls using this API key will start to fail.</p>
        </div>

        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} data-test-id="error" />
        )}
      </ConfirmModal>
    </React.Fragment>
  )
}