import React from 'react';

import {CertificateConfiguration} from '@app/models';
import { translate } from '@app/i18n';

import { FileUpload } from '@app/components/Form';
import InputField from '../FormFields/InputField/InputField';

interface Props {
  certificate: CertificateConfiguration | Omit<CertificateConfiguration, "deserialize"> | null | undefined,
  fileFieldName: string,
  passwordFieldName: string,
  required?: boolean
}

export default function CertificateForm(props : Props) {
  const {certificate} = props;

  return (
    <React.Fragment>
      {certificate?.name && (
        <div className="form-group">
          <label className="control-label">{translate('LABEL_CERTIFICATE')}</label>
          <pre>{certificate.name}</pre>
          <label className="control-label">{translate('LABEL_CERTIFICATE_VALID_FROM')}</label>
          <pre>{certificate.validFrom}</pre>
          <label className="control-label">{translate('LABEL_CERTIFICATE_VALID_TO')}</label>
          <pre>{certificate.validTo}</pre>
        </div>
      )}
      <div className="flex flex-col gap-[10px]">
        <FileUpload name={props.fileFieldName} label={translate('LABEL_CERTIFICATE_UPLOAD')} placeholder={translate('HINT_FILE')} />
        <InputField name={props.passwordFieldName} type="password" label={translate('LABEL_CERTIFICATE_PASSWORD')} placeholder={translate('HINT_PASSWORD')} required={props.required} />
      </div>
    </React.Fragment>
  )
}