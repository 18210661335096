/**
 * @generated SignedSource<<4a4f2c212eb86cac57c9feefb73472d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditUserButton_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditUserModal_user">;
  readonly " $fragmentType": "EditUserButton_user";
};
export type EditUserButton_user$key = {
  readonly " $data"?: EditUserButton_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditUserButton_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditUserButton_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditUserModal_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6bf85fe2e24a46fe29e5045e584cc5f3";

export default node;
