/**
 * @generated SignedSource<<a167a6a1bd13da23be07373f54fed0be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApplicationsScreen_Item_tenant$data = {
  readonly ageverificationEnvironments: ReadonlyArray<Environment>;
  readonly signaturesEnvironments: ReadonlyArray<Environment>;
  readonly verifyEnvironments: ReadonlyArray<Environment>;
  readonly " $fragmentType": "ApplicationsScreen_Item_tenant";
};
export type ApplicationsScreen_Item_tenant$key = {
  readonly " $data"?: ApplicationsScreen_Item_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationsScreen_Item_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationsScreen_Item_tenant",
  "selections": [
    {
      "alias": "verifyEnvironments",
      "args": [
        {
          "kind": "Literal",
          "name": "product",
          "value": "Verify"
        }
      ],
      "kind": "ScalarField",
      "name": "environments",
      "storageKey": "environments(product:\"Verify\")"
    },
    {
      "alias": "signaturesEnvironments",
      "args": [
        {
          "kind": "Literal",
          "name": "product",
          "value": "Signatures"
        }
      ],
      "kind": "ScalarField",
      "name": "environments",
      "storageKey": "environments(product:\"Signatures\")"
    },
    {
      "alias": "ageverificationEnvironments",
      "args": [
        {
          "kind": "Literal",
          "name": "product",
          "value": [
            "Ageverification_DK"
          ]
        }
      ],
      "kind": "ScalarField",
      "name": "environments",
      "storageKey": "environments(product:[\"Ageverification_DK\"])"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "cbdd7d2aa89a9e92e2221db4765bd7a3";

export default node;
