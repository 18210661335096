import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import {AddOrganizationApiKeyButton_organization$key} from './__generated__/AddOrganizationApiKeyButton_organization.graphql';
import {AddOrganizationApiKeyButtonMutation, Environment} from './__generated__/AddOrganizationApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';

import ApiKeyModal from '../OrganizationApiKeyModal';
import Alert from '@app/components/Alert/Alert';
import { useMfa } from '@app/hooks/useMfa';

interface Props {
  organization: AddOrganizationApiKeyButton_organization$key
  environment: Environment
  className?: string
}

export default function AddOrganizationApiKeyButton(props: Props) {
  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const checkMFA = useMfa(props.environment === 'PRODUCTION');

  const organization = useFragment(graphql`
    fragment AddOrganizationApiKeyButton_organization on Organization {
      id

      viewerPermissions {
        testApi: api(environment: TEST)
        productionApi: api(environment: PRODUCTION)
      }
    }
  `, props.organization);

  const [addKeyMutationExecutor, addKeyMutationState] = useMutation<AddOrganizationApiKeyButtonMutation>(graphql`
    mutation AddOrganizationApiKeyButtonMutation($input: CreateOrganizationApiKeyInput!) {
      createOrganizationApiKey(input: $input) {
        organization {
          id
          ...ApiKeys_List_organization
        }

        apiKey {
          ... OrganizationApiKeyModal_apiKey
        }
      }
    }
  `);

  const handleClick = async () => {
    await checkMFA();

    addKeyMutationExecutor.executePromise({
      input: {
        organizationId: organization.id,
        environment: props.environment
      }
    }).then(() => {
      setShowApiKeySecret(true);
    })
    .catch(() => {
      // handled by addKeyMutationState
    });
  };

  if (props.environment === 'TEST' && organization.viewerPermissions.testApi !== 'WRITE') return null;
  if (props.environment === 'PRODUCTION' && organization.viewerPermissions.productionApi !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Button variant="primary" className={`button-small ${props.className || ''}`} autoWidth onClick={handleClick} working={addKeyMutationState.pending} data-test-id="button">
        Add {props.environment.toLowerCase()} key
      </Button>
      {addKeyMutationState.error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={addKeyMutationState.error.message} data-test-id="error" />
      )}
      <ApiKeyModal open={showApiKeySecret} onHide={() => setShowApiKeySecret(false)} apiKey={addKeyMutationState.response?.createOrganizationApiKey!.apiKey!} data-test-id="modal" />
    </React.Fragment>
  )
}