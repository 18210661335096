import React from 'react';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import LogsScreen from '../LogsScreen/LogsScreen';
import Button from '@app/components/Button/Button';
import Panel from '@app/components/Panel/Panel';

import logsImage from './images/logs.png';
import adminImage from './images/admin.png';
import { useTenantId } from '@app/hooks/useTenant';
import useEnvironment from '@app/hooks/useEnvironment';
import { ToolsScreen_Query } from './__generated__/ToolsScreen_Query.graphql';
import { ToolsScreen_ToolsRoutes_Query } from './__generated__/ToolsScreen_ToolsRoutes_Query.graphql';

export function ToolsScreen() {
  const match = useRouteMatch();
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<ToolsScreen_Query>(
    graphql`
      query ToolsScreen_Query($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          viewerPermissions {
            domains(environment: $environment)
          }
          shortTenantId
        }

        viewer {
          ... on UserViewer {
            isCriiptoAdmin
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId,
      environment
    }
  );

  if (!data.tenant) return null;

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Tools</h1>
      </div>
      <div className="app-content-main">
        <div className="flex flex-column lg:flex-row gap-[16px]">
          {data.tenant.viewerPermissions.domains !== 'NONE' && (
            <Panel title="Logs" image={logsImage} to={`${match.url}/logs`}>
              <p>
                <strong>Debug authorize requests</strong> by investigating domain logs. Also accessible via indiviudal domains.
              </p>
              <Button variant="default">Access logs</Button>
            </Panel>
          )}
          {data.viewer?.isCriiptoAdmin && (
            <Panel title="Criipto Admin" image={adminImage} to={`/admin/tenants/${data.tenant.shortTenantId}`}>
              <p>&nbsp;</p>
              <Button variant="default">Criipto Admin</Button>
            </Panel>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function ToolsRoutes() {
  const match = useRouteMatch();
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<ToolsScreen_ToolsRoutes_Query>(
    graphql`
      query ToolsScreen_ToolsRoutes_Query($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          viewerPermissions {
            domains(environment: $environment)
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId,
      environment
    }
  );

  if (!data.tenant) return null;

  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
          <ToolsScreen />
        </React.Suspense>
      </Route>
      {data.tenant.viewerPermissions.domains !== 'NONE' && (
        <Route path={`${match.path}/logs/:domain?`}>
          <div className="app-content-header">
            <div className="breadcrumb">
              <Link to={match.url}>Tools</Link>
              <i className="fa fa-angle-right" />
              <div>Logs</div>
            </div>
            <h1>Logs</h1>
          </div>
          <div className="app-content-main">
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <LogsScreen />
            </React.Suspense>
          </div>
        </Route>
      )}
    </Switch>
  )
}