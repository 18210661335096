import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import Button from '@app/components/Button';
import Modal, {ModalProps} from '@app/components/Modal/Modal';
import Alert from '@app/components/Alert/Alert';
import PlanSelection, {PlanChoice} from '../PlanSelection/PlanSelection';

import { SubscriptionPlanModal_subscription$key } from './__generated__/SubscriptionPlanModal_subscription.graphql';
import { Product } from '../PlanSelection/__generated__/PlanSelection_Query.graphql';
import { SubscriptionPlanModal_plan$key } from './__generated__/SubscriptionPlanModal_plan.graphql';
import { SubscriptionPlanModal_AddMutation } from './__generated__/SubscriptionPlanModal_AddMutation.graphql';
import { SubscriptionPlanModal_ChangeMutation } from './__generated__/SubscriptionPlanModal_ChangeMutation.graphql';
import { productName } from '@app/screens/BillingScreen/utils';

interface Props {
  open: ModalProps["open"]
  onHide: ModalProps["onHide"]
  onChange: () => void
  product?: Product,
  subscription: SubscriptionPlanModal_subscription$key | null
  plan: SubscriptionPlanModal_plan$key | null
}

export default function SubscriptionPlanModal(props: Props) {
  const subscription = useFragment(
    graphql`
      fragment SubscriptionPlanModal_subscription on BillingSubscription {
        id
        isCancelling
        currency
        interval

        products {
          plan {
            id
          }
        }
      }
    `,
    props.subscription
  );

  const plan = useFragment(
    graphql`
      fragment SubscriptionPlanModal_plan on Plan {
        id
        name
        legacyId
        currency
        interval
        product
        subscriptionPrice
      }
    `,
    props.plan
  );

  const multipleProducts = (subscription?.products.filter(p => p.plan).length ?? 0) >= 2;
  const [changeExecutor, changeStatus] = useMutation<SubscriptionPlanModal_ChangeMutation>(
    graphql`
      mutation SubscriptionPlanModal_ChangeMutation($input: ChangePlanInput!) {
        changePlan(input: $input) {
          subscription {
            isCancelling
            ...SubscriptionScreen_ProductPanel_subscription
          }

          product {
            ...SubscriptionScreen_ProductPanel_product
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])
          }
        }
      }
    `
  );

  const [addExecutor, addStatus] = useMutation<SubscriptionPlanModal_AddMutation>(
    graphql`
      mutation SubscriptionPlanModal_AddMutation($input: AddProductPlanInput!) {
        addProductPlan(input: $input) {
          subscription {
            isCancelling
            ...SubscriptionScreen_ProductPanel_subscription
            ...SubscriptionScreen_ProductPanels_subscription
          }

          product {
            ...SubscriptionScreen_ProductPanel_product
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])
          }
        }
      }
    `
  );

  if (!subscription) return null;
  const isCancelling = subscription.isCancelling;
  const [choice, setChoice] = useState<PlanChoice | null>(plan ?? null);

  const handleHide = () => {
    if(props.onHide){
      props.onHide();
    }
  };

  const handleSubmit = async () => {
    if (!choice) return;

    if (props.plan) {
      await changeExecutor.executePromise({
        input: {
          subscriptionId: subscription.id,
          product: props.product ?? 'Verify',
          planId: choice.id
        }
      });
    } else {
      await addExecutor.executePromise({
        input: {
          subscriptionId: subscription.id,
          product: props.product ?? 'Verify',
          planId: choice.id
        }
      });
    }

    // tracking.subscriptionChangedPlan(choice);
    props.onChange();
  };

  const changed = choice?.id !== plan?.id;
  const enabled = changed || isCancelling;

  return (
    <Modal {...props} className='!w-[750px]'>
      <Modal.Header>
        <div>
          <h2>{`Select ${productName(props.product ?? 'Verify').toLowerCase()} plan`}</h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        <PlanSelection
          product={props.product ?? 'Verify'}
          selected={choice || undefined}
          customerInterval={(multipleProducts || !plan) ? subscription.interval : undefined}
          customerCurrency={subscription?.currency}
          onSelect={setChoice}
        />
      </Modal.Content>

      {changeStatus.error && (
        <div className='mb-[40px] mx-[24px]'>
          <Alert variant="error" title="An error occurred" message={changeStatus.error.message} />
        </div>
      )}
      {addStatus.error && (
        <div className='mb-[40px] mx-[24px]'>
          <Alert variant="error" title="An error occurred" message={addStatus.error.message} />
        </div>
      )}

      <Modal.Actions>
        <Button variant="default" onClick={handleHide}>Cancel</Button>
        <Button variant="primary" working={changeStatus.pending || addStatus.pending} disabled={!enabled} onClick={handleSubmit}>
          {plan ? (
            <React.Fragment>
              {isCancelling ? 'Reactivate subscription with ' : 'Change plan to '} {choice ? `${choice.name} (${choice.interval.toLowerCase()}ly in ${choice.currency.toUpperCase()})` : 'selected plan'}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {choice ? `Select ${choice.name} (${choice.interval.toLowerCase()}ly in ${choice.currency.toUpperCase()})` : 'Select plan'}
            </React.Fragment>
          )}
        </Button>

      </Modal.Actions>

    </Modal>
  )
}
