import React, { useState } from 'react';
import {graphql} from 'react-relay';
import { ConnectionHandler, useFragment } from 'react-relay';
import _ from 'lodash';
import useMutation from '@app/hooks/useMutation';
import useEnvironment from '@app/hooks/useEnvironment';
import { useMfa } from '@app/hooks/useMfa';
import { Application, TechnologyGroupTag } from '@app/models';
import { TechnologyChoice, isClientSecretTechnologyChoice } from '../../ApplicationsScreen/components/TechnologySelector/TechnologySelector_v2';
import InputField from '@app/components/FormFields/InputField/InputField';
import Button from '@components/Button';
import { Form, FormError, FormSuccess } from '@app/components/Form';
import ApplicationRealmValidation from '@app/screens/ApplicationsScreen/components/ApplicationEditor/ApplicationRealmValidation';
import ReturnURLs from '@app/screens/ApplicationsScreen/components/ApplicationEditor/ReturnURLs';
import ClientSecretModal from '@app/screens/ApplicationsScreen/components/ClientSecretModal/ClientSecretModal';
import { ApplicationScopeStrategy, CreateAgeVerificationApplicationComponentMutation } from './__generated__/CreateAgeVerificationApplicationComponentMutation.graphql';
import { CreateAgeVerificationApplicationComponent_tenant$key } from './__generated__/CreateAgeVerificationApplicationComponent_tenant.graphql';
import { translate } from '@app/i18n';

interface Props {
  tenant: CreateAgeVerificationApplicationComponent_tenant$key;
  technology?: TechnologyChoice | null;
  technologyGroup?: TechnologyGroupTag | null;
}

type Values = {
  name: string;
  realm: string;
  returnUrls: string[];
  scopeStrategy: ApplicationScopeStrategy;
};

export function CreateAgeVerificationApplicationComponent(props: Props) {
  const { technology, technologyGroup } = props;
  const environment = useEnvironment();
  const tenant = useFragment(
    graphql`
      fragment CreateAgeVerificationApplicationComponent_tenant on Tenant @argumentDefinitions(environment: { type: "Environment" }) {
        domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
          edges {
            node {
              id
              name
            }
          }
        }

        ...DomainField_tenant @arguments(environment: $environment)
      }
    `,
    props.tenant
  );

  const [mutationExecutor, mutationState] = useMutation<CreateAgeVerificationApplicationComponentMutation>(graphql`
    mutation CreateAgeVerificationApplicationComponentMutation(
      $connections: [ID!]!
      $input: CreateApplicationInput!
      $environment: Environment!
    ) {
      createApplication(input: $input) {
        ... on CreateVerifyApplicationOutput {
          applicationEdge @appendEdge(connections: $connections) {
            cursor
            node {
              id
              __typename
              name
              realm

              domain {
                name
              }

              ...GeneralSection_application
              ...VerifyApplicationIntegrate_application
            }
          }
          application {
            id
            domain {
              name
            }
            name
            realm

            ...GeneralSection_application
            ...VerifyApplicationIntegrate_application
          }
          clientSecret

          tenant {
            id
            onboarding {
              hasApplication(environment: $environment, product: Ageverification_DK)
            }
            extensionsEnabled(environment: $environment)
            extensions(environment: $environment) {
              installed {
                id
                name
              }
              available {
                id
                name
              }
            }
          }
        }
      }
    }
  `);

  const checkMfa = useMfa(environment === 'PRODUCTION');
  const [isRealmValid, setRealmValid] = useState(true);
  const [application, setApplication] = useState<{ domain: { name: string }; realm: string; name: string; id: string } | null>(null);

  const [showClientSecret, setShowClientSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const domain = tenant.domains.edges[0].node;

  const initialValues: Values = {
    name: 'Age verification',
    realm: `urn:my:application:identifier:${_.random(1000, 999999)}`,
    returnUrls: [],
    scopeStrategy: 'DYNAMIC',
  };

  const isFormValid = isRealmValid && domain.id;
  const handleSubmit = async (values: Values) => {
    if (!isFormValid) throw new Error('Invalid form fields');
    const connections = [
      ConnectionHandler.getConnectionID(domain.id, 'domain_applications'),
      ConnectionHandler.getConnectionID(domain.id, 'domain_applications', null),
    ];

    const oauth2ClientSecretEnabled = isClientSecretTechnologyChoice(technology ?? undefined, technologyGroup ?? undefined);

    await checkMfa();
    const result = await mutationExecutor.executePromise({
      input: {
        domainId: domain.id,
        name: values.name,
        realm: values.realm,
        callbackUrls: values.returnUrls,
        oauth2ClientSecretEnabled,
        product: 'Ageverification_DK',
        technology,
        technologyGroup,
        scopeStrategy: values.scopeStrategy,
      },
      connections,
      environment,
    });

    if (result.createApplication.clientSecret) {
      setApplication(result.createApplication.application!);
      setClientSecret(result.createApplication.clientSecret);
      setShowClientSecret(true);
    }
  };

  const handleClientSecretHide = () => {
    setClientSecret(null);
    setShowClientSecret(false);
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        data-test-id="form"
        key="application_creater"
        className="max-w-[750px] flex flex-col gap-[8px] form-gap"
      >
        {({ isPending, error, isSuccess, values }) => (
          <>
            <div>
              <InputField<Application>
                type="text"
                label={translate('LABEL_CLIENT_ID')}
                name="realm"
                required
                placeholder={translate('LABEL_CLIENT_ID')}
                helpText={
                  <span>
                    The Client ID/Realm must be unique for each application within the selected domain.
                    <br />
                    When integrating Criipto into your code, you will need the domain name and your client ID.
                  </span>
                }
                iconRight={
                  !(isSuccess || isPending) &&
                  domain && (
                    <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
                      <ApplicationRealmValidation domainId={domain.id} realm={values.realm} onValid={setRealmValid} />
                    </React.Suspense>
                  )
                }
                error={!isRealmValid && domain && <>{translate('REALM_EXISTS')}</>}
              />
            </div>

            <ReturnURLs />

            {error && <FormError error={error} />}

            {isSuccess && <FormSuccess message="Application created" />}

            {technologyGroup ? (
              <div>
                <Button variant="primary" type="submit" working={isPending}>
                  Create application
                </Button>
              </div>
            ) : null}
          </>
        )}
      </Form>
      {application && (
        <ClientSecretModal open={showClientSecret} onHide={handleClientSecretHide} application={application} clientSecret={clientSecret!} />
      )}
    </>
  );
}