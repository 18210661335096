import React from 'react';

import styles from './StatBox.module.css';

interface Props {
  prefix: string | React.ReactNode
  number: number | 'N/A'
  suffix: string | React.ReactNode
}
export default function StatBox(props: Props) {
  return (
    <div className={styles["analytics-stat-box"]}>
      <div className={styles["prefix"]}>{props.prefix}</div>
      <div className={styles["number"]}>{props.number}</div>
      <div className={styles["suffix"]}>{props.suffix}</div>
    </div>
  )
}