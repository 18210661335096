/**
 * @generated SignedSource<<8cf4d600385be5669f0fc1ea02c51e2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type PreviewsScreenDomainQuery$variables = {
  id: string;
  skip: boolean;
};
export type PreviewsScreenDomainQuery$data = {
  readonly domain?: {
    readonly applications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly name: string;
          readonly realm: string;
          readonly ui?: {
            readonly cssClass: string | null;
            readonly javascript: string | null;
            readonly stylesheet: string | null;
            readonly viewVersion: ViewVersion | null;
          };
        };
      }>;
    };
  } | null;
};
export type PreviewsScreenDomainQuery = {
  response: PreviewsScreenDomainQuery$data;
  variables: PreviewsScreenDomainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ApplicationConnection",
  "kind": "LinkedField",
  "name": "applications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "realm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ApplicationUISettings",
                  "kind": "LinkedField",
                  "name": "ui",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cssClass",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stylesheet",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewVersion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "javascript",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "VerifyApplication",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewsScreenDomainQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewsScreenDomainQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b24ab5e6c0c66849b6c831c8e2cbb136",
    "id": null,
    "metadata": {},
    "name": "PreviewsScreenDomainQuery",
    "operationKind": "query",
    "text": "query PreviewsScreenDomainQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  domain(id: $id) @skip(if: $skip) {\n    applications {\n      edges {\n        node {\n          id\n          realm\n          name\n          __typename\n          ... on VerifyApplication {\n            ui {\n              cssClass\n              stylesheet\n              viewVersion\n              javascript\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a174f5fac4288b4dce45f576e966425";

export default node;
