import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {identity} from 'lodash';

// "operational" "under_maintenance" "degraded_performance" "partial_outage" "major_outage"


export enum StatuspageStatus {
  Operational,
  UnderMaintenance,
  DegradedPerformance,
  PartialOutage,
  MajorOutage,
  Unknown
};

type StatusPageCssClass = "ok" | "warning" | "danger";

export interface StatuspageComponent {
  id: string
  name: string
  status: StatuspageStatus
  description: string
  cssClass: StatusPageCssClass
  components?: StatuspageComponent[]
}

export interface StatuspageState {
  status: StatuspageStatus,
  components: StatuspageComponent[],
  tooltip: string;
  cssClass: StatusPageCssClass
};

function statusToCssClass(status : StatuspageStatus) : StatusPageCssClass {
  switch (status) {
    case StatuspageStatus.Operational:
      return 'ok';
    case StatuspageStatus.UnderMaintenance:
      return 'warning';
    case StatuspageStatus.DegradedPerformance:
      return 'warning';
    case StatuspageStatus.PartialOutage:
      return 'warning';
    case StatuspageStatus.Unknown:
      return 'warning';
    case StatuspageStatus.MajorOutage:
      return 'danger';

  }
  assertUnreachable(status); // DU compiler hack
}

function parseComponents(json : any) : StatuspageComponent[] {
  const groups : {[key: string]: StatuspageComponent[]} = {};
  const components : StatuspageComponent[] = json.components.map((values : any) => {
    let status : StatuspageStatus;
    switch(values.status) {
      case "operational":
        status = StatuspageStatus.Operational;
        break;
      case "under_maintenance":
        status = StatuspageStatus.UnderMaintenance;
        break;
      case "degraded_performance":
        status = StatuspageStatus.DegradedPerformance;
        break;
      case "partial_outage":
        status = StatuspageStatus.PartialOutage;
        break;
      case "major_outage":
        status = StatuspageStatus.MajorOutage;
        break;
      default:
        console.warn(`Unknown statuspage component status ${values.status}`);
        status = StatuspageStatus.Unknown;
        break;
    }

    const component : StatuspageComponent = {
      id: values.id,
      name: values.name,
      description: values.description,
      status,
      cssClass: statusToCssClass(status)
    };
    if (values.group_id) {
      groups[values.group_id] = groups[values.group_id] || [];
      groups[values.group_id].push(component);
    } else {
      return component;
    }
  }).filter(identity);

  return components.map(component => {
    component.components = groups[component.id] || null;
    return component;
  });
}

export const initialState: StatuspageState = {
  status: StatuspageStatus.Operational,
  components: [],
  tooltip: "Operational",
  cssClass: "ok"
};

export const fetchComponents = createAsyncThunk<StatuspageComponent[]>(
  'statuspage/fetch',
  async () => {
    const response = await fetch('https://rmcp9k87gctd.statuspage.io/api/v2/components.json');
    const json = await response.json();
    return parseComponents(json);
  }
);

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

function ammendState(state : StatuspageState) : StatuspageState {
  switch (state.status) {
    case StatuspageStatus.Operational:
      return {
        ...state,
        cssClass: statusToCssClass(state.status),
        tooltip: 'Operational'
      };
      case StatuspageStatus.UnderMaintenance:
        return {
          ...state,
          cssClass: statusToCssClass(state.status),
          tooltip: 'Under maintenance'
        };
      case StatuspageStatus.DegradedPerformance:
        return {
          ...state,
          cssClass: statusToCssClass(state.status),
          tooltip: 'Degraded performance'
        };
      case StatuspageStatus.PartialOutage:
        return {
          ...state,
          cssClass: statusToCssClass(state.status),
          tooltip: 'Partial outage'
        };
      case StatuspageStatus.Unknown:
        return {
          ...state,
          cssClass: statusToCssClass(state.status),
          tooltip: 'Unknown'
        };
      case StatuspageStatus.MajorOutage:
        return {
          ...state,
          cssClass: statusToCssClass(state.status),
          tooltip: 'Major outage'
        };

  }
  assertUnreachable(state.status); // DU compiler hack
}

export const environmentSlice = createSlice({
  name: 'statuspage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchComponents.fulfilled, (state, { payload }) => {
      const newState = {
        ...state,
        status: payload.reduce((memo, component) => {
          if (component.status < memo) return component.status;
          return memo;
        }, StatuspageStatus.Operational),
        components: payload
      };

      return ammendState(newState);
    });
    builder.addCase(fetchComponents.rejected, (state, { error, payload }) => {
      console.error(error);
    });
  }
})

// export const { set, toggle } = environmentSlice.actions;
export default environmentSlice;