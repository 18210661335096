/**
 * @generated SignedSource<<ceca42a7efab0a7cca15b8be8663b560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type CopyApplicationModalQuery$variables = {
  environment: Environment;
  id: string;
};
export type CopyApplicationModalQuery$data = {
  readonly tenant: {
    readonly environments: ReadonlyArray<Environment>;
    readonly " $fragmentSpreads": FragmentRefs<"DomainField_tenant">;
  } | null;
};
export type CopyApplicationModalQuery = {
  response: CopyApplicationModalQuery$data;
  variables: CopyApplicationModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "kind": "Variable",
  "name": "environment",
  "variableName": "environment"
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v5 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyApplicationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "DomainField_tenant"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CopyApplicationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "domains",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "environment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [
              "environment"
            ],
            "handle": "connection",
            "key": "tenant_domains",
            "kind": "LinkedHandle",
            "name": "domains"
          },
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c2cbe738cd059d81ceaf271cce8076f",
    "id": null,
    "metadata": {},
    "name": "CopyApplicationModalQuery",
    "operationKind": "query",
    "text": "query CopyApplicationModalQuery(\n  $id: ID!\n  $environment: Environment!\n) {\n  tenant(id: $id) {\n    ...DomainField_tenant_2bptcK\n    environments(product: Verify)\n    id\n  }\n}\n\nfragment DomainField_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        environment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfa60115dfb3d46a63fb2350b6bb3d74";

export default node;
