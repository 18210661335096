/**
 * @generated SignedSource<<b5bdaacf61411a1aeba78b6a94329c3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserInvitationButton_tenant$data = {
  readonly viewerPermissions: {
    readonly users: Permission;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserInvitationModal_tenant">;
  readonly " $fragmentType": "UserInvitationButton_tenant";
};
export type UserInvitationButton_tenant$key = {
  readonly " $data"?: UserInvitationButton_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInvitationButton_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInvitationButton_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserInvitationModal_tenant"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerTenantPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "users",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "562a3e110b412347f5d57fa52ebf3d98";

export default node;
