/**
 * @generated SignedSource<<db072a55b52102c2d50c10d795b957b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInvitationModal_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InvitationForm_tenant">;
  readonly " $fragmentType": "UserInvitationModal_tenant";
};
export type UserInvitationModal_tenant$key = {
  readonly " $data"?: UserInvitationModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInvitationModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInvitationModal_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationForm_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "6f02e351f130dc734fe0381a6739b74b";

export default node;
