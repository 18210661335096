/**
 * @generated SignedSource<<0c144c1da1abc25ccaf8eb65803d9bf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type DomainLookupQuery$variables = {
  domain: string;
  environment: Environment;
  skip: boolean;
  tenantId: string;
};
export type DomainLookupQuery$data = {
  readonly domainLookup?: {
    readonly available: boolean;
    readonly cname: {
      readonly actual: string | null;
      readonly expected: string;
      readonly matches: boolean;
    };
    readonly txt: {
      readonly actual: string | null;
      readonly expected: string;
      readonly matches: boolean;
    };
    readonly valid: boolean;
  } | null;
};
export type DomainLookupQuery = {
  response: DomainLookupQuery$data;
  variables: DomainLookupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "expected",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matches",
    "storageKey": null
  }
],
v5 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "domain",
            "variableName": "domain"
          },
          {
            "kind": "Variable",
            "name": "environment",
            "variableName": "environment"
          },
          {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
          }
        ],
        "concreteType": "DomainLookup",
        "kind": "LinkedField",
        "name": "domainLookup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainAssertion",
            "kind": "LinkedField",
            "name": "cname",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainAssertion",
            "kind": "LinkedField",
            "name": "txt",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "available",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainLookupQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DomainLookupQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "fef0108a01ae774c603562d6f65a1000",
    "id": null,
    "metadata": {},
    "name": "DomainLookupQuery",
    "operationKind": "query",
    "text": "query DomainLookupQuery(\n  $domain: String!\n  $tenantId: ID!\n  $environment: Environment!\n  $skip: Boolean!\n) {\n  domainLookup(domain: $domain, tenantId: $tenantId, environment: $environment) @skip(if: $skip) {\n    cname {\n      actual\n      expected\n      matches\n    }\n    txt {\n      actual\n      expected\n      matches\n    }\n    available\n    valid\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0532f584655e233675b08e43ed5b4ae";

export default node;
