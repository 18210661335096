import {Environment, Network, RecordSource, RequestParameters, Store, Variables} from 'relay-runtime';
import fetchGraphQL, { AuthenticationError } from './fetchGraphQL';
import store from '@app/redux/store';
import { AuthState, setTokens } from './redux/authSlice';

function refreshLogin(state: AuthState) {
  const auth = state.client;
  return Promise.all([
    auth.getIdTokenClaims(),
    auth.getTokenSilently()
  ]).then(([claims, accessToken]) => {
    store.dispatch(setTokens({
      access_token: accessToken,
      id_token: claims.__raw,
      profile: claims
    }));
  }).catch((err) => {
    return auth.loginWithRedirect({
      response_type: 'token id_token',
      appState: {
        returnTo: window.location.pathname + window.location.search
      }
    });
  });
}

export function createRelayEnvironment() {
  return new Environment({
    network: Network.create((params: RequestParameters, variables: Variables) => {
      console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`);
      return fetchGraphQL(params.text!, variables, store.getState().auth as AuthState).catch(async (err) => {
        if (err instanceof AuthenticationError) {
          await refreshLogin(store.getState().auth as AuthState).then(() => {
            console.log(`retrying query ${params.name} after auth refresh with ${JSON.stringify(variables)}`);
            return fetchGraphQL(params.text!, variables, store.getState().auth as AuthState);
          });
        }
        throw err;
      })
    }),
    store: new Store(new RecordSource()),
  });
}

// Export a singleton instance of Relay Environment configured with our network function:
const singleton = createRelayEnvironment();
export default singleton;