/**
 * @generated SignedSource<<9ac3ad50932e65ba714e1bc8fb3ad91e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type StylingScreenGeneralQuery$variables = {
  environment: Environment;
  tenantId: string;
};
export type StylingScreenGeneralQuery$data = {
  readonly tenant: {
    readonly features: {
      readonly CUSTOM_UI: boolean;
    };
    readonly id: string;
    readonly styling: {
      readonly cssOrigins: ReadonlyArray<string>;
      readonly cssUrl: string | null;
      readonly viewVersion: {
        readonly editable: boolean;
        readonly version: ViewVersion;
      };
    };
    readonly viewerPermissions: {
      readonly productionStyling: Permission;
      readonly testStyling: Permission;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PreviewsScreen_tenant">;
  } | null;
};
export type StylingScreenGeneralQuery = {
  response: StylingScreenGeneralQuery$data;
  variables: StylingScreenGeneralQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Variable",
  "name": "environment",
  "variableName": "environment"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cssUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "TenantStyling",
  "kind": "LinkedField",
  "name": "styling",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cssOrigins",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantStylingViewVersion",
      "kind": "LinkedField",
      "name": "viewVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": "testStyling",
      "args": [
        {
          "kind": "Literal",
          "name": "environment",
          "value": "TEST"
        }
      ],
      "kind": "ScalarField",
      "name": "styling",
      "storageKey": "styling(environment:\"TEST\")"
    },
    {
      "alias": "productionStyling",
      "args": [
        {
          "kind": "Literal",
          "name": "environment",
          "value": "PRODUCTION"
        }
      ],
      "kind": "ScalarField",
      "name": "styling",
      "storageKey": "styling(environment:\"PRODUCTION\")"
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "CUSTOM_UI",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StylingScreenGeneralQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "PreviewsScreen_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StylingScreenGeneralQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "domains",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [
              "environment"
            ],
            "handle": "connection",
            "key": "tenant_domains",
            "kind": "LinkedHandle",
            "name": "domains"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUI",
            "kind": "LinkedField",
            "name": "ui",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUIVerify",
                "kind": "LinkedField",
                "name": "verify",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantUIVerifyVersion",
                    "kind": "LinkedField",
                    "name": "draft",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUIFile",
                        "kind": "LinkedField",
                        "name": "html",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "etag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contents",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scriptUrl",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec39a51777cb386377c2339f26a0bcee",
    "id": null,
    "metadata": {},
    "name": "StylingScreenGeneralQuery",
    "operationKind": "query",
    "text": "query StylingScreenGeneralQuery(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    id\n    styling(environment: $environment) {\n      cssUrl\n      cssOrigins\n      viewVersion {\n        version\n        editable\n      }\n    }\n    viewerPermissions {\n      testStyling: styling(environment: TEST)\n      productionStyling: styling(environment: PRODUCTION)\n    }\n    features {\n      CUSTOM_UI\n    }\n    ...PreviewsScreen_tenant_2bptcK\n  }\n}\n\nfragment PreviewsScreen_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ui {\n    verify {\n      draft {\n        html {\n          etag\n          contents\n        }\n        scriptUrl\n        cssUrl\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "976ad65bb61cc8b8c22beb52df512f42";

export default node;
