/**
 * @generated SignedSource<<bfa1b41714cddb270131708c24a544ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingOrderKey = "DKMITID_CUSTOM_DOMAIN" | "NOBANKID" | "SEBANKID" | "%future added value";
export type ToggleIdentityProviderOrderInput = {
  enabled: boolean;
  identityProvider: OnboardingOrderKey;
  note?: string | null;
  tenantId: string;
};
export type IdpOrderToggleMutation$variables = {
  input: ToggleIdentityProviderOrderInput;
};
export type IdpOrderToggleMutation$data = {
  readonly admin_toggleIdentityProviderOrder: {
    readonly tenant: {
      readonly id: string;
      readonly tenant: {
        readonly " $fragmentSpreads": FragmentRefs<"IdpOrderToggle_tenant">;
      };
    };
  };
};
export type IdpOrderToggleMutation = {
  response: IdpOrderToggleMutation$data;
  variables: IdpOrderToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IdpOrderToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleIdentityProviderOrderOutput",
        "kind": "LinkedField",
        "name": "admin_toggleIdentityProviderOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "IdpOrderToggle_tenant"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IdpOrderToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleIdentityProviderOrderOutput",
        "kind": "LinkedField",
        "name": "admin_toggleIdentityProviderOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantFeatures",
                    "kind": "LinkedField",
                    "name": "features",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "NOBANKID_order_form",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "SEBANKID_order_form",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "DKMITID_CUSTOM_DOMAIN_order_form",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a85c340cfc2708f69cbd24af8660f050",
    "id": null,
    "metadata": {},
    "name": "IdpOrderToggleMutation",
    "operationKind": "mutation",
    "text": "mutation IdpOrderToggleMutation(\n  $input: ToggleIdentityProviderOrderInput!\n) {\n  admin_toggleIdentityProviderOrder(input: $input) {\n    tenant {\n      id\n      tenant {\n        ...IdpOrderToggle_tenant\n        id\n      }\n    }\n  }\n}\n\nfragment IdpOrderToggle_tenant on Tenant {\n  id\n  features {\n    NOBANKID_order_form\n    SEBANKID_order_form\n    DKMITID_CUSTOM_DOMAIN_order_form\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7ee7def01e75c78c60649033f73771e";

export default node;
