import {ISegmentv2, SegmentProps} from '../../typings/segment';

import { IdToken } from '@auth0/auth0-spa-js';

import {singleton as config} from '../config';
import { toLongTenantId } from '@app/hooks/useTenant';

export const Events = {
  CLIENT_CREATED: "Client created",
  APPLICATION_CREATED: "Application created",
  CLIENT_DELETED: "Client deleted",
  CLIENT_UPDATED: "Client updated",
  APPLICATION_UPDATED: "Application updated",
  APPLICATION_COPIED: 'Application copied',
  APPLICATION_DELETED: "Application deleted",
  APPLICATION_TECHNOLOGY_CHANGED: 'Application technology changed',
  CLIENTS_OPENED: "Clients opened",
  DASHBOARD_OPENED: "Dashboard opened",
  DOMAIN_REGISTERED: "Domain registered",
  TEST_DOMAIN_CREATED: "Test domain created",
  DOMAIN_REMOVED: "Domain removed",
  DOMAIN_SSL_CERT_ADDED: "SSL cert uplaoded",
  DOMAINS_OPENED: "Domains opened",
  IDENTITY_SERVICES_OPENED: "Identity Services opened",
  STARTED_APPLICATION: "Started application",
  SIGN_IN: "Signed In",
  NEW_TENANT_CREATED: "New tenant created",
  SIGN_UP_BEGIN: "StartTrial",
  SIGNED_UP_USER: "User signed up",
  SIGNED_UP_TENANT: "Signed up",
  SIGNED_UP_ORGANIZATION: "Organization signed up",
  USER_INVITED: "Invited team member",
  INVITATION_ACCEPTED: "Accepted team invitation",
  VIEWED_ONBOARDING_SCREEN: "Viewed onboarding screen",
  VIEWED_DASHBOARD_SCREEN: "Viewed dashboard screen",
  VIEWED_ANALYTICS_SCREEN: "Viewed analytics screen",
  VIEWED_LOGS_SCREEN: "Viewed logs screen",
  VIEWED_STYLING_SCREEN: "Viewed styling screen",
  SUBSCRIPTION_CANCELED: "Subscription canceled",
  SUBSCRIPTION_PLAN_CHANGED: "Subscription plan changed",
  PAID: 'Paid'
};

export function translateEvent(event : keyof typeof Events) {
  return Events[event];
}

export class SegmentV2 {
  private identified : boolean;

  analytics() : ISegmentv2 | undefined {
    if (!window.analytics) return undefined;
    return window.analytics as ISegmentv2;
  }

  identify(profile : IdToken, extra?: SegmentProps) {
    if (!profile.sub) return;
    const traits: SegmentProps = profile.email ? { "email": profile.email, ...extra } : {...extra};
    traits["name"] = profile.name;
    this.analytics()?.identify(profile.sub, traits);
    this.identified = true;
  }

  group(tenant: {name: string, tenantId: string}, extra?: SegmentProps) : void {
    const tenantId = toLongTenantId(tenant.tenantId);

    if (!tenantId) return;

    const traits = {
      tenantId,
      name: tenant.name, $name: tenant.name,
      ...(extra || {})
    };

    this.analytics()?.group(tenantId, traits);

    console.debug(`Segment.group`, tenantId, traits);
  }

  track(event : keyof typeof Events, props? : SegmentProps) {
    props = props || {};
    props.application = config.applicationName
    this.analytics()?.track(Events[event], props);
    console.debug(`Segment.track ${Events[event]}`, props);
  }
}

export const singleton = new SegmentV2();
export default SegmentV2;