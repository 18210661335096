import {deserializer} from './models/utils';
import {EasyIdConfig} from './config';
import {REL, CONTENT_TYPE} from '@app/constants';
import {Environment, IdentityConnection} from './models';

export interface IdentityProviderData {
  name: string;
  urn: string;
}
export class IdentityProvider implements IdentityProviderData {
  name: string;
  urn: string;
  deserialize(data: IdentityProviderData) {
    this.name = data.name;
    this.urn = data.urn;

    return this;
  }
}

export interface DetailedIdentityProviderData {
  id: string;
  specificProviders: IdentityProviderData[];
  name: string;
  specificMethod: string;
}
export class DetailedIdentityProvider implements DetailedIdentityProviderData {
  id: string;
  specificProviders: IdentityProvider[];
  name: string;
  specificMethod: string;

  deserialize(data: DetailedIdentityProviderData) {
    this.id = data.id;
    this.name = data.name;
    this.specificMethod = data.specificMethod;
    this.specificProviders = [];

    this.specificProviders = data.specificProviders.map(deserializer<IdentityProvider, IdentityProviderData>(IdentityProvider));

    return this;
  }
}

const idpDeserializer = deserializer<DetailedIdentityProvider, DetailedIdentityProviderData>(DetailedIdentityProvider);

export const IDENTITY_PROVIDERS = {
  DK_NEMID: idpDeserializer({
    id: "DK_NEMID",
    name: "DK NemID",
    specificMethod: "dkNemId",
    specificProviders: [
      { name: "DK NemID Personal", urn: "urn:grn:authn:dk:nemid:poces" },
      { name: "DK NemID Employee", urn: "urn:grn:authn:dk:nemid:moces" },
      { name: "DK NemID Employee - Code file", urn: "urn:grn:authn:dk:nemid:moces:codefile" }
    ]
  }),
  DK_MITID: idpDeserializer({
    id: "DK_MITID",
    name: "DK MitID",
    specificMethod: "dkMitId",
    specificProviders: [
      { name: "DK MitID Low", urn: "urn:grn:authn:dk:mitid:low" },
      { name: "DK MitID Substantial", urn: "urn:grn:authn:dk:mitid:substantial" },
      { name: "DK MitID High", urn: "urn:grn:authn:dk:mitid:high" },
      /**
       * Not added as it's own top level provider as that has ripple effects in other parts of the UI
       */
      { name: "DK MitID Business", urn: "urn:grn:authn:dk:mitid:business" }
    ]
  }),
  SE_BANKID: idpDeserializer({
    id: "SE_BANKID",
    name: "SE BankID",
    specificMethod: "seBankId",
    specificProviders: [
      { name: "SE BankID This Device", urn: "urn:grn:authn:se:bankid:same-device" },
      { name: "SE BankID Another Device", urn: "urn:grn:authn:se:bankid:another-device" },
      { name: "SE BankID Another Device - QRCode Enabled", urn: "urn:grn:authn:se:bankid:another-device:qr" }
    ]
  }),
  NO_BANKID: idpDeserializer({
    id: "NO_BANKID",
    name: "NO BankID",
    specificMethod: "noBankId",
    specificProviders: [
      { name: "NO BankID", urn: "urn:grn:authn:no:bankid" },
      { name: "NO BankID Biometrics", urn: "urn:grn:authn:no:bankid:substantial" }
    ]
  }),
  NO_VIPPS: idpDeserializer({
    id: "NO_VIPPS",
    name: "NO Vipps",
    specificMethod: "noVipps",
    specificProviders: [
      { name: "NO Vipps", urn: "urn:grn:authn:no:vipps" },
    ]
  }),
  NO_BANKID_OLD: idpDeserializer({
    id: "NO_BANKID_OLD",
    name: "NO BankID (phased out)",
    specificMethod: "noBankIdOld",
    specificProviders: [
      { name: "NO BankID Web", urn: "urn:grn:authn:no:bankid:central" },
      { name: "NO BankID Mobile", urn: "urn:grn:authn:no:bankid:mobile" }
    ]
  }),
  FI_TELIA: idpDeserializer({
    id: "FI_TELIA",
    name: "Finnish Trust Network, FTN",
    specificMethod: "ftn",
    specificProviders: [
      { name: "Finnish Trust Network - All" , urn: "urn:grn:authn:fi:all" },
      { name: "Finnish Trust Network - BankId" , urn: "urn:grn:authn:fi:bank-id" },
      { name: "Finnish Trust Network - MobileId" , urn: "urn:grn:authn:fi:mobile-id" },
      // { name: "Finnish Trust Network - Tupas" , urn: "urn:grn:authn:fi:tupas" }
    ]
  }),
  NL_DIGID: idpDeserializer({
    id: "NL_DIGID",
    name: "NL DigiD",
    specificMethod: "nlDigiD",
    specificProviders: [
      { name: "NL DigiD Basic", urn: "urn:grn:authn:nl:digid:basic" },
      { name: "NL DigiD Middle", urn: "urn:grn:authn:nl:digid:middle" },
      { name: "NL DigiD Substantial", urn: "urn:grn:authn:nl:digid:substantial" },
      { name: "NL DigiD High", urn: "urn:grn:authn:nl:digid:high" }
    ]
  }),
  NL_IDIN: idpDeserializer({
    id: "NL_IDIN",
    name: "NL iDIN",
    specificMethod: "nliDIN",
    specificProviders: [
      { name: "NL iDIN", urn: "urn:grn:authn:nl:idin" }
    ]
  }),
  ITSME: idpDeserializer({
    id: "ITSME",
    name: "Itsme",
    specificMethod: "itsme",
    specificProviders: [
      { name: "Itsme basic", urn: "urn:grn:authn:itsme:basic" },
      { name: "Itsme advanced", urn: "urn:grn:authn:itsme:advanced" }
    ]
  }),
  BE_EID: idpDeserializer({
    id: "BE_EID",
    name: "BE eID",
    specificMethod: "beeID",
    specificProviders: [
      { name: "BE eID verified", urn: "urn:grn:authn:be:eid:verified" }
    ]
  }),
  DE_SOFORT: idpDeserializer({
    id: "DE_SOFORT",
    name: "DE Sofort",
    specificMethod: "sofort",
    specificProviders: [
      { name: "Sofort with Schufa", urn: "urn:grn:authn:de:sofort" }
    ]
  })
} as const;

export const filterProviders = function (input : typeof IDENTITY_PROVIDERS, config : EasyIdConfig, flags: {
  FRENCH_PUBLIK: boolean,
  DKNEMID_ENABLED: boolean
}) {
  let filtered = [];
  let providerKey : keyof typeof IDENTITY_PROVIDERS;
  for (providerKey in input) {
    switch (providerKey) {
      case 'NO_BANKID':
        if (config.norwegianBankIdOidc) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'NO_BANKID_OLD':
        let provider = input[providerKey];

        if (!config.norwegianBankIdOidc) {
          provider.name = "NO BankID"
        }

        filtered.push(provider);
        break;
      case 'DK_NEMID':
        if (flags.DKNEMID_ENABLED) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'DK_MITID':
        if (config.enableDKMitID) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'ITSME':
        if (config.enableItsme) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'DE_SOFORT':
        if (config.enableGermanSofort) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'BE_EID':
        if (config.enableBelgianEID) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'FI_TELIA':
        if (config.enableFinnishTelia) {
          filtered.push(input[providerKey]);
        }
        break;
      case 'NL_IDIN':

        break;
      default:
        filtered.push(input[providerKey]);
        break;
    }
  }

  if (flags.FRENCH_PUBLIK) {
    const provider = new DetailedIdentityProvider();
    provider.id = 'FR_TOULOUSE';
    provider.name = 'French Toulouse';
    provider.specificProviders = [
      (new IdentityProvider()).deserialize({ name: "French Toulouse", urn: "urn:grn:authn:fr:toulouse" })
    ];
    filtered.push(provider);
  }

  return filtered;
}

export function providerRequiresConfiguration(environment: Environment, provider: DetailedIdentityProvider) {
  if (environment === 'PRODUCTION') return true;
  if (['NL_DIGID', 'DK_MITID', 'NL_IDIN', 'FR_TOULOUSE'].includes(provider.id)) return true;
  return false;
}

export function getProviderConfiguration(provider: DetailedIdentityProvider, response: IdentityConnection) {
  if (provider === IDENTITY_PROVIDERS.DE_SOFORT) return response.germanSofort;
  if (provider === IDENTITY_PROVIDERS.NO_BANKID) return response.norwegianBankId;
  if (provider === IDENTITY_PROVIDERS.DK_NEMID) return response.danishNemId;
  if (provider === IDENTITY_PROVIDERS.DK_MITID) return response.danishMitId;
  if (provider === IDENTITY_PROVIDERS.SE_BANKID) return response.swedishBankId;
  if (provider === IDENTITY_PROVIDERS.BE_EID) return response.beeID;
  if (provider === IDENTITY_PROVIDERS.ITSME) return response.itsme;
  if (provider === IDENTITY_PROVIDERS.NO_VIPPS) return response.norwegianVippsLogin;
  if (provider === IDENTITY_PROVIDERS.NL_DIGID) return response.dutchDigId;
  if (provider === IDENTITY_PROVIDERS.NL_IDIN) return response.dutchiDIN;
  if (provider === IDENTITY_PROVIDERS.NO_BANKID_OLD) return response.norwegianBankIdOld;
  return null;
}

export let getDetailedIdp = function (urn: string) {
  if (IDENTITY_PROVIDERS.DK_NEMID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.DK_NEMID;
  else if (IDENTITY_PROVIDERS.DK_MITID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.DK_MITID;
  else if (IDENTITY_PROVIDERS.NO_BANKID_OLD.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.NO_BANKID_OLD;
  else if (IDENTITY_PROVIDERS.NO_BANKID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.NO_BANKID;
  else if (IDENTITY_PROVIDERS.NO_VIPPS.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.NO_VIPPS;
  else if (IDENTITY_PROVIDERS.SE_BANKID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.SE_BANKID;
  else if (IDENTITY_PROVIDERS.NL_DIGID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.NL_DIGID;
  else if (IDENTITY_PROVIDERS.ITSME.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.ITSME;
  else if (IDENTITY_PROVIDERS.BE_EID.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.BE_EID;
  else if (IDENTITY_PROVIDERS.DE_SOFORT.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.DE_SOFORT;
  else if (IDENTITY_PROVIDERS.FI_TELIA.specificProviders.some(value => value.urn === urn))
    return IDENTITY_PROVIDERS.FI_TELIA;
  return null;
};

export function getIdp(urn: string) {
  const detailedIdp = getDetailedIdp(urn);
  if (!detailedIdp) return null;
  return detailedIdp.specificProviders.find(s => s.urn === urn);
}

