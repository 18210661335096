/**
 * @generated SignedSource<<ac9149f8bc2d1271b5b1c5d48f7c89d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationsScreen_Item_application$data = {
  readonly __typename: string;
  readonly acrValues: ReadonlyArray<string>;
  readonly domain: {
    readonly name: string;
  };
  readonly id: string;
  readonly name: string;
  readonly realm: string;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"CopyApplicationButton_application" | "DeleteApplicationButton_application">;
  readonly " $fragmentType": "ApplicationsScreen_Item_application";
};
export type ApplicationsScreen_Item_application$key = {
  readonly " $data"?: ApplicationsScreen_Item_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationsScreen_Item_application">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationsScreen_Item_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acrValues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApplicationButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CopyApplicationButton_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};
})();

(node as any).hash = "fcae9e9c088224312283a854e2f3098f";

export default node;
