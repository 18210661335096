/**
 * @generated SignedSource<<ce2ab3aab1e13456a92bfe0ed27c105a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantMitIDScreen_ServiceProvider_tenant$data = {
  readonly danishMitIdServiceProvider: {
    readonly alias: string;
    readonly companyName: string;
    readonly companyType: string;
    readonly serviceProviderId: string;
    readonly state: string;
    readonly vatCountry: string;
    readonly vatNumber: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "TenantMitIDScreen_ServiceProvider_tenant";
};
export type TenantMitIDScreen_ServiceProvider_tenant$key = {
  readonly " $data"?: TenantMitIDScreen_ServiceProvider_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantMitIDScreen_ServiceProvider_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantMitIDScreen_ServiceProvider_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "concreteType": "DanishMitIdServiceProvider",
      "kind": "LinkedField",
      "name": "danishMitIdServiceProvider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceProviderId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};

(node as any).hash = "e78bd4bd8659ddedc716ad3658a7cecd";

export default node;
