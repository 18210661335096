/**
 * @generated SignedSource<<30581bbf9446d8ed7b121eeb60c59d9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Users_OrganizationUsersScreen_Query$variables = {
  organizationId: string;
};
export type Users_OrganizationUsersScreen_Query$data = {
  readonly organization: {
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"Users_OrganizationUser_User">;
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"Users_OrganizationUser_Organization">;
  };
};
export type Users_OrganizationUsersScreen_Query = {
  response: Users_OrganizationUsersScreen_Query$data;
  variables: Users_OrganizationUsersScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Users_OrganizationUsersScreen_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Users_OrganizationUser_Organization"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationUserConnection",
              "kind": "LinkedField",
              "name": "users",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationUserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrganizationUser",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "Users_OrganizationUser_User"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Users_OrganizationUsersScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUserPermission",
            "kind": "LinkedField",
            "name": "allPermissions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerOrganizationPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "users",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationUserPermission",
                        "kind": "LinkedField",
                        "name": "permissions",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c75c98b7659f9a033e518fa04619b8a1",
    "id": null,
    "metadata": {},
    "name": "Users_OrganizationUsersScreen_Query",
    "operationKind": "query",
    "text": "query Users_OrganizationUsersScreen_Query(\n  $organizationId: ID!\n) {\n  organization(id: $organizationId) {\n    ...Users_OrganizationUser_Organization\n    users {\n      edges {\n        node {\n          id\n          ...Users_OrganizationUser_User\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Users_OrganizationUser_Organization on Organization {\n  allPermissions {\n    id\n    name\n    description\n  }\n  viewerPermissions {\n    users\n  }\n}\n\nfragment Users_OrganizationUser_User on OrganizationUser {\n  id\n  name\n  email\n  permissions {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "08ad8796c1d7ca6db127c55347461769";

export default node;
