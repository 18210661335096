import React, {useState} from 'react';
import {omit} from 'lodash';
import { ConnectionHandler, useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button, {ButtonProps} from '@app/components/Button';
import { ConfirmModal } from '@app/components/Modal/Modal';
import { translate } from '@app/i18n';
import useTracking from '@app/hooks/useTracking';
import { DeleteDomainButton_domain$key } from './__generated__/DeleteDomainButton_domain.graphql';
import { DeleteDomainButtonMutation } from './__generated__/DeleteDomainButtonMutation.graphql';
import useMutation from '@app/hooks/useMutation';
import { getRelayDomainConnections } from '../../utils';
import { Environment } from '@app/models';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

interface Props extends Omit<ButtonProps, 'variant' | 'children'> {
  domain: DeleteDomainButton_domain$key,
  onDelete?: () => void
}
export default function DeleteDomainButton(props : Props) {
  const toast = useToast();
  const [showConfirm, setShowConfirm] = useState(false);
  const tracking = useTracking();

  const domain = useFragment(graphql`
    fragment DeleteDomainButton_domain on Domain {
      id
      name
      environment

      tenant {
        id
      }
    }
  `, props.domain);

  const [{execute, executePromise}, state] = useMutation<DeleteDomainButtonMutation>(graphql`
    mutation DeleteDomainButtonMutation(
      $connections: [ID!]!
      $input: DeleteDomainInput!
    ) {
      deleteDomain(input: $input) {
        deletedDomainID @deleteEdge(connections: $connections)
      }
    }
  `);

  const handleConfirm = () => {
    const connections = getRelayDomainConnections(domain.tenant.id, domain.environment as Environment);

    return executePromise({
      input: {
        domainId: domain.id
      },
      connections
    }).then(() => {
      setShowConfirm(false);
      toast.success({title: 'Domain deleted'});
      tracking.track('DOMAIN_REMOVED', {
        "Production": domain.environment === "PRODUCTION",
        "Environment": domain.environment,
        "Domain": domain.name
      });
      if (props.onDelete) props.onDelete();
    }).catch(() => {});
  }

  const handleClick = (event : React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setShowConfirm(true);
  }

  return (
    <React.Fragment>
      <Button {...omit(props, 'onDelete')} variant="danger" type="button" onClick={handleClick} icon={<i className="fa fa-trash-alt" />} />
      <ConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        confirmText={translate('DELETE_DOMAIN')}
        confirmVariant="danger"
      >
        <div>
          <h2>Delete domain</h2>
          <p className='!m-0'>Are you sure you want to delete '{domain.name}'?</p>
          <p>The domain cannot be recreated after deletion.</p>
        </div>
        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        )}
      </ConfirmModal>
    </React.Fragment>
  )
}