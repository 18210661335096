import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import {ConfirmModal} from '@app/components/Modal';
import {DeleteApiKeyButton_apiKey$key} from './__generated__/DeleteApiKeyButton_apiKey.graphql';
import {DeleteApiKeyButton_application$key} from './__generated__/DeleteApiKeyButton_application.graphql';
import {DeleteApiKeyButtonMutation} from './__generated__/DeleteApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

interface Props {
  apiKey: DeleteApiKeyButton_apiKey$key,
  application: DeleteApiKeyButton_application$key
}

export default function DeleteApiKeyButton(props: Props) {
  const toast = useToast();
  const [showConfirm, setShowConfirm] = useState(false);

  const apiKey = useFragment(graphql`
    fragment DeleteApiKeyButton_apiKey on ApplicationApiKey {
      id
    }
  `, props.apiKey);

  const application = useFragment(graphql`
    fragment DeleteApiKeyButton_application on Signatures_Application {
      id
    }
  `, props.application);

  const [deleteKeyMutationExecutor, deleteKeyMutationState] = useMutation<DeleteApiKeyButtonMutation>(graphql`
    mutation DeleteApiKeyButtonMutation($input: DeleteApplicationApiKeyInput!) {
      deleteSignaturesApplicationApiKey(input: $input) {
        application {
          id
          ... ApiKeys_application
        }
      }
    }
  `);

  const handleClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    return deleteKeyMutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        apiKeyId: apiKey.id
      }
    }).then(() => {
      toast.success({title: 'API key deleted'});
    }).catch(() => {
      // Handled by deleteKeyMutationState
    });
  }

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" autoWidth onClick={handleClick} data-test-id="button">
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        confirmText={"Delete API key"}
        confirmVariant="danger"
        data-test-id="confirm-modal"
      >
        <div>
          <h2>Delete API key</h2>
          <p>Are you sure you want to delete this API key? Any calls using this API key will start to fail.</p>
        </div>
        {deleteKeyMutationState.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={deleteKeyMutationState.error.message} data-test-id="error" />
        )}
      </ConfirmModal>
    </React.Fragment>
  )
}