/**
 * @generated SignedSource<<e4180e1d5ba2eac2ff27a08f3658483a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SupportScreenQuery$variables = {
  tenantId: string;
};
export type SupportScreenQuery$data = {
  readonly tenant: {
    readonly name: string;
    readonly shortTenantId: string;
  } | null;
};
export type SupportScreenQuery = {
  response: SupportScreenQuery$data;
  variables: SupportScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTenantId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupportScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "249d830190dc5f19a4338dbb8856b52b",
    "id": null,
    "metadata": {},
    "name": "SupportScreenQuery",
    "operationKind": "query",
    "text": "query SupportScreenQuery(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    name\n    shortTenantId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "911271c6eea392f5762f9a19c94a1882";

export default node;
