import React from 'react';

import { CustomerRendition } from '@app/api/subscription';
import { InputField, Select } from '@app/components/Form/Form';
import { countries, euCountries } from '@app/screens/BillingScreen/countries';
import { VAT_HELP } from '@app/screens/BillingScreen/vat';

interface Props {
  values?: CustomerRendition
  readonly?: boolean
}

import './BillingInformationFields.css';
export default function BillingInformationFields(props: Props) {
  const {values} = props;

  return (
    <div className="billing-information-fields">
      <div className="flex flex-col gap-4 mb-8">
        <InputField<CustomerRendition>
          type="text"
          name="company"
          placeholder="Company"
          label="Company"
          required
          disabled={props.readonly === true}
        />

        <div className="flex flex-row gap-8">
          <InputField<CustomerRendition>
            type="text"
            name="street"
            placeholder="Street Address"
            label="Street Address"
            required
            groupClassName="flex-1"
            disabled={props.readonly === true}
          />

          <InputField<CustomerRendition>
            type="text"
            name="city"
            placeholder="City"
            label="City"
            required
            groupClassName="flex-1"
            disabled={props.readonly === true}
          />
        </div>

        <div className="flex flex-row gap-8">
          <InputField<CustomerRendition>
            type="text"
            name="zip"
            placeholder="Postal code"
            label="Postal code"
            required
            groupClassName="flex-1"
            disabled={props.readonly === true}
          />

          <Select<CustomerRendition>
            name="country"
            label="Country"
            groupClassName="flex-1"
            options={[
              {value: '', label: 'Select country'}
            ].concat(Object.entries(countries).map(([key, value]) => ({
              value: key,
              label: value
            })))}
            required
            disabled={props.readonly === true}
          />
        </div>

        <InputField<CustomerRendition>
          type="email"
          name="email"
          placeholder="Invoice Email"
          label="Invoice Email"
          required
          disabled={props.readonly === true}
          trim={true}
        />

        <InputField<CustomerRendition>
          type="text"
          name="taxId"
          placeholder={(values?.country && VAT_HELP[values.country]?.text) ? 'E.g. ' + VAT_HELP[values.country].text : 'EU VAT number - E.g. DK12345678'}
          label="EU VAT number"
          required={euCountries.has(values?.country ?? '')}
          pattern={(values?.country && VAT_HELP[values.country]?.pattern) ? VAT_HELP[values.country].pattern : undefined}
          // Required by default to emulate how it is in the current subscription UI
          // Ultimately this field should only be shown if an EU country is selected.
          disabled={props.readonly === true}
          trim={true}
        />
        <small className="help-text">{(values?.country && VAT_HELP[values.country]) ? 'E.g. ' + VAT_HELP[values.country].text : 'EU VAT number - E.g. DK12345678'}</small>
      </div>
    </div>
  )
}
