/**
 * @generated SignedSource<<dc5f04dd9690c6ca81fdea3e8998929c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClaimPropertyFormat = "COMPACT" | "DEFAULT" | "NAMESPACED" | "%future added value";
export type NullClaimValues = "ALLOW" | "REMOVE" | "%future added value";
export type UserInfoResponseStrategy = "FROM_TOKEN_ENDPOINT" | "PLAIN_JSON" | "SIGNED_JWT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OIDCSection_application$data = {
  readonly clientJwks: {
    readonly __typename: "ApplicationJwksStatic";
    readonly jwks: string;
  } | {
    readonly __typename: "ApplicationJwksUrl";
    readonly url: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly clientSecretEnabled: boolean;
  readonly id: string;
  readonly name: string;
  readonly oauth2: {
    readonly callbackOnLocationHash: boolean;
    readonly claimPropertyFormat: ClaimPropertyFormat;
    readonly nullClaimValues: NullClaimValues;
    readonly userInfoResponseStrategy: UserInfoResponseStrategy;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CodeFlow_application" | "OIDCSection_ClientJwks_application">;
  readonly " $fragmentType": "OIDCSection_application";
};
export type OIDCSection_application$key = {
  readonly " $data"?: OIDCSection_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"OIDCSection_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OIDCSection_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSecretEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationOAuth2Settings",
      "kind": "LinkedField",
      "name": "oauth2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nullClaimValues",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userInfoResponseStrategy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimPropertyFormat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "callbackOnLocationHash",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "clientJwks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "type": "ApplicationJwksUrl",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jwks",
              "storageKey": null
            }
          ],
          "type": "ApplicationJwksStatic",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodeFlow_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OIDCSection_ClientJwks_application"
    }
  ],
  "type": "OIDCApplication",
  "abstractKey": "__isOIDCApplication"
};

(node as any).hash = "969c2a6a06dcd3f41fb2a309d99b8e90";

export default node;
