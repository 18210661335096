import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AlertProps } from '@app/components/Alert';

export type ToastAlert = Pick<AlertProps, 'variant' | 'title' | 'message'> & {
  id: string
  date: Date
  autodismiss: number | null
}

interface ToastState {
  alerts: ToastAlert[]
};

export const initialState: ToastState = {
  alerts: []
};

export const ToastSlice = createSlice({
  name: 'toast',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    success: (state, action: PayloadAction<Pick<AlertProps, 'title' | 'message'>>) => {
      return {
        ...state,
        alerts: state.alerts.concat({
          id: Math.random().toString(),
          date: new Date(),
          variant: 'success',
          title: action.payload.title,
          message: action.payload.message,
          autodismiss: 5000
        })
      };
    },
    error: (state, action: PayloadAction<Pick<AlertProps, 'title' | 'message'>>) => {
      return {
        ...state,
        alerts: state.alerts.concat({
          id: Math.random().toString(),
          date: new Date(),
          variant: 'error',
          title: action.payload.title,
          message: action.payload.message,
          autodismiss: null
        })
      };
    },
    dismiss: (state, action: PayloadAction<ToastAlert>) => {
      return {
        ...state,
        alerts: state.alerts.filter(s => s.id !== action.payload.id)
      }
    }
  }
})

export const { success, error, dismiss } = ToastSlice.actions;
export default ToastSlice;