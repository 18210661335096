/**
 * @generated SignedSource<<5ce93e81c28459f2aed9d9ab09223f6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancelSubscriptionModal_tenant$data = {
  readonly billing: {
    readonly subscription: {
      readonly id: string;
      readonly periodEndAt: string;
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly " $fragmentType": "CancelSubscriptionModal_tenant";
};
export type CancelSubscriptionModal_tenant$key = {
  readonly " $data"?: CancelSubscriptionModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelSubscriptionModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelSubscriptionModal_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Billing",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodEndAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "4f196939b7b56c7d17bc018526084573";

export default node;
