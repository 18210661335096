/**
 * @generated SignedSource<<0d7d3e3f0cec266ada00b7a7d6780317>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersScreen_Invitation_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeleteInvitationButton_tenant">;
  readonly " $fragmentType": "UsersScreen_Invitation_tenant";
};
export type UsersScreen_Invitation_tenant$key = {
  readonly " $data"?: UsersScreen_Invitation_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersScreen_Invitation_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersScreen_Invitation_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteInvitationButton_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "756d2dd479c7b187eaf7b7328359ea31";

export default node;
