import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useReducer, useState } from 'react';
import {upperFirst} from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import { useTenantId } from '@app/hooks/useTenant';

import Button from '@app/components/Button/Button';
import Alert from '@app/components/Alert/Alert';
import { StripeElementsProvider } from '@app/stripe';
import PaymentMethodModal from './components/PaymentMethodModal/PaymentMethodModal';
import { Link } from 'react-router-dom';
import { PaymentMethodScreen_Query } from './__generated__/PaymentMethodScreen_Query.graphql';

export default function PaymentMethodScreen() {
  const [showPaymentMethodModal, togglePaymentMethodModal] = useReducer(value => !value, false);
  const [success, setSuccess] = useState(false);
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<PaymentMethodScreen_Query>(
    graphql`
      query PaymentMethodScreen_Query($tenantId: ID!) {
        tenant(id: $tenantId) {
          shortTenantId

          ...PaymentMethodModal_tenant

          viewerPermissions {
            billing
          }

          billing {
            paymentMethod {
              name
              brand
              last4

              ...PaymentMethodModal_paymentMethod
            }

            information {
              ... PaymentMethodModal_information
            }
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const hasAccess = data.tenant?.viewerPermissions.billing === "READ" || data.tenant?.viewerPermissions.billing === "WRITE"
  const handleSuccess = () => {
    setSuccess(true);
    togglePaymentMethodModal();
  }

  if (!hasAccess) return null;
  if (!data.tenant) return null;

  return (
    <StripeElementsProvider>
      <div className="flex flex-col gap-[16px]">
        {data.tenant.billing?.paymentMethod ? (
          <React.Fragment>
            <div>{upperFirst(data.tenant.billing.paymentMethod.brand)} - xxxx xxxx xxxx {data.tenant.billing.paymentMethod.last4}</div>

            <div>
              <Button variant="default" onClick={togglePaymentMethodModal}>
                {data.tenant.billing.paymentMethod.last4 ? 'Change payment method' : 'Add payment method'}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div>
            No payment method registered. Please <Link to={`/tenant/${data.tenant.shortTenantId}/billing`} className="!underline">sign up for a subscription.</Link>
          </div>
        )}

        {success ? (
          <Alert variant="success" className="mt-[15px] max-w-[768px]" title="Payment method updated!"/>
        ) : null}
      </div>
      <PaymentMethodModal
        tenant={data.tenant}
        open={showPaymentMethodModal}
        onHide={togglePaymentMethodModal}
        onSuccess={handleSuccess}
        paymentMethod={data.tenant.billing?.paymentMethod ?? null}
        information={data.tenant.billing?.information ?? null}
      />
    </StripeElementsProvider>
  );
}