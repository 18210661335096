/**
 * @generated SignedSource<<f1847d39f8124271cfd5d1210943f987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DomainCertificateType = "AZURE" | "CUSTOMER_PROVIDED" | "MANAGED" | "%future added value";
export type UploadDomainCertificateInput = {
  certificate: string;
  domainId: string;
  password?: string | null;
};
export type DomainCertificateUploadFormMutation$variables = {
  input: UploadDomainCertificateInput;
};
export type DomainCertificateUploadFormMutation$data = {
  readonly uploadDomainCertificate: {
    readonly domain?: {
      readonly certificate: {
        readonly expiresAt: string;
        readonly isExpired: boolean;
        readonly isValid: boolean;
        readonly issuedAt: string;
        readonly issuer: string;
        readonly subject: string;
        readonly type: DomainCertificateType;
      } | null;
    };
  };
};
export type DomainCertificateUploadFormMutation = {
  response: DomainCertificateUploadFormMutation$data;
  variables: DomainCertificateUploadFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainCertificate",
  "kind": "LinkedField",
  "name": "certificate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExpired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainCertificateUploadFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "uploadDomainCertificate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UploadDomainCertificateOutput",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainCertificateUploadFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "uploadDomainCertificate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UploadDomainCertificateOutput",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95fcdfaf125714c278d1be213b9592f2",
    "id": null,
    "metadata": {},
    "name": "DomainCertificateUploadFormMutation",
    "operationKind": "mutation",
    "text": "mutation DomainCertificateUploadFormMutation(\n  $input: UploadDomainCertificateInput!\n) {\n  uploadDomainCertificate(input: $input) {\n    __typename\n    ... on UploadDomainCertificateOutput {\n      domain {\n        certificate {\n          type\n          isExpired\n          isValid\n          issuedAt\n          expiresAt\n          issuer\n          subject\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e171c1bfbf62f34f22c384ef0917600";

export default node;
