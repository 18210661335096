/**
 * @generated SignedSource<<66b4402aef3f32b14be371335fb249e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CriiptoAdminScreen_OrganizationQuery$variables = {
  id: string;
};
export type CriiptoAdminScreen_OrganizationQuery$data = {
  readonly admin: {
    readonly organization: {
      readonly organization: {
        readonly name: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_organization">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationTenantButton_admin">;
  };
};
export type CriiptoAdminScreen_OrganizationQuery = {
  response: CriiptoAdminScreen_OrganizationQuery$data;
  variables: CriiptoAdminScreen_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CriiptoAdminScreen_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AddOrganizationTenantButton_organization"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddOrganizationTenantButton_admin"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CriiptoAdminScreen_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPermissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminOrganizationPermissions",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settings",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24a3df95b43acf02d3c2e803bef5424d",
    "id": null,
    "metadata": {},
    "name": "CriiptoAdminScreen_OrganizationQuery",
    "operationKind": "query",
    "text": "query CriiptoAdminScreen_OrganizationQuery(\n  $id: ID!\n) {\n  admin {\n    organization(id: $id) {\n      organization {\n        name\n        id\n      }\n      ...AddOrganizationTenantButton_organization\n      id\n    }\n    ...AddOrganizationTenantButton_admin\n  }\n}\n\nfragment AddOrganizationTenantButton_admin on AdminQuery {\n  permissions {\n    organizations {\n      settings\n    }\n  }\n}\n\nfragment AddOrganizationTenantButton_organization on AdminOrganization {\n  id\n  ...AddOrganizationTenantModal_organization\n}\n\nfragment AddOrganizationTenantModal_organization on AdminOrganization {\n  id\n  organization {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e3b0f4133aad51bb64e3a7361aefb88";

export default node;
