/**
 * @generated SignedSource<<7eccb848b16adf1e4dfa5672f2983dd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteApplicationApiKeyInput = {
  apiKeyId: string;
  applicationId: string;
};
export type DeleteApiKeyButtonMutation$variables = {
  input: DeleteApplicationApiKeyInput;
};
export type DeleteApiKeyButtonMutation$data = {
  readonly deleteSignaturesApplicationApiKey: {
    readonly application: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_application">;
    };
  };
};
export type DeleteApiKeyButtonMutation = {
  response: DeleteApiKeyButtonMutation$data;
  variables: DeleteApiKeyButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteSignaturesApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Signatures_Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteSignaturesApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Signatures_Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientSecret",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35f7f765aff492bc17c15b8625eeeefe",
    "id": null,
    "metadata": {},
    "name": "DeleteApiKeyButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteApiKeyButtonMutation(\n  $input: DeleteApplicationApiKeyInput!\n) {\n  deleteSignaturesApplicationApiKey(input: $input) {\n    application {\n      id\n      ...ApiKeys_application\n    }\n  }\n}\n\nfragment AddApiKeyButton_application on Signatures_Application {\n  id\n}\n\nfragment AddApiKeyForm_application on Signatures_Application {\n  id\n}\n\nfragment ApiKeys_application on Signatures_Application {\n  apiKeys {\n    id\n    clientId\n    clientSecret\n    mode\n    note\n    ...DeleteApiKeyButton_apiKey\n    ...RefreshApiKeyButton_apiKey\n  }\n  ...AddApiKeyButton_application\n  ...AddApiKeyForm_application\n  ...DeleteApiKeyButton_application\n  ...RefreshApiKeyButton_application\n}\n\nfragment DeleteApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment DeleteApiKeyButton_application on Signatures_Application {\n  id\n}\n\nfragment RefreshApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment RefreshApiKeyButton_application on Signatures_Application {\n  id\n}\n"
  }
};
})();

(node as any).hash = "f5e7ad3e7b488bcf908ebacc1f7dc751";

export default node;
