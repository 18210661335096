import React from 'react';
import styles from './ConfigurationSection.module.css';

export default function ConfigurationSection(props: {
  children: React.ReactNode
}) {
  return (
    <section className={styles.section}>
      {props.children}
    </section>
  );
}

ConfigurationSection.TwoColumn = (props: {
  children: React.ReactNode
}) => {
  return (
    <div className={styles['two-column']}>
      {props.children}
    </div>
  );
}

ConfigurationSection.FormGroup = (props: {
  children: React.ReactNode
}) => {
  return (
    <div className={styles['formgroup']}>
      {props.children}
    </div>
  );
}