/**
 * @generated SignedSource<<0abadca540991148e3eb62487f137fef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateDomainSSOInput = {
  domainId: string;
  oauth2: boolean;
  wsfederation: boolean;
};
export type SSOMutation$variables = {
  input: UpdateDomainSSOInput;
};
export type SSOMutation$data = {
  readonly updateDomainSSO: {
    readonly domain: {
      readonly sso: {
        readonly oauth2: boolean;
        readonly wsfederation: boolean;
      };
    };
  };
};
export type SSOMutation = {
  response: SSOMutation$data;
  variables: SSOMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainSSO",
  "kind": "LinkedField",
  "name": "sso",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oauth2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wsfederation",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SSOMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainSSOOutput",
        "kind": "LinkedField",
        "name": "updateDomainSSO",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SSOMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainSSOOutput",
        "kind": "LinkedField",
        "name": "updateDomainSSO",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c713e29e3cc4da10833d5c0f89d12960",
    "id": null,
    "metadata": {},
    "name": "SSOMutation",
    "operationKind": "mutation",
    "text": "mutation SSOMutation(\n  $input: UpdateDomainSSOInput!\n) {\n  updateDomainSSO(input: $input) {\n    domain {\n      sso {\n        oauth2\n        wsfederation\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9619a0b4c22609f3aa60691a1648ff86";

export default node;
