/**
 * @generated SignedSource<<75a227a1535f955dd5454a30bc18c496>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type ProvidersScreenQuery$variables = {
  environment: Environment;
  tenantId: string;
};
export type ProvidersScreenQuery$data = {
  readonly tenant: {
    readonly id: string;
    readonly identityProviders: ReadonlyArray<{
      readonly configurationRequired: boolean;
      readonly id: string;
      readonly isConfigured: boolean;
      readonly shortName: string;
    }>;
  };
};
export type ProvidersScreenQuery = {
  response: ProvidersScreenQuery$data;
  variables: ProvidersScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "configurationRequired",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isConfigured",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvidersScreenQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": (v4/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "identityProviders",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "tenant"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProvidersScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "identityProviders",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac7abe6616bf1a93b169e06150fe3365",
    "id": null,
    "metadata": {},
    "name": "ProvidersScreenQuery",
    "operationKind": "query",
    "text": "query ProvidersScreenQuery(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    id\n    identityProviders(environment: $environment) {\n      __typename\n      id\n      shortName\n      configurationRequired\n      isConfigured\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "588dbd605d6774e2ed582e944cf90481";

export default node;
