import React, { useEffect, useMemo, useReducer } from "react";
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import {
  BrowserRouter,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect
} from "react-router-dom";
import {
  RelayEnvironmentProvider
} from 'react-relay/hooks';
import { Provider as ReduxProvider } from 'react-redux';
import {default as reduxStore} from './redux/store';
import { useSelector, useDispatch } from './redux';
import Header from './components/Header';
import {Footer} from './components/Footer';

import usePromise from './hooks/usePromise';
import RedirectScreen, { RedirectScreenLoading } from '@screens/RedirectScreen';
import LogsScreen from '@screens/LogsScreen';
import DashboardScreen from '@screens/DashboardScreen';
import {LoginScreen, LoginCallbackScreen, LoginRefreshScreen, NemIDLoginCallbackScreen, LoginScreenMFA} from '@screens/LoginScreen';
import OnboardingScreen from '@screens/OnboardingScreen';
import OnboardingScreenAgeVerification from "./screens/OnboardingAgeVerificationScreen/OnboardingAgeVerificationScreen";
import StylingScreen from '@screens/StylingScreen';
import ApplicationsScreen from '@screens/ApplicationsScreen';
import ProvidersScreen from '@screens/ProvidersScreen';
import DomainsScreen from './screens/DomainsScreen/DomainsScreen';
import { checkSession, init as initAuth } from '@redux/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import RelayEnvironment from './RelayEnvironment';
import TenantCriiptoAdminScreen from "./screens/TenantCriiptoAdminScreen";
import { useTenantId } from "./hooks/useTenant";
import UsersScreen from "./screens/UsersScreen/UsersScreen";
import AnalyticsScreen from "./screens/AnalyticsScreen/AnalyticsScreen";
import TenantNavigation from "@app/components/TenantNavigation";
import InvitationAcceptScreen from "./screens/InvitationAcceptScreen";
import BillingScreen from "./screens/BillingScreen";
import SettingsScreen from "./screens/SettingsScreen";
import BillingSignupScreen from "./screens/BillingScreen/screens/SignupScreen";
import ToolsScreen from "./screens/ToolsScreen";
import SupportScreen from "./screens/SupportScreen/SupportScreen";
import { StripeElementsProvider } from "./stripe";
import CriiptoAdminScreen from "./screens/CriiptoAdminScreen/CriiptoAdminScreen";
import useTracking from "./hooks/useTracking";
import GraphQLErrorBoundary from "./components/GraphQLErrorBoundary";
import useEnvironment from "./hooks/useEnvironment";
import { routes_TenantScreen_Query } from "./__generated__/routes_TenantScreen_Query.graphql";
import CustomerSupportLoginScreen from "./screens/CustomerSupportLoginScreen/CustomerSupportLoginScreen";
import CustomerSupportRedirectScreen from "./screens/CustomerSupportRedirectScreen/CustomerSupportRedirectScreen";
import { TenantRouteParams } from "./models";
import { routes_ViewerQuery } from "./__generated__/routes_ViewerQuery.graphql";
import SignupTenantScreen from "./screens/SignupScreen/SignupTenantScreen";
import OrganizationScreen from "./screens/OrganizationScreen/OrganizationScreen";
import Helmet from "./components/Helmet";
import Screen from "@app/components/Screen";
import OrganizationTenantLoginScreen from "./screens/OrganizationScreen/screens/TenantLogin/TenantLogin";

const ExtensionsScreen = React.lazy(() => import('./screens/ExtensionsScreen'));

interface RoutesProps {}
function TenantScreen(props: RoutesProps) {
  let match = useRouteMatch<TenantRouteParams>();
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const query = useLazyLoadQuery<routes_TenantScreen_Query>(
    graphql`
      query routes_TenantScreen_Query($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          name
          longTenantId
          viewerPermissions {
            billing
            users
            analytics(environment: $environment)
            providers(environment: $environment)
            domains(environment: $environment)
            styling(environment: $environment)
            applications(environment: $environment)
            extensions(environment: $environment)
          }

          features {
            EXTENSIONS(environment: $environment)
          }
        }

        viewer {
          ... on UserViewer {
            isCriiptoAdmin
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId,
      environment
    }
  );

  const tracking = useTracking();
  // Segment/Mixpanel tracking/grouping
  useEffect(() => {
    if (!query.tenant) return;

    localStorage.setItem('latest_tenant_id', query.tenant.longTenantId);
    tracking.group({name: query.tenant.name, tenantId: query.tenant.longTenantId});
  }, [query.tenant]);

  if (query.tenant === null) return <Redirect to="/" />; // Somehow had a bad tenant link, let them start over

  const nav = Screen.tenantNavigation;
  return (
    <Switch>
      <Route path={`${match.path}/dashboard`}>
        <Screen nav={nav}>
          <Helmet title={null} />
          <DashboardScreen />
        </Screen>
      </Route>
      <Route path={`${match.path}/onboarding`}>
        <OnboardingScreen />
      </Route>
      <Route path={`${match.path}/applications`}>
        {query.tenant.viewerPermissions.applications !== 'NONE' ? (
          <ApplicationsScreen />
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>
      <Route path={`${match.path}/styling`}>
        <Helmet title="Styling" />
        {query.tenant.viewerPermissions.styling !== 'NONE' ? (
          <Screen nav={nav}>
            <StylingScreen />
          </Screen>
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>
      <Route path={`${match.path}/providers`}>
        <Helmet title="Providers" />
        {query.tenant.viewerPermissions.providers !== 'NONE' ? (
          <ProvidersScreen />
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>
      <Route path={`${match.path}/domains`}>
        <Helmet title="Domains" />
        {query.tenant.viewerPermissions.domains !== 'NONE' ? (
          <Screen nav={nav}>
            <DomainsScreen />
          </Screen>
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>
      <Route path={`${match.path}/logs/:domain?`}>
        <Helmet title="Logs" />
        {query.tenant.viewerPermissions.domains !== 'NONE' ? (
          <Screen nav={nav}>
            <LogsScreen />
          </Screen>
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>

      <Route path={`${match.path}/criipto-admin`}>
        <Helmet title="Admin" />
        {query.viewer?.isCriiptoAdmin ? (
          <Screen nav={nav}>
            <TenantCriiptoAdminScreen />
          </Screen>
        ) : (
          <Redirect to={`${match.url}/dashboard`} />
        )}
      </Route>

      {query.tenant.viewerPermissions.analytics !== 'NONE' ? (
        <Route path={`${match.path}/analytics`}>
          <Helmet title="Analytics" />
          <Screen nav={nav}>
            <AnalyticsScreen />
          </Screen>
        </Route>
      ) : null}

      {query.tenant.viewerPermissions.users !== 'NONE' ? (
        <Route path={`${match.path}/users`}>
          <Helmet title="Users" />
          <Screen nav={nav}>
            <UsersScreen />
          </Screen>
        </Route>
      ) : null}

      {query.tenant.viewerPermissions.billing !== 'NONE' ? (
        <Route path={`${match.path}/billing/signup`}>
          <Helmet title="Billing" />
          <Screen nav={nav}>
            <StripeElementsProvider>
              <BillingSignupScreen />
            </StripeElementsProvider>
          </Screen>
        </Route>
      ) : null}

      {query.tenant.viewerPermissions.billing !== 'NONE' ? (
        <Route path={`${match.path}/billing`}>
          <Helmet title="Billing" />
          <Screen nav={nav}>
            <BillingScreen />
          </Screen>
        </Route>
      ) : null}

      {query.tenant.viewerPermissions.billing !== 'NONE' ? (
        <Route path={`${match.path}/settings`}>
          <Helmet title="Settings" />
          <Screen nav={nav}>
            <SettingsScreen />
          </Screen>
        </Route>
      ) : null}

      <Route path={`${match.path}/tools`}>
        <Helmet title="Tools" />
        <Screen nav={nav}>
          <ToolsScreen />
        </Screen>
      </Route>

      {query.tenant.viewerPermissions.extensions !== 'NONE' && query.tenant.features.EXTENSIONS ? (
        <Route path={`${match.path}/extensions`}>
          <Helmet title="Extensions" />
          <ExtensionsScreen />
        </Route>
      ) : null}

      <Route path={`${match.path}/support`}>
        <Helmet title="Support" />
        <Screen nav={nav}>
          <SupportScreen />
        </Screen>
      </Route>

      {/* 404 */}
      <Route path={match.path}>
        <Redirect to={`${match.url}/dashboard`} />
      </Route>
    </Switch>
  );
}
TenantScreen.Loader = () => {
  return (
    <Screen
      nav={({show, onHide}) => (
        <React.Suspense fallback={<TenantNavigation.Loader />}>
          <TenantNavigation key="nav" onHide={onHide} />
        </React.Suspense>
      )}
    >
      <Screen.Loader/>
    </Screen>
  );
}

interface IsAuthenticatedProps {
  children: React.ReactChild
}
export function IsAuthenticated(props : IsAuthenticatedProps) {
  const auth = useSelector(state => state.auth.client);
  const pending = useSelector(state => state.auth.pending);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.valid);
  const location = useLocation();
  const tracking = useTracking();

  // Trigger session check on each navigation
  const [checkSessionResult, checkSessionState] = usePromise(async () => {
    return await dispatch(checkSession()).then(unwrapResult);
  }, []);

  // Segment user tracking
  const profile = checkSessionResult?.profile;
  useEffect(() => {
    if (!profile) return;
    tracking.identify(profile);
  }, [profile?.sub]);

  const data = useLazyLoadQuery<routes_ViewerQuery>(
    graphql`
      query routes_ViewerQuery($skip: Boolean!) {
        viewer @skip(if: $skip) {
          tenants {
            id
            shortTenantId
            longTenantId
          }
        }
      }
    `,
    {
      skip: !isAuthenticated
    }
  );

  // Track all tenants for user
  useEffect(() => {
    if (!profile) return;
    if (!data.viewer) return;

    tracking.tenants(profile, data.viewer.tenants.map(t => ({
      tenantId: t.shortTenantId,
      relayId: t.id
    })));
  }, [profile?.sub, data.viewer]);

  // If user flips to not authenticated (after having done an initial session check)
  // while on a authenticated required route, log them in
  const isRedirecting = !isAuthenticated && !checkSessionState.pending
  useEffect(() => {
    if (isRedirecting) {
      const searchParams = new URLSearchParams(location.search);
      const auth0Connection = searchParams.get('auth0_connection');
      searchParams.delete('auth0_connection');

      const search = searchParams.toString()?.length ? `?${searchParams.toString()}` : '';
      auth.loginWithRedirect({
        appState: {
          returnTo: location.pathname + search
        },
        response_type: 'token id_token',
        connection: auth0Connection ?? undefined
      });
    }
  }, [isRedirecting]);

  if (checkSessionState.pending || isRedirecting) {
    return (
      <React.Fragment>
        <React.Suspense fallback={null}><Header key="header" /></React.Suspense>
        <div className="app-content">
          <i className="fa fa-spinner fa-pulse" />
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  if (checkSessionState.error && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

export function Routes(props : RoutesProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initAuth());
  }, []);

  return (
    <Switch>
      <Route path="/tenant/:tenantId">
        <IsAuthenticated>
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<TenantScreen.Loader />}>
              <TenantScreen {...props} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </IsAuthenticated>
      </Route>
      <Route path="/organization/:organizationId/login/:tenantId">
        <OrganizationTenantLoginScreen />
      </Route>
      <Route path="/organization/:organizationId">
        <Screen>
          <IsAuthenticated>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<div><i className="fa fa-spinner fa-pulse fa-2x" /></div>}>
                <OrganizationScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </IsAuthenticated>
        </Screen>
      </Route>
      <Route path="/admin">
        <Helmet title="Admin" />
        <Screen>
          <IsAuthenticated>
            <CriiptoAdminScreen {...props} />
          </IsAuthenticated>
        </Screen>
      </Route>
      <Route path="/support/:tenantId/login" exact>
        <CustomerSupportLoginScreen />
      </Route>
      <Route path="/support/:tenantId" exact>
        <IsAuthenticated>
          <React.Suspense fallback={<CustomerSupportRedirectScreen.Loader />}>
            <CustomerSupportRedirectScreen />
          </React.Suspense>
        </IsAuthenticated>
      </Route>
      <Route path="/invitation/accept">
        <Screen>
          <IsAuthenticated>
            <InvitationAcceptScreen/>
          </IsAuthenticated>
        </Screen>
      </Route>
      <Route path="/signup/tenant">
        <Helmet title="Create tenant" />
        <Screen>
          <IsAuthenticated>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<div><i className="fa fa-spinner fa-pulse fa-2x" /></div>}>
                <SignupTenantScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </IsAuthenticated>
        </Screen>
      </Route>
      <Route path="/login/nemid/callback">
        <NemIDLoginCallbackScreen />
      </Route>
      <Route path="/login/mfa">
        <Screen>
          <LoginScreenMFA />
        </Screen>
      </Route>
      <Route path="/user/mfa">
        <Screen>
          <LoginScreenMFA />
        </Screen>
      </Route>
      <Route path="/login">
        <Helmet title="Login" />
        <LoginScreen />
      </Route>
      <Route path="/callback">
        <LoginCallbackScreen {...props} />
      </Route>
      <Route path="/refreshlogin/:tenantId?">
        <LoginRefreshScreen />
      </Route>
      <Route path="/:environment(test|production)">
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<RedirectScreenLoading />}>
            <RedirectScreen />
          </React.Suspense>
        </GraphQLErrorBoundary>
      </Route>
      <Route path="/">
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<RedirectScreenLoading />}>
            <RedirectScreen />
          </React.Suspense>
        </GraphQLErrorBoundary>
      </Route>
    </Switch>
  );
}

function RelayEnvironmentWrapper(props: {children: React.ReactElement}) {
  // Code below is causing issues when access_token is updated with MFA step-up
  // const accessToken = useSelector(state => state.auth.access_token);
  // useEffect(() => {
  //   console.debug('Clearing relay store');
  //   const source = RelayEnvironment.getStore().getSource() as MutableRecordSource;
  //   source.clear();
  // }, [accessToken]);

  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  )
}

export function Router(props : RoutesProps) {
  return (
    <ReduxProvider store={reduxStore}>
      <BrowserRouter>
        <RelayEnvironmentWrapper>
          <React.Suspense fallback={null}>
            <Routes {...props} />
          </React.Suspense>
        </RelayEnvironmentWrapper>
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default Router;
