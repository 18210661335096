import React from 'react';
import { useField } from 'formik';

import {translate} from '@app/i18n';
import { DanishMitIDApplyValues } from './Apply';

interface Props {
  disabled?: boolean
}
export default function VatField(props : Props) {
  const [{value: vatCountry}, , {setValue: setVatCountry}] = useField<DanishMitIDApplyValues["vatCountry"]>('vatCountry');
  const [{value: vatNumber}, , {setValue: setVatNumber}] = useField<DanishMitIDApplyValues["vatNumber"]>('vatNumber');

  return (
    <div className="form-group">
      <label className="control-label">{translate('LABEL_DK_MITID_VAT_IDENTIFIER')}</label>
      <div className="vat-identifier">
        <input type="text" className="form-control" value={vatCountry || ''} onChange={(event) => setVatCountry(event.target.value.toUpperCase())} placeholder="Country code" minLength={2} maxLength={2} required disabled={props.disabled} />
        <input type="text" className="form-control" value={vatNumber || ''} onChange={(event) => setVatNumber(event.target.value)} placeholder="VAT Number" required disabled={props.disabled} />
      </div>
    </div>
  )
}