import React, { useReducer } from 'react';
import Button from '@app/components/Button';
import AddApplicationModal from '../AddApplicationModal/AddApplicationModal';

export default function AddApplicationButton() {
  const [showModal, toggleModal] = useReducer((value) => !value, false);

  return (
    <React.Fragment>
      <Button variant="primary" onClick={toggleModal}>
        <i className="fa fa-plus"></i>&nbsp;Add application
      </Button>
      {showModal && (
        <React.Suspense fallback={null}>
          <AddApplicationModal open={true} onHide={toggleModal} />
        </React.Suspense>
      )}
    </React.Fragment>
  );
}
