/**
 * @generated SignedSource<<b105dbf506987b641d5cdd1f4a5c86bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CopyApplicationModal_application$data = {
  readonly domain: {
    readonly environment: Environment;
    readonly id: string;
    readonly name: string;
  };
  readonly id: string;
  readonly name: string;
  readonly realm: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationRealmValidation_application">;
  readonly " $fragmentType": "CopyApplicationModal_application";
};
export type CopyApplicationModal_application$key = {
  readonly " $data"?: CopyApplicationModal_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"CopyApplicationModal_application">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CopyApplicationModal_application",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "environment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationRealmValidation_application"
    }
  ],
  "type": "VerifyApplication",
  "abstractKey": null
};
})();

(node as any).hash = "4bb889b1f718f6bc67042cfa8f44ebb3";

export default node;
