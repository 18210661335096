/**
 * @generated SignedSource<<532991acc59ccebe6f5a37dc663532f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TokenSigningKeysQuery$variables = {
  id: string;
};
export type TokenSigningKeysQuery$data = {
  readonly domain: {
    readonly id: string;
    readonly keys: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly active: boolean;
          readonly certificate: {
            readonly pkcs7: string;
            readonly validFrom: string;
            readonly validTo: string;
          };
          readonly expired: boolean;
          readonly id: string;
          readonly kid: string;
          readonly mayActivate: boolean;
          readonly mayActivateAt: string;
        };
      }>;
      readonly mayPrepare: boolean;
    };
  } | null;
};
export type TokenSigningKeysQuery = {
  response: TokenSigningKeysQuery$data;
  variables: TokenSigningKeysQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Domain",
    "kind": "LinkedField",
    "name": "domain",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "DomainKeyConnection",
        "kind": "LinkedField",
        "name": "keys",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mayPrepare",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainKeyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainKey",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expired",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mayActivate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mayActivateAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BasicCertificate",
                    "kind": "LinkedField",
                    "name": "certificate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validFrom",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validTo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pkcs7",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "keys(first:1000)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TokenSigningKeysQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TokenSigningKeysQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bd31f235d04065b4204030bbf77f0102",
    "id": null,
    "metadata": {},
    "name": "TokenSigningKeysQuery",
    "operationKind": "query",
    "text": "query TokenSigningKeysQuery(\n  $id: ID!\n) {\n  domain(id: $id) {\n    id\n    keys(first: 1000) {\n      mayPrepare\n      edges {\n        node {\n          id\n          kid\n          active\n          expired\n          mayActivate\n          mayActivateAt\n          certificate {\n            validFrom\n            validTo\n            pkcs7\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3566b62fa4b72a8f85e250c5633b57d3";

export default node;
