import React, {useEffect, useState} from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import {chunk} from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {useRouteMatch, Link} from 'react-router-dom';
import cx from 'classnames';

import { useDispatch, useSelector } from '@app/redux';
import Panel, {PanelList} from '@components/Panel';
import {toggleSlackModal} from '@app/redux/modalsSlice';

import styles from './DashboardScreen.module.css';
import StatBox from '../AnalyticsScreen/components/StatBox';
import { useAnalyticsSeriesQuery } from '@app/api/subscription';
import { useTenantId } from '@app/hooks/useTenant';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';
import { DashboardScreen_Query } from './__generated__/DashboardScreen_Query.graphql';
import { DashboardScreen_SubscriptionBox_tenant$key } from './__generated__/DashboardScreen_SubscriptionBox_tenant.graphql';
import { LinkButton } from '@app/components/Button/Button';

interface Props {}

const timezone = moment.tz.guess();
const analyticsStart = moment().subtract(7, 'days').startOf('day').toDate();
const analyticsEnd = moment().endOf('day').toDate();

export default function DashboardScreen(props : Props) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const base = match.url.replace('/dashboard', '');
  const environment = useEnvironment();
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<DashboardScreen_Query>(
    graphql`
      query DashboardScreen_Query($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          viewerPermissions {
            analytics(environment: $environment)
          }

          verifyEnvironments: environments(product: Verify)
          signaturesEnvironments: environments(product: Signatures)
          ageverificationEnvironments: environments(product: [Ageverification_DK])

          ... DashboardScreen_SubscriptionBox_tenant
        }
      }
    `,
    {
      tenantId: tenantId.relayId,
      environment
    }
  );

  const hasAnalyticsAccess = data.tenant?.viewerPermissions.analytics === 'READ' || data.tenant?.viewerPermissions.analytics === 'WRITE';

  const testLogins = useAnalyticsSeriesQuery(hasAnalyticsAccess && tenantId ? {
    tenantId: tenantId.tenantId,
    timezone,
    environment: ['TEST'],
    dataDimension: 'authenticationType',
    bucket: 'MONTH',
    from: analyticsStart,
    to: analyticsEnd,
    transactionType: ['authentication']
  } : skipToken);
  const productionLogins = useAnalyticsSeriesQuery(hasAnalyticsAccess && tenantId ? {
    tenantId: tenantId.tenantId,
    timezone,
    environment: ['PRODUCTION'],
    dataDimension: 'authenticationType',
    bucket: 'MONTH',
    from: analyticsStart,
    to: analyticsEnd,
    transactionType: ['authentication']
  } : skipToken);

  const [playing, setPlaying] = useState<string | null>(null);

  const handlePlay = (id: string) => {
    setPlaying(id);
  };

  const toggleSlack = (event : React.MouseEvent) => {
    event.preventDefault();
    dispatch(toggleSlackModal());
  }

  const tracking = useTracking();
  useEffect(() => {
    if (!data.tenant) return;
    tracking.viewedDashboardScreen();
  }, [data.tenant]);

  if (!data.tenant) return null;

  return (
    <div className={styles["dashboard-screen"]}>
      {hasAnalyticsAccess ? (
        <div className="container">
          <div className={styles["stat-boxes"]}>
            <Link to={`${base}/analytics`} title="More analytics">
              <StatBox
                prefix={<React.Fragment>Total <strong className={`text-PRODUCTION`}>real logins</strong></React.Fragment>}
                number={!productionLogins.isFetching && productionLogins.data ? (productionLogins.data[0]?.total ?? 0) : 'N/A'}
                suffix={<React.Fragment>in the last 7 days</React.Fragment>}
              />
            </Link>
            <Link to={`${base}/analytics`} title="More analytics">
              <StatBox
                prefix={<React.Fragment>Total <strong className={`text-TEST`}>test logins</strong></React.Fragment>}
                number={!testLogins.isFetching && testLogins.data ? (testLogins.data[0]?.total ?? 0) : 'N/A'}
                suffix={<React.Fragment>in the last 7 days</React.Fragment>}
              />
            </Link>
          </div>
        </div>
      ) : null}
      <VideoCarousel playing={playing} onPlay={handlePlay}>
        <Video id="7g9UEzp4fTw" title="Quick guided tour" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="lX9dXXFAnxo" title="Get to the first test login" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="TUh4Z2li1UM" title="What is OpenID Connect?" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="INiAM1u925E" title="POV How to Cook a Steak" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="6RUDa0FKplk" title="No-Knead Bread, Revisited | Kenji's Cooking Show" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="n_7gkhSjSjY" title="START 1000th AND FINISH 1st! IS IT POSSIBLE?! Mountain of Hell, the biggest mountain bike race!" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
        <Video id="akqcYEIXoEo" title="Alex Honnold Breaks Down Climbing Scenes From Movies" description="Lorem ipsum dolor sit amnet Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur" onPlay={handlePlay} />
      </VideoCarousel>
      <div className={styles["panels-background"]}>
        <div className="container">
          <div className={styles["panels"]}>
            <Panel title="Get Started:">
              <PanelList>
                {environment === 'TEST' ? (<Link to={`${base}/onboarding/login`}>Try a test login</Link>) : null}
                {(environment === 'TEST' || data.tenant.verifyEnvironments?.includes('PRODUCTION')) ? (
                  <Link to={`${base}/applications/add`}>Register your application</Link>
                ) : null}
                <Link to={`${base}/domains/add`}>Setup a new domain</Link>
                {(environment === 'TEST' || data.tenant.signaturesEnvironments?.includes('PRODUCTION')) ? (
                  <Link to={`${base}/applications/add?tags=signatures`}>Add a signatures application</Link>
                ) : null}
              </PanelList>
            </Panel>
            <Panel title="Support:">
              <PanelList>
                <a href="#" onClick={toggleSlack} className='flex justify-between'>
                  Personal support
                  <img src="/assets/images/slack-logo-30px.png" className='h-[30px]'/>
                </a>
                <a href="mailto:support@criipto.com">Email support</a>
              </PanelList>
            </Panel>
{/*             <Panel title="Criipto Benefits:">
              <PanelList>
                <a href="#">Easy all-in-one e-ID access</a>
                <a href="#">Accept e-ID logins in a few hours</a>
                <a href="#">How <strong>Tryg</strong> managed easy integration</a>
              </PanelList>
            </Panel>
 */}            <Panel title="FAQ:">
              <PanelList>
                <a href="https://docs.criipto.com/verify/e-ids/" target="_blank" rel="noopener noreferrer">How do I set up test users?</a>
                <a href="https://docs.criipto.com/verify/guides/custom-styling/" target="_blank" rel="noopener noreferrer">How can I customize the login pages?</a>
                <a href="https://docs.criipto.com/verify/guides/production/" target="_blank" rel="noopener noreferrer">How do I move to production?</a>
              </PanelList>
            </Panel>
            </div>
        </div>
      </div>
      <SubscriptionBox tenant={data.tenant} />
    </div>
  )
}

interface VideoCarouselProps {
  playing: string | null
  children: JSX.Element[],
  onPlay: (id: string) => void
}

function VideoCarousel(props : VideoCarouselProps) {
  const pages = chunk(props.children, 3);
  const [page, setPage] = useState(0);

  const prevPage = () => {
    setPage(page => page - 1);
  };
  const nextPage = () => {
    setPage(page => page + 1);
  };

  if (props.playing) {
    const child = props.children.find(child => (child.props as VideoProps).id === props.playing);
    if (!child) return <React.Fragment />;

    const index = props.children.indexOf(child);
    const prev = index !== 0 ? props.children[index - 1].props : null;
    const next = index !== (props.children.length - 1) ? props.children[index + 1].props : null;

    return (
      <VideoPlayer
        id={props.playing}
        onPlay={props.onPlay}
        prev={prev}
        next={next}
      />
    );
  }

  return (
    <div className={styles["video-carousel"]}>
      <div className={styles["container"]}>
        <div className={styles["scroller"]} style={{transform: `translate(-${page * 100}%)`}}>
          {pages.map((children, i) => (
            <div key={i} className={styles["page"]}>
              {children}
            </div>
          ))}
        </div>
        <i className={cx(`prev-page fa fa-angle-left`, {hidden: page === 0})} onClick={prevPage} />
        <i className={cx(`next-page fa fa-angle-right`, {hidden: page === pages.length - 1})} onClick={nextPage} />

      </div>
    </div>
  );
}

interface VideoProps {
  id: string,
  title: string,
  description: string,
  onPlay: (id: string) => void
}

function Video(props : VideoProps) {
  const handleClick = () =>{
    props.onPlay(props.id);
  };
  return (
    <div className={styles["video"]}>
      <div className={styles["video-thumbnail"]} onClick={handleClick}>
        <img src={`https://img.youtube.com/vi/${props.id}/mqdefault.jpg`} />
        <svg className={styles["play-icon"]} width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M25.1761 0.799346C18.716 1.61153 13.1434 4.35574 8.44912 9.03638C4.39182 13.0816 1.87177 17.7371 0.654256 23.4366C0.158537 25.7573 0.158153 32.0523 0.653488 34.3758C1.85738 40.0212 4.56742 45.0581 8.50707 48.9712C22.1437 62.5165 44.9039 58.8539 53.6219 41.7114C59.1036 30.9323 57.1919 18.1365 48.7923 9.38528C46.5041 7.00111 44.3126 5.3537 41.4673 3.87825C37.4557 1.79808 33.8244 0.85289 29.285 0.706842C27.8073 0.659439 25.9584 0.701085 25.1761 0.799346ZM33.4216 4.82286C38.0596 5.76805 42.3046 8.02575 45.6363 11.3192C49.0741 14.7175 51.0936 18.2424 52.3589 23.0528C53.0276 25.5947 53.1895 30.4268 52.698 33.1671C51.783 38.2692 49.4963 42.6754 45.8919 46.2819C37.4833 54.6953 24.4837 55.971 14.6497 49.3478C9.52596 45.8969 6.12674 41.0184 4.48528 34.7596C3.832 32.2686 3.66868 27.856 4.12352 24.9842C4.70253 21.3288 6.25072 17.5636 8.4067 14.568C9.89002 12.5068 13.1919 9.3897 15.2452 8.11192C18.3155 6.20159 22.0107 4.8837 25.5129 4.45016C27.573 4.19511 31.1741 4.36476 33.4216 4.82286ZM21.8963 29.0227C21.8963 37.2523 21.946 39.3871 22.1362 39.3125C22.4868 39.1749 39.6218 29.191 39.7953 29.0233C39.946 28.8774 22.5164 18.6387 22.1178 18.6387C21.9531 18.6387 21.8963 21.3065 21.8963 29.0227Z" />
        </svg>
      </div>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
    </div>
  )
}

interface VideoPlayerProps {
  id: string,
  prev?: VideoProps,
  next?: VideoProps,
  onPlay: (id: string | null) => void
}

function VideoPlayer(props : VideoPlayerProps) {
  const handlePrev = () => {
    props.onPlay(props.prev!.id);
  };
  const handleNext = () => {
    props.onPlay(props.next!.id);
  };
  const handleClose = () => {
    props.onPlay(null);
  };
  return (
    <div className={styles["video-player"]}>
      <div className={styles["container"]}>
        <iframe
          width="791" height="445" src={`https://www.youtube.com/embed/${props.id}?autoplay=1`}
          title="YouTube video player" frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <i className={cx("fa fa-times", styles["close-video"])} onClick={handleClose} />

        {props.prev && (
          <div className={styles["prev"]} onClick={handlePrev}>
            <i className="fa fa-angle-left" />
            <span>{props.prev.title}</span>
          </div>
        )}
        {props.next && (
          <div className={styles["next"]} onClick={handleNext}>
            <span>{props.next.title}</span>
            <i className="fa fa-angle-right" />
          </div>
        )}
      </div>
    </div>
  )
}

interface SubscriptionBoxProps {
  tenant: DashboardScreen_SubscriptionBox_tenant$key
}

function SubscriptionBox(props: SubscriptionBoxProps) {
  const environment = useEnvironment();
  const tenant = useFragment(
    graphql`
      fragment DashboardScreen_SubscriptionBox_tenant on Tenant {
        environments
        shortTenantId
      }
    `,
    props.tenant
  );

  const title = (
    <>
      <span className={`text-${environment}`}>Your subscription</span>
      &nbsp;
      <strong>{tenant.environments.includes('PRODUCTION') ? 'Paid plan' : 'Development'}</strong>
    </>
  )

  return (
    <Panel className={cx(styles["subscription-box"], "container" )} center={true} title={title}>
      {tenant.environments.includes('PRODUCTION') ? (
        <>
          <p>You are set for production and your applications may be - or already are - set up for real e-ID.</p>
          <LinkButton to={`/tenant/${tenant.shortTenantId}/billing`} variant="primary">Manage subscription</LinkButton>
        </>
      ) : (
        <>
          <p>You have unlimited test logins forever.<br />To allow real users with real e-ID, please upgrade to a paid plan.</p>
          <LinkButton to={`/tenant/${tenant.shortTenantId}/billing`} variant="primary">Add subscription</LinkButton>
        </>
      )}
    </Panel>
  )
}