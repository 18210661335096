/**
 * @generated SignedSource<<8009391f455b2e6c90bca3f768453c23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddOrganizationApiKeyButton_organization$data = {
  readonly id: string;
  readonly viewerPermissions: {
    readonly productionApi: Permission;
    readonly testApi: Permission;
  };
  readonly " $fragmentType": "AddOrganizationApiKeyButton_organization";
};
export type AddOrganizationApiKeyButton_organization$key = {
  readonly " $data"?: AddOrganizationApiKeyButton_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddOrganizationApiKeyButton_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddOrganizationApiKeyButton_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerOrganizationPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": "testApi",
          "args": [
            {
              "kind": "Literal",
              "name": "environment",
              "value": "TEST"
            }
          ],
          "kind": "ScalarField",
          "name": "api",
          "storageKey": "api(environment:\"TEST\")"
        },
        {
          "alias": "productionApi",
          "args": [
            {
              "kind": "Literal",
              "name": "environment",
              "value": "PRODUCTION"
            }
          ],
          "kind": "ScalarField",
          "name": "api",
          "storageKey": "api(environment:\"PRODUCTION\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "95992d28ecd08e3a4131f0676fedad9f";

export default node;
