/**
 * @generated SignedSource<<f665e4f2f71dac939f3ebe2ae5adb71b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type EditVerifyApplicationScreenQuery$variables = {
  environment: Environment;
  tenantId: string;
};
export type EditVerifyApplicationScreenQuery$data = {
  readonly tenant: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UISection_tenant">;
  } | null;
};
export type EditVerifyApplicationScreenQuery = {
  response: EditVerifyApplicationScreenQuery$data;
  variables: EditVerifyApplicationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditVerifyApplicationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "UISection_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditVerifyApplicationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "TenantStyling",
            "kind": "LinkedField",
            "name": "styling",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cssUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantStylingViewVersion",
                "kind": "LinkedField",
                "name": "viewVersion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35f34296dc3000cf10092e08e30e62f4",
    "id": null,
    "metadata": {},
    "name": "EditVerifyApplicationScreenQuery",
    "operationKind": "query",
    "text": "query EditVerifyApplicationScreenQuery(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    id\n    ...UISection_tenant_2bptcK\n  }\n}\n\nfragment UISection_tenant_2bptcK on Tenant {\n  styling(environment: $environment) {\n    cssUrl\n    viewVersion {\n      version\n      editable\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8b759ec794626064e0b2bff0b23079c";

export default node;
