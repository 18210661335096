import "core-js/stable";
import React from 'react';
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import '@fortawesome/fontawesome-free/css/all.css';

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";

import './assets/main.css';
import './app/main.css';
import { Router } from "./app/routes";

import './app/segment/load';
import Alert from "./app/components/Alert/Alert";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0
  });

  if ('SecurityPolicyViolationEvent' in window) {
    window.addEventListener('securitypolicyviolation', function(e) {
      const error = new Error(`Refused to load ${e.violatedDirective} ${e.blockedURI} becaues it violates the Content Security Policy`);
      Sentry.captureException(error, {
        extra: {
          policy: e.originalPolicy
        }
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Sentry.ErrorBoundary
      fallback={({error}) => (
        <Alert
          variant="error"
          message={error.message}
          style={{margin: '10px'}}
          title="We encountered an error"
        />
      )}
    >
      <Router />
    </Sentry.ErrorBoundary>,
    document.getElementById('react_root')
  );
});