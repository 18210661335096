/**
 * @generated SignedSource<<c2d62f067f0d4d47521f1e2641f9c28f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type AddApplicationModalQuery$variables = {
  tenantId: string;
};
export type AddApplicationModalQuery$data = {
  readonly tenant: {
    readonly ageverificationEnvironments: ReadonlyArray<Environment>;
    readonly signaturesEnvironments: ReadonlyArray<Environment>;
    readonly verifyEnvironments: ReadonlyArray<Environment>;
  } | null;
};
export type AddApplicationModalQuery = {
  response: AddApplicationModalQuery$data;
  variables: AddApplicationModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v3 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v4 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddApplicationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddApplicationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0a221b54f30b70d5e3ef39e49773fda",
    "id": null,
    "metadata": {},
    "name": "AddApplicationModalQuery",
    "operationKind": "query",
    "text": "query AddApplicationModalQuery(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    verifyEnvironments: environments(product: Verify)\n    signaturesEnvironments: environments(product: Signatures)\n    ageverificationEnvironments: environments(product: [Ageverification_DK])\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b99e8ed2d990172cbea5eab01567e4ee";

export default node;
