/**
 * @generated SignedSource<<c6d174abb882fae94403289a3fb08114>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantMitIDScreen_KYC_tenant$data = {
  readonly id: string;
  readonly kyc: {
    readonly companyName: string;
    readonly vatCountry: string;
    readonly vatNumber: string;
  } | null;
  readonly " $fragmentType": "TenantMitIDScreen_KYC_tenant";
};
export type TenantMitIDScreen_KYC_tenant$key = {
  readonly " $data"?: TenantMitIDScreen_KYC_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantMitIDScreen_KYC_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantMitIDScreen_KYC_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantKYC",
      "kind": "LinkedField",
      "name": "kyc",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};

(node as any).hash = "86f824a281c33f887ab9c1cec05d1742";

export default node;
