/**
 * @generated SignedSource<<90a8a51e4ddd58ac804cf3f30d491bcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type InvoiceCurrency = "DKK" | "EUR" | "NOK" | "SEK" | "%future added value";
export type TenantUsageScreenQuery$variables = {
  environment: Environment;
  from: string;
  tenantId: string;
  to: string;
};
export type TenantUsageScreenQuery$data = {
  readonly admin: {
    readonly tenant: {
      readonly tenant: {
        readonly id: string;
        readonly usage: ReadonlyArray<{
          readonly authentications: number;
          readonly bucket: string;
          readonly invoices: ReadonlyArray<{
            readonly currency: InvoiceCurrency;
            readonly environment: Environment;
            readonly items: ReadonlyArray<{
              readonly description: string;
              readonly quantity: number;
              readonly subtotal: number;
              readonly unitAmount: number;
            }>;
            readonly subtotal: number;
          }> | null;
          readonly signatures: number;
        }>;
      };
    } | null;
  };
};
export type TenantUsageScreenQuery = {
  response: TenantUsageScreenQuery$data;
  variables: TenantUsageScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        },
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "from"
        },
        {
          "kind": "Literal",
          "name": "interval",
          "value": "MONTH"
        },
        {
          "kind": "Variable",
          "name": "to",
          "variableName": "to"
        }
      ],
      "concreteType": "Usage",
      "kind": "LinkedField",
      "name": "usage",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bucket",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authentications",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signatures",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UsageInvoice",
          "kind": "LinkedField",
          "name": "invoices",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "environment",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UsageInvoiceItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unitAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantUsageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "TenantUsageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c96d9bfbc9501dc98d6093352e6f5f80",
    "id": null,
    "metadata": {},
    "name": "TenantUsageScreenQuery",
    "operationKind": "query",
    "text": "query TenantUsageScreenQuery(\n  $tenantId: ID!\n  $environment: Environment!\n  $from: String!\n  $to: String!\n) {\n  admin {\n    tenant(id: $tenantId) {\n      tenant {\n        id\n        usage(environment: $environment, interval: MONTH, from: $from, to: $to) {\n          bucket\n          authentications\n          signatures\n          invoices {\n            currency\n            environment\n            subtotal\n            items {\n              unitAmount\n              quantity\n              description\n              subtotal\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8eff1bbace6796c8972c39c9d653541f";

export default node;
