/**
 * @generated SignedSource<<14804d0de457296d07557f298db93ce8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateDutchDigIDDomainInput = {
  certificate: string;
  domainId: string;
  environment: Environment;
  password?: string | null;
  tenantId: string;
};
export type NLDigIDProviderMutation$variables = {
  input: UpdateDutchDigIDDomainInput;
};
export type NLDigIDProviderMutation$data = {
  readonly updateDutchDigIDDomain: {
    readonly domains: ReadonlyArray<{
      readonly certificate: {
        readonly name: string;
        readonly pkcs7: string;
        readonly validFrom: string;
        readonly validTo: string;
      } | null;
      readonly domain: {
        readonly id: string;
        readonly name: string;
      };
      readonly metadataUrl: string;
    }>;
    readonly id: string;
    readonly isConfigured: boolean;
  };
};
export type NLDigIDProviderMutation = {
  response: NLDigIDProviderMutation$data;
  variables: NLDigIDProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DutchDigID",
    "kind": "LinkedField",
    "name": "updateDutchDigIDDomain",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DutchDigIDDomain",
        "kind": "LinkedField",
        "name": "domains",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BasicCertificate",
            "kind": "LinkedField",
            "name": "certificate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pkcs7",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validTo",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NLDigIDProviderMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NLDigIDProviderMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "eeb38119d2995e73f25ce9bf01ecbea6",
    "id": null,
    "metadata": {},
    "name": "NLDigIDProviderMutation",
    "operationKind": "mutation",
    "text": "mutation NLDigIDProviderMutation(\n  $input: UpdateDutchDigIDDomainInput!\n) {\n  updateDutchDigIDDomain(input: $input) {\n    id\n    isConfigured\n    domains {\n      metadataUrl\n      domain {\n        id\n        name\n      }\n      certificate {\n        name\n        pkcs7\n        validFrom\n        validTo\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d812c8823b8d6071357eafdc4bc9a4d3";

export default node;
