import React, {useEffect, useState} from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import {sortBy} from 'lodash';

import {Switch, Route, Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import useQuery from '@app/hooks/useQuery';

import {translate} from '@app/i18n';
import {LinkButton, AnchorButton} from '@components/Button/Button';
import {Tag} from '@components/Tag';
import InputField from '@app/components/FormFields/InputField/InputField';
import Tooltip from '@components/Tooltip';
import AddApplicationButton from './components/AddApplicationButton/AddApplicationButton';

import DeleteApplicationButton from './components/DeleteApplicationButton';
import { TENANT_ID_PREFIX } from '@app/constants';
import VerifyApplicationCreator from './components/VerifyApplicationCreator';
import SignaturesApplicationCreator from './components/SignaturesApplicationCreator/SignaturesApplicationCreator';
import useEnvironment from '@app/hooks/useEnvironment';
import EditSignaturesApplicationScreen from './screens/EditSignaturesApplicationScreen/EditSignaturesApplicationScreen';
import EditVerifyApplicationScreen from './screens/EditVerifyApplicationScreen/EditVerifyApplicationScreen';
import CopyApplicationButton from './components/CopyApplicationButton/CopyApplicationButton';
import Alert from '@app/components/Alert/Alert';

import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { ApplicationsScreen_CreateQuery, Product } from './__generated__/ApplicationsScreen_CreateQuery.graphql';
import { ApplicationsScreen_EditQuery } from './__generated__/ApplicationsScreen_EditQuery.graphql';
import { ApplicationsScreenQuery } from './__generated__/ApplicationsScreenQuery.graphql';
import { ApplicationsScreen_Item_application$key } from './__generated__/ApplicationsScreen_Item_application.graphql';
import { ApplicationsScreen_Item_tenant$key } from './__generated__/ApplicationsScreen_Item_tenant.graphql';
import { TenantRouteParams } from '@app/models';
import Helmet from '@app/components/Helmet';
import AgeverificationApplicationCreator from './components/AgeverificationApplicationCreator/AgeverificationApplicationCreator';
import EditAgeverificationApplicationScreen from './screens/EditAgeverificationApplicationScreen/EditAgeverificationApplicationScreen';
import Screen from '@app/components/Screen';
import ApplicationIcon from '@app/components/Icons/ApplicationIcon';

export function ApplicationsScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<ApplicationsScreenQuery>(
    graphql`
      query ApplicationsScreenQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          shortTenantId
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id

                applications(first: 1000) @connection(key: "domain_applications") {
                  edges {
                    node {
                      id
                      name
                      domain {
                        name
                      }
                      realm
                      acrValues

                      ... ApplicationsScreen_Item_application
                    }
                  }
                }
              }
            }
          }

          ... ApplicationsScreen_Item_tenant
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  const [search, setSearch] = useState('');
  const tenant = data.tenant;

  const domains = tenant?.domains.edges.map(edge => edge.node);
  const applications = domains?.flatMap(domain => domain.applications.edges.map(edge => edge.node));

  const hasDomains = domains ? domains.length > 0 : false;
  const hasApplications = applications ? applications.length > 0 : false;
  const searchedApplications = applications?.filter(a => {
    if (!search) return a;
    const term = search.toLowerCase();
    return a.name.toLowerCase().includes(term) || a.domain.name.includes(term) ||
           a.realm.toLowerCase().includes(term) || a.acrValues.some(m => m.includes(term));
  });

  if (!tenant) return null;

  // Render dummy accordion (actually uses links)
  return (
    <div>
      <Screen.Header fullscreen={true}>
        <Screen.Header.Title>
          <h1><ApplicationIcon /> Applications</h1>
          <div className="flex flex-gow gap-[10px]">
            {hasDomains ? hasApplications ? (
              <AddApplicationButton/>
            ) : null : null}
          </div>
        </Screen.Header.Title>
      </Screen.Header>
      <div className="p-[32px] grow">
        {hasDomains ? (hasApplications && applications) ? (
          <div className="accordion">
            <React.Fragment>
              {applications.length > 5 ? (
                <InputField
                  name="search"
                  type="search"
                  placeholder="Search for name, domain or realm"
                  iconLeft={(<div className="fa fa-search" />)}
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  className='!mb-[16px]'
                />
              ) : null}

              <div className="accordion-header">
                <div className="row">
                  <div className="col-xs-4">
                    <h3>{environment === 'PRODUCTION' ? translate('PRODUCTION_APPLICATIONS') : translate('TEST_APPLICATIONS')}</h3>
                  </div>
                  <div className="col-xs-3">
                    <h3>{translate('DOMAIN')}</h3>
                  </div>
                  <div className="col-xs-3">
                    <h3>Realm</h3>
                  </div>
                  <div className="col-xs-2">

                  </div>
                </div>
              </div>

              {sortBy(searchedApplications, ['domain.name', 'name']).map(application => (
                <ApplicationsListItem key={application.id} application={application} tenant={tenant} />
              ))}
            </React.Fragment>

            <div className="flex flex-row gap-[10px] pt-[15px] border-t border-[#B8B7B4]">
              <AnchorButton href="https://docs.criipto.com/verify/integrations/auth0" target="_blank" variant="default">
                {translate('VIA_AUTH0')}
              </AnchorButton>
              <AnchorButton href="https://docs.criipto.com/verify/integrations/okta" target="_blank" variant="default">
                {translate('VIA_OKTA')}
              </AnchorButton>
              <AnchorButton href="https://docs.criipto.com/verify/integrations/onelogin" target="_blank" variant="default">
                {translate('VIA_ONELOGIN')}
              </AnchorButton>
              <AnchorButton href="https://docs.criipto.com/verify/integrations/pingfederate" target="_blank" variant="default">
                {translate('VIA_PINGFEDERATE')}
              </AnchorButton>
            </div>
          </div>
        ) : (
          <div>
            <p className='font-medium mb-4'>You currently don't have any applications</p>
            <AddApplicationButton/>
          </div>
        ) : (
          <div className="flex flex-col">
            <p>
              Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong> first.
            </p>
            <LinkButton variant="primary" to={`/tenant/${data.tenant?.shortTenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`} style={{alignSelf: 'flex-start'}}>
              <i className="fa fa-plus"></i>&nbsp;Add domain
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  );
}

function ApplicationsListItem(props: {
  application: ApplicationsScreen_Item_application$key,
  tenant: ApplicationsScreen_Item_tenant$key
}) {
  const environment = useEnvironment();
  const match = useRouteMatch<TenantRouteParams>();
  const application = useFragment(
    graphql`
      fragment ApplicationsScreen_Item_application on Application {
        __typename
        id
        name
        realm
        acrValues

        domain {
          name
        }

        tags

        ... DeleteApplicationButton_application
        ... CopyApplicationButton_application
      }
    `,
    props.application
  );

  const tenant = useFragment(
    graphql`
      fragment ApplicationsScreen_Item_tenant on Tenant {
        verifyEnvironments: environments(product: Verify)
        signaturesEnvironments: environments(product: Signatures)
        ageverificationEnvironments: environments(product: [Ageverification_DK])
      }
    `,
    props.tenant
  );

  const productEnabled =
    application.__typename === 'SignaturesApplication' ?
      tenant.signaturesEnvironments.includes(environment) :
    application.__typename === 'AgeverificationApplication' ?
      tenant.ageverificationEnvironments.includes(environment) :
    tenant.verifyEnvironments.includes(environment);

  const inner = (
    <div className="row">
      <div className="col-xs-4">
        <strong>{application.name}</strong>
        {application.tags?.includes("auth0") && (
          <Tag className="tag-small" style={{marginLeft: '5px'}}>Auth0</Tag>
        )}
        {application.__typename === 'SignaturesApplication' && (
          productEnabled ? (
            <Tag className="tag-small" style={{marginLeft: '5px'}}>Signatures</Tag>
          ) : (
            <Tag className="tag-small" style={{background: 'grey', marginLeft: '5px'}}>Disabled</Tag>
          )
        )}
        {application.__typename === 'VerifyApplication' && !productEnabled && (
          <Tag className="tag-small" style={{background: 'grey', marginLeft: '5px'}}>Disabled</Tag>
        )}
        {application.__typename === 'AgeverificationApplication' && (
          productEnabled ? (
            <Tag className="tag-small" style={{marginLeft: '5px'}}>Age verification</Tag>
          ) : (
            <Tag className="tag-small" style={{background: 'grey', marginLeft: '5px'}}>Disabled</Tag>
          )
        )}
      </div>
      <div className="col-xs-3">
        {application.domain.name}
      </div>
      <div className="col-xs-3 truncate">
        {application.realm}
      </div>
      <div className="col-xs-2" onClick={(e) => e.stopPropagation() /* Fix react-portal click propagation */}>
        <div className="button-group flex-end">
          {productEnabled && (<CopyApplicationButton variant="default" application={application} />)}
          <DeleteApplicationButton application={application} />
        </div>
      </div>
    </div>
  );

  if (!productEnabled) {
    return (
      <Tooltip id="application_disabled" tooltip={`Your tenant does not currently have production access for this type of application`}>
        <div className="accordion-item">
          <div className="accordion-header">
            {inner}
          </div>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="accordion-item">
      <Link to={`${match.url}/${application.id}`} className="accordion-header clickable">
        {inner}
      </Link>
    </div>
  );
}

export function EditApplicationScreen(props : {parentUrl: string}) {
  const history = useHistory();
  const params = useParams<TenantRouteParams & {applicationId: string}>();

  const data = useLazyLoadQuery<ApplicationsScreen_EditQuery>(
    graphql`
      query ApplicationsScreen_EditQuery($tenantId: ID!, $applicationId: ID!) {
        application(id: $applicationId) @required(action: THROW) {
          id

          __typename

          domain {
            environment
          }

          ...EditSignaturesApplicationScreen_application
          ...EditVerifyApplicationScreen_application
          ...EditAgeverificationApplicationScreen_application
        }

        tenant(id: $tenantId) @required(action: THROW) {
          ...EditSignaturesApplicationScreen_tenant
          ...EditVerifyApplicationScreen_tenant
        }
      }
    `
  , {
    applicationId: params.applicationId,
    tenantId: btoa(`tenant:${params.tenantId}`),
  });

  const application = data.application;
  const environment = useEnvironment();

  useEffect(() => {
    if (!application) return;
    if (environment !== application.domain.environment) {
      history.push(props.parentUrl);
    }
  }, [environment, application]);

  const handleDelete = () => {
    history.push(props.parentUrl);
  };

  if (application && application.__typename === 'SignaturesApplication') {
    return (
      <EditSignaturesApplicationScreen
        application={application}
        tenant={data.tenant}
        parentUrl={props.parentUrl}
        onDelete={handleDelete}
      />
    );
  }
  if (application && application.__typename === 'AgeverificationApplication') {
    return (
      <EditAgeverificationApplicationScreen
        application={application}
        parentUrl={props.parentUrl}
        onDelete={handleDelete}
      />
    )
  }

  return (
    <EditVerifyApplicationScreen
      application={application}
      tenant={data.tenant}
      parentUrl={props.parentUrl}
      onDelete={handleDelete}
    />
  );
}

export function CreateApplicationScreen(props : {parentUrl: string}) {
  const query = useQuery();
  const routeMatch = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();
  const tags = (query.get('tags') || '').split(',');
  const product : Exclude<Product, '%future added value'>[] =
    tags.includes('signatures') ? ['Signatures'] :
    tags.includes('ageverification') ? ['Ageverification_DK'] :
    ['Verify'];
  const productTitle =
    product[0] === 'Signatures' ? 'signatures' :
    product[0] === 'Ageverification_DK' ? 'ageverification' :
    product[0] === 'Verify' ? 'login' : assertUnreachable(product[0]);

  const data = useLazyLoadQuery<ApplicationsScreen_CreateQuery>(
    graphql`
      query ApplicationsScreen_CreateQuery($tenantId: ID!, $environment: Environment!, $product: [Product!]!) {
        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }

          environments(product: $product)

          ... VerifyApplicationCreator_tenant @arguments(environment: $environment)
          ... SignaturesApplicationCreator_tenant @arguments(environment: $environment)
          ... AgeverificationApplicationCreator_tenant @arguments(environment: $environment)
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${routeMatch.params.tenantId}`),
      environment,
      product
    }
  );

  const domains = data.tenant?.domains.edges.map(edge => edge.node);
  const hasDomains = domains ? domains.length > 0 : true;

  if (!data.tenant) return null;

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Applications</Link>
          <i className="fa fa-angle-right" />
          <div>Add new {productTitle} application</div>
        </div>

        <h1>Add new {productTitle} application</h1>
      </div>
      <div className="app-content-main">
        {data.tenant?.environments.includes(environment) ? (
          <React.Fragment>
            {hasDomains ? (
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                {product[0] === 'Signatures' ? (
                  <SignaturesApplicationCreator tenant={data.tenant} parentUrl={props.parentUrl}  />
                ) : product.some(s => s.startsWith('Ageverification')) ? (
                  <AgeverificationApplicationCreator tenant={data.tenant} parentUrl={props.parentUrl} />
                ) : (
                  <VerifyApplicationCreator tenant={data.tenant} parentUrl={props.parentUrl} />
                )}
              </React.Suspense>
            ) : (
              <div className="flex flex-col">
                <p>
                  Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong> first.
                </p>
                <LinkButton variant="primary" to={`/tenant/${routeMatch.params.tenantId}/domains/add`} style={{alignSelf: 'flex-start'}}>
                  <i className="fa fa-plus"></i>&nbsp;Add domain
                </LinkButton>
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Alert variant="error" className="mt-[15px]" title={`You are not enabled to create a ${product} application in this environment.`} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}


export default function ApplicationRoutes(props: {nav?: boolean}) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <Screen nav={Screen.tenantNavigation}>
          <Helmet title="Applications" />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<Screen.Loader/>}>
              <ApplicationsScreen />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Screen>
      </Route>
      <Route path={`${match.path}/add`}>
        <Screen nav={Screen.tenantNavigation}>
          <Helmet title="Add application" />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <CreateApplicationScreen parentUrl={match.url} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Screen>
      </Route>
      <Route path={`${match.path}/:applicationId`}>
        <Screen nav={Screen.tenantNavigation}>
          <Helmet title="Edit application" />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={(
              <React.Fragment>
                <div className="app-content-header">
                  <div className="breadcrumb">
                    <Link to={match.url}>Applications</Link>
                    <i className="fa fa-angle-right" />
                    <div>Application Details</div>
                  </div>
                </div>
                <div className="app-content-main">
                <i className="fa fa-spinner fa-pulse fa-2x" />
                </div>
              </React.Fragment>
            )}>
              <EditApplicationScreen parentUrl={match.url} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Screen>
      </Route>
    </Switch>
  )
}

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
