/**
 * @generated SignedSource<<ac230b6a859bb857b75f497141b0e628>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Tenants_OrganizationTenantsScreen_Query$variables = {
  organizationId: string;
  query?: string | null;
};
export type Tenants_OrganizationTenantsScreen_Query$data = {
  readonly organization: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly shortTenantId: string;
    }>;
    readonly viewerPermissions: {
      readonly tenantsLogin: boolean;
    };
  };
};
export type Tenants_OrganizationTenantsScreen_Query = {
  response: Tenants_OrganizationTenantsScreen_Query$data;
  variables: Tenants_OrganizationTenantsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerOrganizationPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenantsLogin",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tenants_OrganizationTenantsScreen_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tenants_OrganizationTenantsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2174676dcbf2201b402449d2e3bd642",
    "id": null,
    "metadata": {},
    "name": "Tenants_OrganizationTenantsScreen_Query",
    "operationKind": "query",
    "text": "query Tenants_OrganizationTenantsScreen_Query(\n  $organizationId: ID!\n  $query: String\n) {\n  organization(id: $organizationId) {\n    viewerPermissions {\n      tenantsLogin\n    }\n    tenants(query: $query) {\n      id\n      name\n      shortTenantId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eab6ce6c19d164de33bb9a663fe845ec";

export default node;
