/**
 * @generated SignedSource<<0bbea3041bbd02d89115fdcf72244a45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteInvitationButton_tenant$data = {
  readonly id: string;
  readonly " $fragmentType": "DeleteInvitationButton_tenant";
};
export type DeleteInvitationButton_tenant$key = {
  readonly " $data"?: DeleteInvitationButton_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteInvitationButton_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteInvitationButton_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "7d088bad245f42ade393db0c2ece16e4";

export default node;
