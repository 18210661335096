/**
 * @generated SignedSource<<cb386f873a866c641923c92e8dc99122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationApiKeyMode = "READ_ONLY" | "READ_WRITE" | "%future added value";
export type CreateApplicationApiKeyInput = {
  applicationId: string;
  mode?: ApplicationApiKeyMode | null;
  note?: string | null;
};
export type AddApiKeyFormMutation$variables = {
  input: CreateApplicationApiKeyInput;
};
export type AddApiKeyFormMutation$data = {
  readonly createSignaturesApplicationApiKey: {
    readonly apiKey: {
      readonly " $fragmentSpreads": FragmentRefs<"ApiKeyModal_apiKey">;
    };
    readonly application: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_application">;
    };
  };
};
export type AddApiKeyFormMutation = {
  response: AddApiKeyFormMutation$data;
  variables: AddApiKeyFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientSecret",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddApiKeyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createSignaturesApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Signatures_Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_application"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplicationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeyModal_apiKey"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddApiKeyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createSignaturesApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Signatures_Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplicationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9a5bead66d438222ad7210462dbfaee",
    "id": null,
    "metadata": {},
    "name": "AddApiKeyFormMutation",
    "operationKind": "mutation",
    "text": "mutation AddApiKeyFormMutation(\n  $input: CreateApplicationApiKeyInput!\n) {\n  createSignaturesApplicationApiKey(input: $input) {\n    application {\n      id\n      ...ApiKeys_application\n    }\n    apiKey {\n      ...ApiKeyModal_apiKey\n      id\n    }\n  }\n}\n\nfragment AddApiKeyButton_application on Signatures_Application {\n  id\n}\n\nfragment AddApiKeyForm_application on Signatures_Application {\n  id\n}\n\nfragment ApiKeyModal_apiKey on ApplicationApiKey {\n  clientId\n  clientSecret\n}\n\nfragment ApiKeys_application on Signatures_Application {\n  apiKeys {\n    id\n    clientId\n    clientSecret\n    mode\n    note\n    ...DeleteApiKeyButton_apiKey\n    ...RefreshApiKeyButton_apiKey\n  }\n  ...AddApiKeyButton_application\n  ...AddApiKeyForm_application\n  ...DeleteApiKeyButton_application\n  ...RefreshApiKeyButton_application\n}\n\nfragment DeleteApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment DeleteApiKeyButton_application on Signatures_Application {\n  id\n}\n\nfragment RefreshApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment RefreshApiKeyButton_application on Signatures_Application {\n  id\n}\n"
  }
};
})();

(node as any).hash = "36f39afe9d072673b0668aa2771d1aff";

export default node;
