import React, {useState, useEffect, useMemo} from 'react';
import { IdToken } from '@auth0/auth0-spa-js';
import {useParams, useHistory} from 'react-router-dom';

import {translate} from '@app/i18n';
import {RootState} from '@redux/store';
import Button from '@components/Button';
import {createNemIDAuthClient} from '@app/auth';
import { setTokens } from '@app/redux/authSlice';
import { useSelector, useDispatch } from '@app/redux';

import './LoginScreen.css';
import useTracking from '@app/hooks/useTracking';
import { OptionalTenantRouteParams } from '@app/hooks/useTenant';
import { useVerifyClaims } from '@app/hooks/useAuth';
import useQuery from '@app/hooks/useQuery';

export function LoginScreen() {
  const auth = useSelector(state => state.auth.client);

  const handleLogin = () => {
    auth.loginWithRedirect({
      response_type: 'token id_token'
    });
  };

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <h4>Welcome to the Criipto Dashboard</h4>
          <p>You must login to proceed.</p>
        </div>
        <Button variant="primary" onClick={handleLogin}>{translate('LOG_IN')}</Button>
      </div>
    </div>
  );
}

export function LoginScreenMFA() {
  const auth = useSelector(state => state.auth.client);
  const history = useHistory();
  const claims = useVerifyClaims();
  const hasMfa = claims?.['http://criipto/mfa'];

  const handleMfa = async () => {
    await auth.loginWithPopup({
      response_type: 'token id_token',
      scope: 'mfa',
      timeoutInSeconds: 15 * 60
    });
    const accessToken = await auth.getTokenSilently({
      response_type: 'token id_token',
      scope: 'mfa'
    });
    history.push('/');
  };

  useEffect(() => {
    if (!hasMfa) handleMfa();
  }, [hasMfa])

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <p>{hasMfa ? 'MFA is setup for your user' : 'Setup MFA for your user'}</p>
          <Button variant="primary" onClick={handleMfa}>{hasMfa ? 'Re-enroll/test MFA' : 'Enroll MFA'}</Button>
        </div>
      </div>
    </div>
  );
}

interface CallbackProps {

}

export function LoginCallbackScreen(props : CallbackProps) {
  const history = useHistory();
  const auth = useSelector((state : RootState) => state.auth.client);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const tracking = useTracking();

  const handleLogin = () => {
    auth.loginWithRedirect({
      response_type: 'token id_token'
    });
  };

  useEffect(() => {
    auth.handleRedirectCallback().then(loginResult => {
      return Promise.all<[IdToken, any]>([
        auth.getIdTokenClaims({
          scope: loginResult.appState?.scope
        }),
        auth.getTokenSilently({
          scope: loginResult.appState?.scope
        })
      ]).then(([claims, accessToken]: [IdToken, any]) => {
        dispatch(setTokens({
          access_token: accessToken,
          id_token: claims.__raw,
          profile: claims
        }));
        tracking.login(claims);

        fetch(`${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/audit-login`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (loginResult.appState?.returnTo) {
          history.push(loginResult.appState?.returnTo);
        } else {
          history.push('/');
        }
      });
    }).catch(err => {
      console.error(err);
      setError(err.message || err);
    });
  }, []);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <p>
            Logging in ...
          </p>

          {error && (
            <div className="alert alert-danger" style={{marginTop: "25px", width: "100%"}}>
              <p>{ error }</p>
              <Button variant="primary" onClick={handleLogin} style={{float: "left"}}>{translate('LABEL_TRY_AGAIN')}</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function LoginRefreshScreen() {
  const auth = useSelector((state : RootState) => state.auth.client);
  const dispatch = useDispatch();
  const params = useParams<OptionalTenantRouteParams>();
  const history = useHistory();
  const query = useQuery();
  const returnTo = query.get('returnTo') ?? (params.tenantId ? `/tenant/${params.tenantId}/dashboard` : `/`);

  useEffect(() => {
    auth.checkSession().then(() => {
      return Promise.all([
        auth.getIdTokenClaims(),
        auth.getTokenSilently({
          ignoreCache: true
        })
      ]).then(([claims, accessToken]) => {
        dispatch(setTokens({
          access_token: accessToken,
          id_token: claims.__raw,
          profile: claims
        }));

        if (params.tenantId) {
          history.push(returnTo);
        } else {
          history.push(returnTo);
        }
      });
    }).catch((err) => {
      auth.loginWithRedirect({
        response_type: 'token id_token',
        appState: {
          returnTo
        }
      });
    });
  }, [returnTo]);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <p>Working ...</p>
        </div>
      </div>
    </div>
  );
}

export function NemIDLoginCallbackScreen(props : CallbackProps) {
  const nemIdAuthClient = useMemo(() => createNemIDAuthClient(), []);

   useEffect(() => {
     nemIdAuthClient.popup.callback(window.location.origin);
  }, []);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <p>
            Logging in ...
          </p>
        </div>
      </div>
    </div>
  );
}