import React from 'react';
import { useFragment } from "react-relay";
import {graphql} from 'react-relay';
import { RemoveOrganizationTenantModal_tenant$key } from './__generated__/RemoveOrganizationTenantModal_tenant.graphql';
import { ConfirmModal, ConfirmModalProps } from '@app/components/Modal/Modal';
import useMutation from '@app/hooks/useMutation';
import { RemoveOrganizationTenantModalMutation } from './__generated__/RemoveOrganizationTenantModalMutation.graphql';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

export default function RemoveOrganizationTenantModal(props: {
  open: ConfirmModalProps["open"]
  onCancel: ConfirmModalProps["onCancel"]
  tenant: RemoveOrganizationTenantModal_tenant$key
}) {
  const toast = useToast();
  const tenant = useFragment(
    graphql`
      fragment RemoveOrganizationTenantModal_tenant on AdminTenant {
        id
        tenant {
          name
        }
        organization {
          id
          organization {
            name
          }
        }
      }
    `,
    props.tenant
  );

  const [executor, status] = useMutation<RemoveOrganizationTenantModalMutation>(
    graphql`
      mutation RemoveOrganizationTenantModalMutation($input: RemoveOrganizationTenantInput!) {
        admin_removeOrganizationTenant(input: $input) {
          adminTenant {
            organization {
              id
              organization {
                name
              }
            }
          }

          adminOrganization {
            tenants {
              id
              ... TenantsScreen_AdminTenantItem_tenant
            }
          }
        }
      }
    `
  );


  const handleConfirm = async () => {
    if (!tenant.organization) throw new Error('Invalid state');
    await executor.executePromise({
      input: {
        tenantId: tenant.id,
        organizationId: tenant.organization.id
      }
    });
    toast.success({title: 'Tenant removed'});
    props.onCancel();
  };
  if (!tenant.organization) return null;

  return (
    <ConfirmModal
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={handleConfirm}
      confirmText={"Remove tenant from organization"}
      confirmVariant="danger"
      data-test-id="confirm-modal"
    >
        <div>
          <h2>Remove tenant from organization</h2>
          <p>Are you sure you want to remove tenant "{tenant.tenant.name}" from "{tenant.organization.organization.name}" organization?</p>
        </div>

      {status.error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} />
      )}
    </ConfirmModal>
  )
}