/**
 * @generated SignedSource<<b34298c4095397243499b0bec93d95af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type TenantFTNScreen_Query$variables = {
  environment: Environment;
  id: string;
};
export type TenantFTNScreen_Query$data = {
  readonly admin: {
    readonly tenant: {
      readonly finnishTelia: {
        readonly callbackDomain: string | null;
        readonly clientId: string | null;
        readonly teliaConnectionId: string | null;
      };
      readonly id: string;
      readonly tenant: {
        readonly domains: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly name: string;
            };
          }>;
        };
      };
    };
  };
};
export type TenantFTNScreen_Query = {
  response: TenantFTNScreen_Query$data;
  variables: TenantFTNScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callbackDomain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teliaConnectionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantFTNScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": (v2/*: any*/),
              "concreteType": "AdminTenant",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "FinnishTelia",
                  "kind": "LinkedField",
                  "name": "finnishTelia",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tenant",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": (v4/*: any*/),
                      "concreteType": "DomainConnection",
                      "kind": "LinkedField",
                      "name": "domains",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DomainEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Domain",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "admin.tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TenantFTNScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "FinnishTelia",
                "kind": "LinkedField",
                "name": "finnishTelia",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "DomainConnection",
                    "kind": "LinkedField",
                    "name": "domains",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Domain",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbd1cb6622000186db9d8b1b37c365a8",
    "id": null,
    "metadata": {},
    "name": "TenantFTNScreen_Query",
    "operationKind": "query",
    "text": "query TenantFTNScreen_Query(\n  $id: ID!\n  $environment: Environment!\n) {\n  admin {\n    tenant(id: $id) {\n      id\n      finnishTelia(environment: $environment) {\n        clientId\n        callbackDomain\n        teliaConnectionId\n        id\n      }\n      tenant {\n        domains(environment: $environment) {\n          edges {\n            node {\n              name\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8c6031a4ef6ddc6954ced0ce068c79c";

export default node;
