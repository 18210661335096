/**
 * @generated SignedSource<<df01a93ac6ae5a731e0801cf1d121687>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feature = "CUSTOM_UI" | "FRENCH_PUBLIK" | "%future added value";
export type ToggleFeatureInput = {
  enabled: boolean;
  feature: Feature;
  note?: string | null;
  tenantId: string;
};
export type FeatureToggleMutation$variables = {
  input: ToggleFeatureInput;
};
export type FeatureToggleMutation$data = {
  readonly admin_toggleFeature: {
    readonly tenant: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"FeatureToggle_tenant">;
    };
  };
};
export type FeatureToggleMutation = {
  response: FeatureToggleMutation$data;
  variables: FeatureToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleFeatureOutput",
        "kind": "LinkedField",
        "name": "admin_toggleFeature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeatureToggle_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeatureToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleFeatureOutput",
        "kind": "LinkedField",
        "name": "admin_toggleFeature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantFeatures",
                "kind": "LinkedField",
                "name": "features",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "FRENCH_PUBLIK",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "CUSTOM_UI",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2a4e633bd31703c96d0287a45f0f8b6",
    "id": null,
    "metadata": {},
    "name": "FeatureToggleMutation",
    "operationKind": "mutation",
    "text": "mutation FeatureToggleMutation(\n  $input: ToggleFeatureInput!\n) {\n  admin_toggleFeature(input: $input) {\n    tenant {\n      id\n      ...FeatureToggle_tenant\n    }\n  }\n}\n\nfragment FeatureToggle_tenant on Tenant {\n  id\n  features {\n    FRENCH_PUBLIK\n    CUSTOM_UI\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd8f4f35be4fea5fbdaa50d1716ac29a";

export default node;
