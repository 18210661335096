import { setAccessToken } from "@app/redux/authSlice";
import { useVerifyClaims } from "./useAuth";
import { useSelector, useDispatch } from '@app/redux';
import { useCallback } from "react";
import { checkMFA } from "@criipto/dashboard-user-authentication";

export function useMfa(required: boolean) {
  const claims = useVerifyClaims();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.client);

  return useCallback(async () => {
    if (!required) return Promise.resolve();
    if (!claims?.['http://criipto/mfa'] || !checkMFA(claims)) {
      await auth.loginWithPopup({
        response_type: 'token id_token',
        scope: 'mfa',
        timeoutInSeconds: 15 * 60
      }).catch(err => {
        console.error('loginWithPopup', err);
        throw err;
      });

      const accessToken = await auth.getTokenSilently({
        response_type: 'token id_token',
        scope: 'mfa'
      }).catch(err => {
        console.error('getTokenSilently', err);
        throw err;
      });
      dispatch(setAccessToken(accessToken));
    }
  }, [claims, required]);
}