import { Environment } from "@app/models";
import { ConnectionHandler } from "relay-runtime";

const CONNECTION_NAMES = [
  'tenant_domains',
  // 'tenant_domainsWithApplications'
];

// This component ensures all relevant relay connections are updated
export function getRelayDomainConnections(tenantId: string, environment: Environment | null) {
  const connections = CONNECTION_NAMES.flatMap(connectionName => ([
    ConnectionHandler.getConnectionID(
      tenantId,
      connectionName
    ),
    (!environment || environment === 'TEST') ? ConnectionHandler.getConnectionID(
      tenantId,
      connectionName,
      {environment: 'TEST'}
    ) : null,
    (!environment || environment === 'PRODUCTION') ? ConnectionHandler.getConnectionID(
      tenantId,
      connectionName,
      {environment: 'PRODUCTION'}
    ) : null
  ])).filter(id => id) as string[];

  return connections;
}

export function getRelayInvitationsConnections(tenantId: string) {
  const connections = [
    ConnectionHandler.getConnectionID(
      tenantId,
      'tenant_invitations'
    ),
    ConnectionHandler.getConnectionID(
      tenantId,
      'tenant_invitations',
      null
    )
  ];

  return connections;
}

export function getRelayUsersConnections(tenantId: string) {
  const connections = [
    ConnectionHandler.getConnectionID(
      tenantId,
      'tenant_users'
    ),
    ConnectionHandler.getConnectionID(
      tenantId,
      'tenant_users',
      null
    )
  ];

  return connections;
}

export function getRelayOrganizationUsersConnections(organizationId: string) {
  const connections = [
    ConnectionHandler.getConnectionID(
      organizationId,
      'organization_users'
    ),
    ConnectionHandler.getConnectionID(
      organizationId,
      'organization_users',
      null
    )
  ];

  return connections;
}

