import { Product } from "./screens/SubscriptionScreen/__generated__/SubscriptionScreen_ProductPanel_product.graphql";


export function transactionName(product: Product, pluralis = false) {
  switch (product) {
    case 'Ageverification_DK': return pluralis ? 'verifications' : 'verification';
    case 'Signatures': return pluralis ? 'signatures' : 'signature';
    case 'Verify': return pluralis ? 'logins' : 'login';
    case '%future added value': return 'N/A';
    default: assertUnreachable(product);
  }
}

export function productName(product: Product) {
  if (product === 'Ageverification_DK') return 'Age verification (DK)';
  return product.toString();
}

function assertUnreachable(x: never): never {
  throw new Error(`Unexpected: ${x}`);
}