import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import {Form, FormError, FormSuccess} from '@app/components/Form';
import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';

import React from 'react';

import Button from '@app/components/Button/Button';
import {FormikHelpers } from 'formik';
import { PermissionsRelay } from '../Permissions';
import { InvitationForm_tenant$key, UserPermission } from './__generated__/InvitationForm_tenant.graphql';
import useMutation from '@app/hooks/useMutation';
import { InvitationForm_inviteUser_Mutation, UserRole } from './__generated__/InvitationForm_inviteUser_Mutation.graphql';
import { getRelayInvitationsConnections } from '@app/screens/DomainsScreen/utils';

export const ROLE_DESCRIPTION : {[key in UserRole]: string} = {
  Admin: 'Can manage users, billing and all other settings',
  Developer: 'Can manage domains, applications and styling but not users or billing',
  '%future added value': ''
};

type Values = {
  email: string
  name: string
  role: UserRole | null
  permissions: UserPermission[]
}

export default function InvitationForm(props: {
  tenant: InvitationForm_tenant$key,
  onInvited?: () => void
}) {
  const tenant = useFragment(graphql`
    fragment InvitationForm_tenant on Tenant {
      id
      userPermissions
      viewerPermissions {
        users
      }

      ... Permissions_tenant
    }
  `, props.tenant);

  const [inviteUserExecutor, inviteUserState] = useMutation<InvitationForm_inviteUser_Mutation>(graphql`
    mutation InvitationForm_inviteUser_Mutation(
      $connections: [ID!]!
      $input: InviteUserInput!
    ) {
      inviteUser(input: $input) {
        invitationEdge @appendEdge(connections: $connections) {
          cursor
          node {
            id
            name
            email

            permissions
          }
        }
      }
    }
  `);

  const handleSubmit = async (values: Values, actions: FormikHelpers<any>) => {
    await inviteUserExecutor.executePromise({
      input: {
        tenantId: tenant.id,
        email: values.email,
        name: values.name,
        acceptUrl: window.location.origin + '/invitation/accept',
        role: values.role?.length ? values.role : null,
        permissions: values.role ? null : values.permissions
      },
      connections: getRelayInvitationsConnections(tenant.id)
    });

    actions.resetForm();
    if (props.onInvited) props.onInvited();
  };

  if (tenant.viewerPermissions.users !== 'WRITE') return null;

  return (
    <Form<Values>
      initialValues={{name: '', email: '', role: 'Developer', permissions: []}}
      onSubmit={handleSubmit}
      key="invite_user"
      style={{width: '100%'}}
    >
      {({isPending, error, isSuccess, values, setValues}) => (
        <React.Fragment>
          <div className="flex flex-col lg:flex-row gap-[10px]">
            <InputField
              type="text"
              name="name"
              required
              placeholder={"Name"}
            />
            <InputField
              type="email"
              name="email"
              required
              placeholder={"Email"}
            />
            <Select
              className='!min-w-[150px]'
              name="role"
              required
              data-testid="invitation_form_role"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const value = event.target.value as UserRole;
                setValues({
                  ...values,
                  role: value,
                });
              }}
              helpText = {values.role? <p>{ROLE_DESCRIPTION[values.role]}</p> : null }>
                <option value="Admin">Admin</option>
                <option value="Developer">Developer</option>
                <option value="">Custom</option>
            </Select>
            <Button className='lg:mt-[2px] lg:!h-[36px]'  variant="primary" type="submit" working={isPending}>Invite user</Button>
          </div>
          {!values.role ? (
            <div className="flex flex-wrap gap-[10px]">
              <PermissionsRelay
                tenant={tenant}
                onSelect={(permission, selected) => {
                  const permissions =
                    selected ?
                      values.permissions.concat([permission]) :
                      values.permissions.filter(s => s !== permission)

                  setValues({
                    ...values,
                    permissions
                  });
                }}
                selectable={true}
                selected={values.permissions}
              />
            </div>
          ) : null}

          {error && (
            <FormError error={error} />
          )}
          {isSuccess && (
            <FormSuccess message={'User invited!'} />
          )}

        </React.Fragment>
      )}
    </Form>
  )
}