/**
 * @generated SignedSource<<0a5dcb68575894c41e6f53dea03c11f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type OrderDanishMitIDDomainInput = {
  domainName: string;
  environment: Environment;
  tenantId: string;
};
export type CustomDomain_OrderDomain_Mutation$variables = {
  input: OrderDanishMitIDDomainInput;
};
export type CustomDomain_OrderDomain_Mutation$data = {
  readonly orderDanishMitIDDomain: {
    readonly " $fragmentSpreads": FragmentRefs<"CustomDomain_DKMitID_identityProvider">;
  };
};
export type CustomDomain_OrderDomain_Mutation = {
  response: CustomDomain_OrderDomain_Mutation$data;
  variables: CustomDomain_OrderDomain_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomDomain_OrderDomain_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DanishMitID",
        "kind": "LinkedField",
        "name": "orderDanishMitIDDomain",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomDomain_DKMitID_identityProvider"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomDomain_OrderDomain_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DanishMitID",
        "kind": "LinkedField",
        "name": "orderDanishMitIDDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "customDomain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainAssertion",
                    "kind": "LinkedField",
                    "name": "cname",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actual",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expected",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "matches",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "PendingDanishMitIDCustomDomain",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "CompletedDanishMitIDCustomDomain",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "561bd979781d5bc75dcab2c979f365c7",
    "id": null,
    "metadata": {},
    "name": "CustomDomain_OrderDomain_Mutation",
    "operationKind": "mutation",
    "text": "mutation CustomDomain_OrderDomain_Mutation(\n  $input: OrderDanishMitIDDomainInput!\n) {\n  orderDanishMitIDDomain(input: $input) {\n    ...CustomDomain_DKMitID_identityProvider\n    id\n  }\n}\n\nfragment CustomDomain_DKMitID_identityProvider on DanishMitID {\n  customDomain {\n    __typename\n    ... on PendingDanishMitIDCustomDomain {\n      domainName\n      cname {\n        actual\n        expected\n        matches\n      }\n    }\n    ... on CompletedDanishMitIDCustomDomain {\n      domainName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84228f7b2278e183c889a2b6e883604d";

export default node;
