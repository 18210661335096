/**
 * @generated SignedSource<<2b1d1b3a8e0d1a5d046e08e1ceeb6a74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TenantDropdown_Query$variables = {
  tenantId: string;
};
export type TenantDropdown_Query$data = {
  readonly tenant: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly viewer: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly organization: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly shortTenantId: string;
    }>;
  } | null;
};
export type TenantDropdown_Query = {
  response: TenantDropdown_Query$data;
  variables: TenantDropdown_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "tenantId"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantDropdown_Query",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantDropdown_Query",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b538a524e13970dda4ef83b28cba371a",
    "id": null,
    "metadata": {},
    "name": "TenantDropdown_Query",
    "operationKind": "query",
    "text": "query TenantDropdown_Query(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    id\n    name\n  }\n  viewer {\n    __typename\n    tenants {\n      id\n      shortTenantId\n      name\n      organization {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b3d8f7959a36207d47288c59ab63d0a";

export default node;
