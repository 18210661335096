import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import {graphql} from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';

import {useSelector, useDispatch} from '@app/redux';
import {set as setEnvironment} from '@app/redux/environmentSlice';

import Screen from '@app/components/Screen/Screen';
import OnboardingScreenLogin from './OnboardingScreenLogin';
import OnboardingScreenMitID from './OnboardingScreenMitID';
import OnboardingScreenAgeVerification from '../OnboardingAgeVerificationScreen/OnboardingAgeVerificationScreen';
import Panel from '@app/components/Panel/Panel';
import { useTenantId } from '@app/hooks/useTenant';
import { TENANT_ID_PREFIX } from '@app/constants';
import { LinkButton } from '@app/components/Button/Button';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';
import { TenantRouteParams } from '@app/models';

import cx from 'classnames';
import styles from './OnboardingScreen.module.css';

import { OnboardingScreenQuery } from './__generated__/OnboardingScreenQuery.graphql';

export default function OnboardingRoutes() {
  const match = useRouteMatch();
  const routeMatch = useRouteMatch<TenantRouteParams>();
  const history = useHistory();
  const tenantId = useTenantId();
  const dispatch = useDispatch();
  const environment = useEnvironment();
  const nav = Screen.tenantNavigation;

  const data = useLazyLoadQuery<OnboardingScreenQuery>(
    graphql`
      query OnboardingScreenQuery($tenantId: ID!) {
        tenant(id: $tenantId) {
          onboarding {
            products
          }
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${routeMatch.params.tenantId}`)
    }
  );

  useEffect(() => {
    if (!tenantId) return;
    if (environment !== "TEST") {
      dispatch(setEnvironment({tenantId: tenantId.tenantId, environment: "TEST"}));
    }
  }, [tenantId, environment]);

  useEffect(() => {
    if (data?.tenant?.onboarding?.products) {
      const products = data.tenant.onboarding.products;

      if (products.length > 0 && products.every(p => p.startsWith('Ageverification'))) {
        history.push(`${match.url}/age-verification`);
      }
    }
  }, [data, history, match.url]);

  return (
    <Switch>
      <Route path={`${match.path}/login/:applicationId`}>
        <OnboardingScreenLogin />
      </Route>
      <Route path={`${match.path}/login`}>
        <OnboardingScreenLogin />
      </Route>
      <Route path={`${match.path}/mitid`}>
        <OnboardingScreenMitID />
      </Route>
      <Route path={`${match.path}/age-verification`}>
        <Screen nav={nav} fullscreen={false}>
          <OnboardingScreenAgeVerification />
        </Screen>
      </Route>
      <Route>
        <OnboardingScreen />
      </Route>
    </Switch>
  );
}
export function OnboardingScreen() {
  const tenantId = useTenantId();
  const tracking = useTracking();
  const nav = Screen.tenantNavigation;

  useEffect(() => {
    if (!tenantId) return;

    tracking.viewedOnboardingScreen();
  }, [tenantId]);

  if (!tenantId) return null;

  return (
    <Screen nav={nav}>
      <div className={cx('app-content-main', styles['onboarding-screen'])}>
        <div className={styles['panels']}>
          <Panel title="Your first test domain">
            <p>
              Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong>.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`}>
              Add test domain
            </LinkButton>
          </Panel>
          <Panel title="Invite team member">
            <p>
              If you are not a developer, or if you need someone else to handle the billing, you can invite them to your Criipto Tenant.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/users`}>
              Invite team member
            </LinkButton>
          </Panel>
          <Panel title="Test login">
            <p>
              If you wish to see the contents of the JWT that Criipto Verify issues when a user logs in, you can try a test login.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/onboarding/login`}>
              Try a test login
            </LinkButton>
          </Panel>
        </div>
        <div className={styles['panels']}>
          <Panel title="Start integrating Criipto Verify">
            <p>
              To start accepting user logins with e-IDs you will first need to integrate a test application.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/applications/add`}>
              Create a verify/login application
            </LinkButton>
          </Panel>
          <Panel title="Start integrating Criipto Signatures">
            <p>
              Sign PDF documents with any of the e-IDs that Criipto supports.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/applications/add?tags=signatures`}>
              Create a signatures application
            </LinkButton>
          </Panel>
          <Panel title="Start integrating Age Verification">
            <p>
              Verify user ages through e-IDs, without collecting personal data.
            </p>
            <LinkButton variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/onboarding/age-verification`}>
              Get started with age verification
            </LinkButton>
          </Panel>
        </div>
      </div>
    </Screen>
  );
}