import React, { useMemo, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useRouteMatch } from 'react-router-dom';
import {graphql} from 'react-relay';
import { AddApplicationModalQuery } from './__generated__/AddApplicationModalQuery.graphql';
import { TenantRouteParams } from '@app/models';
import useEnvironment from '@app/hooks/useEnvironment';
import Modal, { ModalProps } from '@app/components/Modal/Modal';
import Button, { LinkButton } from '@app/components/Button/Button';
import { VerifyIcon, SignaturesIcon, AgeverificationIcon } from '@app/components/Icons/ApplicationTypeIcon';
import styles from './AddApplicationModal.module.css';
import cx from 'classnames';

export type AddApplicationModalProps = {
  open: ModalProps['open'];
  onHide: ModalProps['onHide'];
};

type ApplicationType = 'verify' | 'signatures' | 'age-verification';

export default function AddApplicationModal(props: AddApplicationModalProps) {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<AddApplicationModalQuery>(
    graphql`
      query AddApplicationModalQuery($tenantId: ID!) {
        tenant(id: $tenantId) {
          verifyEnvironments: environments(product: Verify)
          signaturesEnvironments: environments(product: Signatures)
          ageverificationEnvironments: environments(product: [Ageverification_DK])
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${match.params.tenantId}`)
    }
  );
  const tenant = data.tenant;

  const availableProducts : ApplicationType[] = useMemo(() => {
    return [
      tenant?.verifyEnvironments.includes(environment) ? 'verify' : null,
      tenant?.signaturesEnvironments.includes(environment) ? 'signatures' : null,
      tenant?.ageverificationEnvironments.includes(environment) ? 'age-verification' : null,
    ].filter((id): id is ApplicationType => id !== null)
  }, [data]);

  const [selectedApplicationType, setSelectedApplicationType] = useState<ApplicationType | null>(availableProducts.length === 1 ? availableProducts[0] : null);

  const handleHide = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  const handleSelect = (applicationType: ApplicationType) => {
    setSelectedApplicationType(applicationType);
  };

  const redirectUrl =
  selectedApplicationType === 'verify' ? `${match.url}/add/` :
  selectedApplicationType === 'signatures' ? `${match.url}/add/?tags=signatures` :
  selectedApplicationType === 'age-verification' ? `${match.url}/add/?tags=ageverification` :
  selectedApplicationType === null ? null :
  assertUnreachable(selectedApplicationType);

  const addVerifyApplicationSection = tenant?.verifyEnvironments.includes(environment) ? (
    <li
      className={cx(styles['product-container'], {[styles['selected']] : selectedApplicationType === 'verify'})}
      key="verify"
      onClick={() => handleSelect('verify')}
    >
      <VerifyIcon />
      <p className={styles['product-category']}>Criipto Verify</p>
      <h3 className={styles['product-header']}>Authentications</h3>
      <p>Verify users via GDPR compliant eID log in in your product.</p>
    </li>
  ) : null;

  const addSignaturesApplicatonSection = tenant?.signaturesEnvironments.includes(environment) ? (
    <li
      className={cx(styles['product-container'], {[styles['selected']] : selectedApplicationType === 'signatures'})}
      key="signatures"
      onClick={() => handleSelect('signatures')}
    >
      <SignaturesIcon />
      <p className={styles['product-category']}>Criipto Signatures</p>
      <h3 className={styles['product-header']}>Signatures</h3>
      <p>Let users sign PDF documents with GDPR compliant eID verification.</p>
    </li>
  ) : null;

  const addAgeverificationApplicatonSection = tenant?.ageverificationEnvironments.includes(environment) ? (
    <li
      className={cx(styles['product-container'], {[styles['selected']] : selectedApplicationType === 'age-verification'})}
      key="age-verification"
      onClick={() => handleSelect('age-verification')}
    >
      <AgeverificationIcon />
      <p className={styles['product-category']}>Age Verification</p>
      <h3 className={styles['product-header']}>Age Verification</h3>
      <p>Safe and secure age verification with no GDPR concerns.</p>
    </li>
  ) : null;

  return (
    <Modal {...props} onHide={handleHide}>
      <Modal.Header>
        <h2>Add application</h2>
      </Modal.Header>

      <Modal.Content className='mb-[32px]'>
        <p className={styles['content-header']}>Choose a product</p>
        <ul className={styles['product-list']}>
          {addVerifyApplicationSection}
          {addSignaturesApplicatonSection}
          {addAgeverificationApplicatonSection}
        </ul>
      </Modal.Content>

      <Modal.Actions className="!justify-end">
        <Button variant="default" onClick={handleHide}>
          Cancel
        </Button>
        {redirectUrl !== null ? (
          <LinkButton variant="primary" to={redirectUrl}>
            Create
          </LinkButton>
        ) : (
          <Button variant="primary" disabled>
            Create
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}