import React, { useEffect } from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import { Link, useRouteMatch, Switch, Route, NavLink, useHistory } from "react-router-dom";

import { Application } from "@app/models";
import SignatureApplicationApiKeys from '../../components/ApplicationEditor/SignaturesApplicationEditor/Sections/ApiKeys';
import IdentityProvidersSection from '../../components/ApplicationEditor/Sections/IdentityProviders';
import DeleteApplicationButton from '../../components/DeleteApplicationButton/DeleteApplicationButton';
import { Tag } from '@app/components/Tag';
import SignaturesApplicationIntegrate from '../../components/ApplicationEditor/SignaturesApplicationIntegrate';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import GeneralSection from '../../components/ApplicationEditor/Sections/General';
import { EditSignaturesApplicationScreen_application$key, EditSignaturesApplicationScreen_application$data } from './__generated__/EditSignaturesApplicationScreen_application.graphql';
import SignaturesWebhooks from '../../components/ApplicationEditor/SignaturesWebhooks';
import Alert from '@app/components/Alert/Alert';
import { EditSignaturesApplicationScreen_tenant$key } from './__generated__/EditSignaturesApplicationScreen_tenant.graphql';

interface Props {
  parentUrl: string,
  application: EditSignaturesApplicationScreen_application$key,
  tenant: EditSignaturesApplicationScreen_tenant$key,
  onDelete: HeaderProps["onDelete"]
}
export default function EditSignaturesApplicationScreen(props: Props) {
  const match = useRouteMatch();

  const application = useFragment(
    graphql`
      fragment EditSignaturesApplicationScreen_application on Application {
        id
        name

        __typename
        ... on SignaturesApplication {
          signatures {
            ... ApiKeys_application
            ... SignaturesWebhooks_application
          }
        }

        ... GeneralSection_application
        ... IdentityProvidersSection_application
        ... SignaturesApplicationIntegrate_application
        ... DeleteApplicationButton_application
      }
    `
  , props.application);

  const tenant = useFragment(
    graphql`
      fragment EditSignaturesApplicationScreen_tenant on Tenant {
        ... IdentityProvidersSection_tenant
      }
    `
  , props.tenant);

  return (
    <React.Fragment>
      <EditSignaturesApplicationScreenHeader {...props} application={application} />
      <Switch>
        <Route path={match.path} exact={true}>
          <div className="app-tab-content">
            <div className="max-w-[750px]">
              <GeneralSection
                parentUrl={props.parentUrl}
                application={application}
              />
            </div>
          </div>
        </Route>
        <Route path={`${match.path}/e-ids`}>
          <div className="app-tab-content">
            <GraphQLErrorBoundary>
              <IdentityProvidersSection
                tenant={tenant}
                application={application}
                help={(
                  <p>
                    These e-IDs define the authentication choices a user can make when signing a document.
                  </p>
                )}
              />
            </GraphQLErrorBoundary>
          </div>
        </Route>
        <Route path={`${match.path}/api-keys`}>
          <div className="app-tab-content">
            {application?.signatures ? (
              <SignatureApplicationApiKeys application={application.signatures} />
            ) : (
              <Alert variant="error" className="mt-[15px]" title="An error occurred" message="Could not find signature application in Criipto Signatures API." />
            )}
          </div>
        </Route>
        <Route path={`${match.path}/integrate`}>
          <div className="app-tab-content">
            <SignaturesApplicationIntegrate application={application} />
          </div>
        </Route>
        <Route path={`${match.path}/webhooks`}>
          <div className="app-tab-content full-screen">
            {application?.signatures ? (
              <SignaturesWebhooks application={application.signatures} />
            ) : (
              <Alert variant="error" className="mt-[15px]" title="An error occurred" message="Could not find signature application in Criipto Signatures API." />
            )}
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

interface HeaderProps {
  parentUrl: string,
  application: EditSignaturesApplicationScreen_application$data,
  onDelete: () => void,
}
export function EditSignaturesApplicationScreenHeader(props: HeaderProps) {
  const {parentUrl, application, onDelete} = props;
  const match = useRouteMatch();
  return (
    <div className="app-content-header with-tabs">
      <div className="breadcrumb">
        <Link to={parentUrl}>Applications</Link>
        <i className="fa fa-angle-right" />
        <div>Signature Application Details</div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-[10px]">
          <h1>{application.name}</h1>
          <Tag>Criipto Signatures</Tag>
        </div>
        <DeleteApplicationButton onDelete={onDelete} application={application} autoWidth />
      </div>

      <div className="app-content-tabs">
        <ul>
          <li>
            <NavLink to={`${match.url}/integrate`} activeClassName="active">Integrate</NavLink>
          </li>
          <li>
            <NavLink to={match.url} exact={true} activeClassName="active">General</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/e-ids`} activeClassName="active">e-IDs</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/api-keys`} activeClassName="active">API</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/webhooks`} activeClassName="active">Webhooks</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}