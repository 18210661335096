import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button from '@app/components/Button';
import Select from '@app/components/FormFields/Select/Select';
import Modal, { ModalProps } from '@app/components/Modal/Modal';
import React, { useState } from 'react';
import Permissions from '../Permissions';
import { EditUserModal_tenant$key } from './__generated__/EditUserModal_tenant.graphql';
import { EditUserModal_user$key, UserRole } from './__generated__/EditUserModal_user.graphql';
import { ROLE_DESCRIPTION } from '../InvitationForm/InvitationForm';
import useMutation from '@app/hooks/useMutation';
import { EditUserModal_Mutation } from './__generated__/EditUserModal_Mutation.graphql';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

export type EditUserModalProps = {
  open: ModalProps['open'];
  onHide: ModalProps['onHide'];
  tenant: EditUserModal_tenant$key;
  user: EditUserModal_user$key;
};

export default function EditUserModal(props: EditUserModalProps) {
  const toast = useToast();
  const tenant = useFragment(
    graphql`
      fragment EditUserModal_tenant on Tenant {
        id

        ... Permissions_tenant
      }
    `,
    props.tenant
  );

  const user = useFragment(
    graphql`
      fragment EditUserModal_user on User {
        id
        role
        permissions
      }
    `,
    props.user
  );


  const [role, setRole] = useState<UserRole | null>(user.role);
  const [permissions, setPermissions] = useState(() => user.permissions.slice());

  const [executor, status] = useMutation<EditUserModal_Mutation>(graphql`
    mutation EditUserModal_Mutation(
      $input: ChangeUserPermissionsInput!
    ) {
      changeUserPermissions(input: $input) {
        user {
          ...EditUserModal_user
          ...UsersScreen_user
        }
      }
    }
  `);

  const handleHide = () => {
    setRole(user.role);
    setPermissions(user.permissions.slice());
    if(props.onHide){
      props.onHide();
    }
  };

  const handleSave = async () => {
    await executor.executePromise({
      input: {
        userId: user.id,
        role,
        permissions: role ? null : permissions,
      },
    });
    toast.success({ title: 'User permissions updated', message: 'The permissions will take effect the next time the user logs in.' });
    if(props.onHide){
      props.onHide();
    }
  };

  return (
    <Modal {...props} onHide={handleHide}>
      <Modal.Header>
        <div>
          <h2>Edit user</h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Select
          name="role"
          label="Role"
          required
          onChange={(event) => event.target.value?.length ? setRole(event.target.value as UserRole) : setRole(null)}
          value={role ?? ''}
          helpText= {role ? (<p>{ROLE_DESCRIPTION[role]}</p>) : null}
        >
          <option value="Admin">Admin</option>
          <option value="Developer">Developer</option>
          <option value="">Custom</option>
        </Select>
        {!role ? (
          <div className="flex flex-wrap mt-[15px]">
            <Permissions
              tenant={tenant}
              selected={permissions}
              selectable={true}
              onSelect={(permission, selected) => {
                setPermissions(permissions => {
                  permissions =
                    selected ?
                      permissions.concat([permission]) :
                      permissions.filter(s => s !== permission);
                  return permissions;
                });
              }}
            />
          </div>
        ) : null}
        {status.error ? <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} /> : null}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="default" onClick={handleHide}>
          Cancel
        </Button>
        <Button variant="primary" working={status.pending} onClick={handleSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
