/**
 * @generated SignedSource<<d1a583f008c5b1128abede626f71b4cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveOrganizationTenantInput = {
  organizationId: string;
  tenantId: string;
};
export type RemoveOrganizationTenantModalMutation$variables = {
  input: RemoveOrganizationTenantInput;
};
export type RemoveOrganizationTenantModalMutation$data = {
  readonly admin_removeOrganizationTenant: {
    readonly adminOrganization: {
      readonly tenants: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"TenantsScreen_AdminTenantItem_tenant">;
      }>;
    };
    readonly adminTenant: {
      readonly organization: {
        readonly id: string;
        readonly organization: {
          readonly name: string;
        };
      } | null;
    };
  };
};
export type RemoveOrganizationTenantModalMutation = {
  response: RemoveOrganizationTenantModalMutation$data;
  variables: RemoveOrganizationTenantModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveOrganizationTenantModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrganizationTenantOutput",
        "kind": "LinkedField",
        "name": "admin_removeOrganizationTenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminOrganization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "adminOrganization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TenantsScreen_AdminTenantItem_tenant"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveOrganizationTenantModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrganizationTenantOutput",
        "kind": "LinkedField",
        "name": "admin_removeOrganizationTenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminOrganization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "adminOrganization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityIdentifier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortId",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantBillable",
                    "kind": "LinkedField",
                    "name": "billable",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Verify",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Signatures",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantAgeverificationBillableProduct",
                        "kind": "LinkedField",
                        "name": "Ageverification",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdminTenantBillableProduct",
                            "kind": "LinkedField",
                            "name": "dk",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f556baa20c9c650e1c6b48744239a170",
    "id": null,
    "metadata": {},
    "name": "RemoveOrganizationTenantModalMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveOrganizationTenantModalMutation(\n  $input: RemoveOrganizationTenantInput!\n) {\n  admin_removeOrganizationTenant(input: $input) {\n    adminTenant {\n      organization {\n        id\n        organization {\n          name\n          id\n        }\n      }\n      id\n    }\n    adminOrganization {\n      tenants {\n        id\n        ...TenantsScreen_AdminTenantItem_tenant\n      }\n      id\n    }\n  }\n}\n\nfragment TenantsScreen_AdminTenantItem_tenant on AdminTenant {\n  tenant {\n    name\n    shortTenantId\n    longTenantId\n    entityIdentifier\n    organization {\n      id\n      shortId\n      name\n    }\n    id\n  }\n  billable {\n    Verify {\n      actual\n    }\n    Signatures {\n      actual\n    }\n    Ageverification {\n      dk {\n        actual\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3338e52ab402373390bcfb17f23d01ca";

export default node;
