/**
 * @generated SignedSource<<cca9ce958a7a9f52d1cfa75a94e80870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetrySignatureOrderWebhookInput = {
  retryPayload: string;
  signatureOrderId: string;
};
export type SignaturesWebhooks_retry_Mutation$variables = {
  input: RetrySignatureOrderWebhookInput;
};
export type SignaturesWebhooks_retry_Mutation$data = {
  readonly signatures: {
    readonly retrySignatureOrderWebhook: {
      readonly invocation: {
        readonly signatureOrderId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"SignaturesWebhooks_invocation">;
      };
    } | null;
  };
};
export type SignaturesWebhooks_retry_Mutation = {
  response: SignaturesWebhooks_retry_Mutation$data;
  variables: SignaturesWebhooks_retry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signatureOrderId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseStatusCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retryPayload",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retryingAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignaturesWebhooks_retry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SignaturesMutation",
        "kind": "LinkedField",
        "name": "signatures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "RetrySignatureOrderWebhookOutput",
            "kind": "LinkedField",
            "name": "retrySignatureOrderWebhook",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "invocation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SignaturesWebhooks_invocation"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignaturesWebhooks_retry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SignaturesMutation",
        "kind": "LinkedField",
        "name": "signatures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "RetrySignatureOrderWebhookOutput",
            "kind": "LinkedField",
            "name": "retrySignatureOrderWebhook",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "invocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isWebhookInvocation"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requestBody",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responseBody",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "event",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "correlationId",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "type": "WebhookSuccessfulInvocation",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "type": "WebhookHttpErrorInvocation",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exception",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "type": "WebhookExceptionInvocation",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "responseTimeout",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "type": "WebhookTimeoutInvocation",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1056bc61925c17bfea73b78e785b7f96",
    "id": null,
    "metadata": {},
    "name": "SignaturesWebhooks_retry_Mutation",
    "operationKind": "mutation",
    "text": "mutation SignaturesWebhooks_retry_Mutation(\n  $input: RetrySignatureOrderWebhookInput!\n) {\n  signatures {\n    retrySignatureOrderWebhook(input: $input) {\n      invocation {\n        __typename\n        signatureOrderId\n        ...SignaturesWebhooks_invocation\n      }\n    }\n  }\n}\n\nfragment SignaturesWebhooks_invocation on WebhookInvocation {\n  __isWebhookInvocation: __typename\n  timestamp\n  url\n  requestBody\n  responseBody\n  event\n  correlationId\n  signatureOrderId\n  ... on WebhookSuccessfulInvocation {\n    responseStatusCode\n  }\n  ... on WebhookHttpErrorInvocation {\n    responseStatusCode\n    retryPayload\n    retryingAt\n  }\n  ... on WebhookExceptionInvocation {\n    exception\n    retryPayload\n    retryingAt\n  }\n  ... on WebhookTimeoutInvocation {\n    responseTimeout\n    retryPayload\n    retryingAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6e15c3f622bdcaabba5795ad49eb68b";

export default node;
