/**
 * @generated SignedSource<<471f20e3d120d712ec35fcb27526afd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type CreateOrganizationApiKeyInput = {
  environment: Environment;
  organizationId: string;
};
export type AddOrganizationApiKeyButtonMutation$variables = {
  input: CreateOrganizationApiKeyInput;
};
export type AddOrganizationApiKeyButtonMutation$data = {
  readonly createOrganizationApiKey: {
    readonly apiKey: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationApiKeyModal_apiKey">;
    };
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_List_organization">;
    };
  };
};
export type AddOrganizationApiKeyButtonMutation = {
  response: AddOrganizationApiKeyButtonMutation$data;
  variables: AddOrganizationApiKeyButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOrganizationApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createOrganizationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_List_organization"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationApiKeyModal_apiKey"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddOrganizationApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createOrganizationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ViewerOrganizationPermissions",
                "kind": "LinkedField",
                "name": "viewerPermissions",
                "plural": false,
                "selections": [
                  {
                    "alias": "testApi",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "environment",
                        "value": "TEST"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "api",
                    "storageKey": "api(environment:\"TEST\")"
                  },
                  {
                    "alias": "productionApi",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "environment",
                        "value": "PRODUCTION"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "api",
                    "storageKey": "api(environment:\"PRODUCTION\")"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "environment",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientSecret",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1af0f441a3ea5ca97943757fc36186cb",
    "id": null,
    "metadata": {},
    "name": "AddOrganizationApiKeyButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AddOrganizationApiKeyButtonMutation(\n  $input: CreateOrganizationApiKeyInput!\n) {\n  createOrganizationApiKey(input: $input) {\n    organization {\n      id\n      ...ApiKeys_List_organization\n    }\n    apiKey {\n      ...OrganizationApiKeyModal_apiKey\n      id\n    }\n  }\n}\n\nfragment ApiKeys_List_organization on Organization {\n  ...DeleteOrganizationApiKeyButton_organization\n  apiKeys {\n    id\n    clientId\n    environment\n    ...DeleteOrganizationApiKeyButton_apiKey\n  }\n}\n\nfragment DeleteOrganizationApiKeyButton_apiKey on OrganizationApiKey {\n  id\n  environment\n}\n\nfragment DeleteOrganizationApiKeyButton_organization on Organization {\n  id\n  viewerPermissions {\n    testApi: api(environment: TEST)\n    productionApi: api(environment: PRODUCTION)\n  }\n}\n\nfragment OrganizationApiKeyModal_apiKey on OrganizationApiKey {\n  clientId\n  clientSecret\n}\n"
  }
};
})();

(node as any).hash = "ffd4df5c5c2d5bf2f3e897159251366a";

export default node;
