/**
 * @generated SignedSource<<88666d507a6d7a3dd86de3cdd8ec9ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateDomainComponent_tenant$data = {
  readonly viewerPermissions: {
    readonly domains: Permission;
  };
  readonly " $fragmentType": "CreateDomainComponent_tenant";
};
export type CreateDomainComponent_tenant$key = {
  readonly " $data"?: CreateDomainComponent_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateDomainComponent_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "environment"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateDomainComponent_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerTenantPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "environment",
              "variableName": "environment"
            }
          ],
          "kind": "ScalarField",
          "name": "domains",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "08f50cbc6aac1690a31c2b188c3c4ea0";

export default node;
