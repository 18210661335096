import React, { useEffect, useState } from 'react';
import cx from 'classnames';

export type AlertProps = {
  variant: 'success' | 'error'
  title: string
  message?: string
  autodismiss?: number | null
  onDismiss?: () => void
  className?: string
  style?: React.CSSProperties,
  'data-test-id'?: string
}

import styles from './Alert.module.css';
export default function Alert(props: AlertProps) {
  const {variant, title, message, onDismiss, autodismiss} = props;
  const [dismissed, setDismissed] = useState(false);
  const [initial, setInitial] = useState(true);
  const handleDismiss = () => {
    setDismissed(true);
    setTimeout(() => onDismiss?.(), 500);
  };

  useEffect(() => {
    if (!autodismiss) return;
    const timeout = setTimeout(() => {
      handleDismiss();
    }, autodismiss);

    return () => clearTimeout(timeout);
  }, [autodismiss]);

  useEffect(() => {
    setInitial(false);
  }, []);

  return (
    <div
      data-test-id={props['data-test-id']}
      className={
        cx(
          styles.alert,
          styles[variant],
          {
            [styles.dismissable]: !!onDismiss,
            [styles.dismissed]: dismissed,
            [styles.initial]: initial
          },
          props.className
        )}
        style={props.style}
      >
      {onDismiss ? (
        <div className={styles.dismiss} onClick={handleDismiss}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7 5.93934L12.4697 0.46967C12.7626 0.176777 13.2374 0.176777 13.5303 0.46967C13.8232 0.762563 13.8232 1.23744 13.5303 1.53033L8.06066 7L13.5303 12.4697C13.8232 12.7626 13.8232 13.2374 13.5303 13.5303C13.2374 13.8232 12.7626 13.8232 12.4697 13.5303L7 8.06066L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#302935"/>
          </svg>
        </div>
      ) : null}
      {variant === 'success' ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#00E043"/>
          <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : variant === 'error' ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#F13A3A"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.99467 11.8337C10.544 11.8337 10.9893 11.3559 10.9893 10.7665V5.07491C10.9893 4.48552 10.544 4.00773 9.99467 4.00773C9.44533 4.00773 9 4.48552 9 5.07491V10.7665C9 11.3559 9.44533 11.8337 9.99467 11.8337ZM9 14.9328C9 15.5222 9.44533 16 9.99467 16H10.0079C10.5573 16 11.0026 15.5222 11.0026 14.9328C11.0026 14.3434 10.5573 13.8656 10.0079 13.8656H9.99467C9.44533 13.8656 9 14.3434 9 14.9328Z" fill="white"/>
        </svg>
      ) : null}
      <div>
        <strong>{title}</strong>
        {message && (<p>{message}</p>)}
      </div>
    </div>
  )
}