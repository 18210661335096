/**
 * @generated SignedSource<<4fbd0c46aa3e943e603496436f1f3cb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateNorwegianVippsInput = {
  clientId?: string | null;
  clientSecret?: string | null;
  environment: Environment;
  socialNoRequired?: boolean | null;
  tenantId: string;
};
export type NOVippsProviderMutation$variables = {
  input: UpdateNorwegianVippsInput;
};
export type NOVippsProviderMutation$data = {
  readonly updateNorwegianVipps: {
    readonly clientId: string | null;
    readonly id: string;
    readonly isConfigured: boolean;
    readonly socialNoRequired: boolean | null;
  };
};
export type NOVippsProviderMutation = {
  response: NOVippsProviderMutation$data;
  variables: NOVippsProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NorwegianVipps",
    "kind": "LinkedField",
    "name": "updateNorwegianVipps",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "socialNoRequired",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NOVippsProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NOVippsProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "feade73736fdf2630bb6c7953b524c6f",
    "id": null,
    "metadata": {},
    "name": "NOVippsProviderMutation",
    "operationKind": "mutation",
    "text": "mutation NOVippsProviderMutation(\n  $input: UpdateNorwegianVippsInput!\n) {\n  updateNorwegianVipps(input: $input) {\n    id\n    isConfigured\n    clientId\n    socialNoRequired\n  }\n}\n"
  }
};
})();

(node as any).hash = "acacac9d000f4394be595db25788c6ee";

export default node;
