import { combineReducers } from 'redux';

import environmentSlice from './environmentSlice';
import statuspageSlice from './statuspageSlice';
import modalsSlice from './modalsSlice';
import toastSlice from './toastSlice';
import apiSlice from './apiSlice';
import authSlice from './authSlice';
import { subscriptionApi } from '@app/api/subscription';

const reducer = combineReducers({
  [environmentSlice.name]: environmentSlice.reducer,
  [statuspageSlice.name]: statuspageSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [apiSlice.name]: apiSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer
});

export default reducer;