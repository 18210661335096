/**
 * @generated SignedSource<<498de7eb7eaf11069521bf3dcdcedbe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApplicationApiKeyMode = "READ_ONLY" | "READ_WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApiKeys_application$data = {
  readonly apiKeys: ReadonlyArray<{
    readonly clientId: string;
    readonly clientSecret: string | null;
    readonly id: string;
    readonly mode: ApplicationApiKeyMode;
    readonly note: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"DeleteApiKeyButton_apiKey" | "RefreshApiKeyButton_apiKey">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AddApiKeyButton_application" | "AddApiKeyForm_application" | "DeleteApiKeyButton_application" | "RefreshApiKeyButton_application">;
  readonly " $fragmentType": "ApiKeys_application";
};
export type ApiKeys_application$key = {
  readonly " $data"?: ApiKeys_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApiKeys_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiKeys_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationApiKey",
      "kind": "LinkedField",
      "name": "apiKeys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientSecret",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeleteApiKeyButton_apiKey"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshApiKeyButton_apiKey"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddApiKeyButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddApiKeyForm_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApiKeyButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefreshApiKeyButton_application"
    }
  ],
  "type": "Signatures_Application",
  "abstractKey": null
};

(node as any).hash = "bd3e95a3d84c085d36fd7555b5992b5c";

export default node;
