import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { useTenantId } from '@app/hooks/useTenant';
import useEnvironment from '@app/hooks/useEnvironment';
import { TenantFTNScreen_Query } from './__generated__/TenantFTNScreen_Query.graphql';
import useMutation from '@app/hooks/useMutation';
import { TenantFTNScreen_Mutation, UpdateFinnishTeliaInput } from './__generated__/TenantFTNScreen_Mutation.graphql';
import { Form, FormError, FormSuccess, InputField, Select } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';

type Values = Omit<UpdateFinnishTeliaInput, 'tenantId' | 'environment'>

export default function TenantFTNScreen() {
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<TenantFTNScreen_Query>(
    graphql`
      query TenantFTNScreen_Query($id: ID!, $environment: Environment!) {
        admin {
          tenant(id: $id) @required(action: THROW) {
            id
            finnishTelia(environment: $environment) {
              clientId
              callbackDomain
              teliaConnectionId
            }

            tenant {
              domains(environment: $environment) {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: tenantId.relayId,
      environment
    }
  );

  const [executor, status] = useMutation<TenantFTNScreen_Mutation>(
    graphql`
      mutation TenantFTNScreen_Mutation($input: UpdateFinnishTeliaInput!) {
        updateFinnishTelia(input: $input) {
          clientId
          callbackDomain
          teliaConnectionId
        }
      }
    `
  );

  const handleSubmit = async (values: Values) => {
    await executor.executePromise({
      input: {
        tenantId: data.admin.tenant.id,
        environment,
        ...values
      }
    });
  }

  const initialValues : Values = {
    clientId: data.admin.tenant.finnishTelia.clientId ?? '',
    callbackDomain: data.admin.tenant.finnishTelia.callbackDomain ?? data.admin.tenant.tenant.domains.edges[0]?.node.name ?? '',
    teliaConnectionId: data.admin.tenant.finnishTelia.teliaConnectionId ?? '',
  }

  return (
    <div className="max-w-[750px]">
      <h2 className="mb-2 font-bold text-[18px]">FTN</h2>
      <Form
        key={`tenant_ftn_${environment}`}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({isPending, error, isSuccess, values, setFieldValue}) => (
          <React.Fragment>
            <InputField type="text" label="Telia connection ID" name="teliaConnectionId" pattern="TVP00057-(.+)" help={<small className="help-text">Expects TVP00057-**</small>} />
            <InputField type="text" label="Client ID" name="clientId" pattern="\w{8}-\w{4}-\w{4}-\w{4}-\w{12}" help={<small className="help-text">Expects GUID</small>} />
            <Select
              name="callbackDomain"
              label="Callback domain"
              options={[{label: 'Select domain', value: ""}].concat(data.admin.tenant.tenant.domains.edges.map(edge => ({label: edge.node.name, value: edge.node.name})))}
              required
            />

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'FTN settings updated'} />
            )}
            <div className="mt-[15px]">
              <Button variant="primary" type="submit" working={isPending}>Update FTN settings</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}