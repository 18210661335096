/**
 * @generated SignedSource<<70590cb6fd1c01d8da5f511004eb39c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuditScope = "ADMIN" | "ORGANIZATION" | "TENANT" | "%future added value";
export type AuditScreen_OrganizationQuery$variables = {
  from: string;
  id: string;
  scope?: AuditScope | null;
  to: string;
};
export type AuditScreen_OrganizationQuery$data = {
  readonly admin: {
    readonly organization: {
      readonly audit: {
        readonly events: ReadonlyArray<{
          readonly event: string;
          readonly timestamp: string;
          readonly " $fragmentSpreads": FragmentRefs<"AuditScreen_Event_event">;
        }> | null;
      };
    } | null;
  };
};
export type AuditScreen_OrganizationQuery = {
  response: AuditScreen_OrganizationQuery$data;
  variables: AuditScreen_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "from"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "to"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditScreen_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuery",
                "kind": "LinkedField",
                "name": "audit",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "events",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AuditScreen_Event_event"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuditScreen_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuery",
                "kind": "LinkedField",
                "name": "audit",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "events",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isAuditEvent"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditEventUser",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sub",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "api_key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "raw",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b0a2645338212b25a7fd1769e39c99e",
    "id": null,
    "metadata": {},
    "name": "AuditScreen_OrganizationQuery",
    "operationKind": "query",
    "text": "query AuditScreen_OrganizationQuery(\n  $id: ID!\n  $from: String!\n  $to: String!\n  $scope: AuditScope\n) {\n  admin {\n    organization(id: $id) {\n      audit {\n        events(from: $from, to: $to, scope: $scope) {\n          __typename\n          event\n          timestamp\n          ...AuditScreen_Event_event\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AuditScreen_Event_event on AuditEvent {\n  __isAuditEvent: __typename\n  user {\n    sub\n    email\n  }\n  api_key\n  event\n  timestamp\n  raw\n}\n"
  }
};
})();

(node as any).hash = "e36ea6ada2723de73ccd02a56b2976c0";

export default node;
