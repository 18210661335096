/**
 * @generated SignedSource<<8ac0b598dba4f334cc0e84115a3ebf74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeleteOrganizationApiKeyButton_apiKey$data = {
  readonly environment: Environment;
  readonly id: string;
  readonly " $fragmentType": "DeleteOrganizationApiKeyButton_apiKey";
};
export type DeleteOrganizationApiKeyButton_apiKey$key = {
  readonly " $data"?: DeleteOrganizationApiKeyButton_apiKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteOrganizationApiKeyButton_apiKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteOrganizationApiKeyButton_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "environment",
      "storageKey": null
    }
  ],
  "type": "OrganizationApiKey",
  "abstractKey": null
};

(node as any).hash = "aeb6d69be2c828bc8a78ac732bd57b4a";

export default node;
