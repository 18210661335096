import React from 'react';
import { Tooltip as BsTooltip, OverlayTrigger as BsOverlayTrigger } from 'react-bootstrap';

interface Props {
  id: string;
  tooltip: string;
  children: React.ReactNode;
  placement?: string;
}

export default function TooltipTrigger(props: Props) {
  const position = props.placement ?? 'bottom';

  return (
    <BsOverlayTrigger
      placement={position}
      delayShow={350}
      delayHide={0}
      trigger={['hover', 'focus']}
      overlay={
        <BsTooltip id={`tooltip-${props.id}`}>
          {props.tooltip}
        </BsTooltip>
      }
    >
      {props.children}
    </BsOverlayTrigger>
  );
}