import React, { useEffect, useRef, useState } from 'react';
import {graphql} from 'react-relay';
import { useFragment } from 'react-relay';
import cx from 'classnames';

import useEnvironment from '@app/hooks/useEnvironment';
import { set as setEnvironment } from '@app/redux/environmentSlice';

import styles from './Dropdown.module.css';
import { EnvironmentDropdown_tenant$key } from './__generated__/EnvironmentDropdown_tenant.graphql';
import upperFirst from 'lodash/upperFirst';
import ProductionUpgradeRequiredModal from '@app/components/ProductionUpgradeRequiredModal/ProductionUpgradeRequiredModal';
import { useTenantId } from '@app/hooks/useTenant';
import { useDispatch } from '@app/redux';
import { Environment } from '@app/models';

interface Props {
  tenant: EnvironmentDropdown_tenant$key
}

export default function EnvironmentDropdown(props: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const environment = useEnvironment();
  const tenantId = useTenantId();
  const dispatch = useDispatch();
  const tenant = useFragment(
    graphql`
      fragment EnvironmentDropdown_tenant on Tenant {
        environments
        ... ProductionUpgradeRequiredModal_tenant
      }
    `,
    props.tenant
  );

  const [open, setOpen] = useState(false);
  const [showUpgradeModal, setUpgradeModal] = useState(false);

  const arrowRight = (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.34375 7.65625C8.53125 7.84375 8.53125 8.1875 8.34375 8.375L3.34375 13.375C3.15625 13.5625 2.8125 13.5625 2.625 13.375C2.4375 13.1875 2.4375 12.8438 2.625 12.6562L7.28125 8L2.625 3.375C2.4375 3.1875 2.4375 2.84375 2.625 2.65625C2.8125 2.46875 3.15625 2.46875 3.34375 2.65625L8.34375 7.65625Z" fill="#424974"/>
    </svg>
  );
  const arrowDown = (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.625 10.375L1.625 5.375C1.4375 5.1875 1.4375 4.84375 1.625 4.65625C1.8125 4.46875 2.15625 4.46875 2.34375 4.65625L7 9.3125L11.625 4.65625C11.8125 4.46875 12.1562 4.46875 12.3438 4.65625C12.5312 4.84375 12.5312 5.1875 12.3438 5.375L7.34375 10.375C7.15625 10.5625 6.8125 10.5625 6.625 10.375Z" fill="#424974"/>
    </svg>
  );

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!wrapperRef.current) return;

      const target = event.target as HTMLElement;
      if (!wrapperRef.current.contains(target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, []);

  const handleOpen = () => {
    if (!tenant.environments.includes('PRODUCTION')) {
      setUpgradeModal(true);
      return;
    }
    setOpen(open => !open);
  }

  const handleEnvironment = (environment: Environment) => {
    if (!tenantId) return;

    dispatch(setEnvironment({tenantId: tenantId!.tenantId, environment}));
    setOpen(false);
  }

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <button className={cx(styles.button, styles[environment])} onClick={handleOpen} data-test-id="environment_dropdown" data-test-current={environment}>
          <div className={styles.inner}>
            <span className={styles.text}>{upperFirst(environment.toLowerCase())}</span>
            {open ? arrowDown : arrowRight}
          </div>
        </button>
        <ul className={cx(styles.dropdown, styles[environment], {[styles.open]: open})}>
          <li className={cx(styles.item, {[styles.active]: environment === 'PRODUCTION'})} onClick={() => handleEnvironment('PRODUCTION')}>
            <div className={styles.inner}>Production</div>
          </li>
          <li className={cx(styles.item, {[styles.active]: environment === 'TEST'})} onClick={() => handleEnvironment('TEST')}>
            <div className={styles.inner}>Test</div>
          </li>
        </ul>
      </div>
      <React.Suspense fallback={null}>
        <ProductionUpgradeRequiredModal tenant={tenant} show={showUpgradeModal} onHide={() => setUpgradeModal(false)} />
      </React.Suspense>
    </>
  );
}

EnvironmentDropdown.Loader = () => {
  const environment = useEnvironment();
  return (
    <>
      <div className={styles.wrapper}>
        <button className={cx(styles.button, styles[environment])}>
          <div className={styles.inner}>
            <span>{upperFirst(environment.toLowerCase())}</span>
          </div>
        </button>
      </div>
    </>
  );
}