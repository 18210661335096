import React, { useReducer, useState } from 'react';
import useMutation from '@app/hooks/useMutation';
import {graphql} from 'react-relay';

import { ConfirmModal } from '@app/components/Modal/Modal';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import { ProductBillableMutation } from './__generated__/ProductBillableMutation.graphql';
import { useFragment } from 'react-relay';
import { ProductBillable_tenant$key } from './__generated__/ProductBillable_tenant.graphql';
import Button from '@app/components/Button/Button';
import Alert from '@app/components/Alert/Alert';

interface Props {
  product: ProductBillableMutation["variables"]["input"]["product"] | "%future added value"
  tenant: ProductBillable_tenant$key
}

export default function ProductBillable(props: Props) {
  const [showConfirm, toggleConfirm] = useReducer((value) => !value, false);

  const tenant = useFragment(graphql`
    fragment ProductBillable_tenant on AdminTenant {
      id

      billable {
        Verify {
          enabled
          before
          after
          actual
        }
        Signatures {
          enabled
          before
          after
          actual
        }
        Ageverification {
          dk {
            enabled
            before
            after
            actual
          }
        }
      }
    }
  `, props.tenant);

  if (props.product === '%future added value') return null;

  const billable = props.product === 'Ageverification_DK' ? tenant.billable.Ageverification.dk : tenant.billable[props.product];
  const [enabled, setEnabled] = useState(billable.enabled);
  const [after, setAfter] = useState(billable.after);
  const [before, setBefore] = useState(billable.before);
  const [note, setNote] = useState('');

  const [{executePromise}, state] = useMutation<ProductBillableMutation>(graphql`
    mutation ProductBillableMutation($input: UpdateBillableProductInput!) {
      admin_updateBillableProduct(input: $input) {
        tenant {
          id

          ...ProductBillable_tenant
        }
      }
    }
  `);

  const handleConfirm = () => {
    return executePromise({
      input: {
        tenantId: tenant.id,
        product: props.product as ProductBillableMutation["variables"]["input"]["product"],
        enabled,
        after,
        before,
        note: note?.length ? note : null
      }
    }).then(() => {
      toggleConfirm()
    }).catch(() => {});
  }

  return (
    <React.Fragment>
      <div className="form-group">
        <label className="control-label" htmlFor={`${props.product}_billable_enabled`}>{props.product} billable/production</label>
        <label className="switch">
          <input
            id={`${props.product}_billable_enabled`}
            onChange={(event) => setEnabled(event.target.checked)}
            type="checkbox"
            checked={enabled} /><i />
        </label>
      </div>

      <div className="flex flex-row gap-[16px]">
        <div className="form-group !mt-0">
          <label className="control-label" htmlFor={`${props.product}_billable_after`}>After</label>
          <div className="flex flex-row items-center">
            <input type="datetime-local" className="form-control h-[41px]" id={`${props.product}_billable_after`} value={getDateTimeValue(after)} onChange={(event) => setAfter(event.target.value)} />
            <Button variant="default" className="!m-0" autoWidth onClick={(() => setAfter(''))}>Clear</Button>
          </div>
        </div>
        <div className="form-group !mt-0">
          <label className="control-label" htmlFor={`${props.product}_billable_until`}>Until</label>
          <div className="flex flex-row items-center">
            <input type="datetime-local" className="form-control h-[41px]" id={`${props.product}_billable_until`} value={getDateTimeValue(before)} onChange={(event) => setBefore(event.target.value)} />
            <Button variant="default" className="!m-0" autoWidth onClick={(() => setBefore(''))}>Clear</Button>
          </div>
        </div>
      </div>
      <div>
        Actual: {billable.actual ? 'Enabled' : 'Disabled'}
      </div>

      <Button type="button" variant="primary" onClick={() => toggleConfirm()} style={{marginTop: '15px'}}>Save {props.product}</Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={() => toggleConfirm()}
        onConfirm={handleConfirm}
        confirmText={`Change billable for ${props.product}`}
        confirmVariant="primary"
        data-test-id="confirm-modal"
      >

        <div>
          <h2>{`Change billable for ${props.product}`}</h2>
          <p>Are you sure you want to {enabled ? 'enable' : 'disable'} {props.product} {(before && enabled) ? `(until ${before})` : null}</p>
        </div>

        <Textarea className='mt-[15px] !h-[100px] resize-none' name='note-text' label='Note:' value={note} onChange={event => setNote(event.target.value)}/>

        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        )}

      </ConfirmModal>
    </React.Fragment>
  );
}

function getDateTimeValue(input: string) {
  if (!input) return input;
  const date = new Date(input);
  var isoString = date.toISOString();
  return isoString.substring(0, (isoString.indexOf("T")|0) + 6|0);
}
