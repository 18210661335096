import React from "react";
import {graphql} from 'react-relay';
import { useFragment, useLazyLoadQuery } from "react-relay";
import { OrganizationUsersScreenQuery } from "./__generated__/OrganizationUsersScreenQuery.graphql";
import Button from "@app/components/Button/Button";
import { Form, FormError, FormSuccess, InputField } from "@app/components/Form/Form";
import useMutation from "@app/hooks/useMutation";
import { OrganizationUsersScreen_AddForm_organization$key } from "./__generated__/OrganizationUsersScreen_AddForm_organization.graphql";
import { OrganizationUsersScreen_AddUser_Mutation } from "./__generated__/OrganizationUsersScreen_AddUser_Mutation.graphql";
import { FormikHelpers } from "formik";
import { getRelayOrganizationUsersConnections } from "@app/screens/DomainsScreen/utils";

export default function OrganizationUsersScreen(props: {
  organizationId: string
}) {
  const data = useLazyLoadQuery<OrganizationUsersScreenQuery>(
    graphql`
      query OrganizationUsersScreenQuery($id: ID!) {
        organization(id: $id) @required(action: THROW) {
          id
          ...OrganizationUsersScreen_AddForm_organization
          users(first: 1000) @connection(key: "organization_users") {
            edges {
              node {
                id
                sub
                email
                name
              }
            }
          }
        }
      }
    `,
    {
      id: props.organizationId
    }
  );

  return (
    <div className="max-w-[1250px]">
      <p>
        Organization users will have access to all tenants inside the organization, use sparingly.
      </p>
      <div className="accordion">
        <div className="accordion-header">
          <div className="row">
            <div className="col-xs-6">
              <h3>ID</h3>
            </div>
            <div className="col-xs-3">
              <h3>Email</h3>
            </div>
            <div className="col-xs-3">
              <h3>Name</h3>
            </div>
          </div>
        </div>
        {data.organization.users.edges.map(({node: user}) => (
          <div className="accordion-item" key={user.id}>
            <div className="accordion-header">
              <div className="row">
                <div className="col-xs-6">
                  <strong>{user.sub}</strong>
                </div>
                <div className="col-xs-3">
                  {user.email}
                </div>
                <div className="col-xs-3">
                  {user.name}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="accordion-item ">
        <div className="accordion-header large mt-[25px]">
          <AddForm organization={data.organization}/>
        </div>
      </div>
    </div>
  )
}

type AddUserValues = {
  sub: string
  name: string | null
  email: string | null
}
export function AddForm(props: {
  organization: OrganizationUsersScreen_AddForm_organization$key,
}) {
  const organization = useFragment(graphql`
    fragment OrganizationUsersScreen_AddForm_organization on Organization {
      id
    }
  `, props.organization);

  const [addUserExecutor, addUserState] = useMutation<OrganizationUsersScreen_AddUser_Mutation>(graphql`
    mutation OrganizationUsersScreen_AddUser_Mutation(
      $connections: [ID!]!
      $input: AddOrganizationUserInput!
    ) {
      admin_addOrganizationUser(input: $input) {
        userEdge @appendEdge(connections: $connections) {
          cursor
          node {
            id
            sub
            name
            email
          }
        }
      }
    }
  `);

  const handleSubmit = async (values: AddUserValues, actions: FormikHelpers<any>) => {
    await addUserExecutor.executePromise({
      input: {
        organizationId: organization.id,
        sub: values.sub,
        email: values.email?.length ? values.email : null,
        name: values.name?.length ? values.name : null,
      },
      connections: getRelayOrganizationUsersConnections(organization.id)
    });

    actions.resetForm();

  };

  return (
    <Form<AddUserValues>
      initialValues={{name: '', email: '', sub: ''}}
      onSubmit={handleSubmit}
      key="add_organization_user"
      style={{width: '100%'}}
    >
      {({isPending, error, isSuccess, values, setValues}) => (
        <React.Fragment>
          <div className="flex flex-col lg:flex-row gap-[10px]">
            <InputField
              type="text"
              name="sub"
              required
              placeholder={"Auth0 user id"}
            />
            <InputField
              type="email"
              name="email"
              required
              placeholder={"Email"}
            />
            <InputField
              type="text"
              name="name"
              required
              placeholder={"Name"}
            />
            <Button variant="primary" type="submit" working={isPending}>Add user</Button>
          </div>

          {error && (
            <FormError error={error} />
          )}
          {isSuccess && (
            <FormSuccess message={'User added!'} />
          )}
        </React.Fragment>
      )}
    </Form>
  )
}