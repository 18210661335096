/**
 * @generated SignedSource<<d2ecbd1f63793002ac3e677e4b6f28b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Users_OrganizationUser_Organization$data = {
  readonly allPermissions: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly viewerPermissions: {
    readonly users: Permission;
  };
  readonly " $fragmentType": "Users_OrganizationUser_Organization";
};
export type Users_OrganizationUser_Organization$key = {
  readonly " $data"?: Users_OrganizationUser_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Users_OrganizationUser_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Users_OrganizationUser_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUserPermission",
      "kind": "LinkedField",
      "name": "allPermissions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerOrganizationPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "users",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3f8613e453a1f1db001db72dd0373b9c";

export default node;
