import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';


import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess} from '@components/Form';
import EnvironmentDialog from '../EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { DESofortProviderQuery } from './__generated__/DESofortProviderQuery.graphql';
import { DESofortProviderMutation } from './__generated__/DESofortProviderMutation.graphql';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}

export interface FormValues {
  userId : string
  projectId : string
  projectPassword : string
}

export function DESofortProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<DESofortProviderQuery>(
    graphql`
      query DESofortProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on GermanSofort {
            userId
            projectId
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
    }
  `, {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|DE_SOFORT`),
    tenantId: tenant.relayId,
    environment: props.environment
  });


  const [{executePromise}] = useMutation<DESofortProviderMutation>(graphql`
    mutation DESofortProviderMutation(
      $input: UpdateGermanSofortInput!
    ) {
      updateGermanSofort(input: $input) {
        isConfigured
        id
        userId
        projectId
      }
    }
  `);

  if (!data.identityProvider) return null;

  const initialValues : FormValues = {
    userId: data.identityProvider.userId ?? '',
    projectId: data.identityProvider.projectId ?? '',
    projectPassword: ''
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, isSuccess, error}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p>⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_DE_SOFORT_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          {!initialValues.userId ? (
            <EnvironmentDialog environment="PRODUCTION" heading={(
              <React.Fragment>
                <h1>
                  <strong>{translate('INFO_DE_SOFORT_PROJECT')}</strong>
                </h1>
                <br />
                <h3>{translate('INFO_DE_SOFORT_CALLBACK_URL_PROD')}</h3>
              </React.Fragment>
            )}>
              {domains?.map(domain => (
                <p key={domain.name}>
                  https://{domain.name}/DESofort/Callback?state=-USER_VARIABLE_0_URLENCODE-
                </p>
              ))}
            </EnvironmentDialog>
          ) : null}
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {data.identityProvider!.longName} Identification
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_DE_SOFORT_USERID')}
                name="userId"
                placeholder={translate('LABEL_DE_SOFORT_USERID')}
              />
              <InputField
                type="text"
                label={translate('LABEL_DE_SOFORT_PROJECTID')}
                name="projectId"
                placeholder={translate('LABEL_DE_SOFORT_PROJECTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_DE_SOFORT_PROJECTPASSWORD')}
                name="projectPassword"
                placeholder={translate('LABEL_DE_SOFORT_PROJECTPASSWORD')}
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your german sofort settings have been updated`} />

              <div className="flex flex-row mt-6 mb-6 justify-end">
                <Button variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
              </div>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_DE_SOFORT_PROJECT')}</p>
              <p>{translate(isProduction ? 'INFO_DE_SOFORT_CALLBACK_URL_PROD': 'INFO_DE_SOFORT_CALLBACK_URL_TEST')}</p>
              {domains?.map(domain => (
                <p key={domain.name}>
                  https://{domain.name}/DESofort/Callback?state=-USER_VARIABLE_0_URLENCODE-
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
