import React, { useState } from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import { ConfirmModal } from '@app/components/Modal';

import { RefreshApiKeyButton_apiKey$key } from './__generated__/RefreshApiKeyButton_apiKey.graphql';
import { RefreshApiKeyButton_application$key } from './__generated__/RefreshApiKeyButton_application.graphql';
import { RefreshApiKeyButtonMutation } from './__generated__/RefreshApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';
import ApiKeyModal from '../ApiKeyModal/ApiKeyModal';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

interface Props {
  apiKey: RefreshApiKeyButton_apiKey$key;
  application: RefreshApiKeyButton_application$key;
}

export default function RefreshApiKeyButton(props: Props) {
  const toast = useToast();
  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const apiKey = useFragment(
    graphql`
      fragment RefreshApiKeyButton_apiKey on ApplicationApiKey {
        id
      }
    `,
    props.apiKey
  );

  const application = useFragment(
    graphql`
      fragment RefreshApiKeyButton_application on Signatures_Application {
        id
      }
    `,
    props.application
  );

  const [refreshMutationExecutor, refreshMutationState] = useMutation<RefreshApiKeyButtonMutation>(graphql`
    mutation RefreshApiKeyButtonMutation($input: RefreshApplicationApiKeyInput!) {
      refreshSignaturesApplicationApiKey(input: $input) {
        application {
          id
          ...ApiKeys_application
        }

        apiKey {
          ...ApiKeyModal_apiKey
        }
      }
    }
  `);

  const handleClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    return refreshMutationExecutor.executePromise({
        input: {
          applicationId: application.id,
          apiKeyId: apiKey.id,
        },
      })
      .then(() => {
        setShowConfirm(false);
        setShowApiKeySecret(true);
        toast.success({ title: 'API key renewed' });
      }).catch(() => {});
  };

  return (
    <React.Fragment>
      <Button variant="primary" className="button-small" autoWidth onClick={handleClick} data-test-id="button">
        <i className="fa fa-redo" />
      </Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        confirmText={"Renew API key"}
        data-test-id="confirm-modal"
      >
        <div>
          <h2>Renew API key</h2>
          <p className='!mt-0 !mb-0'>Are you sure you want to renew this API key? Any calls using the old client secret will start to fail.</p>
        </div>
        {refreshMutationState.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={refreshMutationState.error.message} data-test-id="error" />
        )}
      </ConfirmModal>

      <ApiKeyModal
        open={showApiKeySecret}
        onHide={() => setShowApiKeySecret(false)}
        apiKey={refreshMutationState.response?.refreshSignaturesApplicationApiKey!.apiKey!}
        data-test-id="modal"
      />
    </React.Fragment>
  );
}