import moment from 'moment';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import BootstrapOverlay from 'react-bootstrap/lib/Overlay';

import { DimensionsResponse } from '@app/api/subscription';
import Button from '@app/components/Button/Button';

import PRESETS from '../../presets';
import { DateSelection } from '../../constants';

import './DateRangePickerButton.css';

interface Props {
  selected: DateSelection,
  dimensions?: DimensionsResponse,
  onChange: (selection: DateSelection) => void
}

const today = moment().endOf('day');

type Selection = {
  from: moment.Moment,
  to: moment.Moment
};

export default function DateRangePickerButton(props: Props) {
  const {selected, onChange, dimensions} = props;
  const presetsButtonRef = useRef<HTMLButtonElement>(null);
  const presetsContainerRef = useRef<HTMLDivElement>(null);

  const [showCalendarOverlay, setShowCalendarOverlay] = useState(false);
  const hideCalendarOverlay = () => setShowCalendarOverlay(false);
  const toggleCalendarOverlay = () => setShowCalendarOverlay(value => !value);

  const [showPresetsOverlay, setShowPresetsOverlay] = useState(false);
  const hidePresetsOverlay = () => {
      setShowPresetsOverlay(false);
      hideCalendarOverlay();
  };
  const togglePresetsOverlay = () => setShowPresetsOverlay(value => !value);

  const reset = () => {
    hidePresetsOverlay();
    hideCalendarOverlay();
  };

  const handleRangeSelected = (range: {from: moment.Moment, to: moment.Moment}) => {
    reset();

    onChange({
      from: range.from.clone().startOf('day'),
      to: range.to.clone().endOf('day')
    });
  };

  const handlePresetSelected = (preset: DateSelection) => {
    reset();
    onChange(preset);
  };

  const [selection, setSelection] = useState<Selection>({
    from: selected.from,
    to: selected.to
  });

  useEffect(() => {
    setSelection({
      from: selected.from,
      to: selected.to
    });
  }, [selected]);

  return (
    <div className="date-range-picker-wrapper" ref={presetsContainerRef}>
      <Button variant="default" autoWidth onClick={togglePresetsOverlay} buttonRef={presetsButtonRef}>
        <i className="fa fa-calendar" />&nbsp;
        {selected.preset ? selected.preset : `${selected.from.format('MMM D')} - ${selected.to.format('MMM D')}`}
        &#9660;
      </Button>
      <BootstrapOverlay
        show={showPresetsOverlay}
        onHide={hidePresetsOverlay}
        rootClose
        placement="bottom"
        container={presetsContainerRef.current}
        target={() => ReactDOM.findDOMNode(presetsButtonRef.current)}
      >
        <div>
          <div className="date-range-picker-overlay">
            <ul>
              {PRESETS.map(preset => (
                <li key={preset.preset} onClick={() => handlePresetSelected(preset)} className={cx({selected: selected.preset === preset.preset})}>
                  {preset.preset}
                </li>
              ))}
              <li onClick={toggleCalendarOverlay} className={cx({selected: showCalendarOverlay})}>Select a date range ...</li>
            </ul>
          </div>
        </div>
      </BootstrapOverlay>
      {showCalendarOverlay && (
        <div className="date-range-calendar-overlay">
          <label
            onClick={(event) => event.stopPropagation()}
          >
            From:&nbsp;
            <input type="date"
              min={dimensions ? moment(dimensions.from).format("YYYY-MM-DD") : undefined}
              max={today.format("YYYY-MM-DD")}
              value={selection.from?.format('YYYY-MM-DD')}
              onChange={(event) => setSelection(selection => ({...selection, from: moment(event?.target.value)}))}
              onClick={(event) => event.stopPropagation()}
            />
          </label>

          <label
            onClick={(event) => event.stopPropagation()}
          >
            To:&nbsp;
            <input type="date"
              min={dimensions ? moment(dimensions.from).format("YYYY-MM-DD") : undefined}
              max={today.format("YYYY-MM-DD")}
              value={selection.to?.format('YYYY-MM-DD')}
              onChange={(event) => setSelection(selection => ({...selection, to: moment(event?.target.value)}))}
              onClick={(event) => event.stopPropagation()}
            />
          </label>

          <Button variant="primary" onClick={() => handleRangeSelected(selection)}>Select date range</Button>
        </div>
      )}
    </div>
  );
}