/**
 * @generated SignedSource<<a9593b0079d0b64d672508ad63c105d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateGermanSofortInput = {
  environment: Environment;
  projectId?: string | null;
  projectPassword?: string | null;
  tenantId: string;
  userId?: string | null;
};
export type DESofortProviderMutation$variables = {
  input: UpdateGermanSofortInput;
};
export type DESofortProviderMutation$data = {
  readonly updateGermanSofort: {
    readonly id: string;
    readonly isConfigured: boolean;
    readonly projectId: string | null;
    readonly userId: string | null;
  };
};
export type DESofortProviderMutation = {
  response: DESofortProviderMutation$data;
  variables: DESofortProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GermanSofort",
    "kind": "LinkedField",
    "name": "updateGermanSofort",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DESofortProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DESofortProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4d63b6f76290fa673d2f3b687807afed",
    "id": null,
    "metadata": {},
    "name": "DESofortProviderMutation",
    "operationKind": "mutation",
    "text": "mutation DESofortProviderMutation(\n  $input: UpdateGermanSofortInput!\n) {\n  updateGermanSofort(input: $input) {\n    isConfigured\n    id\n    userId\n    projectId\n  }\n}\n"
  }
};
})();

(node as any).hash = "320058860df1dc0efc26ee6253cef518";

export default node;
