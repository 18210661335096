/**
 * @generated SignedSource<<7ec762e7b82addb15f1600c3d2da6960>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditVerifyApplicationScreen_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"IdentityProvidersSection_tenant">;
  readonly " $fragmentType": "EditVerifyApplicationScreen_tenant";
};
export type EditVerifyApplicationScreen_tenant$key = {
  readonly " $data"?: EditVerifyApplicationScreen_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditVerifyApplicationScreen_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditVerifyApplicationScreen_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentityProvidersSection_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "76d27d23a667b1efb4d3d8e6322f4ed8";

export default node;
