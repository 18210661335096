import { Application } from '@app/models';
import React from 'react';

export type OidcDiscovery = {
  authorization_endpoint: string
}
type IApplication = {
  returnUrls: string[]
  domain: {name: string}
  realm: string
} | {
  callbackUrls: readonly string[]
  domain: {name: string}
  realm: string
}
export function authorizeUrl(application: IApplication, acr_values: string | string[] | undefined, discovery?: OidcDiscovery) {
  const returnUrls = "returnUrls" in application ? application?.returnUrls.filter(s => !s.endsWith('/qr/')) : application?.callbackUrls.filter(s => !s.endsWith('/qr/'));
  const redirectUri = returnUrls.find(search => search.includes('jwt.io')) || returnUrls[0];
  const base = discovery?.authorization_endpoint ?? `https://${application.domain.name}/oauth2/authorize`;

  const nonce = (window?.crypto as any)?.randomUUID !== undefined ? (window.crypto as any).randomUUID() : Math.random().toString();

  const url = new URL(`${base}?scope=openid&response_type=id_token&response_mode=fragment`);
  url.searchParams.append('nonce', `ecnon-${nonce}`);
  url.searchParams.append('redirect_uri', redirectUri);
  url.searchParams.append('client_id', application.realm);
  if (acr_values) {
    if (Array.isArray(acr_values)) {
      if (acr_values.length) {
        url.searchParams.append('acr_values', acr_values.join(' '));
      }
    } else {
      url.searchParams.append('acr_values', acr_values);
    }
  }
  url.searchParams.append('criipto_sdk', 'dashboard');

  return url;
}

// Remove all spaces (also inside string) and then lowercase
export const trimDomainInput = (input: string): string => input.replace(/ /g, '').toLowerCase();
export const sanitizeDomainInput = (input: string, domainType: "CRIIPTO" | "CLIENT"): string => {
  const trimmed = trimDomainInput(input);

  switch (domainType) {
    // For top-level domains we try to capture everything in-between the protocol and a potential path
    // and use that as the domain, but in cases where the domain doesn't match the pattern, we allow the raw input
    case 'CLIENT': {
      const regex = /.+:\/\/([^/]+)\/?/;
      const [,matchedDomain = ""] = regex.exec(trimmed) ?? [];
      return matchedDomain !== "" ? matchedDomain : trimmed;
    }
    // For subdomains, the only thing we prevent being typed is "." to prevent "subdomains-of-subdomains"
    case 'CRIIPTO': { return trimmed.replace(/[.]/g, '') }
    default: { return assertUnreachable(domainType) }
  }
}
export function validateDomainPrefix(value: string, suffix: string) {
  value = trimDomainInput(value);
  if (value.match(/^\d+$/)) {
    return 'A host name (label) MUST NOT consist of all numeric values';
  }
  if (value.substr(0, 1) === '-' || value.substr(-1, 1) === '-') {
    return 'A host name (label) MUST NOT start or end with a \'-\' (dash)';
  }
  if (value.length < 2) {
    return 'A host name (label) MUST be at least 2 characters';
  }
  if ((value + suffix).length > 63) {
    return 'A host name (label) can be up to 63 characters';
  }
  if (value.includes('.')) {
    return 'A host name (label) MUST NOT include a \'.\' (dot)';
  }
  if (!value.match(/^[\w\d][\w\d\-]*[\w\d]$/)) {
    return 'A host name (label) can start or end with a letter or a number';
  }
}

export const fileToBase64 = (file: File) : Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

function assertUnreachable(domainType: never): never {
  throw new Error(`Encountered unexpected domain type "${domainType}"`);
}
