import React, { useContext, useMemo } from 'react';
import { useRouteMatch } from "react-router-dom";
import { TENANT_ID_PREFIX } from "@app/constants";

export interface OptionalTenantRouteParams {
  tenantId: string | undefined
}

export type TenantId = {
  tenantId: string
  relayId: string
  admin?: boolean
}

export const TenantContext = React.createContext<null | TenantId>(null);

const LOCALHOST_URN = 'urn:grn:development:localhost';

export function toShortTenantId(input: string) {
  if (input === LOCALHOST_URN) return input;
  if (input.startsWith(TENANT_ID_PREFIX)) return input.replace(TENANT_ID_PREFIX, '');
  return input;
}

export function toLongTenantId(input: string) {
  if (input === LOCALHOST_URN) return input;
  if (input.startsWith(TENANT_ID_PREFIX)) return input;
  return TENANT_ID_PREFIX + input;
}

export function useTenantId() : TenantId {
  const match = useRouteMatch<OptionalTenantRouteParams>();
  const contextTenant = useContext(TenantContext);
  if (!match.params.tenantId) throw new Error('useTenantId() called in a non-tenant context');

  return useMemo(() => {
    if (contextTenant) return contextTenant;
    if (!match.params.tenantId) throw new Error('useTenantId() called in a non-tenant context');
    return {tenantId: match.params.tenantId, relayId: btoa(`tenant:${match.params.tenantId}`)};
  }, [match.params.tenantId, contextTenant]);
}

export function useOptionalTenantId() : TenantId | null {
  const match = useRouteMatch<OptionalTenantRouteParams>();
  const contextTenant = useContext(TenantContext);

  return useMemo(() => {
    if (contextTenant) return contextTenant;
    if (!match.params.tenantId) return null;
    return {tenantId: match.params.tenantId, relayId: btoa(`tenant:${match.params.tenantId}`)};
  }, [match.params.tenantId, contextTenant]);
}

export function useAdmin() : boolean {
  const contextTenant = useContext(TenantContext);
  return contextTenant?.admin ?? false;
}