/**
 * @generated SignedSource<<f1d5aa79fc091bfae6af78db31225827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateDutchiDINBluemInput = {
  brandId?: string | null;
  defaultProfile?: string | null;
  environment: Environment;
  senderId?: string | null;
  tenantId: string;
};
export type TenantiDINScreen_Mutation$variables = {
  input: UpdateDutchiDINBluemInput;
};
export type TenantiDINScreen_Mutation$data = {
  readonly updateDutchiDINBluem: {
    readonly brandId: string | null;
    readonly defaultProfile: string | null;
    readonly senderId: string | null;
  };
};
export type TenantiDINScreen_Mutation = {
  response: TenantiDINScreen_Mutation$data;
  variables: TenantiDINScreen_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "senderId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultProfile",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantiDINScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DutchiDINBluem",
        "kind": "LinkedField",
        "name": "updateDutchiDINBluem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantiDINScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DutchiDINBluem",
        "kind": "LinkedField",
        "name": "updateDutchiDINBluem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b0f12d34dbe48cc8d39f593508b696a",
    "id": null,
    "metadata": {},
    "name": "TenantiDINScreen_Mutation",
    "operationKind": "mutation",
    "text": "mutation TenantiDINScreen_Mutation(\n  $input: UpdateDutchiDINBluemInput!\n) {\n  updateDutchiDINBluem(input: $input) {\n    senderId\n    brandId\n    defaultProfile\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd8236ea7238435688677e25dcc53e27";

export default node;
