/**
 * @generated SignedSource<<619dabbcb73e37e3d8c849af6880b9c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationSettingsScreen_Query$variables = {
  id: string;
};
export type OrganizationSettingsScreen_Query$data = {
  readonly admin: {
    readonly organization: {
      readonly id: string;
      readonly kyc: {
        readonly companyName: string;
        readonly vatCountry: string;
        readonly vatNumber: string;
      } | null;
      readonly organization: {
        readonly name: string;
      };
      readonly settings: {
        readonly automatic_production_access_signatures: boolean;
        readonly automatic_production_access_verify: boolean;
      };
    };
  };
};
export type OrganizationSettingsScreen_Query = {
  response: OrganizationSettingsScreen_Query$data;
  variables: OrganizationSettingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminOrganizationSettings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automatic_production_access_verify",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automatic_production_access_signatures",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationKYC",
  "kind": "LinkedField",
  "name": "kyc",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": (v1/*: any*/),
              "concreteType": "AdminOrganization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "admin.organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3730f1506bf99eafbb5caa573267b330",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsScreen_Query",
    "operationKind": "query",
    "text": "query OrganizationSettingsScreen_Query(\n  $id: ID!\n) {\n  admin {\n    organization(id: $id) {\n      id\n      organization {\n        name\n        id\n      }\n      settings {\n        automatic_production_access_verify\n        automatic_production_access_signatures\n      }\n      kyc {\n        companyName\n        vatCountry\n        vatNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "856e41fbd111c15515ca5b29dcc2bfc3";

export default node;
