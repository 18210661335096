/**
 * @generated SignedSource<<f6c8c439298b50363d12ec8d09e643ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeleteDomainButton_domain$data = {
  readonly environment: Environment;
  readonly id: string;
  readonly name: string;
  readonly tenant: {
    readonly id: string;
  };
  readonly " $fragmentType": "DeleteDomainButton_domain";
};
export type DeleteDomainButton_domain$key = {
  readonly " $data"?: DeleteDomainButton_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteDomainButton_domain">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteDomainButton_domain",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "environment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
})();

(node as any).hash = "db7624a7bbc1b4c18596a8669608e352";

export default node;
