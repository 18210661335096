import { useSelector } from "@app/redux";
import jwtDecode from 'jwt-decode';
import { VerifyAuthToken } from '@app/redux/authSlice';
import { useMemo } from "react";

export function useVerifyAccessToken() {
  return useSelector(state => state.auth.access_token);
}

export function useVerifyClaims() {
  const token = useVerifyAccessToken();
  return useMemo(() => token !== null ? jwtDecode<VerifyAuthToken>(token) : null, [token]);
}

export function useIdToken() {
  return useSelector(state => state.auth.id_token);
}

export function useProfile() {
  return useSelector(state => state.auth.profile);
}