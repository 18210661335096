import React, {useEffect, useState} from 'react';
import { useField } from 'formik';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import { ApplicationRealmValidation_application$key } from './__generated__/ApplicationRealmValidation_application.graphql';

import { ApplicationRealmValidationQuery } from './__generated__/ApplicationRealmValidationQuery.graphql';

type RelayProps = {
  realm?: string
  application?: ApplicationRealmValidation_application$key,
  domainId: string,
  onValid: (valid : boolean) => void
}
export default function ApplicationRealmValidation(props : RelayProps) {
  const application = useFragment(graphql`
    fragment ApplicationRealmValidation_application on Application {
      id
      realm
    }
  `, props.application ?? null);
  const sourceRealm = application?.realm ?? props.realm;

  const query = useLazyLoadQuery<ApplicationRealmValidationQuery>(
    graphql`
      query ApplicationRealmValidationQuery($id: ID!) {
        domain(id: $id) {
          applications(first: 1000) @connection(key: "domain_applications") {
            edges {
              node {
                id
                realm
              }
            }
          }
        }
      }
    `,
    {
      id: props.domainId
    }
  );
  const domain = query.domain!;

  const [isValid, setValid] = useState<boolean |undefined>();
  const [{value}] = useField('realm');
  const applications = domain.applications.edges.map(edge => edge.node);

  useEffect(() => {
    const otherApplications = application ? applications.filter(search => search.id !== application.id) : applications;
    setValid(!otherApplications.some(s => s.realm === sourceRealm));
  }, [value, domain, applications]);

  useEffect(() => {
    if (isValid === undefined) return;
    props.onValid(isValid);
  }, [isValid]);

  if (!value) return <React.Fragment />;
  if (!domain) return <React.Fragment />;

  return (
    <>
      {isValid ? (
        <i className="fa fa-check" />
      ) : (
        <React.Fragment>
          <i className="fa fa-times" />
        </React.Fragment>
      )}
    </>
  )
}