/**
 * @generated SignedSource<<623f53efe6692b61a940fa235f0feef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type SignupTenantScreenQuery$variables = {};
export type SignupTenantScreenQuery$data = {
  readonly viewer: {
    readonly organizations?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly viewerPermissions: {
        readonly tenants: Permission;
      };
    }>;
  };
};
export type SignupTenantScreenQuery = {
  response: SignupTenantScreenQuery$data;
  variables: SignupTenantScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organizations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ViewerOrganizationPermissions",
          "kind": "LinkedField",
          "name": "viewerPermissions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tenants",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupTenantScreenQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "viewer"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignupTenantScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb9183214c0f651e1e5e3183684bf4b6",
    "id": null,
    "metadata": {},
    "name": "SignupTenantScreenQuery",
    "operationKind": "query",
    "text": "query SignupTenantScreenQuery {\n  viewer {\n    __typename\n    ... on UserViewer {\n      organizations {\n        id\n        name\n        viewerPermissions {\n          tenants\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3fe046d6dd5112dec54961303bdcf4a";

export default node;
