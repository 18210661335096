
export function getProviderLanguages(provider: 'SwedishBankID' | 'DanishMitID' | 'FinnishTelia' | 'AuthMethodSelector') {
  if (provider === 'SwedishBankID') {
    return ['sv', 'en'];
  }
  if (provider === 'DanishMitID') {
    return ['da', 'en'];
  }
  if (provider === 'FinnishTelia') {
    return ['fi', 'en'];
  }
  if (provider === 'AuthMethodSelector') {
    return ['da', 'sv', 'no', 'en'];
  }
  assertUnreachable(provider);
}

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export type MONACO_EDITOR_EVENT =
  {event: 'mounted'} |
  {event: 'save'} |
  {event: 'change', value: string | undefined} |
  {event: 'props', props: MONACO_EDITOR_PROPS};
export interface MONACO_EDITOR_PROPS {
  readOnly: boolean,
  defaultLanguage: 'html' | 'css',
  path: string
  defaultValue: string,
  value: string | undefined
};