import React, {useState} from 'react';
import { useField } from 'formik';

import { Application } from '@app/models';
import { translate } from '@app/i18n';
import InputField from '@app/components/FormFields/InputField/InputField';
import Button from '@app/components/Button';
import TagGroup from '@app/components/FormFields/TagGroup/TagGroup';
import Tag from '@app/components/FormFields/TagGroup/Tag';

function isValidURL(input: string) {
  return /^.+\:\/\/.+$/.test(input); // very rudimentary check that url must contain [before]://[after]
}

interface Props {
  unremoveableUrls?: string[];
}

export default function ReturnURLs(props: Props) {
  const [{value: callbackUrls}, , {setValue}] = useField<Application['returnUrls']>('returnUrls');
  const [addText, setAddText] = useState('');
  const unremoveableUrls = props.unremoveableUrls ?? [];

  const handleAddClick = (event: React.FormEvent) => {
    if (!addText || !isValidURL(addText)) return;
    setValue(callbackUrls.concat(addText));
    setAddText('');
  };

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (!addText || !isValidURL(addText)) return;
      setValue(callbackUrls.concat(addText));
      setAddText('');
      return;
    }
  };
  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');
    const segments = text.split(/\n|,|;/g).map(s => s.trim());
    const invalid = segments.filter(s => !isValidURL(s));
    const valid = segments.filter(s => isValidURL(s));

    if (invalid.length) setAddText(invalid[0]);
    setValue(callbackUrls.concat(valid));
  };

  const handleRemove = (index: number) => {
    const newValue = callbackUrls.slice(0);
    newValue.splice(index, 1);
    setValue(newValue);
  };

  return (
    <div>
      <TagGroup header={translate('LABEL_CALLBACK_URLS')}>
        {callbackUrls.map((url, index) => (
          <Tag
            key={index}
            tag={url}
            onDelete={!unremoveableUrls.includes(url) ? () => handleRemove(index) : undefined}
          />
        ))}
      </TagGroup>
      <InputField
        type="text"
        name="CallbackUrlsInput"
        id={translate('LABEL_CALLBACK_URLS')}
        placeholder="Add callback URL"
        value={addText}
        onChange={(event) => {
          setAddText(event.target.value)
        }}
        onKeyDown={handleKeydown}
        onKeyUp={handleKeyUp}
        onPaste={handlePaste}
        button={
          <Button type="button" variant="primary" onClick={handleAddClick}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.5 1V6.5H13C13.25 6.5 13.5 6.75 13.5 7C13.5 7.28125 13.25 7.5 13 7.5H7.5V13C7.5 13.2812 7.25 13.5 7 13.5C6.71875 13.5 6.5 13.2812 6.5 13V7.5H1C0.71875 7.5 0.5 7.28125 0.5 7C0.5 6.75 0.71875 6.5 1 6.5H6.5V1C6.5 0.75 6.71875 0.5 7 0.5C7.25 0.5 7.5 0.75 7.5 1Z"
                fill="white"
              />
            </svg>
          </Button>
        }
        helpText={'Hint: You can paste a multi-line string to automatically add multiple URLs. Comma and semicolon seperated strings also work.'}
        error={addText && !isValidURL(addText) ? 'Not a valid URL' : undefined}
      />
    </div>
  );
}
