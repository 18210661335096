import React, { useCallback, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Button, {ButtonProps} from '../Button/Button';
import useMounted from '@app/hooks/useMounted';
import { translate } from '@app/i18n';
import cx from 'classnames';

import styles from './Modal.module.css';

export interface ModalProps {
  open: boolean
  onHide?: () => void
  className?: string
  'data-test-id'?: string
  children: React.ReactNode
  centered?: boolean
}

export function Modal(props: ModalProps) {
  const {open, onHide} = props;
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      modalRef.current.showModal();
    } else {
      modalRef.current.close();
    }
  }, [open]);

  const handleClose = useCallback(() => {
    modalRef.current?.close();
    if (onHide) onHide();
  }, [onHide]);

  const modalContent = (
    <dialog data-test-id={props['data-test-id']} ref={modalRef} className={cx(styles.dialog)} onClick={e => {
      if (e.target === modalRef.current && onHide) {
        handleClose();
      }
    }}>
      <div className={cx('modal-context', styles.modal, props.className, {[styles['with-close']]: !!onHide})}>
        {onHide ? (
          <button className={styles.close} onClick={handleClose}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.84375 1.15625L8 7.3125L14.125 1.15625L14.5 0.8125L15.1875 1.5L14.8438 1.875L8.6875 8L14.8438 14.1562L15.1875 14.5L14.5 15.2188L14.125 14.875L8 8.71875L1.84375 14.875L1.5 15.2188L0.78125 14.5L1.125 14.1562L7.28125 8L1.125 1.875L0.78125 1.5L1.5 0.8125L1.84375 1.15625Z" fill="#6E75A5"/>
            </svg>
          </button>
        ) : null}

        {props.children}
      </div>
    </dialog>
  )

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal_portal')!
  );
}
export default Modal;

Modal.Header = (props: {
  children: React.ReactNode
  className?: string
  centered?: boolean
}) => {
  return (
    <div className={cx(styles.header, props.className, {[styles['centered']]: props.centered})}>
      {props.children}
    </div>
  );
}

Modal.Content = (props: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={cx(styles.content, props.className)}>
      {props.children}
    </div>
  );
}

Modal.Actions = (props: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={cx(styles.actions, props.className)}>
      {props.children}
    </div>
  );
}

export interface ConfirmModalProps extends ModalProps {
  onConfirm: () => Promise<void> | void
  onCancel: () => void
  cancelText?: string
  confirmText: string
  confirmVariant?: ButtonProps["variant"]
}

export function ConfirmModal(props: ConfirmModalProps) {
  const isMounted = useMounted();
  const [working, setWorking] = useState(false);
  const handleConfirm = () => {
    setWorking(true);
    Promise.resolve(props.onConfirm()).finally(() => {
      if (!isMounted.current) return;
      setWorking(false);
    });
  };

  return (
    <Modal
      {...props}
      onHide={props.onCancel}
    >
      <Modal.Header>
        {props.children}
      </Modal.Header>
      <Modal.Actions>
        <Button variant="default" onClick={props.onCancel}>
          {props.cancelText || translate('CANCEL')}
        </Button>
        <Button variant={props.confirmVariant || 'primary'} onClick={handleConfirm} working={working}>
          {props.confirmText}
        </Button>
      </Modal.Actions>
    </Modal>
  )

}