import React from 'react';

import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button from "@app/components/Button";
import { ResendInvitationButton_invitation$key } from './__generated__/ResendInvitationButton_invitation.graphql';
import useMutation from '@app/hooks/useMutation';
import { ResendInvitationButton_resendInvitation_Mutation } from './__generated__/ResendInvitationButton_resendInvitation_Mutation.graphql';
import useToast from '@app/hooks/useToast';

interface Props {
  invitation: ResendInvitationButton_invitation$key
}
export default function ResendInvitationButton(props: Props) {
  const toast = useToast();
  const invitation = useFragment(
    graphql`
      fragment ResendInvitationButton_invitation on Invitation {
        id
      }
    `,
    props.invitation
  );

  const [resendInvitationExecutor, resendInvitationStatus] = useMutation<ResendInvitationButton_resendInvitation_Mutation>(graphql`
    mutation ResendInvitationButton_resendInvitation_Mutation(
      $input: ResendInvitationInput!
    ) {
      resendInvitation(input: $input) {
        dummy
      }
    }
  `);
  const handleClick = async () => {
    await resendInvitationExecutor.executePromise({
      input: {
        id: invitation.id
      }
    }).catch(err => {
      toast.error({
        title: 'Unable to resend invitation',
        message: err.toString()
      });
      throw err;
    });
    toast.success({title: 'Invitation resent!'})
  };

  return (
    <Button
      working={resendInvitationStatus.pending}
      variant="default"
      tooltip="Resend invitation"
      className="button-small"
      data-test-id="resend-invitation-button"
      autoWidth onClick={handleClick}
    >
      <i className="fa fa-envelope" />
    </Button>
  );
}