import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import moment from 'moment';

import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import {graphql} from 'react-relay';

import { useRouteMatch } from 'react-router-dom';
import { PaymentHistoryScreenQuery } from './__generated__/PaymentHistoryScreenQuery.graphql';
import { PaymentHistoryScreen_tenant$key } from './__generated__/PaymentHistoryScreen_tenant.graphql';
import Button from '@app/components/Button/Button';
import { TenantRouteParams } from '@app/models';

export default function PaymentHistoryScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const data = useLazyLoadQuery<PaymentHistoryScreenQuery>(
    graphql`
      query PaymentHistoryScreenQuery ($tenantId: ID!, $count: Int, $cursor: String) {
        tenant(id: $tenantId) {
          viewerPermissions {
            billing
          }
          ...PaymentHistoryScreen_tenant
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${match.params.tenantId}`)
    }
  );

  if (!data.tenant) return null;
  if (data.tenant.viewerPermissions.billing === 'NONE') return null;

  return (
    <div className="accordion">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-3">
            <h3>Date</h3>
          </div>
          <div className="col-xs-3">
            <h3>Paid at</h3>
          </div>
          <div className="col-xs-3">
            <h3>Amount</h3>
          </div>
          <div className="col-xs-3">
            Invoice
          </div>
        </div>
      </div>
      <Paginated tenant={data.tenant} />
    </div>
  );
}

type PaginatedProps = {
  tenant: PaymentHistoryScreen_tenant$key
}
function Paginated(props: PaginatedProps) {
  const {data, loadNext, isLoadingNext} = usePaginationFragment(
    graphql`
      fragment PaymentHistoryScreen_tenant on Tenant @refetchable(queryName: "InvoicesPaginationQuery") {
        id
        billing {
          invoices(first: $count, after: $cursor) @connection(key: "Tenant_invoices") {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                currency
                amount
                paid
                paidAt
                hostedInvoiceUrl
                pdfUrl
                periodStart
                periodEnd
              }
            }
          }
        }
      }
    `,
    props.tenant
  );
  return (
    <React.Fragment>
      {data.billing?.invoices.edges.map(edge => (
        <div className="accordion-item" key={edge.cursor}>
          <div className="accordion-header">
            <div className="col-xs-3">
              <a href={edge.node.hostedInvoiceUrl} target="_blank" rel="noopener noreferrer">
                {moment(edge.node.periodEnd).format('MMM Do, Y')}
              </a>
            </div>
            <div className="col-xs-3">
              {edge.node.paid ? moment(edge.node.paidAt).format('MMM Do, Y') : 'Not Paid'}
            </div>
            <div className="col-xs-3">
              {edge.node.currency.toUpperCase()} {(edge.node.amount / 100).toLocaleString()}
            </div>
            <div className="col-xs-3">
              <a href={edge.node.pdfUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-pdf" /></a>
            </div>
          </div>
        </div>
      ))}
      {data.billing?.invoices.pageInfo.hasNextPage ? (
        <Button variant="default" onClick={() => loadNext(10)} working={isLoadingNext}>
          Load more
        </Button>
      ) : null}
    </React.Fragment>
  );
}