/**
 * @generated SignedSource<<699a5160ec61df3768969cc76bba3abc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSignaturesApplicationScreen_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"IdentityProvidersSection_tenant">;
  readonly " $fragmentType": "EditSignaturesApplicationScreen_tenant";
};
export type EditSignaturesApplicationScreen_tenant$key = {
  readonly " $data"?: EditSignaturesApplicationScreen_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditSignaturesApplicationScreen_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSignaturesApplicationScreen_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentityProvidersSection_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "4870c4f044d8bc6b783375735b42163f";

export default node;
