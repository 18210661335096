/**
 * @generated SignedSource<<803ddc0fdb1c612b5a331da69b20856b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type ApplicationsScreen_CreateQuery$variables = {
  environment: Environment;
  product: ReadonlyArray<Product>;
  tenantId: string;
};
export type ApplicationsScreen_CreateQuery$data = {
  readonly tenant: {
    readonly domains: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly environment: Environment;
          readonly id: string;
          readonly name: string;
        };
      }>;
    };
    readonly environments: ReadonlyArray<Environment>;
    readonly " $fragmentSpreads": FragmentRefs<"AgeverificationApplicationCreator_tenant" | "SignaturesApplicationCreator_tenant" | "VerifyApplicationCreator_tenant">;
  } | null;
};
export type ApplicationsScreen_CreateQuery = {
  response: ApplicationsScreen_CreateQuery$data;
  variables: ApplicationsScreen_CreateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "product"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "environment",
  "variableName": "environment"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DomainEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Domain",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "environment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "product",
      "variableName": "product"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplicationsScreen_CreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": "domains",
            "args": (v5/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "__tenant_domains_connection",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "VerifyApplicationCreator_tenant"
          },
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "SignaturesApplicationCreator_tenant"
          },
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "AgeverificationApplicationCreator_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ApplicationsScreen_CreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "domains",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "environment"
            ],
            "handle": "connection",
            "key": "tenant_domains",
            "kind": "LinkedHandle",
            "name": "domains"
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantFeatures",
            "kind": "LinkedField",
            "name": "features",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "FRENCH_PUBLIK",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "DKNEMID_ENABLED",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e07f49c2753ae36be2bfd6ee623746d6",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "tenant",
            "domains"
          ]
        }
      ]
    },
    "name": "ApplicationsScreen_CreateQuery",
    "operationKind": "query",
    "text": "query ApplicationsScreen_CreateQuery(\n  $tenantId: ID!\n  $environment: Environment!\n  $product: [Product!]!\n) {\n  tenant(id: $tenantId) {\n    domains(first: 1000, environment: $environment) {\n      edges {\n        node {\n          id\n          name\n          environment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    environments(product: $product)\n    ...VerifyApplicationCreator_tenant_2bptcK\n    ...SignaturesApplicationCreator_tenant_2bptcK\n    ...AgeverificationApplicationCreator_tenant_2bptcK\n    id\n  }\n}\n\nfragment AgeverificationApplicationCreator_tenant_2bptcK on Tenant {\n  ...DomainField_tenant_2bptcK\n}\n\nfragment DomainField_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        environment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SignaturesApplicationCreator_tenant_2bptcK on Tenant {\n  ...DomainField_tenant_2bptcK\n  features {\n    FRENCH_PUBLIK\n    DKNEMID_ENABLED\n  }\n}\n\nfragment VerifyApplicationCreator_tenant_2bptcK on Tenant {\n  ...DomainField_tenant_2bptcK\n}\n"
  }
};
})();

(node as any).hash = "fcf5781cc550d5b59c245ba796fc89aa";

export default node;
