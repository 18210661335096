import React from 'react';

import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import {translate} from '@app/i18n';
import InputField from '@app/components/FormFields/InputField/InputField';
import { AdvancedOptionsSection_application$key } from './__generated__/AdvancedOptionsSection_application.graphql';
import { ApplicationRelayForm } from '../../ApplicationForm/ApplicationForm';
import { AdvancedOptionsSectionMutation } from './__generated__/AdvancedOptionsSectionMutation.graphql';
import useMutation from '@app/hooks/useMutation';

type Props =  {
  application: AdvancedOptionsSection_application$key
}

type Values = {
  frameOrigin: string | null
  sessionLifetime: string | null
  tokenLifetime: string | null
}

export default function AdvancedOptionsSection(props: Props) {
  const application = useFragment(graphql`
    fragment AdvancedOptionsSection_application on VerifyApplication {
      id
      frameOrigin
      sessionLifetime
      tokenLifetime
    }
  `, props.application);

  const initialValues : Values = {
    frameOrigin: application.frameOrigin ?? '',
    sessionLifetime: application.sessionLifetime,
    tokenLifetime: application.tokenLifetime ?? ''
  };

  const [mutationExecutor, mutationState] = useMutation<AdvancedOptionsSectionMutation>(graphql`
    mutation AdvancedOptionsSectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          ... AdvancedOptionsSection_application
        }
      }
    }
  `);

  return (
    <ApplicationRelayForm
      formSuccessMessage="Advanced settings updated"
      values={initialValues}
      valid={true}
      onSubmit={async values => {
        await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            sessionLifetime: values.sessionLifetime ?? '00:20:00',
            tokenLifetime: values.tokenLifetime?.length ? values.tokenLifetime : null,
            frameOrigin: values.frameOrigin ?? undefined
          }
        });
      }}
    >
      <div className='flex flex-col gap-[12px]'>
      <InputField<Values>
        type="text"
        label={translate('LABEL_FRAME_ORIGINS')}
        name="frameOrigin"
        placeholder="your-domain.com"
        helpText={translate('INFO_FRAME_ORIGINS')}
      />
      <InputField<Values>
        type="text"
        label="Session lifetime"
        name="sessionLifetime"
        placeholder="00:20:00"
        required
      />
      <InputField<Values>
        type="text"
        label="Token lifetime"
        name="tokenLifetime"
        placeholder="E.g. 00:20:00"
        helpText={<span><strong>OIDC only.</strong> Determines how long issued tokens are valid for. Defaults to session lifetime if not defined.</span>}
      />
      </div>
    </ApplicationRelayForm>
  )
}