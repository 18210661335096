/**
 * @generated SignedSource<<52f43c9613403c4341aa7216d84daab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentMethodModal_tenant$data = {
  readonly id: string;
  readonly " $fragmentType": "PaymentMethodModal_tenant";
};
export type PaymentMethodModal_tenant$key = {
  readonly " $data"?: PaymentMethodModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentMethodModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentMethodModal_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "198ec41e92db1cd261e16ea741e5a45f";

export default node;
