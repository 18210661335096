/**
 * @generated SignedSource<<bc904d28952f2b028edace5eea2d7f3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserPermission = "CONFIGURATION_MANAGEMENT" | "EXTENSIONS_PRODUCTION_READ" | "EXTENSIONS_PRODUCTION_WRITE" | "EXTENSIONS_TEST_READ" | "EXTENSIONS_TEST_WRITE" | "FINANCIALS" | "PRIVATE_KEY_MANAGEMENT" | "REPORTING" | "USER_ADMIN" | "%future added value";
export type UserRole = "Admin" | "Developer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditUserModal_user$data = {
  readonly id: string;
  readonly permissions: ReadonlyArray<UserPermission>;
  readonly role: UserRole | null;
  readonly " $fragmentType": "EditUserModal_user";
};
export type EditUserModal_user$key = {
  readonly " $data"?: EditUserModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditUserModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditUserModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "934c26c16602a6b4fc53bdf81ed4b936";

export default node;
