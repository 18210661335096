import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import { useField } from 'formik';
import { uniq } from 'lodash';
import cx from 'classnames';
import useMutation from '@app/hooks/useMutation';

import { IDENTITY_PROVIDERS, filterProviders, IdentityProvider, DetailedIdentityProvider } from '@app/providers';
import { singleton as config } from '@app/config';
import { Application } from '@app/models';
import Tooltip from '@components/Tooltip';

import Checkbox from '@app/components/Form/Checkbox';

import './IdentityProviders.css';
import { authorizeUrl } from '@app/helpers';
import { IdentityProvidersSection_application$key, IdentityProvidersSection_application$data } from './__generated__/IdentityProvidersSection_application.graphql';
import { ApplicationRelayForm } from '../../ApplicationForm/ApplicationForm';
import { IdentityProvidersSectionMutation } from './__generated__/IdentityProvidersSectionMutation.graphql';
import { IdentityProvidersSection_tenant$data, IdentityProvidersSection_tenant$key } from './__generated__/IdentityProvidersSection_tenant.graphql';

interface Props {
  application: IdentityProvidersSection_application$key,
  tenant: IdentityProvidersSection_tenant$key,
  help?: React.ReactNode
}

export default function IdentityProvidersSectionContainer(props: Props) {
  const application = useFragment(graphql`
    fragment IdentityProvidersSection_application on Application {
      id
      acrValues
      callbackUrls
      tags
      realm

      domain {
        name
      }
    }
  `, props.application);

  const tenant = useFragment(graphql`
    fragment IdentityProvidersSection_tenant on Tenant {
      features {
        FRENCH_PUBLIK
        DKNEMID_ENABLED
      }
    }
  `, props.tenant);

  const [mutationExecutor, mutationState] = useMutation<IdentityProvidersSectionMutation>(graphql`
    mutation IdentityProvidersSectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          ... IdentityProvidersSection_application
        }
      }
    }
  `);

  const [acrValues, setAcrValues] = useState(application.acrValues);
  const isSignatures = application.tags.includes('signatures');

  return (
    <ApplicationRelayForm
      valid={true}
      onSubmit={async () => {
        await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            acrValues: acrValues as string[]
          }
        })
      }}
      values={{}}
    >
      <IdentityProvidersSection
        acrValues={acrValues as string[]}
        setAcrValues={setAcrValues}
        application={application}
        tenant={tenant}
        help={props.help}
        isSignatures={isSignatures}
      />
    </ApplicationRelayForm>
  );
}

export function IdentityProvidersSection(props: {
  acrValues: string[],
  setAcrValues: (acrValues: string[]) => void,
  isSignatures: boolean,
  application?: IdentityProvidersSection_application$data,
  tenant: {
    features: {
      FRENCH_PUBLIK: boolean,
      DKNEMID_ENABLED: boolean
    }
  },
  help?: React.ReactNode
}) {
  const providers = filterProviders(IDENTITY_PROVIDERS, config, props.tenant.features);
  const {acrValues, setAcrValues, isSignatures} = props;
  const [expanded, setExpanded] = useState(providers.reduce((memo, provider) => {
    memo[provider.id] = false;
    return memo;
  }, {} as {[key: string]: boolean}));

  const handleAll = (checked: boolean) => {
    if (checked) {
      setAcrValues(providers.reduce((memo : string[], provider) => {
        return memo.concat(provider.specificProviders.map(s => s.urn));
      }, []));
    } else {
      setAcrValues([]);
    }
  }

  const expandAll = () => {
    setExpanded(providers.reduce((memo, provider) => {
      memo[provider.id] = provider.specificProviders.length > 1;
      return memo;
    }, {} as {[key: string]: boolean}))
  };

  const collapseAll = () => {
    setExpanded(providers.reduce((memo, provider) => {
      memo[provider.id] = false;
      return memo;
    }, {} as {[key: string]: boolean}))
  };

  const handleChangeSingle = (provider : IdentityProvider, checked : boolean) => {
    setAcrValues(checked ? acrValues.concat([provider.urn]) : acrValues.filter(search => search !== provider.urn));
  };

  const handleChangeGroup = (provider : DetailedIdentityProvider, checked : boolean) => {
    const urns = provider.specificProviders.map(s => s.urn);
    if (checked) {
      setAcrValues(uniq(acrValues.concat(urns)));
    } else {
      setAcrValues(acrValues.filter(search => !urns.includes(search)));
    }
  }

  const hasAllGroup = (provider : DetailedIdentityProvider) => {
    return provider.specificProviders.filter(search => acrValues.includes(search.urn)).length === provider.specificProviders.length;
  };
  const hasAnyGroup = (provider : DetailedIdentityProvider) => {
    return provider.specificProviders.some(search => acrValues.includes(search.urn));
  };

  const hasAll = providers.reduce((memo, provider) => {
    return memo && hasAllGroup(provider);
  }, true);

  const allExpanded = providers.reduce((memo, provider) => {
    return memo && (provider.specificProviders.length > 1 ? expanded[provider.id] : true);
  }, true);

  return (
    <React.Fragment>
      {props.help ? props.help : (
        <p>
          These e-IDs only influence our Method selector page.<br />
          To target a particular e-ID directly from your own application (thus bypassing our method selector page), you must specify the corresponding <span className="code">acr_values</span> in your code.<br />
          You can copy the <span className="code">acr_values</span> from the e-IDs below.<br />
        </p>
      )}
      <ul className="multi-check-list">
        <li>
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-group horizontal">
              <Checkbox checked={hasAll} onChange={(checked) => handleAll(checked)} />
              <label className="control-label" onClick={(event) => handleAll(!hasAll)}>
                Select all
              </label>
            </div>
            {allExpanded ? (
              <div className="text-primary pointer p-8" onClick={() => collapseAll()}>
                Collapse all&nbsp;
                <i className="fas fa-angle-up"  />
              </div>
            ) : (
              <div className="text-primary pointer p-8"  onClick={() => expandAll()} >
                Expand all&nbsp;
                <i className="fas fa-angle-right" />
              </div>
            )}
          </div>
        </li>
        {providers.map(provider => (
          <React.Fragment key={provider.id}>
            {provider.specificProviders.length > 1 ? (
              <li key={provider.id} className={cx('group-item', {expanded: expanded[provider.id]})}>
                <div className="d-flex align-items-center">
                  <Checkbox
                    checked={hasAllGroup(provider)}
                    indeterminate={hasAnyGroup(provider)}
                    onChange={(checked) => handleChangeGroup(provider, checked)}
                    tooltip={`${hasAllGroup(provider) ? 'Unselect' : 'Select'} all ${provider.name} methods`}
                  />
                  <div
                    className="d-flex justify-content-between align-items-center flex-grow-1 pointer grey-hover-box align-self-stretch"
                    onClick={() => setExpanded(expanded => ({...expanded, [provider.id]: !expanded[provider.id]}))}
                  >
                    <Tooltip id={`${provider.id}_collapse`} tooltip={expanded[provider.id] ? `Collapse all ${provider.name} methods` : `Expand all ${provider.name} methods`}>
                      <label className="control-label">
                        {provider.name}
                      </label>
                    </Tooltip>
                    <Tooltip id={`${provider.id}_collapse`} tooltip={expanded[provider.id] ? `Collapse all ${provider.name} methods` : `Expand all ${provider.name} methods`}>
                      {expanded[provider.id] ? (
                        <i className="fas fa-angle-up text-primary p-8"  />
                      ) : (
                        <i className="fas fa-angle-right text-primary p-8"  />
                      )}
                    </Tooltip>
                  </div>
                </div>
                <ul>
                  {provider.specificProviders.map(provider => (
                    <IdpItem
                      {...props}
                      application={props.application}
                      key={provider.urn}
                      isSignatures={isSignatures}
                      provider={provider}
                      checked={acrValues.includes(provider.urn)}
                      onChange={(checked) => handleChangeSingle(provider, checked)}
                    />
                  ))}
                </ul>
              </li>
            ) : (
              <IdpItem
                {...props}
                application={props.application}
                isSignatures={isSignatures}
                provider={provider.specificProviders[0]}
                checked={acrValues.includes(provider.specificProviders[0].urn)}
                onChange={(checked) => handleChangeSingle(provider.specificProviders[0], checked)}
              />
            )}
          </React.Fragment>
        ))}
      </ul>
      {props.application && !isSignatures && (
        <div className="multi-check-list-footer">
          <a href={authorizeUrl(props.application, acrValues as string[]).href} target="_blank" rel="noopener noreferrer">
            Try a test login with selected eIDs <i className="far fa-play-circle"></i>
          </a>
        </div>
      )}
    </React.Fragment>
  );
}

interface IdpItemProps {
  provider: IdentityProvider,
  checked: boolean,
  isSignatures: boolean,
  application?: IdentityProvidersSection_application$data,
  onChange: (checked: boolean) => void
}
function IdpItem(props: IdpItemProps) {
  const {provider, checked, application, onChange, isSignatures} = props;

  const clipboardAcr = (event: React.MouseEvent, provider: IdentityProvider) => {
    event.preventDefault();
    navigator.clipboard.writeText(provider.urn);
  }
  const clipboardUrl = (event: React.MouseEvent, provider: IdentityProvider) => {
    if (!application) return;
    event.preventDefault();
    navigator.clipboard.writeText(authorizeUrl(application, provider.urn).href);
  }

  return (
    <li key={provider.urn} className="idp-item single-item">
      <Tooltip id={`${provider.urn}_select`} tooltip={`${checked ? 'Unselect' : 'Select'} ${provider.name}`}>
        <div className="form-group horizontal">
          <Checkbox checked={checked} onChange={(checked) => onChange(checked)} />
          <label className="control-label" onClick={(event) => onChange(!checked)}>{provider.name}</label>
        </div>
      </Tooltip>
      <div className="actions">
        <Tooltip id={`${provider.urn}_copy_acr`} tooltip="Copy acr value">
          <a href="#" onClick={(event) => clipboardAcr(event, provider)}>
            <i className="far fa-copy"></i>
          </a>
        </Tooltip>
        {application && !isSignatures && !provider.urn.includes('digid') && (
          <React.Fragment>
            <Tooltip id={`${provider.urn}_test_login`} tooltip="Try a test login">
              <a href={authorizeUrl(application, provider.urn).href} target="_blank" rel="noopener noreferrer">
                <i className="far fa-play-circle"></i>
              </a>
            </Tooltip>

            <Tooltip id={`${provider.urn}_copy_login`} tooltip="Copy login url">
              <a href="#" onClick={(event) => clipboardUrl(event, provider)}>
                <i className="fas fa-link"></i>
              </a>
            </Tooltip>
          </React.Fragment>
        )}
      </div>
    </li>
  )
}