import React from 'react';
import cx from 'classnames';
import useField from '../useField';
import HelpText from '../HelpText';

import styles from './Checkbox.module.css';

export type BaseCheckboxProps = {
  name: string;
  label: string;
  className?: string;
  /** default: sm */
  size?: 'sm' | 'lg';
  helpText?: React.ReactNode;
};

function className(props: BaseCheckboxProps) {
  const size = props.size ?? 'sm';
  return cx(styles[`size-${size}`]);
}

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>, BaseCheckboxProps {
  name: string;
  size?: 'sm' | 'lg';
}

export default function Checkbox({ ...props }: CheckboxProps) {
  const { size, name, helpText, ...rest } = props;

  const fieldResult = useField(name);
  const [field] = fieldResult ?? [];

  const checkbox = (
    <div>
      <div className={cx(
        className(props),
        props.className,
        styles['checkbox-container']
      )}>
        <label htmlFor={props.id} className={styles['checkbox-label']}>
          <input
            type="checkbox"
            {...field}
            {...rest}
            id={props.id}
            name={name}
            className={className(props)}
          />
          {props.label}
        </label>
      </div>
      {helpText && <HelpText>{helpText}</HelpText>}
    </div>
  );

  return checkbox;
}
