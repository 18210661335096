/**
 * @generated SignedSource<<701ebae204688664f2d1d6c731d027a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type UpdateTenantDanishMitIdServiceProviderInput = {
  environment: Environment;
  tenantId: string;
  vatCountry: string;
  vatNumber: string;
};
export type TenantMitIDScreen_ServiceProvider_Mutation$variables = {
  environment: Environment;
  input: UpdateTenantDanishMitIdServiceProviderInput;
};
export type TenantMitIDScreen_ServiceProvider_Mutation$data = {
  readonly admin_updateTenantDanishMitIdServiceProvider: {
    readonly adminTenant: {
      readonly danishMitIdServiceProvider: {
        readonly vatCountry: string;
        readonly vatNumber: string;
      } | null;
    };
  };
};
export type TenantMitIDScreen_ServiceProvider_Mutation = {
  response: TenantMitIDScreen_ServiceProvider_Mutation$data;
  variables: TenantMitIDScreen_ServiceProvider_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "environment",
      "variableName": "environment"
    }
  ],
  "concreteType": "DanishMitIdServiceProvider",
  "kind": "LinkedField",
  "name": "danishMitIdServiceProvider",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantMitIDScreen_ServiceProvider_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTenantDanishMitIdServiceProviderOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantDanishMitIdServiceProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TenantMitIDScreen_ServiceProvider_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTenantDanishMitIdServiceProviderOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantDanishMitIdServiceProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e411736384feae865b808b11db77823b",
    "id": null,
    "metadata": {},
    "name": "TenantMitIDScreen_ServiceProvider_Mutation",
    "operationKind": "mutation",
    "text": "mutation TenantMitIDScreen_ServiceProvider_Mutation(\n  $input: UpdateTenantDanishMitIdServiceProviderInput!\n  $environment: Environment!\n) {\n  admin_updateTenantDanishMitIdServiceProvider(input: $input) {\n    adminTenant {\n      danishMitIdServiceProvider(environment: $environment) {\n        vatCountry\n        vatNumber\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "388e2d852e781f25546d58d5ebc754e9";

export default node;
