/**
 * @generated SignedSource<<d9d989497e21fd3868029098dd24470f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddOrganizationTenantModal_OrganizationSearchQuery$variables = {
  query: string;
  skip: boolean;
};
export type AddOrganizationTenantModal_OrganizationSearchQuery$data = {
  readonly admin?: {
    readonly organizations: ReadonlyArray<{
      readonly id: string;
      readonly organization: {
        readonly name: string;
      };
    }> | null;
  };
};
export type AddOrganizationTenantModal_OrganizationSearchQuery = {
  response: AddOrganizationTenantModal_OrganizationSearchQuery$data;
  variables: AddOrganizationTenantModal_OrganizationSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOrganizationTenantModal_OrganizationSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminQuery",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AdminOrganization",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddOrganizationTenantModal_OrganizationSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminQuery",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AdminOrganization",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d9e2d0a02f73c88966609f3616129bfa",
    "id": null,
    "metadata": {},
    "name": "AddOrganizationTenantModal_OrganizationSearchQuery",
    "operationKind": "query",
    "text": "query AddOrganizationTenantModal_OrganizationSearchQuery(\n  $query: String!\n  $skip: Boolean!\n) {\n  admin @skip(if: $skip) {\n    organizations(query: $query) {\n      id\n      organization {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e284a2f8c480c4321c50bf6076957b66";

export default node;
