/**
 * @generated SignedSource<<360ea6ee1651640471559367aaccc0c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NativeSection_application$data = {
  readonly android: {
    readonly packageName: string;
    readonly sha256Fingerprints: ReadonlyArray<string>;
  } | null;
  readonly domain: {
    readonly name: string;
  };
  readonly iOS: {
    readonly bundleId: string;
    readonly teamId: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "NativeSection_application";
};
export type NativeSection_application$key = {
  readonly " $data"?: NativeSection_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"NativeSection_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NativeSection_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VerifyApplicationAndroid",
      "kind": "LinkedField",
      "name": "android",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "packageName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sha256Fingerprints",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VerifyApplicationIOS",
      "kind": "LinkedField",
      "name": "iOS",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bundleId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VerifyApplication",
  "abstractKey": null
};

(node as any).hash = "fc4d1a3af22612f2b9cdee39d92c0303";

export default node;
