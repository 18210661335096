/**
 * @generated SignedSource<<8b8c6e951e74a9c8b5ecbda6e180e40b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RedirectScreenQuery$variables = {
  skip: boolean;
};
export type RedirectScreenQuery$data = {
  readonly viewer?: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
      readonly longTenantId: string;
      readonly shortTenantId: string;
    }>;
  } | null;
};
export type RedirectScreenQuery = {
  response: RedirectScreenQuery$data;
  variables: RedirectScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longTenantId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RedirectScreenQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RedirectScreenQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c74b9a8b6936ece1097d4482b8c0b1e5",
    "id": null,
    "metadata": {},
    "name": "RedirectScreenQuery",
    "operationKind": "query",
    "text": "query RedirectScreenQuery(\n  $skip: Boolean!\n) {\n  viewer @skip(if: $skip) {\n    __typename\n    tenants {\n      id\n      shortTenantId\n      longTenantId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b3aac8084902db05477d63c8dc06fcb";

export default node;
