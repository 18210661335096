import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import { CustomerRendition } from '@app/api/subscription';
import { Form, FormError, FormSuccess } from '@app/components/Form';
import Button from '@app/components/Button';

import './BillingInformationScreen.css';
import BillingInformationFields from './components/BillingInformationFields/BillingInformationFields';
import { Link, useRouteMatch } from 'react-router-dom';
import { BillingInformationScreenQuery } from './__generated__/BillingInformationScreenQuery.graphql';
import { BillingInformationScreenMutation, Country } from './__generated__/BillingInformationScreenMutation.graphql';
import useMutation from '@app/hooks/useMutation';
import { TenantRouteParams } from '@app/models';

export default function BillingInformationScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const data = useLazyLoadQuery<BillingInformationScreenQuery>(
    graphql`
      query BillingInformationScreenQuery ($tenantId: ID!) {
        tenant(id: $tenantId) {
          id
          viewerPermissions {
            billing
          }
          billing {
            information {
              company
              email
              country
              street
              city
              zip
              taxId
            }
          }
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${match.params.tenantId}`)
    }
  );

  const [mutationExecutor, mutationState] = useMutation<BillingInformationScreenMutation>(graphql`
    mutation BillingInformationScreenMutation($input: UpdateBillingInformationInput!) {
      updateBillingInformation(input: $input) {
        tenant {
          billing {
            information {
              company
              email
              country
              street
              city
              zip
              taxId
            }
          }
        }
      }
    }
  `);

  if (!data.tenant) return null;
  if (data.tenant.viewerPermissions.billing === 'NONE') return null;

  const handleSubmit = async (values: CustomerRendition) => {
    await mutationExecutor.executePromise({
      input: {
        tenantId: data.tenant!.id,
        company: values.company!,
        email: values.email!,
        country: values.country! as Country,
        street: values.street!,
        city: values.city!,
        zip: values.zip!,
        taxId: values.taxId
      }
    });
  };

  if (!data.tenant.billing?.information) {
      return (
      <React.Fragment>
        Please <Link to={`/tenant/${match.params.tenantId}/billing`} className="!underline">sign up for a subscription.</Link>
      </React.Fragment>
    );
  }

  const initialValues : CustomerRendition = {
    ...data.tenant.billing.information
  }

  return (
    <Form
      initialValues={initialValues}
      key={`${data.tenant.id}_billing_information`}
      onSubmit={handleSubmit}
      className="billing-information-form max-w-[750px]"
    >
      {({isPending, isSuccess, error, values}) => (
        <React.Fragment>
          <BillingInformationFields values={values} />

          <FormError error={error} />
          <FormSuccess message={!isPending && isSuccess && `Your billing information has been updated` || null} />
          <Button variant="primary" working={isPending} type="submit">Update billing information</Button>
        </React.Fragment>
      )}
    </Form>
  );
}