export const TENANT_ID_PREFIX = "urn:grn:easyid:tenant:";
export const LOCALHOST_URN = 'urn:grn:development:localhost';

export function toShortTenantId(input: string) {
  if (input === LOCALHOST_URN) return input;
  if (input.startsWith(TENANT_ID_PREFIX)) return input.replace(TENANT_ID_PREFIX, '');
  return input;
}

export function toLongTenantId(input: string) : string {
  if (input === LOCALHOST_URN) return input;
  if (input.startsWith(TENANT_ID_PREFIX)) return input;
  return TENANT_ID_PREFIX + input;
}

export type UserPermissionClaim =
  "billing-management" | "cfg" | "pkm" | "bill" | "mtrx" | "users" |
  // Reduce JWT sizes when possible
  "ext:test:r" | "ext:test:w" | "ext:prod:r" | "ext:prod:w";
export type UserPermissionRendition =
  'private-key-management' | 'configuration-management'  | 'financials'  | 'reporting'  | 'user-admin' |
  'extensions:test:read' | 'extensions:test:write' | 'extensions:production:read' | 'extensions:production:write'

// * means all permissions, not all tenants (that is implied)
export const AdminPermissions = [
  "customer-support-login",
  "localhost",
  "tenants:*:read",
  "tenants:*:write",
  "organizations:*:read",
  "organizations:*:write",
  "users:read"
] as const;
export type AdminPermissionClaim = typeof AdminPermissions[number];

// * means all tenants, not specific tenants
export const OrganizationUserPermissions = [
  // manage organization users
  'users:read', 'users:write',
  // create tenants
  'tenants:create',
  // read all tenants in organization
  'tenants:*:read',
  // manage users for all tenants in organization
  'tenants:*:users:read', 'tenants:*:users:write',
  // login to all tenants in organization
  'tenants:*:login',
  // manage organization api
  'production:api', 'test:api'
] as const;
export type OrganizationUserPermissionRendition = typeof OrganizationUserPermissions[number];
export type VerifyUserClaims = {
  sub: string
  "http://criipto/permit/scopedUserClaims"?: {
    [key: string]: UserPermissionClaim[]
  },
  "http://criipto/organizations"?: {
    [key: string]: OrganizationUserPermissionRendition[]
  },
  "https://gov.eu/vatid"?: {
    [key: string]: {
      companyName: string
      vatCountry: string
      vatNumber: string
    }
  },
  "http://criipto/customer-support"?: boolean,
  "http://criipto/admin/permissions"?: AdminPermissionClaim[],
  /**
   * Last occurrence of MFA by the user, formatted as YYYY-MM-DDTHH:mm:ss.sssZ (ISO 8601 compatible)
   */
  "http://criipto/mfa"?: string

  iat: number
}
export type VerifyOrganizationClaims = {
  sub: string,
  "http://criipto/permit/scopedUserClaims"?: {
    [key: string]: UserPermissionClaim[]
  },
  "http://criipto/organization": string
  "http://criipto/organization/api_key": string
  "http://criipto/environment": string

  iat: number
}
export type VerifyClaims = VerifyUserClaims | VerifyOrganizationClaims

export type IdTokenClaims = {
  sub: string
  name: string
  email: string
}

/**
 * A Criipto Admin or Criipto Backoffice user is allowed to access teants in a READ_ONLY mode.
 */
export function isCriiptoAdmin(claims: VerifyUserClaims) {
  if (claims['http://criipto/admin/permissions'] && claims['http://criipto/admin/permissions'].length > 0) {
    return true;
  }
  if (!claims["http://criipto/permit/scopedUserClaims"]) return false;
  return Object.entries(claims["http://criipto/permit/scopedUserClaims"]).some(([, permissions]) => permissions.includes("billing-management"));
}

export function hasTenantAccess(claims: VerifyUserClaims, tenantId: string) {
  if (!claims["http://criipto/permit/scopedUserClaims"]) return false;
  return claims["http://criipto/permit/scopedUserClaims"][toShortTenantId(tenantId)] !== undefined ? true : false;
}

export function hasTenantPermission(claims: VerifyUserClaims, tenantId: string, permission: UserPermissionClaim) {
  if (!hasTenantAccess(claims, tenantId)) return false;
  if (!claims["http://criipto/permit/scopedUserClaims"]) return false;
  const tenantClaims = claims["http://criipto/permit/scopedUserClaims"][toShortTenantId(tenantId)];
  if (!tenantClaims) return false;
  return tenantClaims.includes(permission);
}

export function hasOrganizationAccess(claims: VerifyUserClaims, organizationId: string) {
  if (!("http://criipto/organizations" in claims)) return false;
  if (!claims["http://criipto/organizations"]) return false;
  return claims["http://criipto/organizations"][organizationId] !== undefined ? true : false;
}

export function hasOrganizationPermission(claims: VerifyUserClaims, organizationId: string, permission: OrganizationUserPermissionRendition) {
  if (!hasOrganizationAccess(claims, organizationId)) return false;
  if (!("http://criipto/organizations" in claims)) return false;
  if (!claims["http://criipto/organizations"]) return false;
  const organizationClaims = claims["http://criipto/organizations"][organizationId];
  if (!organizationClaims) return false;
  return organizationClaims.includes(permission);
}

export function hasAdminPermission(claims: VerifyUserClaims, permission: AdminPermissionClaim) {
  if (!("http://criipto/admin/permissions" in claims)) return false;
  if (!claims["http://criipto/admin/permissions"]) return false;
  return claims["http://criipto/admin/permissions"].includes(permission);
}

export const MFA_MINUTES = 60;
export function checkMFA(user: VerifyUserClaims) {
  if (!user["http://criipto/mfa"]) return false;
  const mfa = Date.parse(user["http://criipto/mfa"]);
  if (isNaN(mfa)) return false;

  const diff = Date.now() - mfa;
  const diffMinutes = diff / (60 * 1000);
  if (diffMinutes > MFA_MINUTES) return false;
  return true;
}