/**
 * @generated SignedSource<<08fab5c87ea3d4b03e9839ec41f7e94b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentMethodModal_paymentMethod$data = {
  readonly name: string;
  readonly " $fragmentType": "PaymentMethodModal_paymentMethod";
};
export type PaymentMethodModal_paymentMethod$key = {
  readonly " $data"?: PaymentMethodModal_paymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentMethodModal_paymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentMethodModal_paymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "PaymentMethod",
  "abstractKey": null
};

(node as any).hash = "f1b96e7710dd800e85e7dc658bd80776";

export default node;
