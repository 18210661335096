/**
 * @generated SignedSource<<1729c1cb225f3fdfc7517e039cb1da18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "Admin" | "Developer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UsersScreen_invitation$data = {
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly role: UserRole | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteInvitationButton_invitation" | "ResendInvitationButton_invitation">;
  readonly " $fragmentType": "UsersScreen_invitation";
};
export type UsersScreen_invitation$key = {
  readonly " $data"?: UsersScreen_invitation$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersScreen_invitation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersScreen_invitation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteInvitationButton_invitation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResendInvitationButton_invitation"
    }
  ],
  "type": "Invitation",
  "abstractKey": null
};

(node as any).hash = "ef865cd0a39b7de98b2dbbed4a487ce5";

export default node;
