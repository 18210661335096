
import React, { useMemo, useReducer } from 'react';
import cx from 'classnames';
import styles from './Screen.module.css';

import GlobalHeader from '@app/components/Header';
import GlobalFooter from '@app/components/Footer';
import GraphQLErrorBoundary from '../GraphQLErrorBoundary';
import TenantNavigation from '../TenantNavigation/TenantNavigation';
import ScreenLoader from './components/Loader';
import ScreenHeader from './components/Header';
import { useAdmin } from '@app/hooks/useTenant';

export interface ScreenProps {
  nav?: (props: {className?: string, show: boolean, onHide: () => void}) => React.ReactElement,
  fullscreen?: boolean,
  children: React.ReactNode
}

function Screen(props: ScreenProps) {
  const [showNav, toggleNav] = useReducer(value => !value, false);
  const fullscreen = props.fullscreen === undefined ? true : props.fullscreen;

  /** detect if running in a nested screen via admin view */
  const isAdmin = useAdmin();
  if (isAdmin) {
    return <>{props.children}</>;
  }

  const main = (
    <div className={styles.main}>
      <React.Suspense fallback={null}>
        <GlobalHeader key="header" isNavOpen={showNav} onHamburger={toggleNav} />
      </React.Suspense>
      <div className={cx(styles['content-wrapper'], {[styles.fullscreen]: fullscreen})}>
        <div className={styles['content-inner']}>
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<Screen.Loader />}>
              {props.children}
            </React.Suspense>
          </GraphQLErrorBoundary>
        </div>
      </div>
      <GlobalFooter className={cx(styles.footer, {[styles.fullscreen]: fullscreen})} />
    </div>
  );

  return (
    <div className={cx(styles.screen, {[styles['with-nav']]: !!props.nav})}>
      {props.nav ? (
        <>
          <div
            className={cx(styles.backdrop, {[styles.show]: showNav})}
            onClick={() => toggleNav()}
          >
            <props.nav className={styles.nav} show={showNav} onHide={toggleNav} />
          </div>
          {main}
        </>
      ) : main}
    </div>
  )
}
export default Screen;

Screen.Loader = ScreenLoader;
Screen.Header = ScreenHeader;

export const tenantNavigation : React.ComponentProps<typeof Screen>["nav"] = ({className, show, onHide}) => (
  <React.Suspense fallback={<TenantNavigation.Loader className={className} />}>
    <TenantNavigation className={className} key="nav" onHide={onHide} />
  </React.Suspense>
)
Screen.tenantNavigation = tenantNavigation;
