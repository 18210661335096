import React, {useCallback, useEffect, useState} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';


import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, FormError, FormSuccess} from '@components/Form';
import CertificateForm from '@components/CertificateForm';
import useEnvironment from '@app/hooks/useEnvironment';
import { NLDigIDProviderQuery } from './__generated__/NLDigIDProviderQuery.graphql';
import { NLDigIDProviderMutation } from './__generated__/NLDigIDProviderMutation.graphql';
import { fileToBase64 } from '@app/helpers';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId
  environment: Environment
}

export interface FormValues {
  certificate?: {
    file: File,
    password: string
  }
}

type Domain = NonNullable<NonNullable<NLDigIDProviderQuery["response"]["identityProvider"]>["domains"]>[0];

export function NLDigIDProvider(props : Props) {
  const tenant = props.tenant;

  const environment = useEnvironment();
  const [domain, setDomain] = useState<Domain | null>(null);

  const data = useLazyLoadQuery<NLDigIDProviderQuery>(
    graphql`
      query NLDigIDProviderQuery($id: ID!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName
          ... on DutchDigID {
            domains {
              metadataUrl
              domain {
                id
                name
              }
              certificate {
                name
                pkcs7
                validFrom
                validTo
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|NL_DIGID`),
  });

  const domains = data.identityProvider?.domains;

  useEffect(() => {
    if (domains && !domain) {
      setDomain(domains[0]);
    }
  }, [domains]);

  const [{executePromise}] = useMutation<NLDigIDProviderMutation>(graphql`
    mutation NLDigIDProviderMutation(
      $input: UpdateDutchDigIDDomainInput!
    ) {
      updateDutchDigIDDomain(input: $input) {
        id
        isConfigured
        domains {
          metadataUrl
          domain {
            id
            name
          }
          certificate {
            name
            pkcs7
            validFrom
            validTo
          }
        }
      }
    }
  `);

  if (!data.identityProvider) return null;

  const handleDomain = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const domainId = event.target.value;
    const domain = domains?.find(s => s.domain.id === domainId);
    setDomain(domain ?? null);
  };

  return (
    <Form
      key={environment}
      initialValues={{}}
      onSubmit={async (values : FormValues, actions) => {
        if (!domain) return;
        if (!values.certificate) return;
        const certificate = await fileToBase64(values.certificate.file!);

        await executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            domainId: domain.domain.id,
            certificate: certificate,
            password: values.certificate?.password
          }
        });

        actions.setFieldValue('certificate.file', undefined);
        actions.setFieldValue('certificate.password', '');
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {data.identityProvider!.longName}
              </h3>
              <EnvironmentTag />

              <div className="form-group">
                <label className="control-label">{translate('NL_DIGID_CHOOSE_DOMAIN')}</label>
                <select className="form-control" value={domain?.domain.id ?? ''} onChange={handleDomain} data-test-id="domain-select">
                  {data.identityProvider?.domains?.map(pkiDomain => (
                    <option value={pkiDomain.domain.id} key={pkiDomain.domain.id}>{pkiDomain.domain.name}</option>
                  ))}
                </select>
              </div>

              <CertificateForm
                certificate={data.identityProvider?.domains?.find(s => s.domain.id === domain?.domain.id)?.certificate}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your dutch digid settings have been updated for ${domain?.domain.name}` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: data.identityProvider!.longName })}</p>
              <p>{translate('INFO_NL_DIGID')}</p>
              <p>
                {translate('INFO_NL_DIGID_METADATA_ENDPOINT')}
                <a href={`https://${domain?.domain.name}/nldigid/metadata`} target="_blank" rel="noopener noreferrer" style={{textDecoration: "underline"}}>https://{domain?.domain.name}/nldigid/metadata</a><br />
                (The metadata endpoint will work once you have uploaded the PKIoverheid certificate)
              </p>
              <p>
                {translate('INFO_NL_DIGID_SIGNUP')}&nbsp;
                <a href="https://www.logius.nl/ondersteuning/digid/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "underline"}}>{translate('INFO_NL_DIGID_SIGNUP_HERE')}</a>
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}