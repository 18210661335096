/**
 * @generated SignedSource<<aabbf7ed23b3d329299c2822647ca6e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResendInvitationButton_invitation$data = {
  readonly id: string;
  readonly " $fragmentType": "ResendInvitationButton_invitation";
};
export type ResendInvitationButton_invitation$key = {
  readonly " $data"?: ResendInvitationButton_invitation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResendInvitationButton_invitation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResendInvitationButton_invitation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Invitation",
  "abstractKey": null
};

(node as any).hash = "4ac150a7b2771c9a1fa03f15fb5b2f4b";

export default node;
