import { Environment } from "@app/models";
import { useSelector } from "@app/redux";
import { useOptionalTenantId } from "./useTenant";

export default function useEnvironment() : Environment {
  const environment = useOptionalEnvironment();
  return environment || 'TEST';
}

export function useOptionalEnvironment() : Environment | null {
  const tenantId = useOptionalTenantId();
  const environments = useSelector(state => state.environment);
  if (!tenantId) return null;
  if (!environments[tenantId.tenantId]) return null;
  return environments[tenantId.tenantId]?.environment || null;
}