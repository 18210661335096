import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './Header.module.css';

export default function ScreenHeader (props: {
  children: React.ReactNode,
  fullscreen?: boolean
}) {
  const fullscreen = props.fullscreen === undefined ? true : props.fullscreen;
  const tabbed = useMemo(() => React.Children.toArray(props.children).reduce((tabbed, child) => {
    if (typeof child === 'object' && 'type' in child) {
      if (child.type === ScreenHeader.Tabs) return true;
    }
    return tabbed;
  }, false), [props.children]);

  return (
    <div className={cx(styles.header, {[styles['with-tabs']]: tabbed, [styles.fullscreen]: fullscreen})}>
      {props.children}
    </div>
  );
}

ScreenHeader.Title = (props: {
  children: React.ReactNode
}) => {
  return (
    <div className={styles.title}>
      {props.children}
    </div>
  );
}

ScreenHeader.Tabs = (props: {
  children: React.ReactNode
}) => {
  return (
    <div className={styles.tabs}>
      {props.children}
    </div>
  );
}

ScreenHeader.Breadcrumbs = (props: {
  children: React.ReactNode
}) => {
  const count = React.Children.count(props.children);
  return (
    <div className={styles.breadcrumb}>
      {React.Children.map(props.children, (child, index) => (
        <>
          {child}
          {index < (count - 1) ? (
            <div className="w-[20px]">
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                <path d="M6.34375 5.65625C6.53125 5.84375 6.53125 6.1875 6.34375 6.375L1.34375 11.375C1.15625 11.5625 0.8125 11.5625 0.625 11.375C0.4375 11.1875 0.4375 10.8438 0.625 10.6562L5.28125 6L0.625 1.375C0.4375 1.1875 0.4375 0.84375 0.625 0.65625C0.8125 0.46875 1.15625 0.46875 1.34375 0.65625L6.34375 5.65625Z" fill="#604FED" />
              </svg>
            </div>
          ) : null}
        </>
      ))}
    </div>
  );
}