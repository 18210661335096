import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { StripeError } from "@stripe/stripe-js";

export type RtkError = FetchBaseQueryError | SerializedError | {error: string} | string;
export function getErrorString(error: RtkError | StripeError) : string | null {
  return (
    typeof error === "string" ? error :
      "message" in error ? error.message! :
        "error" in error ? error.error! :
          "data" in error ? (error.data! as any).toString() : null
  );
}