import React, { useReducer } from 'react';
import { useFragment } from "react-relay";
import {graphql} from 'react-relay';
import { AddOrganizationTenantButton_tenant$key } from './__generated__/AddOrganizationTenantButton_tenant.graphql';
import { AddOrganizationTenantButton_organization$key } from './__generated__/AddOrganizationTenantButton_organization.graphql';
import Button from '@app/components/Button/Button';
import AddOrganizationTenantModal from './AddOrganizationTenantModal';
import { AddOrganizationTenantButton_admin$key } from './__generated__/AddOrganizationTenantButton_admin.graphql';

export default function AddOrganizationTenantButton(props: {
  admin: AddOrganizationTenantButton_admin$key,
  tenant: AddOrganizationTenantButton_tenant$key
  organization: AddOrganizationTenantButton_organization$key | null
} | {
  admin: AddOrganizationTenantButton_admin$key,
  tenant: AddOrganizationTenantButton_tenant$key | null
  organization: AddOrganizationTenantButton_organization$key
}) {
  const admin = useFragment(
    graphql`
      fragment AddOrganizationTenantButton_admin on AdminQuery {
        permissions {
          organizations {
            settings
          }
        }
      }
    `,
    props.admin
  );

  const tenant = useFragment(
    graphql`
      fragment AddOrganizationTenantButton_tenant on AdminTenant {
        id
        ... AddOrganizationTenantModal_tenant
      }
    `,
    props.tenant
  );

  const organization = useFragment(
    graphql`
      fragment AddOrganizationTenantButton_organization on AdminOrganization {
        id
        ... AddOrganizationTenantModal_organization
      }
    `,
    props.organization
  );

  const [showModal, toggleModal] = useReducer(val => !val, false);

  if (admin.permissions.organizations.settings !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Button variant="default" onClick={toggleModal}>
        {organization ? 'Attach tenant' : 'Attach to organization'}
      </Button>
      {organization ? (
        <AddOrganizationTenantModal
          open={showModal}
          onHide={toggleModal}
          tenant={null}
          organization={organization}
        />
      ) : tenant ? (
        <AddOrganizationTenantModal
          open={showModal}
          onHide={toggleModal}
          tenant={tenant}
          organization={null}
        />
      ) : null}
    </React.Fragment>
  );
}