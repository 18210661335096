/**
 * @generated SignedSource<<34c7193d7fe998eeda035fdd13dddc59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NLDigIDProviderQuery$variables = {
  id: string;
};
export type NLDigIDProviderQuery$data = {
  readonly identityProvider: {
    readonly domains?: ReadonlyArray<{
      readonly certificate: {
        readonly name: string;
        readonly pkcs7: string;
        readonly validFrom: string;
        readonly validTo: string;
      } | null;
      readonly domain: {
        readonly id: string;
        readonly name: string;
      };
      readonly metadataUrl: string;
    }>;
    readonly id: string;
    readonly longName: string;
    readonly tenant: {
      readonly id: string;
    };
  } | null;
};
export type NLDigIDProviderQuery = {
  response: NLDigIDProviderQuery$data;
  variables: NLDigIDProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DutchDigIDDomain",
      "kind": "LinkedField",
      "name": "domains",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metadataUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Domain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BasicCertificate",
          "kind": "LinkedField",
          "name": "certificate",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pkcs7",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validFrom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validTo",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DutchDigID",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NLDigIDProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "identityProvider",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NLDigIDProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "identityProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29841d85a45b44b5d35da9ec531fd16c",
    "id": null,
    "metadata": {},
    "name": "NLDigIDProviderQuery",
    "operationKind": "query",
    "text": "query NLDigIDProviderQuery(\n  $id: ID!\n) {\n  identityProvider(id: $id) {\n    __typename\n    id\n    tenant {\n      id\n    }\n    longName\n    ... on DutchDigID {\n      domains {\n        metadataUrl\n        domain {\n          id\n          name\n        }\n        certificate {\n          name\n          pkcs7\n          validFrom\n          validTo\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ef6e41b74da4cbe974bbc6ac3a222c0";

export default node;
