import React from 'react';

import { translate } from '@app/i18n';
import { Modal, ModalProps } from '@app/components/Modal/Modal';
import CopyValueButton from '@app/components/CopyValueButton';
import InputField from '@app/components/FormFields/InputField/InputField';

interface Props {
  application: { name: string };
  clientSecret: string;
  onHide: ModalProps['onHide'];
  open: ModalProps['open'];
}

export default function ClientSecretModal(props: Props) {
  return (
    <Modal open={props.open} onHide={props.onHide}>
      <Modal.Header>
        <div>
          <h2>{translate('CLIENT_SECRET_FOR') + ' ' + props.application.name}</h2>
          <p>{translate('INFO_CLIENT_SECRET')}</p>
        </div>
          <InputField name="client-secret" type="text" value={props.clientSecret || ''} readOnly className='!border-none' button={<CopyValueButton variant="primary" value={props.clientSecret} tooltip="Copy client secret to clipboard" />}/>
      </Modal.Header>
    </Modal>
  );
}
