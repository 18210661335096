import React, { useEffect, useRef, useState } from 'react';
import {graphql} from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';
import cx from 'classnames';

import useEnvironment from '@app/hooks/useEnvironment';

import styles from './Dropdown.module.css';
import { useTenantId } from '@app/hooks/useTenant';
import { TenantDropdown_Query } from './__generated__/TenantDropdown_Query.graphql';
import { Link, useLocation } from 'react-router-dom';
import InputField from '@app/components/FormFields/InputField/InputField';

interface Props {
  onHide: () => void
}

export default function TenantDropdown(props: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const environment = useEnvironment();
  const location = useLocation();
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<TenantDropdown_Query>(
    graphql`
      query TenantDropdown_Query($tenantId: ID!) {
        tenant(id: $tenantId) {
          id
          name
        }

        viewer {
          tenants {
            id
            shortTenantId
            name

            organization {
              id
              name
            }
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const arrowRight = (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.34375 7.65625C8.53125 7.84375 8.53125 8.1875 8.34375 8.375L3.34375 13.375C3.15625 13.5625 2.8125 13.5625 2.625 13.375C2.4375 13.1875 2.4375 12.8438 2.625 12.6562L7.28125 8L2.625 3.375C2.4375 3.1875 2.4375 2.84375 2.625 2.65625C2.8125 2.46875 3.15625 2.46875 3.34375 2.65625L8.34375 7.65625Z" fill="#424974"/>
    </svg>
  );
  const arrowDown = (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.625 10.375L1.625 5.375C1.4375 5.1875 1.4375 4.84375 1.625 4.65625C1.8125 4.46875 2.15625 4.46875 2.34375 4.65625L7 9.3125L11.625 4.65625C11.8125 4.46875 12.1562 4.46875 12.3438 4.65625C12.5312 4.84375 12.5312 5.1875 12.3438 5.375L7.34375 10.375C7.15625 10.5625 6.8125 10.5625 6.625 10.375Z" fill="#424974"/>
    </svg>
  );

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!wrapperRef.current) return;

      const target = event.target as HTMLElement;
      if (!wrapperRef.current.contains(target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, []);

  const handleOpen = () => {
    setOpen(open => !open);
  }

  useEffect(() => {
    if (!open) return;
    searchRef.current?.focus();
  }, [open]);

  const path = subPath(location.pathname) ?? 'dashboard';

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <button className={cx(styles.button, styles[environment])} onClick={handleOpen}>
          <div className={styles.inner}>
            <span className={styles.text}>{data.tenant?.name}</span>
            {open ? arrowDown : arrowRight}
          </div>
        </button>
        <ul className={cx(styles.dropdown, styles[environment], {[styles.open]: open})}  onClick={props.onHide}>
          <Link to="/signup/tenant" className={styles.item}>
            <div className={styles.inner}>
              New tenant
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 2V7.5H13C13.25 7.5 13.5 7.75 13.5 8C13.5 8.28125 13.25 8.5 13 8.5H7.5V14C7.5 14.2812 7.25 14.5 7 14.5C6.71875 14.5 6.5 14.2812 6.5 14V8.5H1C0.71875 8.5 0.5 8.28125 0.5 8C0.5 7.75 0.71875 7.5 1 7.5H6.5V2C6.5 1.75 6.71875 1.5 7 1.5C7.25 1.5 7.5 1.75 7.5 2Z" fill="#424974"/>
              </svg>
            </div>
          </Link>
          {data.viewer?.tenants && data.viewer.tenants.length > 6 ? (
            <InputField
              name="search"
              type="search"
              placeholder="Search ..."
              iconLeft={(<div className="fa fa-search" />)}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              inputRef={searchRef}
              className={styles.search}
              autoComplete="off"
            />
          ) : null}
          {data.viewer?.tenants.filter(t => {
            if (!search?.length) return true;
            const orgMatch = t.organization ? t.organization.name.toUpperCase().includes(search.toUpperCase()) : false;
            const tenantMatch = t.name.toUpperCase().includes(search.toUpperCase());
            return orgMatch || tenantMatch;
          }).map(tenant => (
            <Link to={`/tenant/${tenant.shortTenantId}/${path}`} key={tenant.id} className={cx(styles.item, {[styles.active]: data.tenant?.id === tenant.id})} onClick={() => setOpen(false)}>
              <div className={styles.inner}>{tenant.name} {tenant.organization ? <>({tenant.organization.name})</> : null}</div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

TenantDropdown.Loader = function () {
  const environment = useEnvironment();
  return (
    <>
      <div className={styles.wrapper}>
        <button className={cx(styles.button, styles[environment])}>
          <div className={styles.inner}>
            <span>Loading ...</span>
          </div>
        </button>
      </div>
    </>
  );
}

function subPath(pathname: string) {
  const match = pathname.match(/^\/tenant\/\w+\/(\w+).*$/);
  if (!match) return null;
  return match[1] ?? null;
}