import React, { useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

import PlanSelection, { PlanChoice } from '../SubscriptionScreen/components/PlanSelection/PlanSelection';
import { CustomerRendition } from '@app/api/subscription';
import { useTenantId } from '@app/hooks/useTenant';
import { Form, FormError, InputField } from '@app/components/Form/Form';
import Alert from '@app/components/Alert/Alert';
import { set as setEnvironment } from '@app/redux/environmentSlice';

import './SignupScreen.css';
import BillingInformationFields from '../BillingInformationScreen/components/BillingInformationFields/BillingInformationFields';
import Button from '@app/components/Button/Button';
import { useDispatch } from '@app/redux';
import { SignupScreen_Billing_Query } from './__generated__/SignupScreen_Billing_Query.graphql';
import useMutation from '@app/hooks/useMutation';
import { Country, SignupScreen_Billing_CreateSubscriptionMutation } from './__generated__/SignupScreen_Billing_CreateSubscriptionMutation.graphql';
import { SignupScreen_Billing_ConfirmMutation } from './__generated__/SignupScreen_Billing_ConfirmMutation.graphql';

export default function BillingSignupScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState<PlanChoice[]>([]);
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<SignupScreen_Billing_Query>(
    graphql`
      query SignupScreen_Billing_Query($tenantId: ID!) {
        tenant(id: $tenantId) {
          id
          shortTenantId
          longTenantId
          viewerPermissions {
            billing
          }

          billing {
            information {
              company
              email
              country
              street
              city
              zip
              taxId
            }
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const [createExecutor, createStatus] = useMutation<SignupScreen_Billing_CreateSubscriptionMutation>(
    graphql`
      mutation SignupScreen_Billing_CreateSubscriptionMutation($input: CreateSubscriptionInput!) {
        createSubscription(input: $input) {
          __typename
          ... on CreateSubscriptionOutput {
            tenant {
              billing {
                subscription {
                  status
                  isCancelling
                  periodStartAt
                  periodEndAt
                  ... SubscriptionPlanModal_subscription
                  ... SubscriptionScreen_ProductPanel_subscription
                }
                paymentMethod {
                  name
                  brand
                  last4
                }
              }

              environments
              verifyEnvironments: environments(product: Verify)
              signaturesEnvironments: environments(product: Signatures)
              ageverificationEnvironments: environments(product: [Ageverification_DK])
            }
          }
          ... on PaymentIntent {
            id
            status
            secret
            subscriptionId
          }
        }
      }
    `
  );

  const [confirmExecutor, confirmStatus] = useMutation<SignupScreen_Billing_ConfirmMutation>(
    graphql`
      mutation SignupScreen_Billing_ConfirmMutation($input: ConfirmPaymentIntentInput!) {
        confirmPaymentIntent(input: $input) {
          subscription {
            status
            ... SubscriptionPlanModal_subscription
            ... ConfirmPaymentButton_subscription
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])

            billing {
              subscription {
                status
                isCancelling
                periodStartAt
                periodEndAt
                ... SubscriptionPlanModal_subscription
                ... SubscriptionScreen_ProductPanel_subscription
              }
            }
          }
        }
      }
    `
  );

  const hasAccess = data.tenant?.viewerPermissions.billing === "WRITE";
  const stripe = useStripe();
  const elements = useElements();

  const initialValues : CustomerRendition | undefined = data.tenant?.billing?.information ?? {
    city: '',
    company: '',
    country: 'DK',
    name: '',
    email: '',
    street: '',
    taxId: '',
    zip: ''
  };

  const handleSubmit = async (values: CustomerRendition) => {
    if (!elements || !stripe || !data.tenant) return;
    if (!plans.length) return;

    const element = elements.getElement(CardElement)!;
    const {error, token} = await stripe.createToken(element, {
      name: values.name ?? undefined,
      address_line1: values.street ?? undefined,
      address_city: values.city ?? undefined,
      address_zip: values.zip!.toString(),
      address_country: values.country ?? undefined
    });

    if (error) throw new Error(error.message);
    if (!token) throw new Error("No payment token available");

    const createResponse = await createExecutor.executePromise({
      input: {
        tenantId: data.tenant.id,
        products: plans.map(planChoice => ({
          planId: planChoice.id,
          product: planChoice.product,
        })),
        information: {
          tenantId: data.tenant.id,
          company: values.company!,
          email: values.email!,
          country: values.country! as Country,
          street: values.street!,
          city: values.city!,
          zip: values.zip!,
          taxId: values.taxId
        },
        stripePaymentToken: token.id
      }
    });

    if (createResponse.createSubscription.__typename === 'PaymentIntent') {
      const paymentIntent = createResponse.createSubscription;
      const confirmResult = await stripe.confirmCardPayment(paymentIntent.secret);
      if (confirmResult.error) throw new Error(confirmResult.error.message);
      await confirmExecutor.executePromise({
        input: {
          paymentIntentId: paymentIntent.id,
          subscriptionId: paymentIntent.subscriptionId!
        }
      });
    }

    dispatch(setEnvironment({tenantId: data.tenant.shortTenantId, environment: 'PRODUCTION'}));
    history.push(`/tenant/${data.tenant.shortTenantId}/billing/subscription`);
  };

  if (!data.tenant) return null;

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Signup for production</h1>
      </div>
      <div className="app-tab-content">
        {hasAccess === false ? (
          <Alert variant="error" className="mt-[15px]" title="Insufficient Permissions" message="You do not have the necessary permissions to setup a paid subscription, please contact your account owner." />
        ) : (
          <React.Fragment>
            <PlanSelection
              products={['Verify', 'Signatures', 'Ageverification_DK']}
              onSelect={setPlans}
            />

            <div className="mt-8 mb-8">
              {plans.length ? (
                <React.Fragment>
                  <div style={{fontSize: '18px'}}>
                    Total {plans[0].currency.toUpperCase()} <strong>{plans.reduce((memo, plan) => memo + plan.subscriptionPrice, 0).toLocaleString()}</strong> per {plans[0].interval.toLowerCase()}.
                  </div>
                  {plans[0].interval === 'MONTH' ? (
                    <div style={{fontSize: '15px'}}>
                      Your subscription period starts from the 1st of the month, and any unused time will be prorated on your first invoice.
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              <div style={{fontSize: '15px'}}>We charge an additional €160 / DKK1.200 setup fee per eID you order through Criipto.</div>
            </div>

            {plans.length ? (
              <Form
                initialValues={initialValues}
                key={`${data.tenant.shortTenantId}_subscription_signup`}
                onSubmit={handleSubmit}
                className="billing-signup-form max-w-[750px]"
              >
                {({isPending, isSuccess, error, values}) => (
                  <React.Fragment>
                    <div className="flex flex-col gap-4">
                      <div className="form-group">
                        <label className="control-label">Credit card</label>
                        <div className="stripe-card-input">
                          <CardElement options={{hidePostalCode: true}} />
                        </div>
                      </div>

                      <InputField<CustomerRendition>
                        type="text"
                        name="name"
                        placeholder="Name on card"
                        label="Name on card"
                        required
                      />

                      <BillingInformationFields values={values} />
                    </div>

                    <FormError error={error} />
                    <Button variant="primary" working={isPending} type="submit" data-test-id="billing_signup_submit">Subscribe now</Button>
                  </React.Fragment>
                )}
              </Form>
            ) : null}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
