/**
 * @generated SignedSource<<8259254b4abc8ca13b3bb5dee528d528>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NOBankIDOldProviderQuery$variables = {
  id: string;
};
export type NOBankIDOldProviderQuery$data = {
  readonly identityProvider: {
    readonly hasCertificate?: boolean;
    readonly id: string;
    readonly longName: string;
    readonly tenant: {
      readonly id: string;
    };
  } | null;
};
export type NOBankIDOldProviderQuery = {
  response: NOBankIDOldProviderQuery$data;
  variables: NOBankIDOldProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longName",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCertificate",
      "storageKey": null
    }
  ],
  "type": "NorwegianBankIDCServer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NOBankIDOldProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "identityProvider",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NOBankIDOldProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "identityProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdd0c54ee78a6ddde3da806a84d8f7c1",
    "id": null,
    "metadata": {},
    "name": "NOBankIDOldProviderQuery",
    "operationKind": "query",
    "text": "query NOBankIDOldProviderQuery(\n  $id: ID!\n) {\n  identityProvider(id: $id) {\n    __typename\n    id\n    tenant {\n      id\n    }\n    longName\n    ... on NorwegianBankIDCServer {\n      hasCertificate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c8548b9c71c927fbdf0c08f68cc840b";

export default node;
