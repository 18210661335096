import React, {useCallback} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch, Select} from '@components/Form';

import useEnvironment from '@app/hooks/useEnvironment';
import { TenantId } from '@app/hooks/useTenant';
import { FRToulouseProviderMutation } from './__generated__/FRToulouseProviderMutation.graphql';
import { FRToulouseProviderQuery } from './__generated__/FRToulouseProviderQuery.graphql';

interface Props {
  tenant: TenantId,
  environment: Environment
}


export interface FormValues {
  clientId : string
  clientSecret : string,
  callbackHost : string
}

export function FRToulouseProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<FRToulouseProviderQuery>(
    graphql`
      query FRToulouseProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on FrenchToulouse {
            clientId
            callbackHost
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|FR_TOULOUSE`),
    tenantId: tenant.relayId,
    environment: props.environment
  });

  const [{executePromise}] = useMutation<FRToulouseProviderMutation>(graphql`
    mutation FRToulouseProviderMutation(
      $input: UpdateFrenchToulouseInput!
    ) {
      updateFrenchToulouse(input: $input) {
        id
        isConfigured
        ... on FrenchToulouse {
          clientId
          callbackHost
        }
      }
    }
  `);

  if (!data.identityProvider) return null;
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  const initialValues : FormValues = {
    clientId: data.identityProvider.clientId ?? '',
    clientSecret: '',
    callbackHost: data.identityProvider.callbackHost ?? domains?.[0]?.name ?? '',
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess, values, setFieldValue}) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} French Toulouse
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label="Client ID"
                name="clientId"
              />
              <InputField
                type="password"
                label="Client Secret"
                name="clientSecret"
              />

            <Select
              name="callbackHost"
              label="Callback host"
              options={domains?.map(domain => ({label: domain.name, value: domain.name})) ?? []}
              help={(
                <small className="help-text">
                  This should match the host of the redirectUri you registered with Toulouse/Publik
                </small>
              )}
            />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your french toulouse settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
