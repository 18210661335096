import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  showSlackModal: boolean
};

export const initialState: ModalState = {
  showSlackModal: false
};

export const ModalSlice = createSlice({
  name: 'modals',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleSlackModal: (state) => {
      return {
        ...state,
        showSlackModal: !state.showSlackModal
      };
    }
  }
})

export const { toggleSlackModal } = ModalSlice.actions;
export default ModalSlice;