/**
 * @generated SignedSource<<5fc1e52674a04d34a591a7a4da43db5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddApiKeyForm_application$data = {
  readonly id: string;
  readonly " $fragmentType": "AddApiKeyForm_application";
};
export type AddApiKeyForm_application$key = {
  readonly " $data"?: AddApiKeyForm_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddApiKeyForm_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddApiKeyForm_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Signatures_Application",
  "abstractKey": null
};

(node as any).hash = "6c330b27b0e49d97c4a3a33b96301e83";

export default node;
