/**
 * @generated SignedSource<<3f161840b9022dbb58a8e0f9044e7470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeOrganizationUserPermissionsInput = {
  permissions: ReadonlyArray<string>;
  userId: string;
};
export type Users_ChangePermissions_Mutation$variables = {
  input: ChangeOrganizationUserPermissionsInput;
};
export type Users_ChangePermissions_Mutation$data = {
  readonly changeOrganizationUserPermissions: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"Users_OrganizationUser_User">;
    };
  };
};
export type Users_ChangePermissions_Mutation = {
  response: Users_ChangePermissions_Mutation$data;
  variables: Users_ChangePermissions_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Users_ChangePermissions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeOrganizationUserPermissionsOutput",
        "kind": "LinkedField",
        "name": "changeOrganizationUserPermissions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Users_OrganizationUser_User"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Users_ChangePermissions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeOrganizationUserPermissionsOutput",
        "kind": "LinkedField",
        "name": "changeOrganizationUserPermissions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUserPermission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6b96ad21ea97cc43e3f293f1cd73d94",
    "id": null,
    "metadata": {},
    "name": "Users_ChangePermissions_Mutation",
    "operationKind": "mutation",
    "text": "mutation Users_ChangePermissions_Mutation(\n  $input: ChangeOrganizationUserPermissionsInput!\n) {\n  changeOrganizationUserPermissions(input: $input) {\n    user {\n      ...Users_OrganizationUser_User\n      id\n    }\n  }\n}\n\nfragment Users_OrganizationUser_User on OrganizationUser {\n  id\n  name\n  email\n  permissions {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "0539dc97105e57b625872acdffb003bf";

export default node;
