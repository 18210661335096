/**
 * @generated SignedSource<<23bfad7e83604c02258f34f496e5e699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivateDomainKeyInput = {
  domainId: string;
  id: string;
  kid: string;
};
export type TokenSigningKeysActivateMutation$variables = {
  input: ActivateDomainKeyInput;
};
export type TokenSigningKeysActivateMutation$data = {
  readonly activateDomainKey: {
    readonly domain: {
      readonly keys: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly active: boolean;
            readonly id: string;
            readonly mayActivate: boolean;
            readonly mayActivateAt: string;
          };
        }>;
        readonly mayPrepare: boolean;
      };
    };
  };
};
export type TokenSigningKeysActivateMutation = {
  response: TokenSigningKeysActivateMutation$data;
  variables: TokenSigningKeysActivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "DomainKeyConnection",
  "kind": "LinkedField",
  "name": "keys",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mayPrepare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainKeyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainKey",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mayActivate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mayActivateAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "keys(first:1000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TokenSigningKeysActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateDomainKeyOutput",
        "kind": "LinkedField",
        "name": "activateDomainKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TokenSigningKeysActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateDomainKeyOutput",
        "kind": "LinkedField",
        "name": "activateDomainKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "026f0286484cf1cd49955b5e49b45450",
    "id": null,
    "metadata": {},
    "name": "TokenSigningKeysActivateMutation",
    "operationKind": "mutation",
    "text": "mutation TokenSigningKeysActivateMutation(\n  $input: ActivateDomainKeyInput!\n) {\n  activateDomainKey(input: $input) {\n    domain {\n      keys(first: 1000) {\n        mayPrepare\n        edges {\n          node {\n            id\n            active\n            mayActivate\n            mayActivateAt\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f79521f5286247b61dd7f12e701bba8";

export default node;
