import React from 'react';
import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import useMutation from '@app/hooks/useMutation';

import { ConfirmModal, ConfirmModalProps } from '@app/components/Modal/Modal';
import { Product } from './SubscriptionPlanModal/__generated__/SubscriptionPlanModal_AddMutation.graphql';
import { RemoveProductPlanModal_subscription$key } from './__generated__/RemoveProductPlanModal_subscription.graphql';
import { RemoveProductPlanModalMutation } from './__generated__/RemoveProductPlanModalMutation.graphql';
import useToast from '@app/hooks/useToast';
import { productName } from '@app/screens/BillingScreen/utils';

type Props = {
  subscription: RemoveProductPlanModal_subscription$key,
  product: Product,
  open: ConfirmModalProps["open"]
  onCancel: ConfirmModalProps["onCancel"]
  onRemoved: () => void
}
export default function RemoveProductPlanModal(props: Props) {
  const toast = useToast();
  const subscription = useFragment(
    graphql`
      fragment RemoveProductPlanModal_subscription on BillingSubscription {
        id
      }
    `,
    props.subscription
  );

  const [executor, status] = useMutation<RemoveProductPlanModalMutation>(
    graphql`
      mutation RemoveProductPlanModalMutation($input: RemoveProductPlanInput!) {
        removeProductPlan(input: $input) {
          subscription {
            ...SubscriptionScreen_ProductPanel_subscription
            ...SubscriptionScreen_ProductPanels_subscription
          }

          product {
            ...SubscriptionScreen_ProductPanel_product
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])
          }
        }
      }
    `
  );

  const handleConfirm = async () => {
    await executor.executePromise({
      input: {
        subscriptionId: subscription.id,
        product: props.product
      }
    });
    toast.success({title: `${productName(props.product)} plan cancelled`});
    props.onRemoved();
  };

  return (
    <ConfirmModal
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={handleConfirm}
      confirmText={`Cancel ${productName(props.product).toLowerCase()} plan`}
      confirmVariant="danger"
    >
      <div className='flex flex-col items-center'>
        <h2>{`Cancel ${productName(props.product).toLowerCase()} plan`}</h2>
        <p>Are you sure you wish to cancel your {productName(props.product).toLowerCase()} plan?</p>
        <p>You will lose access to the feature immediately.</p>
      </div>
    </ConfirmModal>
  )
}