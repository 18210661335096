/**
 * @generated SignedSource<<9dd638ead5d2b677a3880f5dfd90375e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateTenantKYCInput = {
  companyName: string;
  tenantId: string;
  vatCountry: string;
  vatNumber: string;
};
export type TenantMitIDScreen_Mutation$variables = {
  input: UpdateTenantKYCInput;
};
export type TenantMitIDScreen_Mutation$data = {
  readonly admin_updateTenantKYC: {
    readonly adminTenant: {
      readonly kyc: {
        readonly companyName: string;
        readonly vatCountry: string;
        readonly vatNumber: string;
      } | null;
    };
  };
};
export type TenantMitIDScreen_Mutation = {
  response: TenantMitIDScreen_Mutation$data;
  variables: TenantMitIDScreen_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantKYC",
  "kind": "LinkedField",
  "name": "kyc",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantMitIDScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantKYCOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantKYC",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantMitIDScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantKYCOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantKYC",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6992ab7444f8abb548f26b58008ebda1",
    "id": null,
    "metadata": {},
    "name": "TenantMitIDScreen_Mutation",
    "operationKind": "mutation",
    "text": "mutation TenantMitIDScreen_Mutation(\n  $input: UpdateTenantKYCInput!\n) {\n  admin_updateTenantKYC(input: $input) {\n    adminTenant {\n      kyc {\n        companyName\n        vatCountry\n        vatNumber\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f5712fe53715487c54e824cfb9e7fa7";

export default node;
