import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import jwtDecode from 'jwt-decode';
import React, { useEffect, useMemo } from 'react';

import { useVerifyAccessToken } from '@app/hooks/useAuth';
import { VerifyAuthToken } from '@app/redux/authSlice';
import { TenantRouteParams } from '@app/models';
import { Link, NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CriiptoAdminTenantsScreen from './screens/TenantsScreen';
import TenantCriiptoAdminScreen from '../TenantCriiptoAdminScreen';
import { TenantContext } from '@app/hooks/useTenant';
import EnvironmentToggle from '@app/components/EnvironmentToggle/EnvironmentToggle';
import StylingScreen from '../StylingScreen';
import ProviderRoutes from '../ProvidersScreen/ProvidersScreen';
import DomainRoutes from '../DomainsScreen/DomainsScreen';
import { TrackingContext } from '@app/hooks/useTracking';
import ApplicationRoutes from '../ApplicationsScreen/ApplicationsScreen';
import AuditScreen, { OrganizationAuditScreen } from './screens/AuditScreen/AuditScreen';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import DiagnosticsScreen from './screens/DiagnosticsScreen/DiagnosticsScreen';
import { LinkButton } from '@app/components/Button/Button';
import AnalyticsScreen from '../AnalyticsScreen/AnalyticsScreen';
import { CriiptoAdminScreen_TenantQuery } from './__generated__/CriiptoAdminScreen_TenantQuery.graphql';
import CriiptoAdminOrganizationsScreen from './screens/OrganizationsScreen/OrganizationsScreen';
import { CriiptoAdminScreen_OrganizationQuery } from './__generated__/CriiptoAdminScreen_OrganizationQuery.graphql';
import OrganizationTenantsScreen from './screens/OrganizationTenantsScreen/OrganizationTenantsScreen';
import AddOrganizationTenantButton from './components/AddOrganizationTenantButton';
import TenantKYCScreen from './screens/TenantKYCScreen/TenantKYCScreen';
import TenantBillingScreen from './screens/TenantBillingScreen';
import TenantUsageScreen from './screens/TenantUsageScreen';
import OrganizationUsersScreen from './screens/OrganizationUsersScreen';
import UsersScreen from '../UsersScreen/UsersScreen';
import TenantMitIDScreen from './screens/TenantMitIDScreen';
import OrganizationSettingsScreen from './screens/OrganizationSettingsScreen/OrganizationSettingsScreen';
import Helmet from '@app/components/Helmet';
import TenantFTNScreen from './screens/TenantFTNScreen/TenantFTNScreen';
import { isCriiptoAdmin } from '@criipto/dashboard-user-authentication';
import TenantiDINScreen from './screens/TenantiDINScreen/TenantiDINScreen';
import OrganizationUsageScreen from './screens/OrganizationUsageScreen';
import CriiptoAdminUsersScreen from './screens/UsersScreen';
import CriiptoAdminUserScreen from './screens/UserScreen/UserScreen';

export default function CriiptoAdminScreen() {
  const match = useRouteMatch();
  const token = useVerifyAccessToken();
  const tokenClaims = useMemo(() => token !== null ? jwtDecode<VerifyAuthToken>(token) : null, [token]);

  if (!tokenClaims || !isCriiptoAdmin(tokenClaims)) return null;

  const tenantsBreadcrumb = (
    <React.Fragment>
      <Link to={match.url}>Criipto Backoffice</Link>
      <i className="fa fa-angle-right" />
      <Link to={`${match.url}/tenants`}>Tenants</Link>
    </React.Fragment>
  );

  const organizationsBreadcrumb = (
    <React.Fragment>
      <Link to={match.url}>Criipto Backoffice</Link>
      <i className="fa fa-angle-right" />
      <Link to={`${match.url}/organizations`}>Organizations</Link>
    </React.Fragment>
  );

  const usersBreadcrumb = (
    <React.Fragment>
      <Link to={match.url}>Criipto Backoffice</Link>
      <i className="fa fa-angle-right" />
      <Link to={`${match.url}/users`}>Users</Link>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Switch>
        <Route path={match.path} exact={true}>
          <Redirect to={`${match.path}/tenants`} />
        </Route>
        <Route path={`${match.path}/tenants`} exact={true}>
          <Helmet title={['Tenants', 'Admin']} />
          <CriiptoAdminTenantsScreen breadcrumb={tenantsBreadcrumb} />
        </Route>
        <Route path={`${match.path}/tenants/:tenantId`}>
          <Helmet title={['Tenants', 'Admin']} />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <CriiptoAdminTenantScreen breadcrumb={tenantsBreadcrumb} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Route>
        <Route path={`${match.path}/organizations`} exact={true}>
          <Helmet title={['Organizations', 'Admin']} />
          <CriiptoAdminOrganizationsScreen breadcrumb={organizationsBreadcrumb} />
        </Route>
        <Route path={`${match.path}/organizations/:organizationId`}>
          <Helmet title={['Organizations', 'Admin']} />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <CriiptoAdminOrganizationScreen breadcrumb={organizationsBreadcrumb} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Route>
        <Route path={`${match.path}/users`} exact={true}>
          <Helmet title={['Users', 'Admin']} />
          <CriiptoAdminUsersScreen breadcrumb={usersBreadcrumb} />
        </Route>
        <Route path={`${match.path}/users/:userId`}>
          <Helmet title={['Users', 'Admin']} />
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <CriiptoAdminUserScreen breadcrumb={usersBreadcrumb} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Route>
      </Switch>
    </React.Fragment>
  )
}

type TenantProps = {
  breadcrumb: React.ReactNode
}
function CriiptoAdminTenantScreen(props: TenantProps) {
  const {breadcrumb} = props;
  const match = useRouteMatch<TenantRouteParams>();

  const data = useLazyLoadQuery<CriiptoAdminScreen_TenantQuery>(
    graphql`
      query CriiptoAdminScreen_TenantQuery($id: ID!) {
        tenant(id: $id) {
          id
          name
          entityIdentifier
          longTenantId
          shortTenantId
          ... EnvironmentToggle_tenant
        }

        admin {
          permissions {
            tenants {
              login
            }
          }
        }
      }
    `,
    {
      id: btoa(`tenant:${match.params.tenantId}`)
    }
  );

  if (!data.tenant) return null;

  return (
    <TrackingContext.Provider value={{enabled: false}}>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          Tenant details
        </div>
        <div className="flex flex-row justify-between">
          <h1>
            {data.tenant.name}
            <TenantContext.Provider value={{
              tenantId: data.tenant.shortTenantId,
              relayId: data.tenant.id
            }}>
              <EnvironmentToggle disableUpgrade={true} tenant={data.tenant}  />
            </TenantContext.Provider>
          </h1>
          {data.admin.permissions.tenants.login ? (
            <div>
              <LinkButton variant="default" to={`/support/${match.params.tenantId}/login`} target="_blank" rel="noopener noreferrer">
                Login to tenant
              </LinkButton>
              <p>Your actions will be surveilled for compliance reasons.</p>
            </div>
          ) : null}
        </div>

        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={match.url} exact={true} activeClassName="active">Backoffice</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/audit`} activeClassName="active">Audit</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/users`} activeClassName="active">Users</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/providers`} activeClassName="active">Providers</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/domains`} activeClassName="active">Domains</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/applications`} activeClassName="active">Applications</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/styling`} exact={true} activeClassName="active">Styling</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/diagnostics`} exact={true} activeClassName="active">Diagnostics</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/analytics`} activeClassName="active">Analytics</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/kyc`} activeClassName="active">KYC</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/mitid`} activeClassName="active">MitID</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/ftn`} activeClassName="active">FTN</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/idin`} activeClassName="active">iDIN</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/billing`} activeClassName="active">Billing</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/usage`} activeClassName="active">Usage</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-tab-content full-screen">
        <TenantContext.Provider value={{
          tenantId: data.tenant.shortTenantId,
          relayId: data.tenant.id,
          admin: true
        }}>
          <Switch>
            <Route path={match.path} exact={true}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantCriiptoAdminScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/audit`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <AuditScreen context="admin" tenantId={{
                    tenantId: data.tenant.shortTenantId,
                    relayId: data.tenant.id
                  }} />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/users`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <UsersScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/providers`}>
              <GraphQLErrorBoundary>
                <ProviderRoutes />
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/domains`}>
              <GraphQLErrorBoundary>
                <DomainRoutes />
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/applications`}>
              <GraphQLErrorBoundary>
                <ApplicationRoutes nav={false} />
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/styling`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <StylingScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/diagnostics`}>
              <GraphQLErrorBoundary>
                <DiagnosticsScreen />
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/analytics`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <AnalyticsScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/kyc`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantKYCScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/mitid`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantMitIDScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/ftn`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantFTNScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/idin`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantiDINScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/billing`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantBillingScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
            <Route path={`${match.path}/usage`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <TenantUsageScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
          </Switch>
        </TenantContext.Provider>
      </div>
    </TrackingContext.Provider>
  );
}

type OrganizationProps = {
  breadcrumb: React.ReactNode
}
function CriiptoAdminOrganizationScreen(props: OrganizationProps) {
  const {breadcrumb} = props;
  const match = useRouteMatch<{organizationId: string}>();

  const data = useLazyLoadQuery<CriiptoAdminScreen_OrganizationQuery>(
    graphql`
      query CriiptoAdminScreen_OrganizationQuery($id: ID!) {
        admin {
          organization(id: $id) {
            organization {
              name
            }

            ... AddOrganizationTenantButton_organization
          }
          ...AddOrganizationTenantButton_admin
        }
      }
    `,
    {
      id: match.params.organizationId
    }
  );

  if (!data.admin.organization) return null;
  const organization = data.admin.organization.organization;

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          Organization details
        </div>
        <div className="flex flex-row justify-between">
          <h1>
            {organization.name}
          </h1>
          <AddOrganizationTenantButton admin={data.admin} organization={data.admin.organization} tenant={null} />
        </div>

        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={match.url} exact={true} activeClassName="active">Settings</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/tenants`} activeClassName="active">Tenants</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/audit`} activeClassName="active">Audit</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/users`} activeClassName="active">Users</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/usage`} activeClassName="active">Usage</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-tab-content full-screen">
        <Switch>
          <Route path={match.path} exact={true}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <OrganizationSettingsScreen organizationId={match.params.organizationId} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/tenants`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <OrganizationTenantsScreen organizationId={match.params.organizationId} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/audit`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <OrganizationAuditScreen organizationId={match.params.organizationId} context="admin" />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/users`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <OrganizationUsersScreen organizationId={match.params.organizationId} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/usage`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <OrganizationUsageScreen organizationId={match.params.organizationId} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}