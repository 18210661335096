import React, {useState} from 'react';
import {graphql} from 'react-relay';
import { useStripe } from "@stripe/react-stripe-js";

import Button from "@app/components/Button";
import { StripeError } from '@stripe/stripe-js';
import useMutation from '@app/hooks/useMutation';
import { ConfirmPaymentButton_ConfirmMutation } from './__generated__/ConfirmPaymentButton_ConfirmMutation.graphql';
import { useFragment } from 'react-relay';
import { ConfirmPaymentButton_subscription$key } from './__generated__/ConfirmPaymentButton_subscription.graphql';


interface Props {
  onSucces: () => void
  onError: (error: StripeError | any) => void
  subscription: ConfirmPaymentButton_subscription$key
}

export default function ConfirmPaymentButton(props: Props) {
  const {onError, onSucces} = props;
  const stripe = useStripe();
  const [pending, setPending] = useState(false);

  const subscription = useFragment(
    graphql`
      fragment ConfirmPaymentButton_subscription on BillingSubscription {
        id
        paymentIntent {
          id
          status
          secret
          subscriptionId
        }
      }
    `,
    props.subscription
  )

  const [confirmExecutor, confirmStatus] = useMutation<ConfirmPaymentButton_ConfirmMutation>(
    graphql`
      mutation ConfirmPaymentButton_ConfirmMutation($input: ConfirmPaymentIntentInput!) {
        confirmPaymentIntent(input: $input) {
          subscription {
            status
            ... ConfirmPaymentButton_subscription
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])
          }
        }
      }
    `
  );

  if (!subscription.paymentIntent) return null;

  const handleClick = async () => {
    if (!stripe) return;
    setPending(true);
    const result = await stripe.confirmCardPayment(subscription.paymentIntent!.secret);
    if (result.error) {
      onError(result.error);
      setPending(false);
      return;
    }

    await confirmExecutor.executePromise({
      input: {
        subscriptionId: subscription.id,
        paymentIntentId: subscription.paymentIntent!.id
      }
    }).then(() => {
      setPending(false);
      onSucces();
    }).catch(err => {
      setPending(false);
      onError(err);
    });
  };

  return (
    <Button variant="primary" working={pending} onClick={handleClick} autoWidth>Confirm payment</Button>
  )
}