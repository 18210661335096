/**
 * @generated SignedSource<<121446a42d5042335f7b000fc82e96a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveOrganizationTenantModal_tenant$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly organization: {
      readonly name: string;
    };
  } | null;
  readonly tenant: {
    readonly name: string;
  };
  readonly " $fragmentType": "RemoveOrganizationTenantModal_tenant";
};
export type RemoveOrganizationTenantModal_tenant$key = {
  readonly " $data"?: RemoveOrganizationTenantModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveOrganizationTenantModal_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveOrganizationTenantModal_tenant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminOrganization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};
})();

(node as any).hash = "cfd8e670c7195392fbe3f9754353a20e";

export default node;
