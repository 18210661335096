import React from 'react';
import * as Sentry from "@sentry/react";
import Alert from './Alert/Alert';

export default function GraphQLErrorBoundary(props: {children: React.ReactElement}) {
  return(
    <Sentry.ErrorBoundary fallback={({error}) => (
      <Alert
        variant="error"
        title="Server Error"
        message={error.message}
        style={{margin: '10px'}}
      />
    )}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}