/**
 * @generated SignedSource<<97a4a3a4fb52a577833169b992decbb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserScreen_AdminQuery$variables = {
  id: string;
};
export type UserScreen_AdminQuery$data = {
  readonly admin: {
    readonly user: {
      readonly email: string;
      readonly id: string;
      readonly name: string;
      readonly sub: string;
      readonly tenants: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"TenantsScreen_AdminTenantItem_tenant">;
      }>;
    } | null;
  };
};
export type UserScreen_AdminQuery = {
  response: UserScreen_AdminQuery$data;
  variables: UserScreen_AdminQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sub",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserScreen_AdminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TenantsScreen_AdminTenantItem_tenant"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserScreen_AdminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQuery",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenant",
                "kind": "LinkedField",
                "name": "tenants",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longTenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityIdentifier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortId",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantBillable",
                    "kind": "LinkedField",
                    "name": "billable",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Verify",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantBillableProduct",
                        "kind": "LinkedField",
                        "name": "Signatures",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminTenantAgeverificationBillableProduct",
                        "kind": "LinkedField",
                        "name": "Ageverification",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdminTenantBillableProduct",
                            "kind": "LinkedField",
                            "name": "dk",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49b0fe15e450c0f7a3e534924cf4b2c6",
    "id": null,
    "metadata": {},
    "name": "UserScreen_AdminQuery",
    "operationKind": "query",
    "text": "query UserScreen_AdminQuery(\n  $id: ID!\n) {\n  admin {\n    user(id: $id) {\n      id\n      sub\n      name\n      email\n      tenants {\n        id\n        ...TenantsScreen_AdminTenantItem_tenant\n      }\n    }\n  }\n}\n\nfragment TenantsScreen_AdminTenantItem_tenant on AdminTenant {\n  tenant {\n    name\n    shortTenantId\n    longTenantId\n    entityIdentifier\n    organization {\n      id\n      shortId\n      name\n    }\n    id\n  }\n  billable {\n    Verify {\n      actual\n    }\n    Signatures {\n      actual\n    }\n    Ageverification {\n      dk {\n        actual\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4107060111ba8f8ef00ec33679d269a";

export default node;
