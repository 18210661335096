import React, {useState} from 'react';
import { ConnectionHandler, useFragment } from 'react-relay';
import {graphql} from 'react-relay';

import Button from '@app/components/Button';
import Modal, {ConfirmModal} from '@app/components/Modal/Modal';
import { translate } from '@app/i18n';
import { DeleteApplicationButton_application$key } from './__generated__/DeleteApplicationButton_application.graphql';
import useMutation from '@app/hooks/useMutation';
import { DeleteApplicationButtonMutation } from './__generated__/DeleteApplicationButtonMutation.graphql';
import useMounted from '@app/hooks/useMounted';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

type Props = {
  application: DeleteApplicationButton_application$key,
  className?: string,
  onDelete?: () => void,
  autoWidth?: boolean
}
export default function DeleteApplicationButton(props: Props) {
  const toast = useToast();
  const isMounted = useMounted();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAuth0, setShowAuth0] = useState(false);

  const application = useFragment(graphql`
    fragment DeleteApplicationButton_application on Application {
      id
      name
      tags

      domain {
        id
      }
    }
  `, props.application);

  const [{execute, executePromise}, state] = useMutation<DeleteApplicationButtonMutation>(graphql`
    mutation DeleteApplicationButtonMutation(
      $connections: [ID!]!
      $input: DeleteApplicationInput!
    ) {
      deleteApplication(input: $input) {
        deletedApplicationID @deleteEdge(connections: $connections)
      }
    }
  `);

  const isAuth0 = application.tags?.includes('auth0');

  const handleDelete = async () => {
    const connections = [
      ConnectionHandler.getConnectionID(
        application.domain.id,
        'domain_applications'
      ),
      ConnectionHandler.getConnectionID(
        application.domain.id,
        'domain_applications',
        null
      )
    ];

    return executePromise({
      input: {
        applicationId: application.id
      },
      connections
    }).then(() => {
      toast.success({title: 'Application deleted'});
      if (!isMounted.current) return;
      setShowAuth0(false);
      setShowConfirm(false);
      props.onDelete?.();
    });
  }

  const handleConfirm = () => {
    if (isAuth0) {
      setShowConfirm(false);
      setShowAuth0(true);
    } else {
      return handleDelete();
    }
  }

  const handleClick = (event : React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setShowConfirm(true);
  }

  return (
    <React.Fragment>
      <Button
        tooltip="Delete application"
        className={props.className}
        variant="danger"
        type="button"
        onClick={handleClick}
        working={state.pending}
        icon={<i className="fa fa-trash-alt" />}
      />
      <ConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        confirmText={translate('DELETE_APPLICATION')}
        confirmVariant="danger"
      >
          <>
            <h2>{translate('DELETE_APPLICATION')}</h2>
            <p className='!m-0'>{translate('INFO_DELETE_APPLICATION', {application: application.name})}</p>
          </>
        {state.error ? (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        ) : null}
      </ConfirmModal>
      <Modal open={showAuth0} onHide={handleDelete} >
        <Modal.Header>
            <h2>{translate('DELETE_APPLICATION')}</h2>
            <p>{translate('INFO_DELETE_APPLICATION_AUTH0', {application: application.name})}</p>
        </Modal.Header>
      </Modal>
    </React.Fragment>
  )
}