/**
 * @generated SignedSource<<09869f5d70daef46778ee4adfda50b0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Users_OrganizationUser_User$data = {
  readonly email: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly permissions: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "Users_OrganizationUser_User";
};
export type Users_OrganizationUser_User$key = {
  readonly " $data"?: Users_OrganizationUser_User$data;
  readonly " $fragmentSpreads": FragmentRefs<"Users_OrganizationUser_User">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Users_OrganizationUser_User",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUserPermission",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationUser",
  "abstractKey": null
};
})();

(node as any).hash = "b666c72e9d572a2341d0240e05c1413a";

export default node;
