/**
 * @generated SignedSource<<b926f7e400d9f2c38679793b5f5cdf88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Country = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type UpdateBillingInformationInput = {
  city: string;
  company: string;
  country: Country;
  email: string;
  street: string;
  taxId?: string | null;
  tenantId: string;
  zip: string;
};
export type TenantBillingScreenMutation$variables = {
  input: UpdateBillingInformationInput;
};
export type TenantBillingScreenMutation$data = {
  readonly admin_updateTenantBillingInformation: {
    readonly adminTenant: {
      readonly stripe: {
        readonly customerId: string | null;
      } | null;
      readonly tenant: {
        readonly billing: {
          readonly information: {
            readonly city: string | null;
            readonly company: string | null;
            readonly country: Country | null;
            readonly email: string | null;
            readonly street: string | null;
            readonly taxId: string | null;
            readonly zip: string | null;
          } | null;
        } | null;
      };
    };
    readonly tenant: {
      readonly billing: {
        readonly information: {
          readonly city: string | null;
          readonly company: string | null;
          readonly country: Country | null;
          readonly email: string | null;
          readonly street: string | null;
          readonly taxId: string | null;
          readonly zip: string | null;
        } | null;
      } | null;
    };
  };
};
export type TenantBillingScreenMutation = {
  response: TenantBillingScreenMutation$data;
  variables: TenantBillingScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminTenantStripe",
  "kind": "LinkedField",
  "name": "stripe",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingInformation",
  "kind": "LinkedField",
  "name": "information",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Billing",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Billing",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantBillingScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantBillingInformationOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantBillingInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantBillingScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantBillingInformationOutput",
        "kind": "LinkedField",
        "name": "admin_updateTenantBillingInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "adminTenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06ef8816d7e08c700148c53035a1be9c",
    "id": null,
    "metadata": {},
    "name": "TenantBillingScreenMutation",
    "operationKind": "mutation",
    "text": "mutation TenantBillingScreenMutation(\n  $input: UpdateBillingInformationInput!\n) {\n  admin_updateTenantBillingInformation(input: $input) {\n    adminTenant {\n      stripe {\n        customerId\n      }\n      tenant {\n        billing {\n          information {\n            company\n            email\n            country\n            street\n            city\n            zip\n            taxId\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    tenant {\n      billing {\n        information {\n          company\n          email\n          country\n          street\n          city\n          zip\n          taxId\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aaa582affbb7be8730f0ccaeee7262e5";

export default node;
