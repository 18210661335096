import React from 'react';

import {Switch, Form, FormError, FormSuccess} from '@components/Form';
import { translate } from '@app/i18n';
import Button, { AnchorButton } from '@components/Button';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { SSOQuery } from './__generated__/SSOQuery.graphql';
import useMutation from '@app/hooks/useMutation';
import { SSOMutation } from './__generated__/SSOMutation.graphql';

interface Props {
  domain: string
  tenantId: string
}

type SSOConfiguration = NonNullable<SSOQuery["response"]["domain"]>["sso"]

export default function DomainSSOSection(props : Props) {
  const data = useLazyLoadQuery<SSOQuery>(graphql`
    query SSOQuery($id: ID!) {
      domain(id: $id) {
        id
        sso {
          oauth2
          wsfederation
        }
      }
    }
  `, {
    id: btoa(`domain:${props.tenantId}|${props.domain}`)
  });

  const [{executePromise}, state] = useMutation<SSOMutation>(graphql`
    mutation SSOMutation($input: UpdateDomainSSOInput!) {
      updateDomainSSO(input: $input) {
        domain {
          sso {
            oauth2
            wsfederation
          }
        }
      }
    }
  `);

  if (!data.domain) return null;
  const domain = data.domain;

  const handleSubmit = async (values : SSOConfiguration) => {

    return executePromise({
      input: {
        domainId: domain.id,
        oauth2: values.oauth2,
        wsfederation: values.wsfederation
      }
    });
  };

  return (
    <React.Fragment>
      <Form initialValues={domain.sso} onSubmit={handleSubmit} data-test-id="form" key="domain_sso_editor">
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <Switch<SSOConfiguration> name="oauth2" label={translate('INFO_DOMAIN_SSO_enableForOAuth2')} />
            <Switch<SSOConfiguration> name="wsfederation" label={translate('INFO_DOMAIN_SSO_enableForWSFederation')} />

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'SSO settings updated'} />
            )}

            <div className="button-group flex-start" style={{marginTop: '20px'}}>
              <AnchorButton variant="default" autoWidth href="https://docs.criipto.com/verify/guides/sso/"  rel="noopener noreferrer">
                Learn more about SSO
              </AnchorButton>
              <Button variant="primary" type="submit" working={isPending} autoWidth>
                Save SSO settings
              </Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  )
}

DomainSSOSection.Help = <p>{translate('INFO_DOMAIN_SSO')}</p>;