import React, {useState, useMemo, useEffect} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import {useHistory} from 'react-router-dom';

import Button from '@components/Button';
import {useSelector} from '@app/redux';

import styles from './SignupTenantScreen.module.css';
import cx from 'classnames';
import useTracking from '@app/hooks/useTracking';

import useMutation from '@app/hooks/useMutation';
import { SignupTenantScreenQuery } from './__generated__/SignupTenantScreenQuery.graphql';
import { SignupTenantScreenMutation, Product } from './__generated__/SignupTenantScreenMutation.graphql';
import Checkbox from '@app/components/FormFields/Checkbox';
import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';
import Alert from '@app/components/Alert/Alert';

const ALL_PRODUCTS : Exclude<Product, '%future added value'>[] = [
  'Verify',
  'Signatures',
  'Ageverification_DK'
] as const;

export default function SignupTenantScreen() {
  const history = useHistory();
  const tracking = useTracking();

  const data = useLazyLoadQuery<SignupTenantScreenQuery>(
    graphql`
      query SignupTenantScreenQuery {
        viewer @required(action: THROW) {
          ... on UserViewer {
            organizations {
              id
              name

              viewerPermissions {
                tenants
              }
            }
          }
        }
      }
    `,
    {

    }
  );

  const [executor, status] = useMutation<SignupTenantScreenMutation>(
    graphql`
      mutation SignupTenantScreenMutation($input: CreateTenantInput!) {
        createTenant(input: $input) {
          id
          shortTenantId
        }
      }
    `
  );

  const profile = useSelector(state => state.auth.profile);
  const [tenantName, setTenantName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [products, setProducts] = useState<Exclude<Product, '%future added value'>[]>([]);

  useEffect(() => {
    if (!profile) return;
    tracking.identify(profile);
  }, [profile?.sub]);

  if (!data.viewer) return null;

  const handleSubmit = async (event : React.FormEvent) => {
    event.preventDefault();

    const result = await executor.executePromise({
      input: {
        name: tenantName,
        source: window.location.href,
        organizationId: organizationId?.length ? organizationId : null,
        products
      }
    });

    const returnTo = `/tenant/${result.createTenant.shortTenantId}/onboarding`;

    history.push(`/refreshlogin/${result.createTenant.shortTenantId}?returnTo=${encodeURIComponent(returnTo)}`);
  }

  const organizations = data.viewer.organizations?.filter(o => o.viewerPermissions.tenants === 'WRITE') ?? [];

  return (
    <div className="app-content container max-w-[750px]">
      <div className={cx(styles["signup-flow"], styles["signup-organization-screen"])}>
        <h1>Set up your Criipto tenant</h1>

        <form name="form" onSubmit={handleSubmit} data-test-id="form" className="flex flex-col gap-[25px]">
          <p className="">
            The tenant name is for you only and will not be shown to your users.<br />
            Both testing and production happen within the context of an tenant.
          </p>
          <p className="text-red">
            It is free to setup your tenant - no credit card needed
          </p>
          {organizations.length ? (
            <Select
              label="Organization"
              id="organizationId"
              name="organizationId"
              value={organizationId}
              onChange={event => setOrganizationId(event.target.value)}
            >
              <option value="">No organization</option>
              {organizations.map(organization => (
                <option key={organization.id} value={organization.id}>{organization.name}</option>
              ))}
            </Select>
          ) : null}

          <InputField
            label="Your tenant name"
            id="signup_companyname"
            name="signup_companyname"
            value={tenantName}
            onChange={(event) => setTenantName(event.target.value)}
            placeholder="Name of tenant"
            data-test-id="name_input"
            required
          />

          <div className="flex flex-row gap-3">
            {ALL_PRODUCTS.map(product => (
              <Checkbox
                name={product}
                label={productName(product)}
                checked={products.includes(product)}
                onChange={event => setProducts(products => products.filter(s => s !== product).concat(event.target.checked ? [product] : []))}
              />
            ))}
          </div>

          {status.error && (
            <Alert variant="error" title="Error setting up tenant" message={status.error.message} />
          )}
          <div className="flex flex-row flex-end">
            <Button variant="primary" type="submit" data-test-id="submit_button" working={status.pending}>Submit</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export function productName(product: Product) {
  if (product === 'Ageverification_DK') return 'Age verification (DK)';
  return product.toString();
}