/**
 * @generated SignedSource<<5f86d4bf9d9f3fab4dd82a3577e74d18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureToggle_tenant$data = {
  readonly features: {
    readonly CUSTOM_UI: boolean;
    readonly FRENCH_PUBLIK: boolean;
  };
  readonly id: string;
  readonly " $fragmentType": "FeatureToggle_tenant";
};
export type FeatureToggle_tenant$key = {
  readonly " $data"?: FeatureToggle_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureToggle_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureToggle_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "FRENCH_PUBLIK",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "CUSTOM_UI",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "9659ba3f3f3b9d3419de76f570701036";

export default node;
