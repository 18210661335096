import React, { useEffect, useState } from 'react';
import {graphql} from 'react-relay';
import { useQueryLoader, usePreloadedQuery, PreloadedQuery } from 'react-relay';

import InputField from '@app/components/FormFields/InputField/InputField';
import { Tenants_OrganizationTenantsScreen_Query } from './__generated__/Tenants_OrganizationTenantsScreen_Query.graphql';
import { useRouteMatch } from 'react-router-dom';
import { OrganizationRouteParams } from '@app/models';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { LinkButton } from '@app/components/Button/Button';

const Query = graphql`
  query Tenants_OrganizationTenantsScreen_Query($organizationId: ID!, $query: String) {
    organization(id: $organizationId) @required(action: THROW) {
      viewerPermissions {
        tenantsLogin
      }

      tenants(query: $query) {
        id
        name
        shortTenantId
      }
    }
  }
`;

export default function OrganizationTenantsScreen() {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);
  const match = useRouteMatch<OrganizationRouteParams>();

  const [queryReference, loadQuery] = useQueryLoader<Tenants_OrganizationTenantsScreen_Query>(
    Query,
    null
  );

  useEffect(() => {
    loadQuery({
      organizationId: btoa(`organization:${match.params.organizationId}`),
      query: debouncedSearch?.length ? debouncedSearch : null
    });
  }, [match, debouncedSearch]);

  return (
    <div className="max-w-[750px]">
      <div className="accordion">
        <InputField
          name="search"
          type="search"
          placeholder="Search for tenant name"
          iconLeft={(<div className="fa fa-search" />)}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className='!mb-[16px]'
        />
        {queryReference ? (
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <Results queryReference={queryReference} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        ) : null}
      </div>
    </div>
  );
}

function Results(props: {queryReference: PreloadedQuery<Tenants_OrganizationTenantsScreen_Query>}) {
  const match = useRouteMatch<OrganizationRouteParams>();
  const data = usePreloadedQuery<Tenants_OrganizationTenantsScreen_Query>(Query, props.queryReference);
  const canLogin = data.organization.viewerPermissions.tenantsLogin;

  return (
    <>
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-4">
            <h3>Tenant</h3>
          </div>
          <div className="col-xs-4">

          </div>
          <div className="col-xs-4">
          </div>
        </div>
      </div>
      {data.organization.tenants.map(tenant => (
        <div className="accordion-item" key={tenant.id}>
          <div className="accordion-header">
            <div className="row">
              <div className="col-xs-4">
                <strong>{tenant.name}</strong>
              </div>
              <div className="col-xs-4">
                {canLogin ? (
                  <LinkButton to={`/organization/${match.params.organizationId}/login/${tenant.shortTenantId}`} variant="default" target="_blank">
                    Login
                  </LinkButton>
                ) : null}
              </div>
              <div className="col-xs-4">
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}