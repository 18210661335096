import { saveAs } from 'file-saver';

export function arraysToCSV(arrays: (string | number)[][], delimiter = ';') {
  if (!arrays.length) return null;

  return arrays.reduce(function(csv, items) {
    return (
      csv +
      items
        .map(item => `"${(item ? item.toString() : '').replace(/\"/g, '"')}"`)
        .join(delimiter) +
      '\n'
    );
  }, '');
}

type Object = {
  [key: string]: string | number
}

export function objectsToCSV(objects: Object[], delimiter = ';') {
  if (!objects.length) return null;

  const columns = objects.reduce((columns: string[], object) => {
    Object.keys(object).forEach(key => {
      if (!columns.includes(key)) columns.push(key);
    });
    return columns;
  }, [] as string[]);

  return arraysToCSV(
    [columns].concat(
      objects.map(function(object) {
        return columns.reduce(function(values, column) {
          return values.concat([object[column]?.toString() ?? '']);
        }, [] as string[]);
      })
    ),
    delimiter
  );
}

export function downloadCSV(filename: string, contents: string) {
  if (!contents) return null;

  const file = new File([contents], filename, {
    type: 'text/csv;charset=utf-8'
  });
  saveAs(file);
}