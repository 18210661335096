/**
 * @generated SignedSource<<61a3ec053962c9c2a719efceb0b212a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IdentityProvidersSection_tenant$data = {
  readonly features: {
    readonly DKNEMID_ENABLED: boolean;
    readonly FRENCH_PUBLIK: boolean;
  };
  readonly " $fragmentType": "IdentityProvidersSection_tenant";
};
export type IdentityProvidersSection_tenant$key = {
  readonly " $data"?: IdentityProvidersSection_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"IdentityProvidersSection_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IdentityProvidersSection_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "FRENCH_PUBLIK",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "DKNEMID_ENABLED",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "3b66a053a2b9a293a3e3f90ef9f59cc2";

export default node;
