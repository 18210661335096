import React, {useMemo, useState} from 'react';
import {LOGIN_NEMID_CALLBACK_URL} from '@app/constants';
import {createNemIDAuthClient} from '@app/auth';
import jwt_decode from "jwt-decode";

import Button from '@components/Button';
import Alert from '@app/components/Alert/Alert';
import { useField } from 'formik';
import { DanishMitIDApplyValues } from './Apply';

interface MocesClaims {
  'gov:saml:attribute:CvrNumberIdentifier': string,
  'oid:2.5.4.10': string
}

interface Props {
  acrValue: string,
  children: React.ReactNode,
  style?: React.CSSProperties
}

export default function NemIdLoginButton(props: Props) {
  const [error, setError] = useState<Error | null>(null);
  const [, , {setValue: setVatCountry}] = useField<DanishMitIDApplyValues["vatCountry"]>('vatCountry');
  const [, , {setValue: setVatNumber}] = useField<DanishMitIDApplyValues["vatNumber"]>('vatNumber');
  const [, , {setValue: setCompanyName}] = useField<DanishMitIDApplyValues["companyName"]>('companyName');
  const [, , {setValue: setBearerToken}] = useField<DanishMitIDApplyValues["bearerToken"]>('bearerToken');
  const nemIdAuthClient = useMemo(() => createNemIDAuthClient(), []);

  const handleLogin = async () => {
    await nemIdAuthClient.popup.authorize({
      redirectUri: LOGIN_NEMID_CALLBACK_URL,
      acrValues: props.acrValue
    }).then(response => {
      if (response.error) {
        setError(new Error(response.error));
        return;
      }

      /*
      * We unsafely decode the token here to provide a preview of data
      * Backend will verify token and override data in case of modifications
      * TODO: @criipto/auth-js should return a claims object, that via ACR value and (namespaced|compact) can be type guarded into a specific claims type
      */
      const decoded = jwt_decode<MocesClaims>(response.id_token!);
      const vatNumber = decoded['gov:saml:attribute:CvrNumberIdentifier'].trim();
      setVatCountry('DK');
      setVatNumber(vatNumber);
      setCompanyName(decoded['oid:2.5.4.10'].replace(`// CVR:${vatNumber}`, '').trim());
      setBearerToken(response.id_token!);
    }).catch(err => {
      setError(err);
    });
  };

  return (
    <React.Fragment>
      {error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={error.message} />
      )}
      <Button variant="primary" onClick={handleLogin} style={props.style}>{props.children}</Button>
    </React.Fragment>
  )
}