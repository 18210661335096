/**
 * @generated SignedSource<<b89b463b48423061b7d97e4303346347>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RenameTenantInput = {
  name: string;
  tenantId: string;
};
export type SettingsScreen_Mutation$variables = {
  input: RenameTenantInput;
};
export type SettingsScreen_Mutation$data = {
  readonly renameTenant: {
    readonly tenant: {
      readonly name: string;
    };
  };
};
export type SettingsScreen_Mutation = {
  response: SettingsScreen_Mutation$data;
  variables: SettingsScreen_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameTenantOutput",
        "kind": "LinkedField",
        "name": "renameTenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameTenantOutput",
        "kind": "LinkedField",
        "name": "renameTenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e189d7c44c5ed2fb157c08897b5f6483",
    "id": null,
    "metadata": {},
    "name": "SettingsScreen_Mutation",
    "operationKind": "mutation",
    "text": "mutation SettingsScreen_Mutation(\n  $input: RenameTenantInput!\n) {\n  renameTenant(input: $input) {\n    tenant {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4e0c3f622223fe2fb2c6c26dddb9144";

export default node;
