/**
 * @generated SignedSource<<29735a6170324567f88cd8b286f27e5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apply_MitID_tenant$data = {
  readonly id: string;
  readonly longTenantId: string;
  readonly name: string;
  readonly " $fragmentType": "Apply_MitID_tenant";
};
export type Apply_MitID_tenant$key = {
  readonly " $data"?: Apply_MitID_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"Apply_MitID_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Apply_MitID_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longTenantId",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "e9518a6ccccf1eceaef256ea37ae4f60";

export default node;
