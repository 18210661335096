/**
 * @generated SignedSource<<5003c92b33a9a379dbc7ca45bb6ab8c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationUsersScreenQuery$variables = {
  id: string;
};
export type OrganizationUsersScreenQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly id: string;
          readonly name: string | null;
          readonly sub: string;
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationUsersScreen_AddForm_organization">;
  };
};
export type OrganizationUsersScreenQuery = {
  response: OrganizationUsersScreenQuery$data;
  variables: OrganizationUsersScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationUserEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUser",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sub",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsersScreenQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OrganizationUsersScreen_AddForm_organization"
            },
            {
              "alias": "users",
              "args": null,
              "concreteType": "OrganizationUserConnection",
              "kind": "LinkedField",
              "name": "__organization_users_connection",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationUsersScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "users(first:1000)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "organization_users",
            "kind": "LinkedHandle",
            "name": "users"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0aa54746cb96546c7a6946743dd3bdb6",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "organization",
            "users"
          ]
        }
      ]
    },
    "name": "OrganizationUsersScreenQuery",
    "operationKind": "query",
    "text": "query OrganizationUsersScreenQuery(\n  $id: ID!\n) {\n  organization(id: $id) {\n    id\n    ...OrganizationUsersScreen_AddForm_organization\n    users(first: 1000) {\n      edges {\n        node {\n          id\n          sub\n          email\n          name\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment OrganizationUsersScreen_AddForm_organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "c3ad9506f0d07a76243e8b8895d88e3b";

export default node;
