/**
 * @generated SignedSource<<3a78fb786e7401c656d16ca667d23f67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TenantKYCScreen_Query$variables = {
  id: string;
};
export type TenantKYCScreen_Query$data = {
  readonly admin: {
    readonly tenant: {
      readonly id: string;
      readonly kyc: {
        readonly companyName: string;
        readonly vatCountry: string;
        readonly vatNumber: string;
      } | null;
    } | null;
  };
};
export type TenantKYCScreen_Query = {
  response: TenantKYCScreen_Query$data;
  variables: TenantKYCScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQuery",
    "kind": "LinkedField",
    "name": "admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": "AdminTenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantKYC",
            "kind": "LinkedField",
            "name": "kyc",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vatCountry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vatNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantKYCScreen_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantKYCScreen_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9fa8172706ca5ec0bcc3e329e33f1c52",
    "id": null,
    "metadata": {},
    "name": "TenantKYCScreen_Query",
    "operationKind": "query",
    "text": "query TenantKYCScreen_Query(\n  $id: ID!\n) {\n  admin {\n    tenant(id: $id) {\n      id\n      kyc {\n        companyName\n        vatCountry\n        vatNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de8637817aab425c37aee885b4336181";

export default node;
