import React from 'react';
import {graphql} from 'react-relay';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import {  useRouteMatch } from 'react-router-dom';

import { OrganizationRouteParams } from '@app/models';
import { ApiKeys_Organization_Query } from './__generated__/ApiKeys_Organization_Query.graphql';
import AddOrganizationApiKeyButton from './components/AddOrganizationApiKeyButton/AddOrganizationApiKeyButton';
import { ApiKeys_List_organization$key } from './__generated__/ApiKeys_List_organization.graphql';
import DeleteOrganizationApiKeyButton from './components/DeleteOrganizationApiKeyButton/DeleteOrganizationApiKeyButton';

export default function OrganizationApiKeysScreen() {
  const match = useRouteMatch<OrganizationRouteParams>();
  const query = useLazyLoadQuery<ApiKeys_Organization_Query>(
    graphql`
      query ApiKeys_Organization_Query($organizationId: ID!) {
        organization(id: $organizationId) @required(action: THROW) {
          name
          ...ApiKeys_List_organization
          ...AddOrganizationApiKeyButton_organization
        }
      }
    `,
    {
      organizationId: btoa(`organization:${match.params.organizationId}`)
    }
  );

  return (
    <div>
      <p>
        Organization API keys allow you to programmatically modify your Criipto tenants and resources such as domains and applications.<br />
        Test keys can create/modify test domains and applications, and production keys can create/modify production resources.
      </p>
      <ApiKeyList organization={query.organization} />
      <div className="flex flex-row gap-[10px]">
        <AddOrganizationApiKeyButton organization={query.organization} environment="TEST" />
        <AddOrganizationApiKeyButton organization={query.organization} environment="PRODUCTION" />
      </div>
    </div>
  );
}

function ApiKeyList(props: {organization: ApiKeys_List_organization$key}) {
  const organization = useFragment(graphql`
    fragment ApiKeys_List_organization on Organization {
      ... DeleteOrganizationApiKeyButton_organization

      apiKeys {
        id
        clientId
        environment
        ... DeleteOrganizationApiKeyButton_apiKey
      }
    }
  `, props.organization);

  return (
    <table className="mb-8">
      <thead>
        <tr>
          <th>Environment</th>
          <th className="px-6">Client ID</th>
          <th className="px-6">Client Secret</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {organization.apiKeys.map(apiKey => (
          <tr key={apiKey.id}>
            <td>{apiKey.environment.toLowerCase()}</td>
            <td className="px-6">{apiKey.clientId}</td>
            <td className="px-6">***********************</td>
            <td className="actions flex justify-end py-1">
              <DeleteOrganizationApiKeyButton organization={organization} apiKey={apiKey} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}