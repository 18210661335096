/**
 * @generated SignedSource<<1d9edf2e1b1f36058c7bb4a387bbaf81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Permission = "NONE" | "READ" | "WRITE" | "%future added value";
export type ToolsScreen_ToolsRoutes_Query$variables = {
  environment: Environment;
  tenantId: string;
};
export type ToolsScreen_ToolsRoutes_Query$data = {
  readonly tenant: {
    readonly viewerPermissions: {
      readonly domains: Permission;
    };
  } | null;
};
export type ToolsScreen_ToolsRoutes_Query = {
  response: ToolsScreen_ToolsRoutes_Query$data;
  variables: ToolsScreen_ToolsRoutes_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerTenantPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "environment",
          "variableName": "environment"
        }
      ],
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolsScreen_ToolsRoutes_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ToolsScreen_ToolsRoutes_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91de359bcf3fa565a6cc327f5dcbe6e8",
    "id": null,
    "metadata": {},
    "name": "ToolsScreen_ToolsRoutes_Query",
    "operationKind": "query",
    "text": "query ToolsScreen_ToolsRoutes_Query(\n  $tenantId: ID!\n  $environment: Environment!\n) {\n  tenant(id: $tenantId) {\n    viewerPermissions {\n      domains(environment: $environment)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3dc6f8e4e4a69182aa20cc8fd51c039f";

export default node;
