/**
 * @generated SignedSource<<d25934377232c1b0b122cda287a086b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptInvitationInput = {
  code: string;
  id: string;
};
export type InvitationAcceptScreen_Mutation$variables = {
  input: AcceptInvitationInput;
};
export type InvitationAcceptScreen_Mutation$data = {
  readonly acceptInvitation: {
    readonly tenant: {
      readonly id: string;
      readonly shortTenantId: string;
    };
  };
};
export type InvitationAcceptScreen_Mutation = {
  response: InvitationAcceptScreen_Mutation$data;
  variables: InvitationAcceptScreen_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AcceptInvitationOutput",
    "kind": "LinkedField",
    "name": "acceptInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortTenantId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationAcceptScreen_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationAcceptScreen_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd7a2602671fdcbb924d8e8733aa4b35",
    "id": null,
    "metadata": {},
    "name": "InvitationAcceptScreen_Mutation",
    "operationKind": "mutation",
    "text": "mutation InvitationAcceptScreen_Mutation(\n  $input: AcceptInvitationInput!\n) {\n  acceptInvitation(input: $input) {\n    tenant {\n      id\n      shortTenantId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cd468d6ed152e88f052f2f656076823";

export default node;
