/**
 * @generated SignedSource<<0f98f31589a5bc2b6a241d624e41b72a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CriiptoAdminScreen_TenantQuery$variables = {
  id: string;
};
export type CriiptoAdminScreen_TenantQuery$data = {
  readonly admin: {
    readonly permissions: {
      readonly tenants: {
        readonly login: boolean;
      };
    };
  };
  readonly tenant: {
    readonly entityIdentifier: string;
    readonly id: string;
    readonly longTenantId: string;
    readonly name: string;
    readonly shortTenantId: string;
    readonly " $fragmentSpreads": FragmentRefs<"EnvironmentToggle_tenant">;
  } | null;
};
export type CriiptoAdminScreen_TenantQuery = {
  response: CriiptoAdminScreen_TenantQuery$data;
  variables: CriiptoAdminScreen_TenantQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityIdentifier",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longTenantId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTenantId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminQuery",
  "kind": "LinkedField",
  "name": "admin",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantPermissions",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "login",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CriiptoAdminScreen_TenantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnvironmentToggle_tenant"
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CriiptoAdminScreen_TenantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "environments",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "95a7be045088c38880c0033bd9b604e0",
    "id": null,
    "metadata": {},
    "name": "CriiptoAdminScreen_TenantQuery",
    "operationKind": "query",
    "text": "query CriiptoAdminScreen_TenantQuery(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    id\n    name\n    entityIdentifier\n    longTenantId\n    shortTenantId\n    ...EnvironmentToggle_tenant\n  }\n  admin {\n    permissions {\n      tenants {\n        login\n      }\n    }\n  }\n}\n\nfragment EnvironmentToggle_tenant on Tenant {\n  environments\n  ...ProductionUpgradeRequiredModal_tenant\n}\n\nfragment ProductionUpgradeRequiredModal_tenant on Tenant {\n  name\n  shortTenantId\n}\n"
  }
};
})();

(node as any).hash = "770eac61c5ca5b20999bca0d28b47c0f";

export default node;
