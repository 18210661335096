import React, { useEffect } from 'react';

import {graphql} from 'react-relay';

import {translate} from '@app/i18n';
import {Domain as RelayDomain} from '@app/components/RelayDomains';
export type {RelayDomain} from '@app/components/RelayDomains';
import { useFragment } from 'react-relay';
import { DomainField_tenant$key } from './__generated__/DomainField_tenant.graphql';
import Select from '@app/components/FormFields/Select/Select';

interface RelayProps {
  tenant: DomainField_tenant$key
  domain?: RelayDomain | null
  onChange: (domain : RelayDomain) => void
}

export default function DomainField(props : RelayProps) {
  const {onChange, domain} = props;

  const tenant = useFragment(
    graphql`
      fragment DomainField_tenant on Tenant @argumentDefinitions(
        environment: {type: "Environment"}
      ) {
        domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
          edges {
            node {
              id
              name
              environment
            }
          }
        }
      }
    `,
    props.tenant
  );

  if (!tenant) return null;

  const domains = tenant.domains.edges.map(edge => edge.node);
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const domain = domains.find(search => search.id === event.target.value)!;
    onChange(domain);
  };

  useEffect(() => {
    /** Default to the first domain
     * if tenant has exactly one domain
     * (no need to let the user choose in that case)
     */
    if (domains.length !== 1) return;
    onChange(domains[0]);
  }, [domains, onChange]);

  return (
    <>
      {!domains.length ? (
        <p>You have no domains available in this environment.</p>
      ) : (
        <Select
          name="Select Domain"
          label={translate('LABEL_CHOOSE_DOMAIN')}
          onChange={handleChange}
          value={domain?.id}
          required
        >
          <option value="">Choose a domain</option>
          {domains.map(domain => (
            <option key={domain.id} value={domain.id}>{domain.name}</option>
          ))}
        </Select>
      )}
    </>
  );
}
