import React from 'react';
import styles from './Loader.module.css';

export default function ScreenLoader() {
  return (
    <div className={styles.loader}>
      <div className={styles.spinner}>
        <div className={styles.bar}></div>
      </div>
      Loading ...
    </div>
  );
}