import {Form, FormError, FormSuccess, RenderProps} from '@app/components/Form';
import React from "react";
import Button from "@app/components/Button/Button";
import { FormikValues } from 'formik';
import { isFunction } from 'lodash';

interface ApplicationRelayFormProps<Values extends FormikValues> {
  values: Values
  children: React.ReactNode | ((props : RenderProps<Values>) => React.ReactNode),
  valid: boolean
  formSuccessMessage?: string
  onSubmit: (values: Values) => Promise<void>
}

export function ApplicationRelayForm<Values extends FormikValues>(props: ApplicationRelayFormProps<Values>) {
  const {values, children, onSubmit, formSuccessMessage, valid} = props;

  const handleSubmit = async (values : Values) => {
    if (!valid) return Promise.reject(new Error('Invalid form fields'));
    return onSubmit(values);
  };

  const render =
    isFunction(props.children) ?
    props.children :
    (_ : RenderProps<Values>) => props.children

  return (
    <React.Fragment>
      <Form initialValues={values} onSubmit={handleSubmit} data-test-id="form" key="application_form">
        {({isPending, error, isSuccess, values}) => (
          <React.Fragment>
            {render({isPending, error, values, isSuccess})}

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={formSuccessMessage || 'Application updated!'} />
            )}

            <Button variant="primary" type="submit" working={isPending} disabled={!valid} className="mt-8">
              Save
            </Button>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
}

export default ApplicationRelayForm;