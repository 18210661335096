import React, { useEffect, useReducer, useState } from 'react';
import { useFragment, useLazyLoadQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import {graphql} from 'react-relay';

import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import useQuery from '@app/hooks/useQuery';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { OrganizationsScreen_Admin_Query } from './__generated__/OrganizationsScreen_Admin_Query.graphql';
import { OrganizationsScreen_AdminOrganizationItem_organization$key } from './__generated__/OrganizationsScreen_AdminOrganizationItem_organization.graphql';
import Modal, { ModalProps } from '@app/components/Modal/Modal';
import { Form, FormError } from '@app/components/Form/Form';
import InputField from '@app/components/FormFields/InputField/InputField';
import Button from '@app/components/Button/Button';
import { FormikHelpers } from 'formik';
import useMutation from '@app/hooks/useMutation';
import { OrganizationsScreen_CreateOrganizationMutation } from './__generated__/OrganizationsScreen_CreateOrganizationMutation.graphql';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';
import { OrganizationsScreen_Admin_PermissionsQuery } from './__generated__/OrganizationsScreen_Admin_PermissionsQuery.graphql';

function removeTrailingSlash(input: string) {
  if (input.endsWith('/')) {
    return input.substring(0, input.length - 1);
  }
  return input;
}
function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const SearchQuery = graphql`
  query OrganizationsScreen_Admin_Query($query: String!, $skip: Boolean!) {
    admin @skip(if: $skip) {
      organizations(query: $query) {
        id
        ...OrganizationsScreen_AdminOrganizationItem_organization
      }
    }
  }
`;

type Props = {
  breadcrumb: React.ReactNode
}
export default function CriiptoAdminOrganizationsScreen(props: Props) {
  const {breadcrumb} = props;
  const match = useRouteMatch();
  const history = useHistory();
  const routeQuery = useQuery();
  const [search, setSearch] = useState(routeQuery.get('query') || '');
  const debouncedSearch = useDebounce(search, 200);

  const [queryReference, loadQuery] = useQueryLoader<OrganizationsScreen_Admin_Query>(
    SearchQuery,
    null
  );

  const permissionsQuery = useLazyLoadQuery<OrganizationsScreen_Admin_PermissionsQuery>(graphql`
    query OrganizationsScreen_Admin_PermissionsQuery{
      admin {
        permissions {
          organizations {
            settings
          }
        }
      }
    }
  `, {});

  const [showCreateModal, toggleCreateModal] = useReducer(val => !val, false);

  useEffect(() => {
    history.push(`${removeTrailingSlash(match.url)}?query=${debouncedSearch}`);
    loadQuery({
      query: debouncedSearch,
      skip: !debouncedSearch?.length
    });
  }, [debouncedSearch]);

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          <div>Search</div>
        </div>
        <div className="flex flex-row justify-between">
          <h1>Criipto Backoffice</h1>
          {permissionsQuery.admin.permissions.organizations.settings === 'WRITE' ? (
            <Button variant="default" onClick={toggleCreateModal}>Create organization</Button>
          ) : null}
        </div>
        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={`/admin/tenants`} activeClassName="active">Tenants</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/organizations`} activeClassName="active">Organizations</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/users`} activeClassName="active">Users</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-content-main max-w-[1280px]">
        <div className="mb-8">
          <InputField
            name="organization-search"
            type="search"
            label='Search for an organization'
            value={search}
            onChange={event => setSearch(event.target.value)}
            helpText="Hint: Search for organization id or name"
          />
        </div>

        {queryReference !== null ? (
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <Results queryReference={queryReference} />
            </React.Suspense>
          </GraphQLErrorBoundary>
        ) : null}
      </div>
      <CreateOrganizationModal open={showCreateModal} onHide={toggleCreateModal} />
    </React.Fragment>
  )
}

function Results(props: {queryReference: any}) {
  const data = usePreloadedQuery<OrganizationsScreen_Admin_Query>(SearchQuery, props.queryReference);
  return (
    <div>
      {data.admin?.organizations && (
        <React.Fragment>
          {data.admin?.organizations.map(organization => (
            <OrganizationItem key={organization.id} adminOrganization={organization} />
          ))}
        </React.Fragment>
      )}
    </div>
  );
}

function OrganizationItem(props: {adminOrganization: OrganizationsScreen_AdminOrganizationItem_organization$key}) {
  const adminOrganization = useFragment(
    graphql`
      fragment OrganizationsScreen_AdminOrganizationItem_organization on AdminOrganization {
        id
        organization {
          name
        }
      }
    `,
    props.adminOrganization
  )

  const match = useRouteMatch();

  return (
    <Link className="block p-4 border-b border-primary-700 flex flex-row justify-between" to={`${removeTrailingSlash(match.url)}/${adminOrganization.id}`}>
      <div>
        <strong>{adminOrganization.organization.name}</strong>
      </div>
    </Link>
  );
}

function CreateOrganizationModal(props: {
  open: ModalProps["open"]
  onHide: ModalProps["onHide"]
}) {
  const history = useHistory();
  const toast = useToast();
  const [executor, status] = useMutation<OrganizationsScreen_CreateOrganizationMutation>(
    graphql`
      mutation OrganizationsScreen_CreateOrganizationMutation($input: CreateOrganizationInput!) {
        admin_createOrganization(input: $input) {
          adminOrganization {
            id
          }
        }
      }
    `
  );

  const handleSubmit = async (values: {name: string}, actions: FormikHelpers<any>) => {
    if (status.pending) return;
    const response = await executor.executePromise({
      input: {
        name: values.name
      }
    });
    history.push(`/admin/organizations/${response.admin_createOrganization.adminOrganization.id}`);
    toast.success({title: 'Organization created'});
  }

  return (
    <Modal {...props}>
      <Modal.Header>
        <div>
          <h2>Create organization</h2>
        </div>
      </Modal.Header>
      <Form
        key="backoffice_create_organization"
        initialValues={{name: ''}}
        onSubmit={handleSubmit}
      >
        {({isPending, values, error}) => (
          <React.Fragment>
            <Modal.Content>
              <InputField
                type="text"
                label="Name"
                name="name"
                required
                placeholder="Name"
              />

              {error && <FormError error={error} />}

              {status.error ? (
                <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} />
                ) : null}
            </Modal.Content>
            <Modal.Actions>
              <Button variant="default" onClick={props.onHide}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" working={isPending} disabled={!values.name}>
                Create
              </Button>
            </Modal.Actions>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  )
}