/**
 * @generated SignedSource<<b49e411165db3585a8a4c2bb9f84f1c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAgeverificationApplicationScreen_application$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"AgeverificationApplicationIntegrate_application" | "CopyApplicationButton_application" | "DeleteApplicationButton_application" | "GeneralSection_application" | "OIDCSection_application">;
  readonly " $fragmentType": "EditAgeverificationApplicationScreen_application";
};
export type EditAgeverificationApplicationScreen_application$key = {
  readonly " $data"?: EditAgeverificationApplicationScreen_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAgeverificationApplicationScreen_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAgeverificationApplicationScreen_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneralSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OIDCSection_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgeverificationApplicationIntegrate_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApplicationButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CopyApplicationButton_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};

(node as any).hash = "dd2918a5a785ce00d4f593ddcb97695b";

export default node;
