export interface ILanguageDefinitionCollection {
  [key: string]: ILanguageDefinition;
}

export interface ILanguageDefinition {
  fallback?: string;
  displayName: string;
  strings: {
    [key: string]: string
  };
}

const EnglishStrings = {
  ADD_DOMAIN: "Add domain",
  ALL: "All",
  APPLICATION_NAME: "Name",
  APPLICATION_IDENTITIES: "National identities",
  CANCEL: "Cancel",
  CLIENT_SECRET: "Client secret",
  CLIENT_SECRET_FOR: "Client secret for",
  COMPANY_NAME: "Company name",
  CONNECT: "Connect",
  CONNECT_AUTH0: "Connect Auth0",
  CONNECT_AUTH0_SUCCESS: "The Auth0 connection has been created successfully!",
  CONNECTED_AUTH0: "connected to Auth0",
  CUSTOM_STYLING: "Customized styling (leave blank to use built-ins)",
  CREATE_MANUALLY: "or create manually below",
  DASHBOARD_TITLE: "Dashboard",
  DASHBOARD_CONTENT_1: "Making ID easy since 2016!",
  DASHBOARD_CONTENT_2: "Please have a look around.",
  DELETE: "Delete",
  DELETE_APPLICATION: "Delete application",
  DELETE_DOMAIN: "Delete domain",
  DOMAIN: "Domain",
  DNS_NAME: "Your test domain",
  DNS_NAME_AVAILABLE: "is available.",
  DNS_NAME_UNAVAILABLE: "is unavailable, please try something else.",
  ERROR: "An error occurred: {{ message }}",
  HINT_CHOOSE_DOMAIN: "Choose a domain",
  HINT_CHOOSE_JWT_PROPERTY_FORMAT: "JWT property format",
  HINT_DKNEMID_LOGON_TO: " as specified in the order form to NETS DK",
  HINT_DKNEMID_PIDCPR_SPID: " for PID/CPR service",
  HINT_DKNEMID_CPR_FOR_POCES: "Add CPR for personal NemId logins",
  HINT_DKNEMID_SHOW_MITID: "Show MitID (if configured)",
  HINT_FILE: "Select a file",
  HINT_ID_SERVICE_STYLESHEET_TEST: "Enter the absolute URL for your custom stylesheet to use on test connections",
  HINT_ID_SERVICE_STYLESHEET_PROD: "Enter the absolute URL for your custom stylesheet to use on production connections",
  HINT_ID_SERVICE_CONFIG_ALL_SET_NO_BANKIDOLD: "You can still use this version, but is being phased out.",
  HINT_ID_SERVICE_CONFIG_ALL_SET: "You are all set",
  HINT_ID_SERVICE_CONFIG_NOT_NEEDED: "No need for any configuration",
  HINT_ID_SERVICE_CONFIG_CUSTOM_BE_EID_WHAT_TO_AVOID: "We do support a customized setup for Belgian eID test users, but you will need to purchase your own physical test cards and have a card reader available. Please make sure that you do not accidentally enter your production settings here.",
  HINT_ID_SERVICE_CONFIG_CUSTOM_DE_SOFORT_WHAT_TO_AVOID: "We do support a customized setup for German Sofort projects in test-mode. Please make sure that you do not accidentally enter your production settings here.",
  HINT_ID_SERVICE_CONFIG_CUSTOM_DKNEMID_WHAT_TO_AVOID: "We do support a customized setup for Danish NemID test users, but please make sure that you do not accidentally upload a production VOCES certificate here.",
  HINT_ID_SERVICE_CONFIG_CUSTOM_ITSME_WHAT_TO_AVOID : "We do support a customized setup for Itsme test users, but please make sure that you do not accidentally enter your production project settings here.",
  HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKID_WHAT_TO_AVOID: "We do support a customized setup for Norwegian Bank ID Login test users",
  HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKIDOLD_WHAT_TO_AVOID: "We do support a customized setup for Norwegian BankID test users, but please make sure that you do not accidentally upload a production merchant certificate here.",
  HINT_ID_SERVICE_CONFIG_CUSTOM_NOVIPPS_WHAT_TO_AVOID: "We do support a customized setup for Norwegian Vipps Login test users",
  HINT_ID_SERVICE_CONFIG_CUSTOM_SEBANKID_WHAT_TO_AVOID: "We do support a customized setup for Swedish BankID test users, but please make sure that you do not accidentally upload a production certificate here.",
  HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_DKNEMID: "When in doubt - assume that the VOCES certificate, SPID and Friendly name you have is for production. That is usually the case.",
  HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_NOBANKID: "When in doubt - assume that the merchant certificate (.BID file) you have is for production. That is usually the case.",
  HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_NOBANKIDOLD: "When in doubt - assume that the merchant certificate (.BID file) you have is for production. That is usually the case.",
  HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_SEBANKID: "When in doubt - assume that the certificate you have is for production. That is usually the case.",
  HINT_OAUTH2_AUTHZCODEFLOW_ENABLED: "Enable OAuth2 Code Flow",
  HINT_OAUTH2_NEW_APPLICATION: "OAuth2 secret will be displayed after creation",
  HINT_OAUTH2_AFTER_SAVE: "OAuth2 secret will be displayed after you save.",
  HINT_OAUTH2_NULL_CLAIMS_VALUES_HANDLING: "Null claims values handling",
  INFO_OAUTH2_NULL_CLAIMS_VALUES_HANDLING: "Some integrations do not allow for null claim values in the issued token. Disable the toggle if yours do not.",
  HINT_PASSWORD: "Enter your certificate password",
  HINT_PRODUCTION_MODE: "Use real identities",
  HINT_SETUP_IS_OPTIONAL: ". Leave blank to use pre-configured setup",
  HINT_SETUP_IS_REQUIRED: " - your own setup is required!",
  HINT_SETUP_REAL_IDENTITIES: "Production environment",
  HINT_SETUP_TEST_IDENTITIES: "Test environment",
  HOW_TO_USE: "How to use",
  INFO: "Info",
  INFO_AUTH0_CONNECTION: "We will create an enterprise connection in Auth0 with this name.",
  INFO_AUTH0_INSTANCE: "If you are using Auth0's public service (https://auth0.com), do not change this value. Otherwise, enter your custom Root Tenant Authority here.",
  INFO_AUTH0_POPUP: "Please follow the instructions in the popup we just opened.",
  INFO_BE_EID_CLIENT: "Configure your client id and client secret.",
  INFO_BE_EID_CALLBACK_URL_PROD: "⚠️ Note that you must whitelist at least one of the following URLs with Connective.",
  INFO_BE_EID_CALLBACK_URL_TEST: "For testing, you may whitelist the following URL(s) with Connective. Still, if you use the default setup you don't need to do anything.",
  INFO_NL_IDIN_CLIENT: "Configure your client id and client secret.",
  INFO_NL_IDIN_CALLBACK_URL_PROD: "⚠️ Note that you must whitelist at least one set of the following callback/cancel URLs with Evidos.",
  INFO_NL_IDIN_CALLBACK_URL_TEST: "For testing, you may whitelist the following set of callback/cancel URL(s) with Evidos. Still, if you use the default setup you don't need to do anything.",
  INFO_FEATURE_STYLING: "Style with your own CSS",
  INFO_FEATURE_STYLING_DESCRIPTION: "Set a link to your own CSS to style the dialogs previewed below.",
  INFO_FEATURE_STYLING_DESCRIPTION_B: "NOTE: If switching to 'Unified' be sure to test well before applying in production. 'Unified' introduces both new HTML and CSS.",
  INFO_CALLBACK_URLS: "Allowed callback URLs, both newline- and comma-separated are supported",
  INFO_CERTIFICATE: "Upload your certificate to identify to the {{ provider }} service",
  INFO_CLIENT_SECRET: "This is your applications OAuth2 client secret. Guard it well. Please note that this is the only time you will be able to see the actual value in Criipto Verify.",
  INFO_CSS_CLASS: "Adds a custom css class to the login/signing screen, useful when customizing CSS",
  INFO_DE_SOFORT_PROJECT: "Configure your user id, project id and project password.",
  INFO_DE_SOFORT_CALLBACK_URL_PROD: "⚠️ Note that you must whitelist exactly one of the following URLs on your Sofort project's Return URL (under 'Base settings ... Common settings for Ident').",
  INFO_DE_SOFORT_CALLBACK_URL_TEST: "For testing, you may whitelist exactly one of the following URLs on your Sofort project. Still, if you use the default setup you don't need to do anything.",
  INFO_DELETE_APPLICATION: "Are you sure you want to delete {{ application }}?",
  INFO_DELETE_APPLICATION_AUTH0: "The application is now deleted, but its connection in Auth0 is not. To completely remove {{ application }}, you will need to remove its connection in your Auth0 dashboard.",
  INFO_DELETE_DOMAIN: "Are you sure you want to delete {{ domain }}?",
  INFO_DK_NEMID: "The NemID certificate and TU settings are used to identify your organization towards NemID's servers.",
  INFO_DK_NEMID_TU_SIGNUP: "If you haven't already done so, you can sign up for a",
  INFO_DK_NEMID_TU_SIGNUP_WHERE: "here",
  INFO_DK_NEMID_DETAILS: "You have specified the friendly name in the online order form to NETS, and NETS will have replied to you (typically via email) with the SPID value to use.",
  INFO_DK_NEMID_PUBLICSECTOR: "If you have a TU agreement that allows for automated CPR lookups, please get in touch with us. The Criipto Verify runtime supports this, we just have to enable it for your tenant.",
  INFO_DOMAIN_SSO: "Configure your domains SSO settings per protocol",
  INFO_DOMAIN_SSO_enableForOAuth2: "Enable SSO for OAuth2",
  INFO_DOMAIN_SSO_enableForWSFederation: "Enable SSO for WS Federation",
  INFO_DOMAIN_SSO_SUCCESS: "Your SSO settings have been saved",
  INFO_APPLICATION_ERRORSTRATEGY: "Errors will be returned in standard OAuth2 format. If disabled, errors will be shown in UI.",
  INFO_EXPERIMENTAL: "EXPERIMENTAL feature",
  INFO_EXPERIMENTAL_FEATURE_STYLING: "You are most welcome to try this out, but please do expect unannounced breaking changes to the HTML that the stylesheet will be applied to.",
  INFO_FRAME_ORIGINS: "Setting a frame origin will restrict framing of the login page to this hostname.",
  INFO_IDENTITIES: "Enable the national identities you want to use in your application.",
  INFO_ITSME_REDIRECT_URI: "Redirect URI",
  INFO_ITSME_URLS_PROD: "⚠️ Note that you must whitelist at least one of the following pairs of URLs with Itsme.",
  INFO_ITSME_URLS_TEST: "For testing, you may whitelist one the following pairs of URLs with Itsme. Still, if you use the default setup you don't need to do anything.",
  INFO_ITSME_CLIENT: "Enter your client id an service code as provided by the Itsme onboarding team.",
  INFO_ITSME_JWKSET_URL: "JWKSet URL",
  INFO_ITSME_REQUESTURI_NOT_USED: "Note that Criipto Verify does not currently use the optional request_uri sematics supported by Itsme, so you can just leave that out when filling out the onboarding sheet",
  INFO_MANAGED_CERTIFICATE: "You can opt to have Criipto automatically provision an SSL certificate for your custom domain, or you can choose to upload your own certificate.",
  INFO_MANAGED_CERTIFICATE_DELAY_SHORT: "Note: This may take up to 20 minutes",
  INFO_MANAGED_CERTIFICATE_DELAY_LONG: "Note: Automatic provisioning of SSL certificates may take up to 20 minutes",
  INFO_NEW_APPLICATION: "Name your connection and choose on which domain it should be reachable.",
  INFO_NEW_APPLICATION_AUTH0_1: "If you use Auth0, simply use this form. We will then register this Criipto Verify application as an identity provider, an ADFS Connection, in Auth0.",
  INFO_NEW_APPLICATION_AUTH0_2: "Note that you still need to go to ",
  INFO_NEW_APPLICATION_AUTH0_3: "the application in Auth0",
  INFO_NEW_APPLICATION_AUTH0_4: " and enable the newly created Criipto Verify connection.",
  INFO_NEW_DOMAIN: "To make your domain work you must configure a DNS CNAME record to point your domain to",
  INFO_NL_DIGID: "The PKIoverheid certificate is used to identify your organization to the DigiD service.",
  INFO_NL_DIGID_METADATA_ENDPOINT: "The SAML metadata you need to register with DigiD may be found at ",
  INFO_NL_DIGID_SIGNUP: "If you don't know what all this means, or you just haven't signed up for DigiD, learn more and register with ",
  INFO_NL_DIGID_SIGNUP_HERE: "Logius",
  INFO_NO_BANKID: "Configure your client id and client secret. ",
  INFO_NO_BANKID_OLD: "The BankID certificate to identify your organization to the BankID server, which in turn communicates with the BankID app on your computer or mobile device to handle the actual user authentication.",
  INFO_NO_BANKID_CLIENT: "Enter your client id and client secret as provided by Bidbax (BankID BankAxept).",
  INFO_NO_BANKID_CALLBACK_URL_PROD: "⚠️ Note that you must whitelist at least one of the following URLs with Bidbax (BankID BankAxept).",
  INFO_NO_BANKID_CALLBACK_URL_TEST: "For testing, you may whitelist the following URL(s) with Bidbax. Still, if you use the default setup you don't need to do anything.",
  INFO_NO_VIPPS_CLIENT: "Configure your client id and client secret.",
  INFO_NO_VIPPS_CALLBACK_URL_PROD: "⚠️ Be aware, you must whitelist at least one of the following URL's.",
  INFO_NO_VIPPS_CALLBACK_URL_TEST: "You can whitelist the following URL's for testing.",
  INFO_OAUTH2: "OAuth2 and OpenID Connect specific options",
  INFO_OAUTH2_AUTHZCODEFLOW_ENABLED: "Enable Authorization Code Flow, will issue a client secret for use with back-channel/server code exchange.",
  INFO_OAUTH2_CALLBACKONLOCATIONHASH: "Enable this for single-page apps, to make the id_token available to JavaScript.",
  INFO_OAUTH2_CLIENTSECRET_INITIALIZE_1: "This appears to be the first time this application is being enabled for ",
  INFO_OAUTH2_CLIENTSECRET_INITIALIZE_2: ". We'll automatically set the client secret for the application when you click on ",
  INFO_OIDC_USERINFO_STRATEGY: "We support 3 modes for retreiving user info: As a plain JSON object ('plainJson'), as a signed JWT ('signedJwt'), or directly from the Token endpoint - embedded in the id_token ('fromTokenEndpoint'). The 'fromTokenEndpoint' flow is not standard, but if you are working with a product that does not call the UserInfo endpoint, this option can be useful.",
  INFO_OIDC_JWT_PROPERTY_FORMAT: "The keys in the returned JSON payloads can be either simple - short - strings ('compact'), a mix of compact and absolute URI's ('namespaced'), or a mix of compact and partial URI's ('default').",
  INFO_REALM: "The realm must be unique for each application within the selected domain.",
  INFO_SE_BANKID: "The BankID certificate to identify your organization to the BankID server, which in turn communicates with the BankID app on your computer or mobile device to handle the actual user authentication.",
  INFO_TOKEN_SIGNING_KEYS: "Token signing keys",
  INFO_TOKEN_SIGNING_KEY_IS_ACTIVE: "Is active",
  INFO_TOKEN_SIGNING_KEY_VALID_FROM: "Valid from",
  INFO_TOKEN_SIGNING_KEY_VALID_TO: "Valid to",
  INFO_TOKEN_SIGNING_KEY_PKCS7: "PEM",
  INFO_TOKEN_SIGNING_KEY_EXPIRES_IN: "Expires in",
  INFO_TOKEN_SIGNING_KEYS_MANAGE: "Here, you can manage the active token signing key for your Criipto Verify domain.\n\n\n\n" +
    "The token signing key is trusted by your application - and sometimes, depending on the platform your application is built on, it is pre-registered there. In those cases, you must register new token signing keys in your applications trust - store before activating a new key. Otherwise, your application will not be able to validate the tokens signed by the new keys.",
  INFO_MITID_SERVICE_PROVIDER_ID: "Your service provider id is an internal technical identifier that Criipto may ask you to supply in support requests",
  INFO_DK_MITID_BROKER_DOMAIN: "Your MitID broker domain is the DNS domain on which users will perform MitID logins.",
  INFO_DK_MITID_NEMID_DOMAIN: "NemID fallback domain is the DNS domain which will be used for side-by-side switching between NemID/MitID during transition.",
  INFO_DK_MITID_REFERENCE_TEXT: "By default, we show the name of your application to end users during the MitID login flow. If your application does not have a name, this value is used as a fallback.",
  INFO_MITID_APPLY: "To enable MitID please fill out the form and we will initiate the process with MitID and get back to you.",
  LABEL_ADVANCED_OPTIONS: "Advanced options",
  LABEL_APP_NAME: "Name",
  LABEL_APPLICATION_CSS_CLASS: "CSS Class",
  LABEL_AUTH0_CONNECTION: "Auth0 connection name",
  LABEL_AUTH0_INSTANCE: "Auth0 Root Tenant Authority",
  LABEL_BE_EID_CLIENTID: "Client id",
  LABEL_BE_EID_CLIENTSECRET : "Client secret",
  LABEL_BE_EID_REQUEST_ADDRESS: "Request address",
  LABEL_BE_EID_REQUEST_NATIONAL_NUMBER: "Request national number",
  LABEL_BE_EID_REQUEST_PERSONAL_INFO: "Request personal info",
  LABEL_BE_EID_REQUEST_PHOTO: "Request photo",
  LABEL_BE_EID_SKIP_SUMMARY: "Skip summary",
  LABEL_NL_IDIN_CLIENTID: "Client id",
  LABEL_NL_IDIN_CLIENTSECRET : "Client secret",
  LABEL_CALLBACK_URLS: "Callback URLs",
  LABEL_CERTIFICATE: "Certificate",
  LABEL_CERTIFICATE_UPLOAD: "Upload certificate",
  LABEL_CERTIFICATE_PASSWORD: "Certificate password",
  LABEL_CERTIFICATE_VALID_FROM: "Valid from",
  LABEL_CERTIFICATE_VALID_TO: "Valid to",
  LABEL_CHOOSE_DOMAIN: "Available on domain",
  LABEL_CHOOSE_JWT_PROPERTY_FORMAT: "JWT property format",
  LABEL_CHOOSE_USERINFO_RESPONSE_STRATEGY: "User info response strategy",
  LABEL_CHOOSE_NULL_CLAIM_VALUES: "Null claim values handling",
  LABEL_CLIENT_ID: "Client ID/Realm",
  LABEL_DE_SOFORT_PROJECTID: "Project ID",
  LABEL_DE_SOFORT_PROJECTPASSWORD : "Project password",
  LABEL_DE_SOFORT_USERID: "Customer number",
  LABEL_DKNEMID_LOGON_TO: "Friendly name",
  LABEL_DKNEMID_PIDCPR_SPID: "SPID for PID/CPR service",
  LABEL_DOMAIN: "Your domain name",
  LABEL_NEW_DOMAIN: "Your company name",
  LABEL_FRAME_ORIGINS: "Frame origin",
  LABEL_HOLD_SSO_SESSION: "Hold SSO session",
  LABEL_ID_SERVICE_STYLESHEET_TEST: "Link to your own stylesheet",
  LABEL_ID_SERVICE_STYLESHEET_PROD: "Link to your own stylesheet",
  LABEL_IDENTITIES: "Choose identities",
  LABEL_IDP_CERTIFICATE: "Certificate",
  LABEL_ITSME_CLIENTID: "Client id",
  LABEL_ITSME_SERVICECODE: "Service code",
  LABEL_ITSME_REQUEST_EXTRAIDDATA: "Request extra ID data",
  LABEL_ITSME_REQUEST_SECURITYDATA: "Request security data",
  LABEL_LOGOUT_URLS: "Logout URLs",
  LABEL_NOBANKID_OPTIONAL: "Optional",
  LABEL_NOBANKID_REQUIRED: "Required",
  LABEL_NOBANKID_CLIENTID: "Client id",
  LABEL_NOBANKID_SECRET: "Client secret",
  LABEL_NOBANKID_REQUESTADDRESS: "Request address",
  LABEL_NOBANKID_REQUESTEMAIL: "Request email",
  LABEL_NOBANKID_REQUESTEPHONE: "Request phone number",
  LABEL_NOBANKID_SOCIALNOREQUIRED: "Require SSN (fødselsnummer)",
  LABEL_NOVIPPS_CLIENTID: "Client id",
  LABEL_NOVIPPS_SECRET: "Client secret",
  LABEL_NOVIPPS_SOCIALNOREQUIRED: "Include SSN (fødselsnummer)",
  LABEL_NOVIPPS_SOCIALREQUIRED: "Include SSN (fødselsnummer)",
  LABEL_OAUTH2_CALLBACKONLOCATIONHASH: "Callback on location hash",
  LABEL_APPLICATION_ERRORSTRATEGY: "Return errors to application",
  LABEL_APPLICATION_SCOPESTRATEGY: "Enable dynamic scopes",
  LABEL_PRODUCTION: "Enable production",
  LABEL_SSL_CERTIFICATE: "SSL certificate (PFX/PKCS12)",
  LABEL_TOKEN_LIFETIME: "Token lifetime",
  LABEL_DK_MITID_SERVICE_PROVIDER_ID: "Service provider ID",
  LABEL_DK_MITID_REFERENCE_TEXT: "Fallback reference text",
  LABEL_DK_MITID_NEMID_DOMAIN: "NemID fallback domain",
  LABEL_DK_MITID_BROKER_DOMAIN: "MitID broker domain",
  LABEL_DK_MITID_COMPANY_NAME: "Company name (legal name)",
  LABEL_DK_MITID_COMPANY_ALIAS: "Company alias",
  LABEL_DK_MITID_COMPANY_TYPE: "Company type",
  LABEL_DK_MITID_CONTACT_EMAIL: "Contact email",
  LABEL_DK_MITID_CONTACT_NAME: "Contact name",
  LABEL_DK_MITID_VAT_IDENTIFIER: "VAT Number",
  LABEL_DK_MITID_DOMAIN_PREFIX: "Domain prefix",
  LABEL_TRY_AGAIN: "Try again",
  HINT_DK_MITID_CPR : "Add CPR for MitID logins",
  HINT_DK_MITID_CPR_IS_OPTIONAL: "CPR Optional",
  HINT_DK_MITID_INCLUDE_POSTAL_ADDRESS: "Include postal address",
  HINT_DK_MITID_COMPANY_ALIAS: "Displayed to users in the MitID client",
  HINT_DK_MITID_SHOW_ON_NEMID: "Also offer MitID login when NemID is requested",
  LOG_IN: "Log in",
  LOG_OUT: "Log out",
  LOGGING_IN: "Logging in...",
  MANAGE_ADMINS: "Manage admins",
  MANAGE_USERS: "Manage users",
  MANAGE_USERS_POPUP: "(opens in a popup)",
  NEW_INTEGRATION: "Register your application",
  NEW_ORGANIZATION: "Create new...",
  NEW_PRODUCTION_DOMAIN: "New production domain",
  NEW_TEST_DOMAIN: "New test domain",
  NEW_DOMAIN: "New domain",
  NL_DIGID_CHOOSE_DOMAIN: "Choose PKIOverheid certificate domain",
  OAUTH2: "OAuth2",
  OAUTH2_CLIENTSECRET_INITIALIZE: "Initialize OAuth2 client secret",
  OAUTH2_MATCH_CLIENTSECRET: "Does it match?",
  OAUTH2_RECYCLE_CLIENTSECRET: "Re-generate client secret",
  OK: "OK",
  OIDC: "OpenID Connect",
  OR_CONNECT: "or connect",
  PROCEED: "Proceed",
  PRODUCTION_APPLICATIONS: "Production applications",
  PRODUCTION_DOMAIN: "Production domain",
  PRODUCTION_DOMAINS: "Production domains",
  PRODUCTION_TOGGLE_INFO_MESSAGE: "Dear Customer,\n\n"+
    "As you may notice, we have upgraded our user interface, introducing a clearer distinction between test and production modes. Production mode is for working with real e-ID users and requires a paid subscription. Over the coming weeks and months, more changes will be introduced with the aim to continuously increase the ease and speed of leveraging the various e-ID services."+
    "\n\nSend an email to support@criipto.com or hit us up on Slack to leave feedback!" +
    "\n\nThank you,"+
    "\nThe Criipto team",
  PROVIDER: "Provider",
  REAL_IDENTITIES: "Real identities",
  REALM_EXISTS: "You already have an application with this client ID on the selected domain",
  SAVE: "Save",
  SAVING: "Saving...",
  SIGNUP_LIST_1: "Create a new Criipto Verify tenant",
  SIGNUP_LIST_2: "Set up your criipto.id test domain",
  SIGN_UP_HEADER: "Set up your organization with Criipto Verify",
  SIGNUP_ORGANIZATION_NAME: "Your organization name",
  SIGN_UP: "Create tenant",
  SIGNING_UP: "Setting up new tenant...",
  SLACK_JOIN_SUPPORT: "Sign up for our Slack support channel.",
  SLACK_JOIN_BUTTON: "Send",
  SLACK_JOIN_EMAIL: "Enter your email address to receive an invitation:",
  SLACK_JOIN_HEADER: "Join us on Slack",
  SLACK_JOIN_SENDING: "Sending invitation...",
  TAB_DOMAINS: "Domains",
  TAB_HOME: "Dashboard (old)",
  TAB_DASHBOARD: "Dashboard",
  TAB_INTEGRATION: "Applications",
  TAB_APPLICATIONS: "Applications",
  TAB_PROVIDERS_OLD: "Identity services",
  TAB_PROVIDERS: "Identity sources",
  TAB_STYLING: "Styling",
  TAB_USERS: "Users",
  TAB_LOGS: "Logs",
  TAB_EXTENSIBILITY: "Extensibility",
  TENANTS: "Tenants",
  TENANT_NAME: "Tenant name",
  TENANT_NAME_HINT: "Name of your company or project",
  TENANT_NAME_EXISTS: "You already have a tenant with this name",
  TEST_APPLICATIONS: "Test applications",
  TEST_DOMAIN: "Test domain",
  TEST_DOMAINS: "Test domains",
  TEST_IDENTITIES: "Test identities",
  VERIFY_DOMAIN: "Verify your domain settings",
  VERIFY_ERROR: "Expected domain to resolve to {{expected}}, but actually resolved to {{actual}}",
  VERIFYING: "Verifying your domain settings...",
  VIA_AUTH0: "Connect to Auth0",
  VIA_OKTA: "Connect to Okta",
  VIA_ONELOGIN: "Connect to OneLogin",
  VIA_PINGFEDERATE: "Connect to PingFederate",
  VERIFIED_DATA: "Verified data",
  UNVERIFIED_DATA: "Unverified data",
  WORKING_PROD: "Production configuration",
  WORKING_TEST: "Test configuration",
  XYZ: "Kudu build engine test"
};

export const languages: ILanguageDefinitionCollection = {
  en: {
    displayName: "English",
    strings: EnglishStrings
  }
};

export function translate(key : keyof typeof EnglishStrings, values?: {[key: string]: string}) {
  let value = languages.en.strings[key];

  if (values) {
    value = Object.keys(values).reduce((value, key) => {
      return value.replace(`{{${key}}}`, values[key]).replace(`{{ ${key} }}`, values[key]);
    }, value);
  }

  return value;
}
