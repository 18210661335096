
import React from 'react';
import {graphql} from 'react-relay';
import { useFragment } from 'react-relay';

import {translate} from '@app/i18n';
import {Form, InputField, FormError, FormSuccess, Select} from '@components/Form';
import Button from '@components/Button';
import VATField from './VATField';

import './Apply.css';
import NemIdLoginButton from './NemIdLoginButton';
import useEnvironment from '@app/hooks/useEnvironment';
import { Apply_MitID_tenant$key } from './__generated__/Apply_MitID_tenant.graphql';
import { Apply_MitID_identityProvider$key } from './__generated__/Apply_MitID_identityProvider.graphql';
import useMutation from '@app/hooks/useMutation';
import { ApplyMutation } from './__generated__/ApplyMutation.graphql';

const CompanyTypes = ["private", "public"] as const;

function encode(str: string) {
  return str.replace(/ /g, '%20').replace(/\n/g, '%0D%0A');
}

export type DanishMitIDApplyValues = Omit<ApplyMutation["variables"]["input"], 'tenantId' | 'environment'>
type Props = {
  tenant: Apply_MitID_tenant$key
  identityProvider: Apply_MitID_identityProvider$key
}
export default function DKMitIDProviderApply(props: Props) {
  const environment = useEnvironment();

  const tenant = useFragment(
    graphql`
      fragment Apply_MitID_tenant on Tenant {
        id
        name
        longTenantId
      }
    `,
    props.tenant
  );

  const identityProvider = useFragment(
    graphql`
      fragment Apply_MitID_identityProvider on DanishMitID {
        kyc {
          vatCountry
          vatNumber
          companyName
        }
      }
    `,
    props.identityProvider
  );

  /**
   * Mutation explicitely does not update local store, as it usually takes 15-20 minutes before it works, and we want to display a message to this effect.
   */
  const [{executePromise}] = useMutation<ApplyMutation>(graphql`
    mutation ApplyMutation(
      $input: SetupDanishMitIDInput!
    ) {
      setupDanishMitID(input: $input) {
        id
        isConfigured
      }
    }
  `);

  const supportSubject = encode(`${tenant.name} requires assistance with MitID application`);
  const supportBody = `Hi Criipto
%0D%0A
%0D%0AI want to apply for Danish MitID but I cannot authenticate with NemID for employees, as our company is not registered in Denmark.
%0D%0A
%0D%0ACompany Name:
%0D%0AVAT Country:
%0D%0AVAT Number:
%0D%0A
%0D%0AMy tenant ID is: ${tenant.longTenantId}`;

  const requireLogin = environment === 'PRODUCTION' && !identityProvider.kyc;

  const initialValues : DanishMitIDApplyValues = {
    vatCountry: identityProvider.kyc?.vatCountry ?? 'DK',
    vatNumber: identityProvider.kyc?.vatNumber ?? '',
    companyName: identityProvider.kyc?.companyName ?? '',
    alias: "",
    companyType: CompanyTypes[0],
    contactEmail: "",
    contactName: "",
    bearerToken: "",
  };

  return (
    <Form<DanishMitIDApplyValues>
      key={environment}
      initialValues={initialValues}
      onSubmit={async (values : DanishMitIDApplyValues) => {
        await executePromise({
          input: {
            ...values,
            environment,
            tenantId: tenant.id
          }
        })
      }}
      data-test-id="form"
    >
      {({values, isPending, error, errors, isSuccess}) => (
        <div className="max-w-[750px]">
          <h3>
              Apply for Danish MitID
          </h3>
          <p>
            To enable MitID please fill out the form, this will set you up as a service provider in the MitID system and you can start accepting logins after 15-20 minutes.
          </p>
          {(requireLogin && !values.vatNumber) ? (
            <React.Fragment>
              <NemIdLoginButton acrValue="urn:grn:authn:dk:mitid:business">
                Login with MitID Erhverv to start your application
              </NemIdLoginButton>
              <p style={{marginTop: "15px"}}>
                MitID login will open in a popup.
              </p>
              <p style={{marginTop: "15px"}}>
                If your company is not registered in Denmark, please <a href={`mailto:support@criipto.com?subject=${supportSubject}&body=${supportBody}`}>contact our support team</a>.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="row" style={{marginTop: "25px"}}>
                <div className="col-sm-6">
                  <InputField<DanishMitIDApplyValues>
                    type="text"
                    label={translate('LABEL_DK_MITID_COMPANY_NAME')}
                    name="companyName"
                    required
                    disabled={requireLogin || !!identityProvider.kyc}
                  />
                </div>
                <div className="col-sm-6">
                  <InputField<DanishMitIDApplyValues>
                    type="text"
                    label={translate('LABEL_DK_MITID_COMPANY_ALIAS')}
                    name="alias"
                    required
                    help={<small className="form-text text-muted">{translate('HINT_DK_MITID_COMPANY_ALIAS')}</small>}
                  />
                </div>
              </div>
              <div className="row" style={{marginTop: "25px"}}>
                  <div className="col-sm-6">
                    <Select<DanishMitIDApplyValues>
                      name="companyType"
                      label={translate('LABEL_DK_MITID_COMPANY_TYPE')}
                      options={CompanyTypes.map(type => ({label: type, value: type}))}
                      help={values.companyType === 'public' ? <small className="form-text text-warning">Only government/public sector organizations can register as a public service provider, if you are unsure, please contact support before submitting.</small> : null}
                    />
                  </div>
                  <div className="col-sm-6">
                    <VATField disabled={requireLogin || !!identityProvider.kyc} />
                  </div>
              </div>

              <div className="row" style={{marginTop: "25px"}}>
                <div className="col-sm-6">
                  <InputField<DanishMitIDApplyValues>
                    type="email"
                    label={translate('LABEL_DK_MITID_CONTACT_EMAIL')}
                    name="contactEmail"
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <InputField<DanishMitIDApplyValues>
                    type="text"
                    label={translate('LABEL_DK_MITID_CONTACT_NAME')}
                    name="contactName"
                    required
                  />
                </div>
              </div>

              <div style={{marginTop: "25px"}}>
                <FormError error={error} />
                <FormSuccess message={!isPending && isSuccess && `Thank you! Expect a 15-20 minute delay before you can start testing the MitID login setup`} />
                <div className="flex flex-end mt-[25px]">
                  <Button variant="primary" working={isPending} disabled={isSuccess} type="submit">Apply</Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </Form>
  )
}
