import React, { useEffect, useState } from 'react';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {singleton as config} from '@app/config';
import {
  Elements
} from '@stripe/react-stripe-js';

let globalStripePromise : Promise<Stripe | null> | null = null;

export function StripeElementsProvider(props: {children: React.ReactNode}) {
  const [stripePromise, setStripePromise] = useState(globalStripePromise);

  useEffect(() => {
    globalStripePromise = globalStripePromise || loadStripe(config.STRIPE_PK);
    if (!stripePromise) setStripePromise(globalStripePromise);
  }, [stripePromise]);

  return (
    <Elements stripe={stripePromise}>
      {props.children}
    </Elements>
  );
}