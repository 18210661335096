import React, {useState} from 'react';

import {useSelector} from '@app/redux';
import {translate} from '@app/i18n';
import { Environment } from '@app/models';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  children?: React.ReactNode,
  environment: Environment,
  heading?: React.ReactNode
}

export default function EnvironmentDialog(props : Props) {
  const [open, setOpen] = useState(true);
  const environment = useEnvironment();

  if (environment !== props.environment || !open) return null;

  const heading = !props.heading && props.environment === "TEST" ?
    <React.Fragment><h1><strong>{translate('HINT_ID_SERVICE_CONFIG_ALL_SET')}</strong><br />{translate('HINT_ID_SERVICE_CONFIG_NOT_NEEDED')}</h1></React.Fragment> :
    props.heading

  return (
    <div className="test-env-message">
      <section >
        <i className="fa fa-times fa-lg close-dialog" onClick={() => setOpen(false)} />
        <div className="panel-heading">
          {heading}
        </div>
      </section>
      {props.children}
    </div>
  )
}
