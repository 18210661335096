/**
 * @generated SignedSource<<827face9449692c7c79429f8b32379ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type UpdateStylingInput = {
  cssOrigins: ReadonlyArray<string>;
  cssUrl?: string | null;
  environment: Environment;
  tenantId: string;
  viewVersion?: ViewVersion | null;
};
export type StylingScreenGeneralMutation$variables = {
  environment: Environment;
  input: UpdateStylingInput;
};
export type StylingScreenGeneralMutation$data = {
  readonly updateStyling: {
    readonly tenant: {
      readonly styling: {
        readonly cssOrigins: ReadonlyArray<string>;
        readonly cssUrl: string | null;
        readonly viewVersion: {
          readonly editable: boolean;
          readonly version: ViewVersion;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"PreviewsScreen_tenant">;
    };
  };
};
export type StylingScreenGeneralMutation = {
  response: StylingScreenGeneralMutation$data;
  variables: StylingScreenGeneralMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "Variable",
  "name": "environment",
  "variableName": "environment"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cssUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "TenantStyling",
  "kind": "LinkedField",
  "name": "styling",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cssOrigins",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantStylingViewVersion",
      "kind": "LinkedField",
      "name": "viewVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StylingScreenGeneralMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateStylingOutput",
        "kind": "LinkedField",
        "name": "updateStyling",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "PreviewsScreen_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StylingScreenGeneralMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateStylingOutput",
        "kind": "LinkedField",
        "name": "updateStyling",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "DomainConnection",
                "kind": "LinkedField",
                "name": "domains",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "environment"
                ],
                "handle": "connection",
                "key": "tenant_domains",
                "kind": "LinkedHandle",
                "name": "domains"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUI",
                "kind": "LinkedField",
                "name": "ui",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantUIVerify",
                    "kind": "LinkedField",
                    "name": "verify",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUIVerifyVersion",
                        "kind": "LinkedField",
                        "name": "draft",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantUIFile",
                            "kind": "LinkedField",
                            "name": "html",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "etag",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contents",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "scriptUrl",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adf450cd542d7baf996ab8f624bae5d2",
    "id": null,
    "metadata": {},
    "name": "StylingScreenGeneralMutation",
    "operationKind": "mutation",
    "text": "mutation StylingScreenGeneralMutation(\n  $input: UpdateStylingInput!\n  $environment: Environment!\n) {\n  updateStyling(input: $input) {\n    tenant {\n      styling(environment: $environment) {\n        cssUrl\n        cssOrigins\n        viewVersion {\n          version\n          editable\n        }\n      }\n      ...PreviewsScreen_tenant_2bptcK\n      id\n    }\n  }\n}\n\nfragment PreviewsScreen_tenant_2bptcK on Tenant {\n  domains(first: 1000, environment: $environment) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ui {\n    verify {\n      draft {\n        html {\n          etag\n          contents\n        }\n        scriptUrl\n        cssUrl\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0dc6d4a2c29848a7a9a21cdd757a4a3";

export default node;
