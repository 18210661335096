import React, {useEffect, useState} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';
import { sortBy } from 'lodash';
import {singleton as config} from '@app/config';

import {Switch, Route, useRouteMatch, Link, useHistory, NavLink} from 'react-router-dom';

import {translate} from '@app/i18n';
import {Domain, TenantRouteParams} from '@app/models';

import {LinkButton} from '@app/components/Button/Button';
import Alert from '@app/components/Alert/Alert';

import DeleteDomainButton from './components/DeleteDomainButton';
import DomainCreater, { DomainType } from './components/DomainCreater';

import LogsScreen from '../LogsScreen/LogsScreen';
import AnalyticsScreen from '../AnalyticsScreen/AnalyticsScreen';
import DomainTokenSigningKeysSection from './components/DomainEditor/Sections/TokenSigningKeys';
import DomainSSOSection from './components/DomainEditor/Sections/SSO';
import useEnvironment from '@app/hooks/useEnvironment';
import DomainSettingsSection from './components/DomainEditor/Sections/Settings';
import { DomainsScreenQuery } from './__generated__/DomainsScreenQuery.graphql';
import { DomainsScreenDomainQuery } from './__generated__/DomainsScreenDomainQuery.graphql';
import DomainApplications from './components/DomainApplications';
import { DomainsScreenCreateQuery } from './__generated__/DomainsScreenCreateQuery.graphql';
import Screen from '@app/components/Screen/Screen';

export function DomainsScreenSkeleton(props: {children: React.ReactElement}) {
  const match = useRouteMatch<TenantRouteParams>();

  return (
    <React.Fragment>
      <DomainsScreenHeader />
      <div className="app-content-main">
        <div className="accordion">
          {props.children}

          <div className="accordion-item ">
            <div className="accordion-header large">
              <div className="row">
                <div className="col-xs-12 flex flex-end">
                  <LinkButton variant="primary" to={`${match.url}/add`} className="button-icon">
                    <i className="fa fa-plus"></i>
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

function DomainsScreenHeader() {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  return (
    <div className="app-content-header flex align-items-center justify-content-between">
        <h1>Domains</h1>
        <div>
          <LinkButton variant="primary" to={`${match.url}/add`}>
            <i className="fa fa-plus"></i>&nbsp;Add {environment.toLowerCase()} domain
          </LinkButton>
        </div>
      </div>
  );
}

export function DomainsScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<DomainsScreenQuery>(
    graphql`
      query DomainsScreenQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                environment
                name

                ...DeleteDomainButton_domain
              }
            }
          }
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  if (!data.tenant) return null;
  const domains = data.tenant.domains;

  // Render dummy accordion (actually uses links)
  return (
    <DomainsScreenSkeleton>
      <React.Fragment>
        <div className="accordion-header">
          <div className="row">
            <div className="col-xs-9">
              <h3>{environment === 'PRODUCTION' ? translate('PRODUCTION_DOMAINS') : translate('TEST_DOMAINS')}</h3>
            </div>
            <div className="col-xs-3">

            </div>
          </div>
        </div>

        {sortBy(domains.edges, edge => edge.node.name).map(edge => (
          <div className="accordion-item" key={edge.node.name}>
            <Link to={`${match.url}/${edge.node.name}`} className="accordion-header clickable">
              <div className="row">
                <div className="col-xs-9">
                  <strong>{edge.node.name}</strong>
                </div>
                <div className="col-xs-3" onClick={(e) => e.stopPropagation() /* Fix react-portal click propagation */}>
                  <div className="button-group flex-end">
                    <DeleteDomainButton domain={edge.node} className="button-small" autoWidth />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </React.Fragment>
    </DomainsScreenSkeleton>
  )
}

export function EditDomainScreen(props : {parentUrl: string}) {
  const history = useHistory();
  const match = useRouteMatch<TenantRouteParams & {domain: string}>();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<DomainsScreenDomainQuery>(graphql`
    query DomainsScreenDomainQuery($id: ID!) {
      domain(id: $id) {
        id
        name
        environment

        ... DeleteDomainButton_domain
      }
    }
  `, {
    id: btoa(`domain:${match.params.tenantId}|${match.params.domain}`)
  });

  const domain = data.domain;
  if (!domain) return null;

  useEffect(() => {
    if (!domain) return;
    if (environment !== domain.environment) {
      history.push(props.parentUrl);
    }
  }, [environment, domain]);

  const handleDelete = () => {
    history.push(props.parentUrl);
  };

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Domains</Link>
          <i className="fa fa-angle-right" />
          <div>Domain Details</div>
        </div>

        <div className="flex justify-between">
          <h1>{domain.name}</h1>
          <div className="flex flex-row items-center gap-[10px]">
            <DeleteDomainButton onDelete={handleDelete} domain={domain} autoWidth />
          </div>
        </div>

        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={match.url} exact={true} activeClassName="active">Settings</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/sso`} exact={true} activeClassName="active">SSO</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/keys`} exact={true} activeClassName="active">Keys</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/applications`} exact={true} activeClassName="active">Applications</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/logs`} exact={true} activeClassName="active">Logs</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/analytics`} exact={true} activeClassName="active">Analytics</NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route path={match.path} exact={true}>
          <div className="app-tab-content">
            <React.Suspense fallback={<Screen.Loader/>}>
              <DomainSettingsSection tenantId={match.params.tenantId} domain={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
        <Route path={`${match.path}/sso`}>
          <div className="app-tab-content">
            <React.Suspense fallback={<Screen.Loader/>}>
              <p>
                Configure your domains SSO settings per protocol.
              </p>
              <p>
                You can also disable SSO for OAuth2 requests by using <code>prompt=login</code>
              </p>
              <DomainSSOSection tenantId={match.params.tenantId} domain={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
        <Route path={`${match.path}/keys`}>
          <div className="app-tab-content">
            <React.Suspense fallback={<Screen.Loader/>}>
              <p>
                Here, you can manage the active token signing key for your Criipto Verify domain.
                The token signing key is trusted by your application.
              </p>
              <p>
                Sometimes, depending on the platform your application is built on, it is pre-registered there.<br />
                In those cases, you must register new token signing keys in your applications trust - store before activating a new key.
                Otherwise, your application will not be able to validate the tokens signed by the new keys.
              </p>
              <p>
                These keys are also published on the <a href={`https://${match.params.domain}/.well-known/jwks`} target="_blank" rel="noopener noreferrer" className="text-underline">jwks metadata endpoint</a>
              </p>
              <DomainTokenSigningKeysSection tenantId={match.params.tenantId} domain={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
        <Route path={`${match.path}/applications`}>
          <div className="app-tab-content">
            <React.Suspense fallback={<Screen.Loader/>}>
              <DomainApplications tenantId={match.params.tenantId} domain={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
        <Route path={`${match.path}/logs`}>
          <div className="app-tab-content">
            <React.Suspense fallback={<Screen.Loader/>}>
              <LogsScreen domainName={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
        <Route path={`${match.path}/analytics`}>
          <div className="app-tab-content full-screen">
            <React.Suspense fallback={<Screen.Loader/>}>
              <AnalyticsScreen domainName={match.params.domain} />
            </React.Suspense>
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export function CreateDomainScreen(props : {parentUrl: string}) {
  const history = useHistory();
  const environment = useEnvironment();
  const match = useRouteMatch<TenantRouteParams>();
  const [domainType, setDomainType] = useState<DomainType>('CRIIPTO')

  const data = useLazyLoadQuery<DomainsScreenCreateQuery>(graphql`
    query DomainsScreenCreateQuery($id: ID!, $environment: Environment!) {
      tenant(id: $id) {
        viewerPermissions {
          domains(environment: $environment)
        }

        features {
          CUSTOM_DOMAINS(environment: $environment)
        }
      }
    }
  `, {
    id: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  if (data.tenant?.viewerPermissions.domains !== 'WRITE') {
    return (
      <Alert variant="error" className="mt-[15px]" title="Access Restriction" message="You do not have permissions to access this feature." />
    );
  }

  const handleSave = (domain? : {name: string}) => {
    if (!domain) return history.push(props.parentUrl);
    history.push(props.parentUrl + `/${domain.name}`);
  };

  const handleCancel = () => {
    history.push(props.parentUrl);
  };

  if (!data.tenant) return null;

  const testCustomDisabled = !data.tenant.features.CUSTOM_DOMAINS && environment === 'TEST';

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Domains</Link>
          <i className="fa fa-angle-right" />
          <div>Add new domain</div>
        </div>

        <h1>Add new domain</h1>
      </div>
      <div className="app-content-main flex flex-col gap-16">
        <div>
          <p className="!m-0">
            Domains are what you use to execute authorize requests against in authentication scenarios, where it also acts as the issuer of credentials.
          </p>
          <p className="!m-0 !mt-4">
            The domain will be shown in the browser in certain cases, like Swedish BankID login, the signing UI or the auth method selector page.
          </p>
          <p className="!m-0 !mt-4">
            If in doubt, simply start with a <em>{config.easyIdDns} domain</em>.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-[48px] px-[48px] max-w-[750px]">
          <div
            onClick={() => setDomainType('CRIIPTO')}
            className={`text-center flex flex-col items-center justify-between cursor-pointer p-[15px] bg-white border ${domainType === 'CRIIPTO' ? 'border-primary-900' : 'border-grey hover:border-primary-700'}`}
          >
            <div className="flex flex-col items-center">
              <div className="mb-4 h-[100px] w-[120px] bg-zinc-200 flex flex-row align-center items-center justify-center gap-2 rounded-lg" style={{fontSize: '40px'}}>
                <i className="fa fa-check" />
                <i className="fa fa-cloud" />
              </div>
              <strong>{config.easyIdDns} domain</strong>
            </div>
            <p className="text-xl text-zinc-500">
              Fastest way to get started.<br />
              No hassle. No management required.
            </p>
          </div>
          <div
            onClick={() => testCustomDisabled ? null : setDomainType('CLIENT')}
            className={`
              text-center flex flex-col items-center justify-between ${testCustomDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} p-[15px] bg-white border
              ${domainType === 'CLIENT' ? 'border-primary-900' : !testCustomDisabled ? 'border-grey hover:border-primary-700' : null}
              relative
            `}
          >
            <div className="flex flex-col items-center">
              <div className="mb-4 h-[100px] w-[120px] bg-zinc-200 flex flex-row align-center items-center justify-center gap-2 rounded-lg" style={{fontSize: '40px'}}>
                <i className="fa fa-cog" />
                <i className="fa fa-cloud" />
              </div>
              <strong>custom domain</strong>
            </div>
            <p className="text-xl text-zinc-500">
              Also called vanity domains, allows you to setup a domain with a custom top-level domain, such as <em>auth.mycompany.com</em>.
            </p>
            {testCustomDisabled ? (
              <div className="
                absolute inset-0 flex pt-4
                text-2xl text-white bg-zinc-800/75
              ">
                <p>Custom domain are available in test per request, please contact support.</p>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className="form-group">
            <label className="control-label" style={{fontSize: '18px'}}>
              <strong>Domain settings</strong>
            </label>
          </div>
          <DomainCreater onSave={handleSave} onCancel={handleCancel} domainType={domainType} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default function DomainRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <React.Suspense
          fallback={
            <>
              <DomainsScreenHeader />
              <Screen.Loader/>
            </>
          }
        >
          <DomainsScreen />
        </React.Suspense>
      </Route>
      <Route path={`${match.path}/add`}>
        <React.Suspense
            fallback={
              <>
                <DomainsScreenHeader />
                <Screen.Loader/>
              </>
            }
          >
          <CreateDomainScreen parentUrl={match.url} />
        </React.Suspense>
      </Route>
      <Route path={`${match.path}/:domain`}>
        <React.Suspense
            fallback={
              <>
                <DomainsScreenHeader />
                <Screen.Loader/>
              </>
            }
          >
          <EditDomainScreen parentUrl={match.url} />
        </React.Suspense>
      </Route>
    </Switch>
  )
}
