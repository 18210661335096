import { useDispatch } from "@app/redux";
import * as toast from "@app/redux/toastSlice";
import { useMemo } from "react";

export interface Toast {
  success: (props: Parameters<typeof toast.success>[0]) => void,
  error: (props: Parameters<typeof toast.error>[0]) => void,
  dismiss: (props: Parameters<typeof toast.dismiss>[0]) => void,
}

export default function useToast() {
  const dispatch = useDispatch();

  return useMemo(() => ({
    success: (props: Parameters<typeof toast.success>[0]) => {
      dispatch(toast.success(props))
    },
    error: (props: Parameters<typeof toast.error>[0]) => {
      dispatch(toast.error(props))
    },
    dismiss: (props: Parameters<typeof toast.dismiss>[0]) => {
      dispatch(toast.dismiss(props))
    },
  }), [dispatch]);
}