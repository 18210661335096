/**
 * @generated SignedSource<<1ed71c1e253200239cfdaa25dbe77e8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAdminOrganizationInput = {
  kyc?: OrganizationKYCInput | null;
  name: string;
  organizationId: string;
  settings: AdminOrganizationSettingsInput;
};
export type AdminOrganizationSettingsInput = {
  automatic_production_access_signatures: boolean;
  automatic_production_access_verify: boolean;
};
export type OrganizationKYCInput = {
  companyName: string;
  vatCountry: string;
  vatNumber: string;
};
export type OrganizationSettingsScreen_Mutation$variables = {
  input: UpdateAdminOrganizationInput;
};
export type OrganizationSettingsScreen_Mutation$data = {
  readonly admin_updateOrganization: {
    readonly adminOrganization: {
      readonly kyc: {
        readonly companyName: string;
        readonly vatCountry: string;
        readonly vatNumber: string;
      } | null;
      readonly settings: {
        readonly automatic_production_access_signatures: boolean;
        readonly automatic_production_access_verify: boolean;
      };
    };
    readonly organization: {
      readonly name: string;
    };
  };
};
export type OrganizationSettingsScreen_Mutation = {
  response: OrganizationSettingsScreen_Mutation$data;
  variables: OrganizationSettingsScreen_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminOrganizationSettings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automatic_production_access_verify",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automatic_production_access_signatures",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationKYC",
  "kind": "LinkedField",
  "name": "kyc",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminOrganizationOutput",
        "kind": "LinkedField",
        "name": "admin_updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "adminOrganization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsScreen_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminOrganizationOutput",
        "kind": "LinkedField",
        "name": "admin_updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminOrganization",
            "kind": "LinkedField",
            "name": "adminOrganization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63a06564c317ea3f8a9894133549ac8f",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsScreen_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsScreen_Mutation(\n  $input: UpdateAdminOrganizationInput!\n) {\n  admin_updateOrganization(input: $input) {\n    organization {\n      name\n      id\n    }\n    adminOrganization {\n      settings {\n        automatic_production_access_verify\n        automatic_production_access_signatures\n      }\n      kyc {\n        companyName\n        vatCountry\n        vatNumber\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "380aab2ca4c0aebe4b4969ef208913d2";

export default node;
