/**
 * @generated SignedSource<<2644be79472cb2f52b562a0633adfae4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignaturesWebhooks_application$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SignaturesWebhooks_LogEnty_application">;
  readonly " $fragmentType": "SignaturesWebhooks_application";
};
export type SignaturesWebhooks_application$key = {
  readonly " $data"?: SignaturesWebhooks_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignaturesWebhooks_application">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignaturesWebhooks_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SignaturesWebhooks_LogEnty_application"
    }
  ],
  "type": "Signatures_Application",
  "abstractKey": null
};

(node as any).hash = "ce8ac67a0221d9e0999e72fa31d878b9";

export default node;
