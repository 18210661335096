import React, { useState } from 'react';
import {graphql} from 'react-relay';

import InputField from '@app/components/FormFields/InputField/InputField';
import RadioButton from '@app/components/FormFields/RadioButton/RadioButton';
import { ConfirmModal, ConfirmModalProps } from '@app/components/Modal/Modal';
import moment from 'moment';
import { useFragment } from 'react-relay';
import { CancelSubscriptionModal_tenant$key } from './__generated__/CancelSubscriptionModal_tenant.graphql';
import useMutation from '@app/hooks/useMutation';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

const CUSTOM_REASON = 'Other reason';
const REASONS = [
  'We no longer need e-ID services',
  'We are switching to another provider',
  CUSTOM_REASON
];

interface Props {
  open: ConfirmModalProps["open"]
  onCancel: ConfirmModalProps["onCancel"],
  tenant: CancelSubscriptionModal_tenant$key
}
export default function CancelSubscriptionModal(props: Props) {
  const {open, onCancel} = props;
  const toast = useToast();

  const tenant = useFragment(
    graphql`
      fragment CancelSubscriptionModal_tenant on Tenant {
        billing {
          subscription {
            id
            status
            periodEndAt
          }
        }
      }
    `,
    props.tenant
  )

  const [executor, status] = useMutation(
    graphql`
      mutation CancelSubscriptionModalMutation($input: CancelSubscriptionInput!) {
        cancelSubscription(input: $input){
          subscription {
            status
            isCancelling
          }
        }
      }
    `
  )
  const [selectedReason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');

  const handleConfirm = async () => {
    if (!tenant?.billing?.subscription) return;
    const reason = selectedReason === CUSTOM_REASON ? customReason : selectedReason;
    await executor.executePromise({
      input: {
        subscriptionId: tenant.billing.subscription.id,
        reason
      }
    });

    toast.success({title: 'Subscription cancelled', message: `Subscription ends on ${moment(subscription.periodEndAt).format('MMMM Do, YYYY')}.`});
    onCancel();
  };

  if (!tenant.billing) return null;
  if (!tenant.billing.subscription) return null;
  const subscription = tenant.billing.subscription;
  return (
    <ConfirmModal
      onConfirm={handleConfirm}
      open={open}
      onCancel={onCancel}
      confirmText="Yes, cancel my subscription"
      confirmVariant="danger"
      cancelText="No, nevermind"
    >
      <div>
        <h2>Cancel Criipto subscription</h2>
        <p>Are you sure you wish to cancel your subscription?</p>
        <p>After <strong>{moment(subscription.periodEndAt).format('MMMM Do, YYYY')}</strong> you will no longer be able to accept logins from real users.</p>

        <div className="mt-8">
          <strong className='text-light-blue-900'>If you don't mind, we would love to hear why you are cancelling:</strong>
          {REASONS.map(reason => (
            <div key={reason} className="flex flex-row items-center gap-[8px]" onClick={() => setReason(reason)}>
              <RadioButton className="!mt-1" label={reason} name="reason" checked={reason === selectedReason} onChange={() => {}} />
            </div>
          ))}

          {selectedReason === CUSTOM_REASON ? (
            <InputField name="reason-for-canceling" placeholder="Please type your reason for cancelling" type="text" className="mt-4" value={customReason} onChange={(event) => setCustomReason(event.target.value)}/>
          ) : null}
        </div>
      </div>

      {status.error ? (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} />
      ) : null}
    </ConfirmModal>
  )
}