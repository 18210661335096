/**
 * @generated SignedSource<<16f5c9099232ab9663039a46c1b21d7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductBillable_tenant$data = {
  readonly billable: {
    readonly Ageverification: {
      readonly dk: {
        readonly actual: boolean;
        readonly after: string;
        readonly before: string;
        readonly enabled: boolean;
      };
    };
    readonly Signatures: {
      readonly actual: boolean;
      readonly after: string;
      readonly before: string;
      readonly enabled: boolean;
    };
    readonly Verify: {
      readonly actual: boolean;
      readonly after: string;
      readonly before: string;
      readonly enabled: boolean;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "ProductBillable_tenant";
};
export type ProductBillable_tenant$key = {
  readonly " $data"?: ProductBillable_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductBillable_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actual",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductBillable_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminTenantBillable",
      "kind": "LinkedField",
      "name": "billable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantBillableProduct",
          "kind": "LinkedField",
          "name": "Verify",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantBillableProduct",
          "kind": "LinkedField",
          "name": "Signatures",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantAgeverificationBillableProduct",
          "kind": "LinkedField",
          "name": "Ageverification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminTenantBillableProduct",
              "kind": "LinkedField",
              "name": "dk",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};
})();

(node as any).hash = "b1ade9ae540a8c819b023d01c69ea1b2";

export default node;
