import React, { useReducer } from 'react';

import { useFragment } from 'react-relay';
import {graphql} from 'react-relay';
import CopyApplicationModal from '../CopyApplicationModal';
import Button, { ButtonProps } from '@app/components/Button/Button';
import { CopyApplicationButton_application$key } from './__generated__/CopyApplicationButton_application.graphql';

type Props = {
  application: CopyApplicationButton_application$key
  variant: ButtonProps["variant"],
  className?: string
}

export default function CopyApplicationButton(props: Props) {
  const [showModal, toggleModal] = useReducer(value => !value, false);

  const application = useFragment(graphql`
    fragment CopyApplicationButton_application on Application {
      id
      tags
      ...CopyApplicationModal_application
    }
  `, props.application);

  if (application.tags?.includes('auth0') || application.tags?.includes('signatures')) return null;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    toggleModal();
  }

  const handleHide = () => {
    toggleModal();
  }

  return (
    <React.Fragment>
      <Button tooltip="Copy application" onClick={handleClick} variant={props.variant} className={props.className} icon={<i className="fa fa-copy" />} />
      {showModal && (
        <React.Suspense fallback={null}>
          <CopyApplicationModal
            key={`copy_application_modal_${application.id}`}
            application={application}
            show={showModal}
            onHide={handleHide}
          />
        </React.Suspense>
      )}
    </React.Fragment>
  )
}