import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import {graphql} from 'react-relay';

import { CustomerRendition } from '@app/api/subscription';
import { Form, FormError, FormSuccess } from '@app/components/Form';
import Button from '@app/components/Button';

import { Link, useRouteMatch } from 'react-router-dom';
import { TenantBillingScreenQuery } from './__generated__/TenantBillingScreenQuery.graphql';
import { TenantBillingScreenMutation, Country } from './__generated__/TenantBillingScreenMutation.graphql';
import useMutation from '@app/hooks/useMutation';
import { TenantRouteParams } from '@app/models';
import BillingInformationFields from '@app/screens/BillingScreen/screens/BillingInformationScreen/components/BillingInformationFields/BillingInformationFields';

export default function TenantBillingScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const data = useLazyLoadQuery<TenantBillingScreenQuery>(
    graphql`
      query TenantBillingScreenQuery ($tenantId: ID!) {
        admin {
          tenant(id: $tenantId) {
            id

            stripe {
              customerId
            }

            tenant {
              billing {
                information {
                  company
                  email
                  country
                  street
                  city
                  zip
                  taxId
                }
              }
            }
          }
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${match.params.tenantId}`)
    }
  );

  const [mutationExecutor, mutationState] = useMutation<TenantBillingScreenMutation>(graphql`
    mutation TenantBillingScreenMutation($input: UpdateBillingInformationInput!) {
      admin_updateTenantBillingInformation(input: $input) {
        adminTenant {
          stripe {
            customerId
          }
          tenant {
            billing {
              information {
                company
                email
                country
                street
                city
                zip
                taxId
              }
            }
          }
        }

        tenant {
          billing {
            information {
              company
              email
              country
              street
              city
              zip
              taxId
            }
          }
        }
      }
    }
  `);

  if (!data.admin.tenant) return null;

  const handleSubmit = async (values: CustomerRendition) => {
    await mutationExecutor.executePromise({
      input: {
        tenantId: data.admin.tenant!.id,
        company: values.company!,
        email: values.email!,
        country: values.country! as Country,
        street: values.street!,
        city: values.city!,
        zip: values.zip!,
        taxId: values.taxId
      }
    });
  };

  const initialValues : CustomerRendition = {
    company: data.admin.tenant.tenant.billing?.information?.company ?? '',
    email: data.admin.tenant.tenant.billing?.information?.email ?? '',
    country: data.admin.tenant.tenant.billing?.information?.country ?? '',
    street: data.admin.tenant.tenant.billing?.information?.street ?? '',
    city: data.admin.tenant.tenant.billing?.information?.city ?? '',
    zip: data.admin.tenant.tenant.billing?.information?.zip ?? '',
    taxId: data.admin.tenant.tenant.billing?.information?.taxId ?? ''
  }

  return (
    <React.Fragment>
      <div className="row" style={{marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label"><a href={data.admin.tenant.stripe ? `https://manage.stripe.com/customers/${data.admin.tenant.stripe.customerId}` : undefined} target="_blank" rel="noopener noreferrer">Stripe customer ID</a></label>
            <input className="form-control" readOnly={true} defaultValue={data.admin.tenant.stripe?.customerId ?? ''} />
          </div>
        </div>
      </div>
      <Form
        initialValues={initialValues}
        key={`${data.admin.tenant.id}_billing_information`}
        onSubmit={handleSubmit}
        className="billing-information-form max-w-[750px]"
      >
        {({isPending, isSuccess, error, values}) => (
          <React.Fragment>
            <BillingInformationFields values={values} />

            <FormError error={error} />
            <FormSuccess message={!isPending && isSuccess && `Tenant billing information has been updated` || null} />
            <Button variant="primary" working={isPending} type="submit">Update billing information</Button>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
}