/**
 * @generated SignedSource<<70711a05b9dbd37360e269c184a89bdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserPermission = "CONFIGURATION_MANAGEMENT" | "EXTENSIONS_PRODUCTION_READ" | "EXTENSIONS_PRODUCTION_WRITE" | "EXTENSIONS_TEST_READ" | "EXTENSIONS_TEST_WRITE" | "FINANCIALS" | "PRIVATE_KEY_MANAGEMENT" | "REPORTING" | "USER_ADMIN" | "%future added value";
export type UserRole = "Admin" | "Developer" | "%future added value";
export type InviteUserInput = {
  acceptUrl: string;
  email: string;
  name: string;
  permissions?: ReadonlyArray<UserPermission> | null;
  role?: UserRole | null;
  tenantId: string;
};
export type InvitationForm_inviteUser_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: InviteUserInput;
};
export type InvitationForm_inviteUser_Mutation$data = {
  readonly inviteUser: {
    readonly invitationEdge: {
      readonly cursor: string;
      readonly node: {
        readonly email: string;
        readonly id: string;
        readonly name: string;
        readonly permissions: ReadonlyArray<UserPermission>;
      };
    };
  };
};
export type InvitationForm_inviteUser_Mutation = {
  response: InvitationForm_inviteUser_Mutation$data;
  variables: InvitationForm_inviteUser_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "InvitationEdge",
  "kind": "LinkedField",
  "name": "invitationEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invitation",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationForm_inviteUser_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserOutput",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationForm_inviteUser_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserOutput",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "invitationEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8021be06cc9041788c162fba80c87aa",
    "id": null,
    "metadata": {},
    "name": "InvitationForm_inviteUser_Mutation",
    "operationKind": "mutation",
    "text": "mutation InvitationForm_inviteUser_Mutation(\n  $input: InviteUserInput!\n) {\n  inviteUser(input: $input) {\n    invitationEdge {\n      cursor\n      node {\n        id\n        name\n        email\n        permissions\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b92a30088c703baaf68e3296c951d1ed";

export default node;
