/**
 * @generated SignedSource<<2ab14be622c7e3fa5306e39c814dbe66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Country = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type PaymentIntentStatus = "canceled" | "processing" | "requires_action" | "requires_capture" | "requires_confirmation" | "requires_payment_method" | "succeeded" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid" | "%future added value";
export type CreateSubscriptionInput = {
  information?: UpdateBillingInformationInput | null;
  products: ReadonlyArray<SubscriptionProductInput>;
  stripePaymentMethod?: string | null;
  stripePaymentToken?: string | null;
  tenantId: string;
};
export type SubscriptionProductInput = {
  planId: string;
  product: Product;
};
export type UpdateBillingInformationInput = {
  city: string;
  company: string;
  country: Country;
  email: string;
  street: string;
  taxId?: string | null;
  tenantId: string;
  zip: string;
};
export type SignupScreen_Billing_CreateSubscriptionMutation$variables = {
  input: CreateSubscriptionInput;
};
export type SignupScreen_Billing_CreateSubscriptionMutation$data = {
  readonly createSubscription: {
    readonly __typename: "CreateSubscriptionOutput";
    readonly tenant: {
      readonly ageverificationEnvironments: ReadonlyArray<Environment>;
      readonly billing: {
        readonly paymentMethod: {
          readonly brand: string;
          readonly last4: string;
          readonly name: string;
        } | null;
        readonly subscription: {
          readonly isCancelling: boolean;
          readonly periodEndAt: string;
          readonly periodStartAt: string;
          readonly status: SubscriptionStatus;
          readonly " $fragmentSpreads": FragmentRefs<"SubscriptionPlanModal_subscription" | "SubscriptionScreen_ProductPanel_subscription">;
        } | null;
      } | null;
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  } | {
    readonly __typename: "PaymentIntent";
    readonly id: string;
    readonly secret: string;
    readonly status: PaymentIntentStatus;
    readonly subscriptionId: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SignupScreen_Billing_CreateSubscriptionMutation = {
  response: SignupScreen_Billing_CreateSubscriptionMutation$data;
  variables: SignupScreen_Billing_CreateSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelling",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodStartAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodEndAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "kind": "LinkedField",
  "name": "paymentMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last4",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v9 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v10 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v11 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    (v12/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secret",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    }
  ],
  "type": "PaymentIntent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupScreen_Billing_CreateSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Billing",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingSubscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SubscriptionPlanModal_subscription"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SubscriptionScreen_ProductPanel_subscription"
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateSubscriptionOutput",
            "abstractKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupScreen_Billing_CreateSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Billing",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingSubscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "interval",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubscriptionProduct",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Plan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateSubscriptionOutput",
            "abstractKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3546aa21922c5e2704d9c38659baf91",
    "id": null,
    "metadata": {},
    "name": "SignupScreen_Billing_CreateSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation SignupScreen_Billing_CreateSubscriptionMutation(\n  $input: CreateSubscriptionInput!\n) {\n  createSubscription(input: $input) {\n    __typename\n    ... on CreateSubscriptionOutput {\n      tenant {\n        billing {\n          subscription {\n            status\n            isCancelling\n            periodStartAt\n            periodEndAt\n            ...SubscriptionPlanModal_subscription\n            ...SubscriptionScreen_ProductPanel_subscription\n            id\n          }\n          paymentMethod {\n            name\n            brand\n            last4\n          }\n          id\n        }\n        environments\n        verifyEnvironments: environments(product: Verify)\n        signaturesEnvironments: environments(product: Signatures)\n        ageverificationEnvironments: environments(product: [Ageverification_DK])\n        id\n      }\n    }\n    ... on PaymentIntent {\n      id\n      status\n      secret\n      subscriptionId\n    }\n  }\n}\n\nfragment RemoveProductPlanModal_subscription on BillingSubscription {\n  id\n}\n\nfragment SubscriptionPlanModal_subscription on BillingSubscription {\n  id\n  isCancelling\n  currency\n  interval\n  products {\n    plan {\n      id\n    }\n    id\n  }\n}\n\nfragment SubscriptionScreen_ProductPanel_subscription on BillingSubscription {\n  status\n  ...SubscriptionPlanModal_subscription\n  ...RemoveProductPlanModal_subscription\n}\n"
  }
};
})();

(node as any).hash = "71501482b3fc8f0c917d3e68f27a1bf0";

export default node;
