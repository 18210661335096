import React, {useRef, useState, useLayoutEffect} from 'react';

const DOCS_AUTHORITY = 'https://docs.criipto.com';
export default function DocsEmbed(props: {path: string}) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(400);

  let {path} = props;

  if (path.startsWith(DOCS_AUTHORITY)) {
    path = path.replace(DOCS_AUTHORITY, '');
  }
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  useLayoutEffect(() => {
    if (!iframeRef.current) return;
    const iframe = iframeRef.current;

    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== DOCS_AUTHORITY) return;
      if (event.source !== iframe.contentWindow) return;
      if (event.data.startsWith('height:')) {
        const height = parseInt(event.data.replace('height:', ''), 10);
        setHeight(height + 50 /* Leeway */);
      }

    }
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [iframeRef.current]);

  return (
    <iframe ref={iframeRef} src={`${DOCS_AUTHORITY}/_embedded${path}`} style={{width: '100%', border: 0, height: `${height}px`}} />
  );
}