import React, { useEffect, useState } from 'react';
import {graphql} from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';
import { useRouteMatch } from 'react-router-dom';
import { useTenantId } from '@app/hooks/useTenant';
import { technologyTags, technologyToGroup, technologyGroupTags } from '@app/models';
import ConfigurationSection from '@app/components/Configuration/ConfigurationSection';
import TechnologySelector from '../ApplicationsScreen/components/TechnologySelector/TechnologySelector_v2';
import TechnologyGroupSelector_v2 from '@app/screens/ApplicationsScreen/components/TechnologyGroupSelector/TechnologyGroupSelector_v2';
import { CreateDomainComponent } from './components/CreateDomainComponent';
import { CreateAgeVerificationApplicationComponent } from './components/CreateAgeVerificationApplicationComponent';
import { OnboardingAgeVerificationScreenQuery } from './__generated__/OnboardingAgeVerificationScreenQuery.graphql';
import { TENANT_ID_PREFIX } from '@app/constants';
import { sanitizeDomainInput } from '@app/helpers';
import Screen from '@app/components/Screen';
import InputField from '@app/components/FormFields/InputField/InputField';
import Button, { LinkButton } from '@components/Button';
import { TenantRouteParams } from '@app/models';
import useEnvironment from '@app/hooks/useEnvironment';
import { singleton as config } from '@app/config';
import styles from './OnboardingAgeVerificationScreen.module.css';

function useSessionStorageState(key: string, initialValue?: string) {
  const [state, setState] = useState(() => {
    const saved = sessionStorage.getItem(key);
    if (saved) {
      try {
        return JSON.parse(saved);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return initialValue;
      }
    }
    return initialValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default function OnboardingScreenAgeVerification() {
  const tenantId = useTenantId();
  const environment = useEnvironment();
  const routeMatch = useRouteMatch<TenantRouteParams>();

  const [technology, setTechnology] = useSessionStorageState('technology');
  const [technologyGroup, setTechnologyGroup] = useSessionStorageState('technologyGroup');
  const [companyName, setCompanyName] = useSessionStorageState('companyName', '');
  const [companyNameDomain, setCompanyNameDomain] = useState('');

  const data = useLazyLoadQuery<OnboardingAgeVerificationScreenQuery>(
    graphql`
      query OnboardingAgeVerificationScreenQuery($tenantId: ID!, $environment: Environment!, $product: [Product!]!) {
        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
              }
            }
          }

          environments(product: $product)

          ...CreateAgeVerificationApplicationComponent_tenant @arguments(environment: $environment)
          ...CreateDomainComponent_tenant @arguments(environment: $environment)

          onboarding {
            products
            hasDomain(environment: $environment)
            hasApplication(environment: $environment, product: Ageverification_DK)
          }
        }
      }
    `,
    {
      tenantId: btoa(`tenant:${routeMatch.params.tenantId}`),
      environment,
      product: ['Ageverification_DK'],
    }
  );

  const tenant = data.tenant;
  const hasDomain = tenant?.onboarding.hasDomain;
  const hasApplication = tenant?.onboarding.hasApplication;

  const handleSave = (newCompanyName: string) => {
    setCompanyName(newCompanyName);
  };

  const domainPrefix = environment === 'TEST' ? '-test' : null;

  useEffect(() => {
    if (companyName) {
      const updatedCompanyNameDomain = sanitizeDomainInput(companyName, 'CLIENT').replace(/[\s'"/.,;:´!?&]/g, '');
      setCompanyNameDomain(updatedCompanyNameDomain);
    }
  }, [companyName]);

  const showDomainCreator = companyName && companyNameDomain && !hasDomain;
  const showDomainSuccess = (technology && hasDomain && !hasApplication) || (hasDomain && hasApplication);
  const showApplicationCreator = technology && hasDomain && !hasApplication;
  const showApplicationSuccess = hasDomain && hasApplication;

  return (
    <>
      <Screen.Header fullscreen={false}>
        <Screen.Header.Title>
          <h1>Age Verification Onboarding</h1>
        </Screen.Header.Title>
      </Screen.Header>
      <div className="flex flex-col gap-xl">
        {!hasApplication && (
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div className={styles['section-header']}>
                <h3>Which platform are you using for your project?</h3>
                <p>Selecting a technology will help us guide you to the right tutorials and documentation.</p>
              </div>
              <div className="flex flex-col gap-[6px]">
                <TechnologyGroupSelector_v2
                  groups={technologyGroupTags
                    .filter((s) => s !== 'technology-group:native')
                    .slice()
                    .reverse()}
                  group={technologyGroup}
                  onChange={setTechnologyGroup}
                />
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
        )}
        {!hasApplication && technologyGroup ? (
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div className={styles['section-header']}>
                <h3>Which technology are you using for your project?</h3>
                <p>
                  If your technology is not listed, please provide feedback by selecting "Other" and inputting your platform. This helps inform SDK
                  and guide development.
                </p>
              </div>
              <div>
                <TechnologySelector
                  technology={technology}
                  onChange={setTechnology}
                  technologies={technologyTags.filter((t) => technologyToGroup(t) === technologyGroup)}
                />
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
        ) : null}

        {technology && !hasDomain ? (
          <CompanyNameInput
            onSave={handleSave}
            companyName={companyName}
          />
        ) : showDomainSuccess ? (
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div className={styles['section-header']}>
                <h3>
                  Domain created
                  <span>
                    &nbsp;
                    <i className="fas fa-check" />
                  </span>
                </h3>
              </div>
              <div>
                <p className="!text-[14px]">
                  Your domain name: <span className="!font-semibold">{tenant.domains.edges[0].node.name}</span>
                </p>
                <LinkButton className="!text-white !no-underline" variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/domains`}>
                  Domain settings
                </LinkButton>
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
        ) : null}

        {showDomainCreator ? (
          <React.Suspense
            fallback={
              <div className="flex items-center justify-center">
                <i className="fa fa-spinner fa-pulse fa-2x" />
              </div>
            }
          >
            <ConfigurationSection>
              <ConfigurationSection.TwoColumn>
                <div className={styles['section-header']}>
                  <h3>Add your first domain</h3>
                  <p>
                    Everything you do with Criipto requires that you register a <strong>domain</strong> first.
                  </p>
                  <p>
                    <a href="https://docs.criipto.com/verify/getting-started/basics/#domains" target="_blank" rel="noopener noreferrer">
                      A domain
                    </a>
                    &nbsp;is a web address where your Criipto Verify authentication services are hosted.
                  </p>

                  <p>Your domain will be shown in browsers in certain cases, for instance, when users are redirected to your domain to log in with an eID.</p>

                  {companyName && (
                    <p className="!font-semibold">
                      You suggested domain name is: {companyNameDomain}
                      {domainPrefix}.{config.easyIdDns}
                    </p>
                  )}
                </div>
                {tenant && <CreateDomainComponent initialValue={companyNameDomain} tenant={tenant}/>}
              </ConfigurationSection.TwoColumn>
            </ConfigurationSection>
          </React.Suspense>
        ) : null}

        {showApplicationCreator ? (
          <React.Suspense
            fallback={
              <div className="flex items-center justify-center">
                <i className="fa fa-spinner fa-pulse fa-2x" />
              </div>
            }
          >
            <ConfigurationSection>
              <ConfigurationSection.TwoColumn>
                <div className={styles['section-header']}>
                  <h3>Add new age verification application</h3>
                </div>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <CreateAgeVerificationApplicationComponent
                    tenant={tenant}
                    technology={technology}
                    technologyGroup={technologyGroup}
                  />
                </React.Suspense>
              </ConfigurationSection.TwoColumn>
            </ConfigurationSection>
          </React.Suspense>
        ) : showApplicationSuccess ? (
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div className={styles['section-header']}>
                <h3>
                  Application created
                  <span>
                    &nbsp;
                    <i className="fas fa-check" />
                  </span>
                </h3>
              </div>
              <div className={styles['application-section']}>
                <LinkButton className="!text-white !no-underline" variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/applications`}>
                  Application settings
                </LinkButton>
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
        ) : null}
        {showApplicationSuccess ? (
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div className={styles['section-header']}>
                <h3>You're all set</h3>
                <p>You've completed the setup and can now proceed with the implementation.</p>
              </div>
              <div className={styles['final-section']}>
                <div className="flex-1">
                  <p className="!text-[14px] !mb-md">
                    For developers, the next step is to consult the integration guide for your technology, available under the <strong>Integrate</strong> section of your <strong>Age Verification Application</strong>.
                  </p>
                  <LinkButton className="!text-white !no-underline" variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/applications`}>
                    Start integration
                  </LinkButton>
                </div>
                <div className="flex-1">
                  <p className="!text-[14px] !mb-md">If you are not a developer, or if technical integration will be handled by someone else, you can invite them to your Criipto Tenant.</p>
                  <LinkButton className="!text-white !no-underline" variant="primary" to={`/tenant/${tenantId.tenantId.replace(TENANT_ID_PREFIX, '')}/users`}>
                    Invite team member
                  </LinkButton>
                </div>
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
        ) : null}
      </div>
    </>
  );
}

interface CompanyNameInputProps {
  onSave: (newCompanyName: string) => void;
  companyName: string;
}

export function CompanyNameInput({ onSave, companyName }: CompanyNameInputProps) {
  const [companyNameInput, setCompanyNameInput] = useState(companyName || '');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNameInput(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSave(companyNameInput);
  };

  return (
    <ConfigurationSection>
      <ConfigurationSection.TwoColumn>
        <div className={styles['section-header']}>
          <h3>What is your company name?</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <InputField
            name="companyName"
            value={companyNameInput}
            onChange={handleInputChange}
            button={
              <Button variant="primary" type="submit">
                Save
              </Button>
            }
          />
        </form>
      </ConfigurationSection.TwoColumn>
    </ConfigurationSection>
  );
}
